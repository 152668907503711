import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";

import * as API from "../../../utils/api.js";
import { hidePlannerAssignment } from "../../../actions/index.js";

const PlannerAssignment = ({ club, planners, hidePlannerAssignment }) => {
	const [url] = useState(window.location.href.split("/").at(-1));
	const [membershipsLevelPlanners, setMembershipsLevelPlanners] = useState(
		[]
	);
	const [membershipPlans, setMembershipPlans] = useState([]);
	const [membershipLevels, setMembershipLevels] = useState([]);
	const [membership, setMembership] = useState([]);
	const [membershipPlanner, setMembershipPlanner] = useState({
		membershipId: 0,
		levelId: 0,
		plannerId: 0,
		clubId: 0,
	});
	const [selMembershipLevel_ID, setSelMembershipLevel_ID] = useState(0);
	const [selPlanner_ID, setSelPlanner_ID] = useState(planners[0]._id);
	const [associatedPlanners, setAssociatedPlanners] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response1 = await API.getMembershipLevelPlanners(
					club._id
				);
				const response2 = await API.getMembershipPlans(club._id);
				const response3 = await API.getMembershipLevels(club._id);

				setMembershipsLevelPlanners(response1);
				setMembershipPlans(response2.planner);
				setMembershipLevels(response3.membershipLevels);
				setSelMembershipLevel_ID(response3.membershipLevels[0]._id);
				setMembership(response3.membership.find((x) => x.slug === url));
				setMembershipPlanner({
					membershipId: response3.membership.find(
						(x) => x.slug === url
					)._id,
					levelId: response3.membershipLevels[0]._id,
					plannerId: selPlanner_ID,
					clubId: response3.membershipLevels[0].clubId,
				});
				setIsLoading(false);
			} catch (error) {
				console.error("Error:", error);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		if (!isLoading) {
			handleAssocitedPlanners();
		}
	}, [isLoading, selMembershipLevel_ID]);

	const handleClose = () => {
		hidePlannerAssignment();
	};

	const handleLevelChange = () => {
		API.addMembershipPlanners(membershipPlanner);
		handleClose();
	};

	const handleAssocitedPlanners = () => {
		let exists = membershipPlans.find(
			(item) => item.levelId === selMembershipLevel_ID
		);
		if (exists) {
			const matchingElements = planners.filter((item) =>
				exists.plannerId.includes(item._id)
			);
			setAssociatedPlanners(matchingElements);
		} else {
			setAssociatedPlanners([]);
			exists = null;
		}
	};

	const handleSelMembershipLevelChange = (e) => {
		e.persist();
		setSelMembershipLevel_ID(e.target.value);
		setMembershipPlanner((prevState) => ({
			...prevState,
			levelId: e.target.value,
		}));
		handleAssocitedPlanners();
	};

	const handleSelPlannerChange = (e) => {
		e.persist();
		setSelPlanner_ID(e.target.value);
		setMembershipPlanner((prevState) => ({
			...prevState,
			plannerId: e.target.value,
		}));
	};

	const removeSelectedPlan = (e, plannerId) => {
		let exists = membershipPlans.find(
			(item) => item.levelId === selMembershipLevel_ID
		);
		const matchingElements = planners.filter((item) =>
			exists.plannerId.includes(item._id)
		);
		const remainingObjects = findObjectById(plannerId, matchingElements);
		API.removeMembershipPlan(selMembershipLevel_ID, plannerId);
		setAssociatedPlanners(remainingObjects);
	};

	const findObjectById = (str, arr) => {
		const filteredArr = arr.filter((obj) => obj._id !== str);
		return filteredArr;
	};

	if (isLoading) {
		return <p>Loading...</p>;
	}
	return (
		<Modal
			centered
			size="md"
			show={true}
			onHide={handleClose}
			dialogClassName="planner-dialog"
			className="customize-excersice "
			id="plannerAssignment"
		>
			<Modal.Header closeButton>
				<Modal.Title>Planner Assignment</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="modal-body-styling">
					<div className="select-planner-group-level">
						<label>Select Group Level</label>
						<div className="styled-select">
							<select
								id="planner-group-level"
								onChange={handleSelMembershipLevelChange}
							>
								{membershipLevels?.map((level) => (
									<option
										key={"membership-levels-" + level._id}
										value={level._id}
									>
										{level.levelName}
									</option>
								))}
							</select>
						</div>
						<br />
						<br />
						<label>Select Planner</label>
						<div className="input-cover">
							<select
								id="group-level-planner"
								className="styled-select"
								style={{ width: "100%" }}
								onChange={handleSelPlannerChange}
							>
								<option value="">Select Planner</option>
								{planners.map((planner) => (
									<option
										key={"assign-planner-" + planner._id}
										value={planner._id}
									>
										{planner.title}
									</option>
								))}
							</select>
						</div>
						<br />
						<br />
						<div className="create-planner-btn">
							<input
								onClick={handleLevelChange}
								type="submit"
								id="assignplannerbtn"
								className="assignplannerbtn"
								name=""
								value="Assign Planner"
							/>
						</div>
					</div>
					<div className="group-level-associated-planners">
						{associatedPlanners.length === 0
							? "No planners associated"
							: associatedPlanners?.map((planner, idx) => (
									<div key={idx} className="assigned-planner">
										{planner.title}{" "}
										<span
											onClick={(e) =>
												removeSelectedPlan(
													e,
													planner._id
												)
											}
										>
											x
										</span>
									</div>
							  ))}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer className="buttons-save"></Modal.Footer>
		</Modal>
	);
};

const mapStateToProps = (state) => {
	return {
		planners: state.planner.planners,
	};
};

export default connect(mapStateToProps, { hidePlannerAssignment })(
	PlannerAssignment
);
