import React, { useEffect, useState } from "react";
import profileBlank from "../../../assets/profile_blank.png";
import { getServerUrl } from "../../../utils/api";

const Avatar = ({ url, style, state, alt, className, onClick }) => {
	const [imageUrl, setImageUrl] = useState("");

	useEffect(() => {
		const fallbackImage = () => {
			setImageUrl(profileBlank);
		};

		const img = new Image();
		img.onload = () => {
			setImageUrl(getServerUrl().apiURL + (url || "") + (state || ""));
		};
		img.onerror = fallbackImage;
		img.src = getServerUrl().apiURL + (url || "") + (state || "");

		return () => {
			img.onload = null;
			img.onerror = null;
		};
	}, [url, state]);

	return (
		<img
			src={imageUrl}
			alt={imageUrl !== profileBlank ? alt : ""}
			style={style}
			className={className}
			onError={() => {
				setImageUrl(profileBlank);
			}}
			onClick={onClick}
		/>
	);
};

export default Avatar;
