import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { hidePrintProgramSessions } from '../../../actions';
import { getSessionAndActivities, getProgramById, searchProgramSessions, getsessionActivity } from '../../../utils/api.js'
import { Modal, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactToPrint from 'react-to-print';
import { ComponentToPrint } from "./PrintComponent";
const PrintProgramSessions = (props) => {
    const [programName, setprogramName] = useState('');
    const [Programs, setPrograms] = useState([]);
    const [sessions, setSessions] = useState({ selectedTitle: null, programSessions: [] });
    const [selectedDate, setSelectedDate] = useState(null);
    const [findBy, setfindBy] = useState('session');
    const [programSessions, setprogramSessions] = useState(null);
    const [sessionActivities, setSessionActivities] = useState(null);
    const [loadResp, setloadResp] = useState(false);
    const [activites, setActivities] = useState(null);
    const componentRef = useRef();
    const [searchParams, setSearchParams] = useState({});
    const [plannerTittle, setplannerTittle] = useState(false);
    const [displayPlanner, setdisplayPlanner] = useState(false);
    const [programTittle, setprogramTittle] = useState(false);
    const [diplayPrograms, setdiplayPrograms] = useState(false);
    const [displayDate, setDisplayDate] = useState(false);
    useEffect(() => {
        setPrograms(props.programId.planner.programs);

        getsessionActivity().then((response) => {
            setSessionActivities(response);
        });
    }, []);
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleClose = () => {
        props.hidePrintProgramSessions();
    };
    const formatDateMonth = (dateString) => {
        const date = new Date(dateString);
        var options = { day: 'numeric', month: 'long' };
        return date.toLocaleDateString('en-US', options);

    };
    const handleSelectPrograms = async (event) => {
        const { name, value } = event.target;

        try {
            if (value == 'all') {
                setDisplayDate(true);
            } else {
                setDisplayDate(false);
            }
            // setSearchParams((prevState) => ({
            //     ...prevState,
            //     [name]: value,
            // }));
            let params = {
                ...searchParams,
                [name]: value
            };
            setSearchParams(params);
            setloadResp(true);
            searchProgramSessions(params).then((response) => {
                setprogramSessions(response);
                setloadResp(false);
            });
        } catch (error) {
            console.log('error', error)
        }
    }
    const handleDateChange = (date) => {
        setSelectedDate(date);
        let params = {
            ...searchParams,
            ["program_date"]: date
        };
        setSearchParams(params);
        searchProgramSessions(params).then((response) => {
            setprogramSessions(response.sessions);
        });
    };
    const handleFilterBy = (value) => {
        setfindBy(value);
        try {
            if (findBy == 'session') {
                //setSessions({ Filtertitle: 'Select Session', programSessions: [sessions] });
            } else {
                //setSessions({ Filtertitle: 'Select Day', programSessions: [sessions] });
            }

        } catch (e) {
            console.log('error',)
        }
        console.log('value', value);
    }
    const selectSession = (event) => {
        console.log('findby', findBy);
        const { name, value } = event.target;
        let params = {};
        if (findBy == 'day') {
            params = {
                ...searchParams,
                ["days"]: parseInt(value)
            };
        } else {
            params = {
                ...searchParams,
                [name]: value
            };
        }
        setSearchParams(params);
        console.log('params', params);
        setloadResp(true);

        searchProgramSessions(params).then((response) => {
            setloadResp(false);
            setprogramSessions(response);
        });
        // searchProgramSessions(searchParams).then((response) => {
        //     if (findBy == 'session') {
        //         setSessions({ Filtertitle: 'Select Session', programSessions: response.sessions });
        //     } else {
        //         setSessions({ Filtertitle: 'Select Day', programSessions: response.sessions });
        //     }
        // });
    };
    const selectActivity = (event) => {
        const { name, value } = event.target;
        let params = {
            ...searchParams,
            [name]: value
        };
        setSearchParams(params);
        setloadResp(true);
        searchProgramSessions(params).then((response) => {
            setloadResp(false);
            setprogramSessions(response);
        });
    }
    const handleDisplayPlannerTitle = (event) => {
        let value = event.target.checked;
        setplannerTittle(value);
        setdisplayPlanner(value)
    }
    const handleDisplayProgramTitle = (event) => {
        let value = event.target.checked;
        setdiplayPrograms(value);
    }


    return (
        <Modal
            centered
            size="lg"
            show={true}
            onHide={handleClose}
            dialogClassName="planner-dialog" className='customize-edit-planner'
        >
            <Modal.Header closeButton>
                <Modal.Title>Print Planner: {programName ? programName : ''}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                </div>
                <div className='select-planner'>
                    <select id="print-select-program" name='program_id' className="styled-select" onChange={handleSelectPrograms}>
                        <option value="">Select Program</option>
                        <option value="all">All Programs</option>
                        {
                            Programs && Programs.map((program, index) => (
                                <option key={index} value={program.programId}>{program.title}</option>
                            ))
                        }
                    </select>
                    {
                        (displayDate) ?
                            <div className='row'>
                                <label>Select Date</label>
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    dateFormat="yyy-MM-dd"
                                />
                                <div>
                                    <label><input id="coach-display-planner-title" type="checkbox" onChange={handleDisplayPlannerTitle} value={plannerTittle ? 'no' : 'yes'} /> Display planner title</label>
                                </div>

                                <div>

                                    <label><input id="coach-display-program-title" type="checkbox" onChange={handleDisplayProgramTitle} value={programTittle ? 'no' : 'yes'} /> Display program title</label>
                                </div>
                            </div> : <>

                                <div id="print-sessions-date-select">
                                    <div id="print-findby-select">
                                        <label>Find By:</label> &nbsp;&nbsp;
                                        <input type="radio" name="print-findby" id="print-findby-session" checked={findBy === "session"} value="session" onClick={(e) => handleFilterBy(e.target.value)} /> <label for="print-findby-session"> Session</label> &nbsp;&nbsp;
                                        <input type="radio" name="print-findby" id="print-findby-day" checked={findBy === "day"} onClick={(e) => handleFilterBy(e.target.value)} value="day" />  <label for="print-findby-day">Day</label>
                                    </div>

                                </div>
                                <div id="print-sessions-select">
                                    <label>{"Select " + findBy.charAt(0).toUpperCase() + findBy.slice(1)}</label>


                                    <select id="print-select-session" name='session_id' className="styled-select" onChange={selectSession} >
                                        <option value="">Select Session</option>
                                        {(sessions.programSessions.length > 0) && sessions.programSessions.map((session, index) => (
                                            <option key={index} value={findBy == "session" ? session._id : session.days}>{findBy == "session" ? session.days + ':' + session.title : session.days}</option>
                                        ))
                                        }
                                    </select>

                                </div>
                            </>
                    }



                    <div id="print-sessions-select-activites">
                        <label>Select Activity Type</label>
                        <select id="print-select-session-activites" name='activity_id' className="styled-select" onChange={selectActivity} >
                            <option value="">Please Select</option>
                            {
                                (sessionActivities) && sessionActivities.map((activity, index) => (
                                    <option key={index} value={activity.value}>{activity.title}</option>
                                ))
                            }
                        </select>


                    </div>
                    <div class="print-planner-sessions-btn">

                        {
                            (!loadResp && searchParams.program_id != null) ? (<>
                                <ReactToPrint trigger={() => <input type="button" id="print-planner-sessions" name="" value="Print Session(s)" />} content={() => componentRef.current} />
                                <div style={{ display: "none" }}>
                                    <ComponentToPrint ref={componentRef} programSessions={programSessions} programDetails={programName} planner={props.programId.planner} />
                                </div>
                            </>) : (<>
                                <input className='btn-secondary' type="button" disabled id="print-planner-sessions" name="" value="Print Session(s)" />
                            </>)
                        }
                        {/* {
                            programSessions && programSessions.length > 0 ? <>
                                <ReactToPrint trigger={() => <input type="button" id="print-planner-sessions" name="" value="Print Session(s)" />} content={() => componentRef.current} />

                                <div style={{ display: "none" }}>
                                    <ComponentToPrint ref={componentRef} programSessions={programSessions} programDetails={programName} planner={props.programId.planner} />
                                </div>
                            </> : ""
                        } */}
                        {/* <input type="button" id="print-planner-export-word-sessions" name="" value="Export Session(s) to Word" /> */}

                    </div>
                </div>

            </Modal.Body>

        </Modal>
    );
}

const mapStateToProps = state => {
    return {
        EditPlanner: state.planner.modals.EditPlanner,
        programId: state.planner.programId,
    };
};
export default connect(mapStateToProps, { hidePrintProgramSessions })(PrintProgramSessions);
