import React, { Component } from "react";
import {
	getServerUrl,
	retrieveUserConversation,
	retrieveBoardConversation,
	updateMessages,
} from "../utils/api";
import { connect } from "react-redux";
import { assignTimeAgo } from "../utils/helper";
import animationData from "../assets/animationData.gif";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";

import { MdClose } from "react-icons/md";
import profileBlank from "../assets/profile_blank.png";
import InputField from "./common/inputField";
import ReactionPopup from "./messages/reactionPopup";
import DropDown from "./messages/dropDown";
import AlertBox from "./common/alertBox";
import Avatar from "./common/avatar";
class Conversation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			typing: false,
			isTyping: false,
			showEmojiPicker: false,
			selectedMsgInfo: {},
			typingUserInfo: {
				sender: "",
				receiver: "",
			},
			showReact: {
				index: 0,
				show: false,
			},
			selectedEmoji: {},
			isAddingUniqReader: false, // Flag to track if addUniqReader is running
			popUp: {
				index: 0,
				show: false,
			},
			showCopyMessage: false,
		};
		this.scrollRef = React.createRef();
	}

	componentDidMount() {
		this.mounted = true; // Set the local mounted flag to true
		this.setupSocketListeners();
		this.scrollToLatest();
		this.handleReactionSelected();
	}

	typingHandler = (e) => {
		const { socket, user, receiver } = this.props;
		const { typing } = this.state;
		if (socket && !typing) {
			this.setState({ typing: true });
			socket.emit("typing", user._id, receiver._id);
		}
		let lastTypingTime = new Date().getTime();
		const timerLength = 3000;

		setTimeout(() => {
			const timeNow = new Date().getTime();
			const timeDiff = timeNow - lastTypingTime;

			if (timeDiff >= timerLength && this.state.typing) {
				socket.emit("stopTyping", user._id, receiver._id);
				this.setState({ typing: false });
			}
		}, timerLength);

		this.props.messageQueryChange(e);
	};

	handleEmojiSelect = (e) => {
		let newMessage = this.props.message + e.native;
		this.props.updateMessage(newMessage);
	};

	handleReactionSelected = (e) => {
		this.props.conversations.forEach((m) => {
			if (m.msgReaction) {
				this.setState((prevState) => ({
					selectedEmoji: {
						...prevState.selectedEmoji,
						[m._id]: m.msgReaction,
					},
				}));
			}
		});
	};

	setupSocketListeners() {
		const {
			socket,
			receiver,
			user,
			onUpdateConversation,
			onUpdateReceiverStatus,
			onUpdateSenders,
			onUpdateMsgUnseenCount,
			updateMessageReaction,
			conversationVisible,
		} = this.props;

		if (socket) {
			socket.on("typing", (sender, receiver) => {
				this.setState({
					typingUserInfo: {
						sender,
						receiver,
					},
				});
			});

			socket.on("stopTyping", () => {
				this.setState({
					typingUserInfo: {
						sender: "",
						receiver: "",
					},
				});
			});

			socket.on("getMessage", async (data) => {
				if (data.type && this.mounted) {
					if (
						receiver &&
						!receiver._id
							.toString()
							.localeCompare(data.fromId.toString())
					) {
						const receiverId = user._id;
						if (
							data.type === "users" &&
							conversationVisible &&
							!this.state.isAddingUniqReader && // Check the flag before calling addUniqReader
							this.mounted // Check the local mounted flag before calling addUniqReader
						) {
							this.setState({
								isAddingUniqReader: true,
							});

							const res = await retrieveUserConversation(
								receiverId,
								data.fromId
							);
							if (this.mounted) {
								// Check the local mounted flag again before updating the state
								// Check again before updating the state
								const messages = await this.addUniqReader(
									res.messages,
									receiverId.toString(),
									conversationVisible
								);
								this.setState({
									isAddingUniqReader: false,
								});

								onUpdateConversation([...messages]);
								res.isReceiverOnline &&
									onUpdateReceiverStatus("online");
							}
						} else if (
							data.type === "team" &&
							this.mounted // Check the local mounted flag before calling addUniqReader
						) {
							const res = await retrieveBoardConversation(
								user._id,
								receiver._id,
								data.type
							);
							if (this.mounted) {
								onUpdateSenders([...res.senders]);
								onUpdateConversation([...res.messages]);
							}
						} else if (
							data.type === "group" &&
							this.mounted // Check the local mounted flag before calling addUniqReader
						) {
							const res = await retrieveBoardConversation(
								user._id,
								receiver._id,
								data.type
							);
							if (this.mounted) {
								onUpdateSenders([...res.senders]);
								onUpdateConversation([...res.messages]);
							}
						}
					} else {
						onUpdateMsgUnseenCount(data.fromId, data.type);
					}
				}
			});

			socket.on("getReaction", (data) => {
				updateMessageReaction(data.message);
			});
		}
	}
	componentWillUnmount() {
		this.mounted = false;

		const { socket, socketConnected } = this.props;
		if (socket && socketConnected) {
			socket.off("getMessage");
			socket.off("typing");
			socket.off("stopTyping");
			socket.off("getReaction");
		}
	}
	componentDidUpdate(prevProps, _prevState) {
		// scroll conversation to the bottom smoothly
		// trigger condition: load conversation & send message
		if (
			prevProps.conversations.length !== this.props.conversations.length
		) {
			this.scrollToLatest();
		}
		if (prevProps.conversations !== this.props.conversations) {
			this.handleReactionSelected();
		}
	}

	hasNewReader = (originalReaders, updatedReaders) => {
		return !updatedReaders.every((reader) =>
			originalReaders.includes(reader)
		);
	};

	addUniqReader = async (messages, readerId) => {
		const updatedMessages = await Promise.all(
			messages.map(async (msg) => {
				if (msg.receiver.toString().localeCompare(readerId)) {
					// Retain message info if current user is not the receiver of the message
					return msg;
				} else {
					if (msg.read && msg.read.length > 0) {
						// Update read list with unique readerId
						const updatedReadList = {
							...msg,
							read: Array.from(new Set([...msg.read, readerId])),
						};

						if (this.hasNewReader(msg.read, updatedReadList.read)) {
							// Push only if there is a new reader
							await updateMessages([updatedReadList]);
						}

						return updatedReadList;
					} else {
						// Create a new message with the readerId
						const updatedReaderMsg = { ...msg, read: [readerId] };
						await updateMessages([updatedReaderMsg]);

						return updatedReaderMsg;
					}
				}
			})
		);

		return updatedMessages;
	};

	scrollToLatest = () => {
		return this.scrollRef.current?.scrollIntoView({
			behavior: "smooth",
		});
	};

	handleEmojiPickerToggle = () => {
		this.setState((prevState) => ({
			showEmojiPicker: !prevState.showEmojiPicker,
		}));
	};

	formatDynamicText = (message) => {
		const dynamicTextRegex = /\*(.*?)\*/g; // Regular expression to match text between asterisks
		const matches = message.match(dynamicTextRegex); // Find all matches of dynamic text pattern
		let formattedMessage = message; // Initialize formatted message
		if (matches) {
			// If matches are found, update the formatted message to highlight the dynamic text
			matches.forEach((match) => {
				const dynamicText = match.substring(1, match.length - 1); // Extract the dynamic text without asterisks
				formattedMessage = formattedMessage.replace(
					match,
					`<strong>${dynamicText}</strong>`
				); // Wrap the dynamic text in <strong> tags
			});
		}
		return formattedMessage;
	};

	optionsPopUp = (index) => {
		this.setState({
			popUp: {
				index: index,
				show: !this.state.popUp.show,
			},
		});
	};
	selectedMessage = (info, senderID, index, remove) => {
		const { tab, senders, receiver } = this.props;
		const name = senderID
			? "You"
			: tab === "teams" || tab === "groups"
			? `${senders[index]?.firstName} ${senders[index]?.lastName}`
			: receiver.name;

		this.setState({
			selectedMsgInfo: !remove
				? {
						createdAt: info.createdAt,
						imgFileName: info.imgFileName,
						message: this.formatDynamicText(info.message),
						read: info.read,
						receiver: info.receiver,
						sender: info.sender,
						type: info.type,
						updatedAt: info.updatedAt,
						__v: info.__v,
						_id: info._id,
						name,
				  }
				: {},
		});
	};
	sendMessage = async () => {
		const { conversations, user, receiver, message, imgs, tab } =
			this.props;
		const { selectedMsgInfo } = this.state;

		if (this.state.loading) {
			return; // Return if message is already being sent
		}
		this.setState({ loading: true }); // Disable the button

		this.selectedMessage(null, null, null, true);

		this.setState({ showEmojiPicker: false });
		try {
			await this.props.onMsgButtonPress(
				conversations.length > 0
					? {
							type: conversations[0].type,
							sender: user._id,
							receiver: receiver._id,
							message: message,
							imgFileName: imgs,
							reply: selectedMsgInfo,
					  }
					: {
							sender: this.props.user._id,
							receiver: receiver._id,
							type: receiver.type
								? receiver.type
								: tab === "people"
								? "users"
								: "",
							message: message,
							imgFileName: imgs,
							reply: selectedMsgInfo,
					  }
			);
			this.setState({ loading: false });
		} catch (error) {
			console.error("Error sending message:", error);
			this.setState({ loading: false }); // Make sure to reset even on error
		}
	};
	showReaction = (index) => {
		this.setState({
			showReact: {
				index: index,
				show: !this.state.showReact.show,
			},
		});
	};

	selectedEmoji = (emoji, messageId) => {
		this.setState(
			(prevState) => {
				const updatedReactions = {
					...prevState.selectedEmoji,
					[messageId]: emoji, // Update the selectedEmoji for the specific messageId
				};

				return {
					selectedEmoji: updatedReactions,
					showReact: {
						index: 0,
						show: !prevState.showReact.show,
					},
				};
			},
			() => {
				this.props.sendReaction(messageId, emoji);
			}
		);
	};
	handleOptionSelect = (func) => {
		func(); // Call the function associated with the selected option
		this.setState((prevState) => ({
			popUp: {
				index: "",
				show: !prevState.popUp.show,
			},
		}));
	};

	selectAllTextFromMessage = (info) => {
		// Copy the message text to the clipboard
		const textField = document.createElement("textarea");
		textField.innerText = this.formatDynamicText(info.message);
		document.body.appendChild(textField);
		textField.select();
		document.execCommand("copy");
		textField.remove();
		// Set the state to show the copy success message
		this.setState({ showCopyMessage: true });

		// Hide the copy success message after 3 seconds
		setTimeout(() => {
			this.setState({ showCopyMessage: false });
		}, 3000); // Duration in milliseconds (3 seconds)
	};

	render() {
		const {
			receiver,
			senders,
			imgsFile,
			imgs,
			conversations,
			user,
			tab,
			uploadMsgPictureRef,
			uploadMsgPictureToTemp,
			onCloseConversation,
			onOpenUploadMsgPicture,
			isReceiverOnline,
			onOpenPictureViewer,
			removePhoto,
			sendingMessage,
			handleUserSelection,
			searchResults,
			message,
			updateMessage,
		} = this.props;
		const {
			selectedMsgInfo,
			showReact,
			selectedEmoji,
			popUp,
			showCopyMessage,
		} = this.state;
		const { selectedMessage, selectAllTextFromMessage } = this;

		const reaction = ["😊", "👍", "❤", "😂", "😢"];
		const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
		return (
			<>
				<div className={`cnvrsbx ${isiOS ? "ios" : ""}`}>
					<div className={`msghdr ${isiOS ? "ios" : ""}`}>
						<h5>
							Conversation Between{" "}
							<span className="grntxt">You</span> and{" "}
							<span className="grntxt">{receiver.name}</span>
						</h5>
						<span
							className="close"
							onClick={() => onCloseConversation(receiver._id)}
						>
							×
						</span>
						{showCopyMessage && (
							<AlertBox
								status={"success"}
								message={"Message copied to clipboard!"}
							/>
						)}
					</div>

					<div
						className="conversation-parent"
						style={{
							height: `${imgsFile.length > 0 ? "65%" : "87.5%"}`,
						}}
					>
						{conversations.map((m, index) => {
							const senderID = !m.sender
								.toString()
								.localeCompare(this.props.user._id);
							const formattedMessage = this.formatDynamicText(
								m.message
							);
							const formattedMessageReply =
								m.reply?.message &&
								this.formatDynamicText(m.reply.message);
							const options = [
								{
									name: "reply",
									func: () => {
										selectedMessage(
											m,
											senderID,
											index,
											false
										);
									},
								},
								{
									name: "select all",
									func: () => {
										selectAllTextFromMessage(m);
									},
								},
							];

							return (
								<div
									key={`conversation-${m._id}`}
									ref={this.scrollRef}
								>
									<div
										className={`usrmsgbx ${
											senderID ? "msgyou" : "sender"
										}`}
										style={{
											...(index ===
												conversations.length - 1 && {
												marginBottom: "40px",
											}),
										}}
									>
										<div className="usrtop">
											<div
												className="row"
												style={{
													marginRight: "0px",
												}}
											>
												{!senderID && (
													<div className="col-auto f12">
														<div className="userthumb">
															<a className="userbx">
																<Avatar
																	url={
																		"/uploads/user/"
																	}
																	state={
																		senderID
																			? user.profilePicture
																			: receiver.profilePicture
																	}
																	alt={
																		"Profile Pic"
																	}
																/>
															</a>
														</div>
													</div>
												)}
												<div className="col nopad flex-col">
													<div
														className={`${
															senderID
																? "timeline-sender"
																: "timeline-receiver"
														}`}
													>
														{!senderID && (
															<a>
																{senderID
																	? "You"
																	: tab ===
																			"teams" ||
																	  tab ===
																			"groups"
																	? `${senders[index]?.firstName} ${senders[index]?.lastName}`
																	: receiver.name}
															</a>
														)}
														<span className="small pstim">
															{assignTimeAgo(
																m.createdAt
															)}
														</span>
													</div>
													<div className="parent-f14">
														{m.reply?.message ? (
															<div className="replyy">
																<div className="reply-infoo">
																	<div className="f14">
																		<div className="reply-conversation-name">
																			{
																				m
																					.reply
																					.name
																			}
																		</div>
																		<div
																			className="reply-conversation"
																			dangerouslySetInnerHTML={{
																				__html: formattedMessageReply,
																			}}
																		/>
																	</div>
																	<div
																		onClick={() =>
																			this.optionsPopUp(
																				index
																			)
																		}
																		className="mes"
																		dangerouslySetInnerHTML={{
																			__html: formattedMessage,
																		}}
																	/>
																</div>
															</div>
														) : (
															<>
																{m.imgFileName
																	.length >
																	0 && (
																	<div className="msgwhtimgbx">
																		{m.imgFileName.map(
																			(
																				imgFileName,
																				index
																			) => (
																				<img
																					key={
																						imgFileName
																					}
																					src={`${
																						getServerUrl()
																							.apiURL
																					}/uploads/message/${imgFileName}`}
																					onClick={() =>
																						onOpenPictureViewer(
																							`${
																								getServerUrl()
																									.apiURL
																							}/uploads/message/${imgFileName}`
																						)
																					}
																				/>
																			)
																		)}
																	</div>
																)}

																<div className="emoji-reaction">
																	{formattedMessage && (
																		<p
																			className="f14"
																			onClick={() =>
																				this.optionsPopUp(
																					index
																				)
																			}
																		>
																			<span
																				dangerouslySetInnerHTML={{
																					__html: formattedMessage,
																				}}
																				key={
																					m._id
																				}
																			/>
																		</p>
																	)}
																	<div className="reaction-popup">
																		{index ===
																			showReact.index &&
																			showReact.show && (
																				<ReactionPopup
																					reaction={
																						reaction
																					}
																					selectedEmoji={(
																						emoji
																					) =>
																						this.selectedEmoji(
																							emoji,
																							m._id
																						)
																					} // Pass the messageId along with the emoji
																				/>
																			)}
																		<span
																			role="img"
																			className="emoji-react"
																			onClick={() =>
																				this.showReaction(
																					index
																				)
																			}
																		>
																			😊
																		</span>
																	</div>
																	{selectedEmoji[
																		m._id
																	] && (
																		<div className="reaction-show">
																			{
																				selectedEmoji[
																					m
																						._id
																				]
																			}
																		</div>
																	)}
																</div>
																<div>
																	{index ===
																		popUp.index &&
																		popUp.show && (
																			<DropDown
																				options={
																					options
																				}
																				onOptionSelect={
																					this
																						.handleOptionSelect
																				}
																			/>
																		)}
																</div>
															</>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
					<div className="msgwrtbx" style={{ paddingBottom: "10px" }}>
						{(conversations[0]?.sender ===
							this.state.typingUserInfo.sender ||
							conversations[0]?.sender ===
								this.state.typingUserInfo.receiver) && (
							<h5>
								<span className="grntxt">
									{receiver.name} is
								</span>
								<img src={animationData} alt="my-gif" />
							</h5>
						)}
						{imgs.length > 0 && (
							<div className="msgwhtimgbx">
								{imgs.map((imgFileName, index) => (
									<span key={imgFileName}>
										<img
											src={`${
												getServerUrl().apiURL
											}/uploads/temp/${imgFileName}`}
											onClick={() =>
												onOpenPictureViewer(
													`${
														getServerUrl().apiURL
													}/uploads/temp/${imgFileName}`
												)
											}
										/>
										<MdClose
											onClick={() =>
												removePhoto(imgFileName)
											}
										/>
									</span>
								))}
							</div>
						)}
						{this.state.showEmojiPicker && (
							<Picker
								data={data}
								onEmojiSelect={this.handleEmojiSelect}
							/>
						)}
						{selectedMsgInfo &&
							Object.keys(selectedMsgInfo).length !== 0 && (
								<div className="reply">
									<div className="reply-status">
										<div className="name">
											{selectedMsgInfo.name}
										</div>
										<div
											className="closeBtn"
											onClick={() =>
												this.selectedMessage(
													null,
													null,
													null,
													true
												)
											}
										>
											x
										</div>
									</div>

									<div className="reply-info">
										{selectedMsgInfo.message !== "" && (
											<p
												className="f14"
												dangerouslySetInnerHTML={{
													__html: selectedMsgInfo.message,
												}}
											/>
										)}
									</div>
								</div>
							)}
						<div className="form-group">
							<InputField
								value={message}
								onChange={this.typingHandler}
								updateMessage={updateMessage}
								sendMessage={this.sendMessage}
								handleUserSelection={handleUserSelection}
								searchResults={searchResults}
								handleEmojiPickerToggle={
									this.handleEmojiPickerToggle
								}
								onOpenUploadMsgPicture={onOpenUploadMsgPicture}
								uploadMsgPictureRef={uploadMsgPictureRef}
								uploadMsgPictureToTemp={uploadMsgPictureToTemp}
								scrollToLatest={this.scrollToLatest}
								sendingMessage={sendingMessage}
							/>
						</div>
					</div>
				</div>
				<div className="cnvrsusrsbx">
					<div className="cnvrsusrscontainer">
						<div className="cnvrsusrimg">
							<img
								src={
									receiver.profilePicture
										? `${
												getServerUrl().apiURL
										  }/uploads/user/${
												receiver.profilePicture
										  }`
										: profileBlank
								}
								className="cnvrsusrimg"
							/>
							{tab === "people" && isReceiverOnline && (
								<div className="cnvrusronlinebadge"></div>
							)}
						</div>
						<div className="cnvrsusrname">{receiver.name}</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	};
};

export default connect(mapStateToProps, {})(Conversation);
