/**
 * =====================================
 * REACT COMPONENT CLASS
 * =====================================
 * @date created: 20 October 2019
 * @authors: Waqas Rehmani
 *
 * This file defines the PictureViewer component. The class PictureViewer
 * is where the component is defined.
 *
 * This is a component defined for reusability.
 *
 * This component enlarges a larger picture that is in the app.
 *
 */
import React from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const PictureViewer = (props) => {
	if (!props.visible) {
		return null;
	}

	return (
		<Lightbox
			mainSrc={props.image}
			onCloseRequest={props.closePictureViewer}
		/>
	);
};

export default PictureViewer;
