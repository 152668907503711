import React from "react";
import { getServerUrl } from "../../../utils/api";

const TabToRemove = ({
	title,
	tabTo,
	removePhoto,
	logo,
	upload,
	post = false,
}) => {
	return (
		<>
			{post ? (
				<div className="new-post-uploaded-image mt-1 post-img-parent">
					<div onClick={() => removePhoto(logo)}>{tabTo}</div>
					<img
						className="post-img"
						src={`${getServerUrl().apiURL}${upload}${logo}`}
						alt="cover photo"
					/>
				</div>
			) : (
				<div className="form-group">
					<div className="form-label">{title}</div>
					<div
						style={{
							border: !logo && "1px solid #e4e6e7",
							borderRadius: "9px",
							padding: !logo && "10px",
							minHeight: "40px",
						}}
					>
						{logo && (
							<div className="new-post-uploaded-image mt-1 post-img-parent">
								<div onClick={() => removePhoto(logo)}>
									{tabTo}
								</div>
								<img
									className="post-img"
									src={`${
										getServerUrl().apiURL
									}${upload}${logo}`}
									alt="cover photo"
								/>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default TabToRemove;
