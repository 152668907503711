import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class SplashSocial extends Component {
	constructor(props) {
		super(props);

		this.state = {
			redirect: false,
		};
	}

	componentDidMount() {
		this.id = setTimeout(() => this.setState({ redirect: true }), 3000);
	}

	componentWillUnmount() {
		clearTimeout(this.id);
	}

	render() {
		return this.state.redirect ? (
			<Redirect to="/home" />
		) : (
			<div className="outbx" style={{ height: "100%" }}>
				<div className="splsh displayoff">
					<div className="splshin-logo">
						<img src="/uploads/images/logo-social.png" />
					</div>
					<div className="text-center wbtxt">www.coachly.com</div>
					<div className="splshin">
						<img src="/uploads/images/splash-social.jpg" />
					</div>
				</div>

				<div className="splsh2" style={{ height: "100%" }}>
					<div className="splshin-logo">
						<img src="/uploads/images/logo-planner.png" />
					</div>
					<div className="text-center wbtxt">www.coachly.com</div>
					<div className="splshin">
						<img src="/uploads/images/splash-planner.jpg" />
					</div>
				</div>
			</div>
		);
	}
}

export default SplashSocial;
