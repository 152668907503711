import React, { useState } from "react";
import { userIdExists } from "../../../utils/api";
import LoadButton from "../../common/button";
import HeaderCoachly from "../../common/header";
import Message from "../../common/message";
import InputLoader from "../../common/inputLoader";
import GoBackLink from "../../common/goBackLink";

const SendEmail = ({ handleSubmit, message, submitting }) => {
	const [email, setEmail] = useState("");
	const [showLoading, setShowLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [userExists, setUserExists] = useState(true);
	const [btnDisable, setBtnDisable] = useState(true);

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	};

	const checkUserExists = () => {
		if (email === "") {
			setShowLoading(false);
			setLoading(false);
			setUserExists(true);
			setBtnDisable(true);
		} else {
			setShowLoading(true);
			setLoading(true);
			userIdExists(email).then((res) => {
				setLoading(false);
				setUserExists(res);
				setBtnDisable(!res);
			});
		}
	};

	return (
		<div className="outbx">
			<HeaderCoachly title="Forget Password" />
			<div className="loginbxin">
				<form onSubmit={(e) => handleSubmit(e, email)}>
					<Message display={userExists} text={"Incorrect email"} />
					<div className="form-group">
						<input
							className="form-control"
							type="text"
							placeholder="Email or User Name"
							value={email}
							onChange={handleEmailChange}
							onBlur={checkUserExists}
							required
						/>
						<InputLoader
							showLoading={showLoading}
							loading={loading}
							userExists={userExists}
						/>
					</div>
					<LoadButton
						btnDisable={btnDisable}
						submitting={submitting}
						title={"Send"}
					/>
				</form>
				<Message display={message} text={message} />
				<GoBackLink to="/signInUser" title={"Go back"} />
			</div>
		</div>
	);
};

export default SendEmail;
