import React from "react";

const TabsCount = ({ title, count }) => {
	return (
		<div className="tabs-counts">
			<span>{title}</span>
			{count > 0 && (
				<div className="notification-count-tabs">
					{count > 99 ? "99+" : count}
				</div>
			)}
		</div>
	);
};

export default TabsCount;
