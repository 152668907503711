import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import LeftSidebar from "./left-sidebar";
import {
	getTeam,
	retrieveUserConversation,
	getServerUrl,
	getMessagesUsersAndBoards,
	uploadMsgMediaMain,
	handleMessageRequest,
	getMessageRequests,
	sendNotifications,
} from "../../utils/api.js";
import { RiQuestionnaireLine } from "react-icons/ri";
import profileBlank from "../../assets/profile_blank.png";

import { loadingIcon } from "../../assets/loading.svg";
import { io } from "socket.io-client";
import PrivateMessagesContactBox from "./private-messages-contact-box";
import PrivateMessageConversation from "./private-message-conversation";

const PrivateMessages = ({ clubSlug, user }, props) => {
	const [club, setClub] = useState("");
	const [loading, setLoading] = useState(true);
	const [leftSidebarDisplay, setLeftSidebarDisplay] = useState(true);
	const [conversationVisible, setConversationVisible] = useState(false);
	const [users, setUsers] = useState([]);
	const [conversations, setConversations] = useState([]);
	const [receiver, setReceiver] = useState(null);
	const [isReceiverOnline, setIsReceiverOnline] = useState(false);
	const [msgReqViewAll, setMsgReqViewAll] = useState(false);
	const [message, setMessage] = useState("");
	const [imgsFile, setImgsFile] = useState([]);
	const [imgs, setImgs] = useState([]);
	const [imgsDateNow, setImgsDateNow] = useState([]);
	const [socket, setSocket] = useState(null);
	const [teams, setTeams] = useState([]);
	const [groups, setGroups] = useState([]);
	const [senders, setSenders] = useState([]);
	const [membersInfo, setMembersInfo] = useState({
		members: [],
		memberRequests: [],
		bannedUsers: [],
	});
	const [messagesVisible, setMessagesVisible] = useState(false);
	const [retrieving, setRetrieving] = useState(true);
	const [notFriendLatestMsg, setNotFriendLatestMsg] = useState(true);
	const [messagesUsersNeedUpdate, setMessagesUsersNeedUpdate] =
		useState(false);
	const [notFriendUsers, setNotFriendUsers] = useState(true);
	const [sendingMessage, setSendingMessage] = useState(true);
	const [searchResults, setSearchResults] = useState(true);
	const [memtionedPerson, setMemtionedPerson] = useState(true);
	const [selectedMember, setSelectedMember] = useState(true);

	useEffect(() => {
		getTeam(clubSlug).then((response) => {
			if (response.team === null) {
				setClub(null);
			} else {
				if (response.team.creatorId === user._id)
					setClub(response.team);
				else setClub(null);
			}
			setLoading(false);
		});
	}, [clubSlug, user]);

	const leftSidebarChange = () => {
		setLeftSidebarDisplay(!leftSidebarDisplay);
	};

	if (club === null) {
		return <Redirect to="/home" />;
	}

	if (loading) {
		return (
			<div className="profile-container-loading">
				<img src={loadingIcon} alt="" />
			</div>
		);
	}

	const loadConversation = (
		e,
		receiverId = null,
		receiverInformation = null,
		msgReqViewAll = false
	) => {
		e.preventDefault();
		const senderUserId = user._id;
		setMembersInfo({
			members: [],
			memberRequests: [],
			bannedUsers: [],
		});
		setSearchResults({ searchResults: [] });
		setMemtionedPerson({ memtionedPerson: [] });
		retrieveUserConversation(senderUserId, receiverId).then((res) => {
			if (res.success) {
				conversationVisible && setConversationVisible(false);
				const updatedUsers = users.map((user) =>
					user._id.toString() === receiverId.toString()
						? { ...user, unseenCount: 0 }
						: { ...user }
				);
				setUsers(updatedUsers);
				setConversations([...res.messages] || []);
				setReceiver(receiverInformation);
				setIsReceiverOnline(res.isReceiverOnline);
				setConversationVisible(true);
				setMsgReqViewAll(msgReqViewAll);
				setMessage("");
				setImgsFile([]);
				setImgs([]);
				setImgsDateNow([]);
			} else {
				setReceiver(null);
				setConversationVisible(false);
				setMessage("");
				setImgsFile([]);
				setImgs([]);
				setImgsDateNow([]);
			}
		});
	};

	const toggleMessages = (e) => {
		const messagesVisible = !messagesVisible;

		// open messages tab -> connect socket
		// close messages tab -> disconnect socket
		if (messagesVisible) {
			getMessagesUsersAndBoards(user.userId).then((res) => {
				if (res.success) {
					const updatedUsers = res.users.map((user, index) => ({
						...user,
						unseenCount:
							res.unseenCountsForUsers &&
							res.unseenCountsForUsers[index]
								? res.unseenCountsForUsers[index]
								: 0,
					}));
					setUsers(updatedUsers);
					setRetrieving(false);

					let socket = io(getServerUrl().apiURL);
					socket.on("connect", () => {
						setSocket({ socket });
					});
					socket.emit("addUser", props.user._id);
					socket.on("getUsers", (users) => {
						console.log(
							"Socket get users: " + JSON.stringify(users)
						);
					});

					socket.on("getMessage", (data) => {
						// notify there is a new message from the actual sender
						// condition: 1. no active current chat
						//            2. current chat is not the sender
						if (!conversationVisible) {
							updateMsgUnseenCount(data.fromId, data.type);
						}
					});
				}
			});
		} else {
			if (socket) {
				// leave room
				// condition: if the previous conversation on screen is from a board
				conversationVisible && socket.emit("leave", receiver._id);
				socket.disconnect();
				setSocket(null);
				setRetrieving(true);
			}
		}
		setMessagesVisible(messagesVisible);
		setConversationVisible(false);
	};

	const updateMsgUnseenCount = (targetId, type) => {
		let targets = [];

		switch (type) {
			case "users":
				targets = [...users];
				break;
			default:
				break;
		}

		let sortedTargetsByLatestMsg = [];
		targets.forEach((target) =>
			!target._id.toString().localeCompare(targetId.toString())
				? sortedTargetsByLatestMsg.unshift({
						...target,
						unseenCount: target.unseenCount
							? target.unseenCount + 1
							: 1,
				  })
				: sortedTargetsByLatestMsg.push({ ...target })
		);

		switch (type) {
			case "users":
				setUsers({ users: [...sortedTargetsByLatestMsg] });
				break;
			default:
				break;
		}
	};

	const updateReceiverStatus = (status) => {
		if (status === "online") {
			setIsReceiverOnline({ isReceiverOnline: true });
		}
	};

	const closeConversation = (receiverId) => {
		// leave room
		// condition: if the previous conversation on screen is from a board
		socket && socket.emit("leave", receiverId);
		setConversationVisible({
			conversationVisible: false,
		});
	};

	const updateConversation = (conversations) => {
		setConversations({ conversations });
	};

	const updateSenders = (senders) => {
		setSenders({ senders });
	};

	const onMsgEnterPress = (e, information) => {
		if (e.keyCode === 13) {
			e.preventDefault();
			if (information.message === "") {
				alert("Please enter content");
				return;
			}
			sendMessage(information);
		}
	};

	const sendMessage = (information) => {
		sendMessage(information).then(async (res) => {
			if (res.success) {
				const { user } = this.props;

				// upload all images to message upload folder
				if (imgs.length > 0) {
					await uploadMsgMediaMain(imgs);
				}

				const receiverIsNotFriend =
					information.type === "users" &&
					receiver.userId &&
					user.followers &&
					!user.followers.includes(receiver.userId) &&
					user.following &&
					!user.following.includes(receiver.userId);

				if (msgReqViewAll || receiverIsNotFriend) {
					const res = await handleMessageRequest(
						user._id,
						information.receiver,
						"accept"
					);

					if (res.success) {
						await getNotFriendUsers();
						setMessagesUsersNeedUpdate(true);
					}
				}

				// notify receiver with socket
				if (socket) {
					socket.emit("sendMessage", {
						fromId: information.sender,
						msg: information.message,
						imgs: information.imgFileName,
						type: information.type,
					});

					// update receiver status to online if socket receive this prompt
					// applicable only:
					// 1. receiver is initially offline
					// 2. receiver became online when the sender send the message
					socket.once("receiverIsOnline", () => {
						this.updateReceiverStatus("online");
					});
				}

				setConversations({
					conversations: [...conversations, { ...res.message }],
				});
				setMessage({ message: "" });
				setImgsFile({ imgsFile: [] });
				setImgs({ imgs: [] });
				setImgsDateNow({ imgsDateNow: [] });
				sendingMessage({ sendingMessage: false });
			} else {
				setSendingMessage({ sendingMessage: false });
			}
		});
		this.state.memtionedPerson.forEach((element) => {
			sendNotifications({
				notificationInfo: {
					userId: element.userId,
					item_id: this.props.user.userId,
					item_id2: this.state.receiver.type
						? this.state.receiver.type
						: "personal",
					component: "messages",
					action: "new",
				},
			});
		});
		setMemtionedPerson([]);
		setSearchResults([]);
	};

	const getNotFriendUsers = async () => {
		const messageRequests = await getMessageRequests(user.userId);
		if (messageRequests.success) {
			setNotFriendUsers({
				notFriendUsers: messageRequests.notFriendUsers,
			});
			setNotFriendLatestMsg({
				notFriendLatestMsg: messageRequests.notFriendLatestMsg,
			});
		}
	};

	const onMsgButtonPress = (information) => {
		if (information.message === "") {
			alert("Please enter content");
			return;
		}

		sendMessage(information);
		setSendingMessage({ sendingMessage: true });
	};

	const messageQueryChange = (e) => {
		setMessage({ message: e.target.value });
		const searchTerm = e.target.value.match(/@(\w+)?$/);
		if (searchTerm) {
			const trimmedSearchTerm = searchTerm[1] || ""; // Extract the search term without '@'
			if (membersInfo.members.length === 0) {
				const searchResults = [receiver];
				setSearchResults({ searchResults });
			} else {
				const searchResults = membersInfo.members.filter(
					(user) =>
						user.firstName
							.toLowerCase()
							.includes(trimmedSearchTerm.toLowerCase()) ||
						user.lastName
							.toLowerCase()
							.includes(trimmedSearchTerm.toLowerCase())
				);
				setSearchResults({ searchResults });
			}
		} else {
			setSearchResults({
				searchResults: [],
			});
		}
	};

	const handleUserSelection = (user) => {
		const regex = /@(\w+)?$/;
		const updatedMessage = message.replace(regex, () => {
			const name = user.firstName
				? `${user.firstName} ${user.lastName}`
				: user.name;
			return `@${name} `;
		});
		setSelectedMember({ selectedMember: user });
		setMessage({ message: updatedMessage });
		setSearchResults({ searchResults: [] });
		setMemtionedPerson({
			memtionedPerson: [...memtionedPerson, user],
		});
	};

	return (
		<div>
			{console.log("conversationVisible", conversationVisible)}
			{console.log("messagesVisible", messagesVisible)}
			<div className="container-large clearfix">
				<div
					id="wrapper"
					className={`athlete-profile${
						leftSidebarDisplay ? "" : " toggled-left"
					}`}
				>
					<LeftSidebar
						club={club}
						sidebarDisplay={leftSidebarDisplay}
						leftSidebarChange={leftSidebarChange}
					/>
					<div id="page-content-wrapper" className="message-section">
						<div className="container-fluid">
							<div className="row">
								<div className="col-lg-12-large">
									<div className="dashboard-heading">
										<h3>
											<a
												href="#menu-toggle-left"
												className="menu-toggle-left"
												id="show-after-left-close"
												style={
													!leftSidebarDisplay
														? {
																display:
																	"inline-block",
														  }
														: {}
												}
												onClick={(e) => {
													e.preventDefault();
													leftSidebarChange();
												}}
											>
												<span className="icon-bar"></span>
												<span className="icon-bar"></span>
												<span className="icon-bar"></span>
											</a>
											Messages
										</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div onClick={(e) => () => toggleMessages(e)}>
							{messagesVisible && !conversationVisible && (
								<PrivateMessagesContactBox
									retrieving={retrieving}
									users={users}
									onToggleMessages={toggleMessages}
									onLoadConversation={loadConversation}
								/>
							)}
						</div>
						{conversationVisible && (
							<PrivateMessageConversation
								receiver={receiver}
								senders={senders}
								imgsFile={imgsFile}
								imgs={imgs}
								message={message}
								conversations={conversations}
								socket={socket}
								isReceiverOnline={isReceiverOnline}
								onUpdateReceiverStatus={updateReceiverStatus}
								onCloseConversation={closeConversation}
								onUpdateConversation={updateConversation}
								onMsgReqViewAll={msgReqViewAll}
								onUpdateSenders={updateSenders}
								onMsgEnterPress={onMsgEnterPress}
								onMsgButtonPress={onMsgButtonPress}
								messageQueryChange={messageQueryChange}
								searchResults={searchResults}
								handleUserSelection={handleUserSelection}
								onUpdateMsgUnseenCount={updateMsgUnseenCount}
								sendingMessage={sendingMessage}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	};
};

export default connect(mapStateToProps, {})(PrivateMessages);
