/**
 * =====================================
 * REACT COMPONENT CLASS
 * =====================================
 * @date created: 17 August 2019
 * @authors: Waqas Rehmani
 *
 * This file defines the Header component. The class Header
 * is where the component is defined.
 *
 * This is a component defined for reusability.
 *
 * This component defines the header that is seen throughout the app.
 *
 */

// Importing libraries for setup
import React, { Component, createRef } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { io } from "socket.io-client";
import {
	logOutUser,
	updateEvents,
	updateGroups,
	updateGroupsAdmin,
	updateTeamsAdmin,
	updateTeams,
	updateNotification,
} from "../actions";
import {
	mobileQuery,
	getMessagesUsers,
	getMessagesBoards,
	getMessagesUsersAndBoards,
	getMessageRequests,
	handleMessageRequest,
	sendMessage,
	retrieveUserConversation,
	retrieveBoardConversation,
	filterMessagesUsers,
	filterMessagesBoards,
	uploadMsgMediaTemp,
	uploadMsgMediaMain,
	getGroupMembers,
	getTeamMembers,
	sendNotifications,
	getUserNotifications,
	getUserEvents,
	loadUserGroups,
	loadUserTeams,
	addReaction,
} from "../utils/api";

// Importing icons and pictures
import { FaUser } from "react-icons/fa";
import { getServerUrl } from "../utils/api";
import logo from "../assets/logo.svg";
import profileBlank from "../assets/profile_blank.png";
import notificationSound from "../assets/sounds/notification.mp3";
import Message from "./Message";
import MessageRequest from "./MessageRequest";
import Conversation from "./Conversation";
import PostActivityFilter from "./PostActivityFilter";
import SearchActivityFilter from "./SearchActivityFilter";
import { LocalNotifications } from "@capacitor/local-notifications";
import icon from "../assets/print-icon.png";
import Notifications from "../screens/Notifications";
import Avatar from "./common/avatar";
class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loginVisible: false,
			dropdownVisible: false,
			burgerMenuVisible: false,
			messagesVisible: false,
			messagesUsersNeedUpdate: false,
			messageRequestsVisible: false,
			conversationVisible: false,
			boardVisible: false,
			query: "",
			history: [],
			users: [],
			teams: [],
			groups: [],
			conversations: [],
			notFriendUsers: [],
			notFriendLatestMsg: [],
			socket: null,
			receiver: {
				_id: Math.floor(1e9 * Math.random()),
				name: "",
				profilePicture: "",
			},
			senders: [],
			isReceiverOnline: false,
			message: "",
			retrieving: true,
			searchUsersQuery: "",
			searchTeamsQuery: "",
			searchGroupsQuery: "",
			imgsFile: [],
			imgs: [],
			imgsDateNow: [],
			msgReqViewAll: false,
			tab: "people",
			sendingMessage: false,
			searchResults: [],
			membersInfo: {
				members: [],
				memberRequests: [],
				bannedUsers: [],
			},
			selectedMember: "",
			memtionedPerson: [],
			events: [],
			reload: false,
			previousNotificationCount: 0,
			socketConnected: false,
			notificationVisible: false,
			loading: false,
		};

		this.uploadMsgPictureRef = createRef();

		// this.notificationSound = new Audio(notificationSound);
	}
	componentWillUnmount() {
		// if (this.state.socket) {
		// 	const { tab, conversationVisible, receiver } = this.state;
		// 	// leave room
		// 	// condition: if the previous conversation on screen is from a board
		// 	if (tab === "boards" && conversationVisible) {
		// 		this.state.socket.emit("leave", receiver._id);
		// 	}

		// 	this.state.socket.disconnect();
		// 	this.setState({
		// 		socket: null,
		// 		retrieving: true,
		// 		socketConnected: false,
		// 	});
		// }
		clearInterval(this.interval);
	}

	async componentDidMount() {
		// Call all the required functions asynchronously using Promise.all
		this.socketConnection();
		await Promise.all([
			this.getMessagesUsersAndBoards(),
			this.recoverHistory(),
		]);

		// Restore the query props
		if (this.props.history.location.pathname === "/search/posts") {
			const urlParams = new URLSearchParams(window.location.search);
			const query = urlParams.get("query");
			this.setState({ query });
		}

		try {
			const { user } = this.props;

			// Get user events and update the events count
			const eventsResponse = await getUserEvents(user._id, "");
			if (eventsResponse.success === true) {
				this.props.updateEvents(eventsResponse.resultEvents.length);
			}

			let teamSlug = "";
			if (this.props.teamSlug) teamSlug = this.props.teamSlug;

			// Load user groups and update group counts
			const groupsResponse = await loadUserGroups(user._id, teamSlug);
			if (groupsResponse.success === true) {
				this.props.updateGroupsAdmin(groupsResponse.myGroups.length);
				this.props.updateGroups(groupsResponse.joinedGroups.length);
			}

			// Load user teams and update team counts
			const teamsResponse = await loadUserTeams(user._id);
			if (teamsResponse.success === true) {
				this.props.updateTeamsAdmin(teamsResponse.myTeams.length);
				this.props.updateTeams(teamsResponse.joinedTeams.length);
			}

			// Get user notifications and update the notification count
			const notificationResponse = await getUserNotifications(
				user.userId
			);
			if (notificationResponse.success === true) {
				this.props.updateNotification({
					...this.props.notificationCount,
					notification: notificationResponse.displayNotif,
				});
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}

		// Set up the interval to continuously fetch notifications
		this.interval = setInterval(() => {
			this.getUserNotifications();
		}, 10000); // Change 10000 to the desired interval time in milliseconds
	}

	componentDidUpdate(prevProps) {
		const {
			notificationCount: { notification },
		} = this.props;

		const prevNotificationCount =
			prevProps.notificationCount.notification.length;
		const currentNotificationCount = notification.length;

		// Check if the notification count has changed and it's not the first render
		if (
			currentNotificationCount !== prevNotificationCount &&
			currentNotificationCount > 0 &&
			prevNotificationCount > 0
		) {
			const newNotification = notification[currentNotificationCount - 1];
			let body = "";
			let title = "";

			switch (newNotification.action) {
				case "comment":
					body = `Commented on your post`;
					break;
				case "followed":
					body = `Started following you`;
					break;
				case "answer":
					body = `Answered on your question`;
					break;
				case "newMessage":
					body = `${
						newNotification.type === "personal"
							? "Sent you a text message"
							: `Mentioned you in ${newNotification.type} chat`
					}`;
					break;
				case "messagesReaction":
					body = `Reacted on your message`;
					break;
				default:
					break;
			}

			if (newNotification.user) {
				title = `${newNotification.user.firstName} ${newNotification.user.lastName}`;
			}

			this.handleNotification({ body, title });
		}
	}

	handleNotification = ({ title, body }) => {
		LocalNotifications.schedule({
			notifications: [
				{
					title: title,
					body: body,
					sound: notificationSound,
					id: 1,
					schedule: { at: new Date(Date.now() + 1000) },
					visibility: 1,
					vibrate: [1000, 500, 1000],
					smallIcon: icon,
				},
			],
		});
	};

	getUserNotifications = () => {
		const { user } = this.props;
		getUserNotifications(user.userId)
			.then((resp) => {
				if (resp.success === true) {
					const currentNotificationCount = resp.displayNotif;
					if (
						currentNotificationCount.length !==
						this.state.previousNotificationCount
					) {
						this.setState({
							previousNotificationCount:
								currentNotificationCount.length,
						});
					}
					this.props.updateNotification({
						...this.props.notificationCount,
						notification: currentNotificationCount,
					});
				}
			})
			.catch((error) => {
				// Handle errors if necessary
				console.error("Error fetching user notifications:", error);
			});
	};

	socketConnection = () => {
		const socket = io(getServerUrl().apiURL);
		socket.on("connect", () => {
			this.setState({ socket, socketConnected: true });
			socket.emit("addUser", this.props.user._id);
		});
		socket.on("getUsers", (users) => {
			console.log("Socket get users: " + JSON.stringify(users));
		});

		socket.on("getMessage", (data) => {
			// notify there is a new message from the actual sender
			// condition: 1. no active current chat
			//            2. current chat is not the sender
			const { conversationVisible } = this.state;
			if (!conversationVisible) {
				this.updateMsgUnseenCount(data.fromId, data.type);
			}
		});
		this.setState({ socket });
	};

	updateMsgUnseenCountFromChild = (fromId, type) => {
		const { conversationVisible } = this.state;
		if (!conversationVisible) {
			this.updateMsgUnseenCount(fromId, type);
		}
	};

	// Enables/disables dropdown with options for Login and Signup.
	toggleDropdown = () => {
		this.setState((prevState) => ({
			dropdownVisible: !prevState.dropdownVisible,
		}));
	};

	// toggle burger menu
	toggleBurgerMenu = () => {
		this.setState((prevState) => ({
			burgerMenuVisible: !prevState.burgerMenuVisible,
		}));
		this.props.onToggleBurgerMenu();
	};

	// close burger menu
	closeBurgerMenu = () => {
		this.setState({
			burgerMenuVisible: false,
		});
		this.props.onCloseBurgerMenu();
	};

	changeMsgTab = (key, callback) => {
		this.setState({ conversationVisible: false, tab: key }, callback);
		this.props.onHandleConversation("close");
	};

	updateReceiverStatus = (status) => {
		if (status === "online") {
			this.setState({ isReceiverOnline: true });
		}
	};

	toggleMessages = (e) => {
		const messagesVisible = !this.state.messagesVisible;
		this.props.onHandleConversation("close");

		this.setState({
			messagesVisible,
			conversationVisible: false,
		});
	};

	getMessagesUsersAndBoards = async () => {
		try {
			const res = await getMessagesUsersAndBoards(this.props.user.userId);

			if (res.success) {
				const {
					users,
					teams,
					groups,
					unseenCountsForUsers,
					unseenCountsForTeams,
					unseenCountsForGroups,
				} = res;

				// Map the users, teams, and groups data with unseen counts
				const updatedUsers = users.map((user, index) => ({
					...user,
					unseenCount: unseenCountsForUsers?.[index] || 0,
				}));

				const updatedTeams = teams.map((team, index) => ({
					...team,
					unseenCount: unseenCountsForTeams?.[index] || 0,
				}));

				const updatedGroups = groups.map((group, index) => ({
					...group,
					unseenCount: unseenCountsForGroups?.[index] || 0,
				}));

				// Calculate the total unseen counts for groups, teams, and personal
				const groupCount = updatedGroups.reduce(
					(total, obj) => total + obj.unseenCount,
					0
				);
				const teamsCount = updatedTeams.reduce(
					(total, obj) => total + obj.unseenCount,
					0
				);
				const personalCount = updatedUsers.reduce(
					(total, obj) => total + obj.unseenCount,
					0
				);

				// Update the notification count in your Redux store or component state
				this.props.updateNotification({
					...this.props.notificationCount,
					groupCount,
					teamsCount,
					personalCount,
				});

				// Update the state with the new data
				this.setState({
					users: updatedUsers,
					teams: updatedTeams,
					groups: updatedGroups,
					retrieving: false,
				});
			}
		} catch (error) {
			console.error("Error fetching messages, users, and boards:", error);
		}
	};

	updateMsgUnseenCount = (targetId, type) => {
		const { users, teams, groups } = this.state;
		const updatedTargets = [];

		switch (type) {
			case "users":
				users.forEach((user) => {
					if (user._id.toString() === targetId.toString()) {
						updatedTargets.push({
							...user,
							unseenCount: user.unseenCount
								? user.unseenCount + 1
								: 1,
						});
					} else {
						updatedTargets.push({ ...user });
					}
				});
				this.setState({
					users: updatedTargets,
					reload: !this.state.reload,
				});
				this.props.updateNotification({
					...this.props.notificationCount,
					personalCount: updatedTargets.reduce(
						(total, obj) => total + obj.unseenCount,
						0
					),
				});
				break;

			case "team":
				teams.forEach((team) => {
					if (team._id.toString() === targetId.toString()) {
						updatedTargets.push({
							...team,
							unseenCount: team.unseenCount
								? team.unseenCount + 1
								: 1,
						});
					} else {
						updatedTargets.push({ ...team });
					}
				});
				this.setState({
					teams: updatedTargets,
					reload: !this.state.reload,
				});
				this.props.updateNotification({
					...this.props.notificationCount,
					teamsCount: updatedTargets.reduce(
						(total, obj) => total + obj.unseenCount,
						0
					),
				});
				break;

			case "group":
				groups.forEach((group) => {
					if (group._id.toString() === targetId.toString()) {
						updatedTargets.push({
							...group,
							unseenCount: group.unseenCount
								? group.unseenCount + 1
								: 1,
						});
					} else {
						updatedTargets.push({ ...group });
					}
				});
				this.setState({
					groups: updatedTargets,
					reload: !this.state.reload,
				});
				this.props.updateNotification({
					...this.props.notificationCount,
					groupCount: updatedTargets.reduce(
						(total, obj) => total + obj.unseenCount,
						0
					),
				});
				break;

			default:
				break;
		}
	};

	getNotFriendUsers = async () => {
		const messageRequests = await getMessageRequests(
			this.props.user.userId
		);
		if (messageRequests.success) {
			this.setState({
				notFriendUsers: messageRequests.notFriendUsers,
				notFriendLatestMsg: messageRequests.notFriendLatestMsg,
			});
		}
	};

	handleMessageRequestsBtn = async (e) => {
		e.preventDefault();

		await this.getNotFriendUsers();

		// update the messages users list when closing the message requests and trying to go back to the messages tab
		if (this.state.messagesUsersNeedUpdate) {
			this.searchUsers("");
		}

		this.setState({
			messageRequestsVisible: !this.state.messageRequestsVisible,
			messagesUsersNeedUpdate: false,
		});
	};

	handleMessageRequest = async (e, receiverId, index, action) => {
		e.preventDefault();

		const res = await handleMessageRequest(
			this.props.user._id,
			receiverId,
			action
		);

		if (res.success) {
			document.getElementById(`msgreq-${action}`).className = `btn ${
				action === "accept"
					? "msgbtngrn"
					: action === "decline"
					? "btnred"
					: ""
			}`;
			setTimeout(() => {
				if (index >= 0) {
					let notFriendUsers = [...this.state.notFriendUsers];
					notFriendUsers.splice(index, 1);
					this.setState({
						notFriendUsers,
						messagesUsersNeedUpdate: action === "accept",
					});
				}
			}, 2500);
		}
	};

	loadConversation = async (
		e,
		receiverId = null,
		receiverInformation = null,
		msgReqViewAll = false
	) => {
		e.preventDefault();
		const senderUserId = this.props.user._id;
		this.setState({
			searchResults: [],
			memtionedPerson: [],
			membersInfo: {
				members: [],
				memberRequests: [],
				bannedUsers: [],
			},
		});
		const { conversationVisible, tab } = this.state;
		if (tab === "people") {
			try {
				const res = await retrieveUserConversation(
					senderUserId,
					receiverId
				);
				if (res.success) {
					// close conversation
					conversationVisible &&
						this.setState({
							conversationVisible: false,
						});
					this.props.onHandleConversation("close");

					// remove the unseen count of the current receiver for the private message (if any)
					const users = this.state.users.map((user) =>
						!user._id
							.toString()
							.localeCompare(receiverId.toString())
							? { ...user, unseenCount: 0 }
							: { ...user }
					);
					this.props.updateNotification({
						...this.props.notificationCount,
						personalCount: users.reduce(
							(total, obj) => total + obj.unseenCount,
							0
						),
					});
					this.setState({
						users,
						conversations: [...res.messages] || [],
						receiver: receiverInformation,
						isReceiverOnline: res.isReceiverOnline,
						conversationVisible: true,
						msgReqViewAll,
						message: "",
						imgsFile: [],
						imgs: [],
						imgsDateNow: [],
					});
					this.props.onHandleConversation("open");
				} else {
					// state conversation visibility to false in the parent
					this.props.onHandleConversation("close");

					this.setState({
						receiver: null,
						conversationVisible: false,
						message: "",
						imgsFile: [],
						imgs: [],
						imgsDateNow: [],
					});
				}
			} catch (error) {
				console.error("Error: ", error);
			}
		}
		if (tab === "teams") {
			try {
				const res = await retrieveBoardConversation(
					senderUserId,
					receiverId,
					receiverInformation.type
				);
				if (res.success) {
					// close conversation
					conversationVisible &&
						this.setState({
							conversationVisible: false,
						});
					this.props.onHandleConversation("close");

					this.state.socket &&
						this.state.socket.emit("join", receiverId);

					// remove the unseen count of the current receiver for the teams/groups conversation (if any)
					const teams = this.state.teams.map((team) =>
						!team._id
							.toString()
							.localeCompare(receiverId.toString())
							? { ...team, unseenCount: 0 }
							: { ...team }
					);
					this.setState({ teams });
					this.props.updateNotification({
						...this.props.notificationCount,
						teamsCount: teams.reduce(
							(total, obj) => total + obj.unseenCount,
							0
						),
					});

					this.setState({
						conversations: [...res.messages] || [],
						receiver: receiverInformation,
						senders: res.senders || [],
						conversationVisible: true,
						msgReqViewAll,
						message: "",
						imgsFile: [],
						imgs: [],
						imgsDateNow: [],
					});
					this.props.onHandleConversation("open");

					if (this.state.receiver.type === "team") {
						const resp = await getTeamMembers(
							this.state.receiver._id
						);
						if (resp.success === true) {
							this.setState({
								membersInfo: {
									members: resp.members,
									memberRequests: resp.memberRequests,
									bannedUsers: [],
								},
							});
						}
					}
				} else {
					// state conversation visibility to false in the parent
					this.props.onHandleConversation("close");

					this.setState({
						receiver: null,
						conversationVisible: false,
						message: "",
						imgsFile: [],
						imgs: [],
						imgsDateNow: [],
					});
				}
			} catch (error) {
				console.error("Error: ", error);
			}
		}
		if (tab === "groups") {
			try {
				const res = await retrieveBoardConversation(
					senderUserId,
					receiverId,
					receiverInformation.type
				);
				if (res.success) {
					// close conversation
					conversationVisible &&
						this.setState({
							conversationVisible: false,
						});
					this.props.onHandleConversation("close");

					this.state.socket &&
						this.state.socket.emit("join", receiverId);

					// remove the unseen count of the current receiver for the teams/groups conversation (if any)
					const groups = this.state.groups.map((group) =>
						!group._id
							.toString()
							.localeCompare(receiverId.toString())
							? { ...group, unseenCount: 0 }
							: { ...group }
					);
					this.setState({ groups });
					this.props.updateNotification({
						...this.props.notificationCount,
						groupCount: groups.reduce(
							(total, obj) => total + obj.unseenCount,
							0
						),
					});

					this.setState({
						conversations: [...res.messages] || [],
						receiver: receiverInformation,
						senders: res.senders || [],
						conversationVisible: true,
						msgReqViewAll,
						message: "",
						imgsFile: [],
						imgs: [],
						imgsDateNow: [],
					});
					this.props.onHandleConversation("open");

					if (this.state.receiver.type === "group") {
						const resp = await getGroupMembers(
							this.state.receiver._id
						);
						if (resp.success === true) {
							this.setState({
								membersInfo: {
									members: resp.members,
									memberRequests: resp.memberRequests,
									bannedUsers: resp.bannedUsers,
								},
							});
						}
					}
				} else {
					// state conversation visibility to false in the parent
					this.props.onHandleConversation("close");

					this.setState({
						receiver: null,
						conversationVisible: false,
						message: "",
						imgsFile: [],
						imgs: [],
						imgsDateNow: [],
					});
				}
			} catch (error) {
				console.error("Error: ", error);
			}
		}
	};

	updateConversation = (conversations) => {
		this.setState({ conversations });
	};

	updateSenders = (senders) => {
		this.setState({ senders });
	};

	onMsgButtonPress = (information) => {
		if (
			information.message.trim() === "" &&
			information.imgFileName.length === 0
		) {
			alert("Please enter content");
			return;
		}

		this.sendMessage(information);
		this.setState({ sendingMessage: true });
	};

	sendMessage = async (information) => {
		if (this.state.loading) {
			return; // Return if message is already being sent
		}
		this.setState({ loading: true }); // Disable the button

		await sendMessage(information).then(async (res) => {
			if (res.success) {
				const { imgs, msgReqViewAll, socket, conversations, receiver } =
					this.state;

				const { user } = this.props;

				// upload all images to message upload folder
				if (imgs.length > 0) {
					await uploadMsgMediaMain(imgs);
				}

				const receiverIsNotFriend =
					information.type === "users" &&
					receiver.userId &&
					user.followers &&
					!user.followers.includes(receiver.userId) &&
					user.following &&
					!user.following.includes(receiver.userId);

				if (msgReqViewAll || receiverIsNotFriend) {
					const res = await handleMessageRequest(
						user._id,
						information.receiver,
						"accept"
					);

					if (res.success) {
						await this.getNotFriendUsers();
						this.setState({ messagesUsersNeedUpdate: true });
					}
				}

				// notify receiver with socket
				if (socket) {
					socket.emit("sendMessage", {
						fromId: information.sender,
						msg: information.message,
						imgs: information.imgFileName,
						type: information.type,
						reply: information.reply,
					});

					// update receiver status to online if socket receive this prompt
					// applicable only:
					// 1. receiver is initially offline
					// 2. receiver became online when the sender send the message
					socket.once("receiverIsOnline", () => {
						this.updateReceiverStatus("online");
					});
				}

				this.setState({
					conversations: [...conversations, { ...res.message }],
					message: "",
					imgsFile: [],
					imgs: [],
					imgsDateNow: [],
					sendingMessage: false,
					loading: false,
				});
			} else {
				this.setState({ sendingMessage: false, loading: false });
			}
		});
		this.state.receiver.type && this.state.memtionedPerson
			? this.state.memtionedPerson.forEach((element) => {
					sendNotifications({
						notificationInfo: {
							userId: element.userId,
							item_id: this.props.user.userId,
							item_id2: this.state.receiver.type,
							item_id3: this.state.receiver._id,
							component: "messages",
							action: "new",
						},
					});
			  })
			: sendNotifications({
					notificationInfo: {
						userId: this.state.receiver.userId,
						item_id: this.props.user.userId,
						item_id3: this.state.receiver._id,
						item_id2: "personal",
						component: "messages",
						action: "new",
					},
			  });
		this.setState({
			memtionedPerson: [],
			searchResults: [],
		});
	};
	sendReaction = (messageId, emoji) => {
		addReaction(messageId, emoji).then(async (res) => {
			if (res.success) {
				const { socket } = this.state;
				// Emit the 'sendReaction' event to the backend with the messageId and reaction data
				if (socket) {
					socket.emit("sendReaction", { messageId, reaction: emoji });
				}
			} else {
				this.setState({ sendingMessage: false });
			}
		});
		this.state.receiver.userId &&
			sendNotifications({
				notificationInfo: {
					userId: this.state.receiver.userId,
					item_id: this.props.user.userId,
					item_id2: "personal",
					component: "messagesReaction",
					action: "react",
				},
			});
	};

	updateMessageReaction = (updatedMessage) => {
		this.setState((prevState) => {
			const updatedConversations = prevState.conversations.map((conv) =>
				conv._id === updatedMessage._id ? updatedMessage : conv
			);
			return { conversations: updatedConversations };
		});
	};

	updateMessage = (newMessage) => {
		this.setState({ message: newMessage });
	};

	messageQueryChange = (e) => {
		const searchTerm = e.target.value.match(/@(\w+)?$/);
		if (searchTerm) {
			const trimmedSearchTerm = searchTerm[1] || ""; // Extract the search term without '@'
			if (this.state.membersInfo.members.length === 0) {
				const searchResults = [this.state.receiver];
				this.setState({ searchResults });
			} else {
				const searchResults = this.state.membersInfo.members.filter(
					(user) =>
						user.firstName
							.toLowerCase()
							.includes(trimmedSearchTerm.toLowerCase()) ||
						user.lastName
							.toLowerCase()
							.includes(trimmedSearchTerm.toLowerCase())
				);
				this.setState({ searchResults });
			}
		} else {
			this.setState({
				searchResults: [],
			});
		}
	};

	handleUserSelection = (user) => {
		const { message } = this.state;
		const regex = /@(\w+)?$/;
		const updatedMessage = message.replace(regex, () => {
			const name = user.firstName
				? `${user.firstName} ${user.lastName}`
				: user.name;
			return `@${name} `;
		});
		this.setState({
			selectedMember: user,
			memtionedPerson: [...this.state.memtionedPerson, user],
			message: updatedMessage,
			searchResults: [],
		});
	};

	closeConversation = (receiverId) => {
		this.setState({
			conversationVisible: false,
		});
		// state conversation visibility to false in the parent
		this.props.onHandleConversation("close");
		// leave room
		// condition: if the previous conversation on screen is from a board
		this.state.tab === "boards" &&
			this.state.socket &&
			this.state.socket.emit("leave", receiverId);
	};

	attachMsgUnseenCount = (lst, type) => {
		let targets = [];

		switch (type) {
			case "people":
				targets = [...this.state.users];
				break;
			case "teams":
				targets = [...this.state.teams];
				break;
			case "groups":
				targets = [...this.state.groups];
				break;
			default:
				break;
		}
		const newList = lst.map((obj) => {
			const data = targets.find(
				(u) => !u._id.toString().localeCompare(obj._id)
			);
			if (data) {
				return { ...data };
			} else {
				return { ...obj, unseenCount: 0 };
			}
		});

		return newList;
	};

	updateUsersQuery = (e) => {
		this.setState({ searchUsersQuery: e.target.value });
	};

	handleUserKeyDown = (e) => {
		if (e.key === "Enter") {
			// only enable search other than empty string
			// if (e.target.value) {
			this.searchUsers(e.target.value);
			// }
		}
	};

	searchUsers = (searchUsersQuery) => {
		this.setState({ retrieving: true });

		if (searchUsersQuery) {
			filterMessagesUsers(
				searchUsersQuery,
				this.state.users,
				this.props.user.userId
			).then((res) => {
				if (res.success) {
					const users = this.attachMsgUnseenCount(
						res.users,
						"people"
					);

					this.setState({
						searchUsersQuery,
						users,
						retrieving: false,
					});
				}
			});
		} else {
			getMessagesUsers(this.props.user.userId).then((res) => {
				if (res.success) {
					const users = res.users.map((user, index) => ({
						...user,
						unseenCount:
							res.unseenCountsForUsers &&
							res.unseenCountsForUsers[index]
								? res.unseenCountsForUsers[index]
								: 0,
					}));

					this.setState({
						searchUsersQuery: "",
						users,
						retrieving: false,
					});
				}
			});
		}
	};

	updateBoardsQuery = (e) => {
		const { tab } = this.state;

		if (tab === "teams") {
			this.setState({ searchTeamsQuery: e.target.value });
		} else if (tab === "groups") {
			this.setState({ searchGroupsQuery: e.target.value });
		}
	};

	handleTeamKeyDown = (e) => {
		if (e.key === "Enter") {
			// only enable search other than empty string
			// if (e.target.value) {
			this.searchBoards(e.target.value);
			// }
		}
	};

	searchBoards = (searchBoardsQuery) => {
		const { tab, teams, groups } = this.state;
		const isTeam = tab === "teams";
		const currBoards = isTeam ? teams : groups;

		if (searchBoardsQuery) {
			filterMessagesBoards(searchBoardsQuery, currBoards).then((res) => {
				if (res.success) {
					const boards = this.attachMsgUnseenCount(res.boards, tab);

					if (isTeam) {
						this.setState({
							teams: boards,
							searchTeamsQuery: searchBoardsQuery,
						});
					} else {
						this.setState({
							groups: boards,
							searchGroupsQuery: searchBoardsQuery,
						});
					}
				}
			});
		} else {
			getMessagesBoards(this.props.user.userId, tab).then((res) => {
				if (res.success) {
					const boards = res.boards.map((board, index) => ({
						...board,
						unseenCount:
							res.unseenCountsForBoards &&
							res.unseenCountsForBoards[index]
								? res.unseenCountsForBoards[index]
								: 0,
					}));

					if (isTeam) {
						this.setState({ teams: boards, searchTeamQuery: "" });
					} else {
						this.setState({
							groups: boards,
							searchGroupsQuery: "",
						});
					}
				}
			});
		}
	};

	openUploadMsgPicture = () => {
		this.uploadMsgPictureRef.current.click();
	};

	uploadMsgPictureToTemp = () => {
		let msgPicture = this.uploadMsgPictureRef.current.files[0];
		let fileTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

		if (msgPicture === undefined) {
			alert("Please select image file to upload");
		} else if (fileTypes.indexOf(msgPicture.type) === -1) {
			alert("Please select file type of JPEG, JPG, PNG or GIF");
		} else {
			const dateNow = Date.now();
			const imageData = new FormData();
			imageData.append("mediaDateNow", dateNow);
			imageData.append("mediaToTempUpload", msgPicture);

			uploadMsgMediaTemp(imageData).then((imgUpload) => {
				this.setState({
					imgsFile: [...this.state.imgsFile, msgPicture],
					imgs: [...this.state.imgs, imgUpload.filename],
					imgsDateNow: [...this.state.imgsDateNow, dateNow],
				});
			});
		}
	};

	uploadMsgPictureToMessage = (imgs) => {
		uploadMsgMediaMain(imgs);
	};

	// Removing the photo from post.
	removePhoto = (filename) => {
		const { imgs, imgsFile, imgsDateNow } = this.state;

		let photoIndex = imgs.indexOf(filename);
		const newImgList = imgs.filter((img) => img !== filename);
		const newImgDateNowList = [...imgsDateNow];
		newImgDateNowList.splice(photoIndex, 1);

		const newImgFileList = [...imgsFile];
		newImgFileList.splice(photoIndex, 1);

		this.setState({
			imgs: newImgList,
			imgsDateNow: newImgDateNowList,
			imgsFile: newImgFileList,
		});
	};

	// Logs out the user.
	logOutUser = () => {
		if (window.confirm("Are you sure you wish to log out?")) {
			this.props.logOutUser();
			this.props.history.push("/signInUser");
			window.location.reload();
		}
	};

	recoverHistory = () => {
		let history = JSON.parse(localStorage.getItem("history"));

		this.setState({ history: history ? history : [] });
	};

	saveToStorage = (input) => {
		// remove old search input (same as the current input)
		let filteredInputs = [...this.state.history];
		filteredInputs = filteredInputs.filter(
			(prevInput) => prevInput !== input
		);

		const history = [input, ...filteredInputs];

		this.setState({ history, query: "" });

		localStorage.setItem("history", JSON.stringify(history));
	};

	handleSearchChange = (e) => {
		this.setState({ query: e.target.value });
	};

	handleSearchPress = (e, query) => {
		e.preventDefault();
		// only enable search other than empty string
		if (query) {
			this.saveToStorage(query);
			this.setState({ query });
			this.props.history.push({
				pathname: "/search/posts",
				search: `?query=${this.state.query}`,
			});
		}
	};
	handleNotificationClick = () => {
		this.setState({ notificationVisible: !this.state.notificationVisible });
	};
	handleNotificationClickForRestLinks = () => {
		this.setState({ notificationVisible: false });
	};

	render() {
		const {
			query,
			history,
			imgs,
			imgsFile,
			messagesVisible,
			messageRequestsVisible,
			message,
			conversationVisible,
			conversations,
			notFriendUsers,
			notFriendLatestMsg,
			users,
			teams,
			groups,
			tab,
			receiver,
			senders,
			isReceiverOnline,
			retrieving,
			searchUsersQuery,
			searchTeamsQuery,
			searchGroupsQuery,
			socket,
			msgReqViewAll,
			socketConnected,
			notificationVisible,
		} = this.state;
		let isMobile = window.matchMedia(mobileQuery).matches;
		const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

		if (!isMobile) {
			if (this.props.userSignedIn) {
				return (
					<div className="hdrsec">
						<div className="container">
							<div className="row">
								<div className="col-4 leftprt">
									<div className="row">
										<div className="col-6">
											<div className="logo">
												<img src="/uploads/images/logo.jpg" />
											</div>
										</div>
										<div className="col-6">
											<ul>
												<li>
													<Link
														className={
															this.props.location
																.pathname ===
															"/home"
																? "selsec"
																: ""
														}
														to={"/home"}
													>
														<img src="/uploads/images/home.svg" />
														<span>Feed</span>
													</Link>
												</li>
												<li>
													<Link
														className={
															this.props.location
																.pathname ===
															"/trending"
																? "selsec"
																: ""
														}
														to={"/trending"}
													>
														<img src="/uploads/images/trending.svg" />
														<span>Trending</span>
													</Link>
												</li>
												<li>
													<Link
														className={
															this.props.location
																.pathname ===
															"/questions"
																? "selsec"
																: ""
														}
														to={"/questions"}
													>
														<img src="/uploads/images/questions.svg" />
														<span>Answers</span>
													</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="col-4 centerprt">
									<div className="form-group">
										<input
											type="text"
											list="searchHistoryList"
											className="form-control"
											placeholder="Search"
											value={query}
											onChange={this.handleSearchChange}
											required
										/>
										<datalist id="searchHistoryList">
											{history.map((query) => (
												<option
													value={query}
													key={`query-${query}`}
												></option>
											))}
										</datalist>
										<button
											type="submit"
											onClick={(e) =>
												this.handleSearchPress(e, query)
											}
											className={`${
												!query ? "dislinks" : ""
											}`}
										>
											<img
												src="/uploads/images/search.svg"
												className={`${
													!query ? "blur" : ""
												}`}
											/>
										</button>
									</div>
								</div>

								<div className="col-4 leftprt">
									<ul>
										<li>
											<a href="#">
												<img src="/uploads/images/notification.svg" />
												<span>Alerts</span>
											</a>
										</li>
										<li>
											<a
												href="#"
												onClick={this.toggleMessages}
											>
												<img src="/uploads/images/messages.svg" />
												<span>Messages</span>
											</a>
										</li>
									</ul>
								</div>

								{messagesVisible && (
									<Message
										retrieving={retrieving}
										users={users}
										teams={teams}
										groups={groups}
										tab={tab}
										searchUsersQuery={searchUsersQuery}
										searchTeamsQuery={searchTeamsQuery}
										searchGroupsQuery={searchGroupsQuery}
										onUpdateBoardsQuery={
											this.updateBoardsQuery
										}
										onToggleMessages={this.toggleMessages}
										onHandleMessageRequestsBtn={
											this.handleMessageRequestsBtn
										}
										getNotFriendUsers={
											this.getNotFriendUsers
										}
										notFriendUsers={notFriendUsers}
										onLoadConversation={
											this.loadConversation
										}
										onUpdateUsersQuery={
											this.updateUsersQuery
										}
										onSearchUsers={this.searchUsers}
										onSearchBoards={this.searchBoards}
										onChangeMsgTab={this.changeMsgTab}
										handleUserKeyDown={
											this.handleUserKeyDown
										}
										handleTeamKeyDown={
											this.handleTeamKeyDown
										}
									/>
								)}

								{messageRequestsVisible && (
									<MessageRequest
										onHandleMessageRequestsBtn={
											this.handleMessageRequestsBtn
										}
										notFriendUsers={notFriendUsers}
										notFriendLatestMsg={notFriendLatestMsg}
										onHandleMessageRequest={
											this.handleMessageRequest
										}
										onLoadConversation={
											this.loadConversation
										}
									/>
								)}

								{conversationVisible && (
									<Conversation
										receiver={receiver}
										retrieving={retrieving}
										senders={senders}
										imgsFile={imgsFile}
										imgs={imgs}
										message={message}
										conversations={conversations}
										tab={tab}
										socket={socket}
										socketConnected={socketConnected}
										isReceiverOnline={isReceiverOnline}
										onUpdateReceiverStatus={
											this.updateReceiverStatus
										}
										onCloseConversation={
											this.closeConversation
										}
										onUpdateConversation={
											this.updateConversation
										}
										onMsgReqViewAll={msgReqViewAll}
										onUpdateSenders={this.updateSenders}
										onMsgButtonPress={this.onMsgButtonPress}
										sendReaction={this.sendReaction}
										updateMessageReaction={
											this.updateMessageReaction
										}
										messageQueryChange={
											this.messageQueryChange
										}
										updateMessage={this.updateMessage}
										searchResults={this.state.searchResults}
										handleUserSelection={
											this.handleUserSelection
										}
										onOpenUploadMsgPicture={
											this.openUploadMsgPicture
										}
										onOpenPictureViewer={
											this.props.openPictureViewer
										}
										uploadMsgPictureRef={
											this.uploadMsgPictureRef
										}
										uploadMsgPictureToTemp={
											this.uploadMsgPictureToTemp
										}
										onUpdateMsgUnseenCount={
											this.updateMsgUnseenCount
										}
										removePhoto={this.removePhoto}
										sendingMessage={
											this.state.sendingMessage
										}
									/>
								)}
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div className="header-container">
						<img
							src={logo}
							className="header-logo"
							onClick={() => this.props.history.push("/home")}
							alt=""
						/>

						<div className="header-buttons">
							<Link
								to={"/"}
								className={
									this.props.location.pathname === "/"
										? "header-button header-selected"
										: "header-button"
								}
							>
								W H A T ' S &nbsp; N E W
							</Link>

							<Link
								to={"/home"}
								className={
									this.props.location.pathname === "/home"
										? "header-button header-selected"
										: "header-button"
								}
								onClick={() => window.scrollTo(0, 0)}
							>
								H O M E
							</Link>

							<Link
								to={"/trending"}
								className={
									this.props.location.pathname === "/trending"
										? "header-selected header-button"
										: "header-button"
								}
							>
								T R E N D I N G
							</Link>
						</div>

						<div className="header-profile">
							<div>
								<FaUser
									className={
										this.state.dropdownVisible
											? "header-profile-drowndown-button header-selected"
											: "header-profile-drowndown-button"
									}
									onClick={this.toggleDropdown}
								/>
								<div
									className={
										this.state.dropdownVisible
											? "dropdown-visible dropdown"
											: "dropdown-invisible dropdown"
									}
								>
									<div
										className={"dropdown-item"}
										onClick={() => {
											this.toggleDropdown();
										}}
									>
										<Link to="/signInUser">L O G I N</Link>
									</div>
									<div
										className={"dropdown-item"}
										onClick={() => {
											this.props.history.push(
												"/signupUser"
											);
											this.toggleDropdown();
										}}
									>
										S I G N U P
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		} else {
			if (this.props.userSignedIn) {
				return (
					<>
						<div
							className={` ${
								isiOS ? " hdrsec-parent-ios" : " hdrsec-parent"
							}`}
						>
							<div
								className={`hdrsec ${
									isiOS ? " hdrsec-ios" : " hdrsec-fix"
								}`}
							>
								<ul>
									<li>
										<a
											href="#"
											onClick={this.toggleBurgerMenu}
										>
											<img src="/uploads/images/menu.svg" />
										</a>
									</li>
									<li>
										<Link
											to={"/home"}
											className={
												this.props.location.pathname ===
												"/home"
													? "selsec"
													: ""
											}
											onClick={() => {
												this.handleNotificationClickForRestLinks();
												this.closeBurgerMenu();
											}}
										>
											<img src="/uploads/images/home.svg" />
											<span>Feed</span>
										</Link>
									</li>
									<li>
										<Link
											to={"/trending"}
											className={
												this.props.location.pathname ===
													"/trending" ||
												this.props.location.pathname ===
													"/trending/posts" ||
												this.props.location.pathname ===
													"/trending/events" ||
												this.props.location.pathname ===
													"/trending/teams" ||
												this.props.location.pathname ===
													"/trending/groups" ||
												this.props.location.pathname ===
													"/trending/users" ||
												this.props.location.pathname ===
													"/trending/questions"
													? "selsec"
													: ""
											}
											onClick={() => {
												this.handleNotificationClickForRestLinks();
												this.closeBurgerMenu();
											}}
										>
											<img src="/uploads/images/trending.svg" />
											<span>Trending</span>
										</Link>
									</li>
									<li>
										<Link
											to={"/questions"}
											className={
												this.props.location.pathname ===
												"/questions"
													? "selsec"
													: ""
											}
											onClick={() => {
												this.handleNotificationClickForRestLinks();
												this.closeBurgerMenu();
											}}
										>
											<img src="/uploads/images/questions.svg" />
											<span>Answers</span>
										</Link>
									</li>
									<li>
										<Link
											to={"/search"}
											className={
												this.props.location.pathname ===
													"/search" ||
												this.props.location.pathname ===
													"/search/posts" ||
												this.props.location.pathname ===
													"/search/events" ||
												this.props.location.pathname ===
													"/search/teams" ||
												this.props.location.pathname ===
													"/search/groups" ||
												this.props.location.pathname ===
													"/search/users" ||
												this.props.location.pathname ===
													"/search/questions"
													? "selsec"
													: ""
											}
											onClick={() => {
												this.handleNotificationClickForRestLinks();
												this.closeBurgerMenu();
											}}
										>
											<img src="/uploads/images/search.svg" />
											<span>Search</span>
										</Link>
									</li>
									<li>
										<a
											className={
												notificationVisible
													? "selsec"
													: ""
											}
											onClick={() => {
												this.handleNotificationClick();
												this.closeBurgerMenu();
											}}
										>
											<>
												{this.props.notificationCount
													.notification.length >
													0 && (
													<div className="notification-count">
														{
															this.props
																.notificationCount
																.notification
																.length
														}
													</div>
												)}
												<img src="/uploads/images/notification.svg" />
												<span>
													{notificationVisible &&
														"Alerts"}
												</span>
											</>
										</a>
									</li>
									<li>
										<a
											className={
												messagesVisible ? "selsec" : ""
											}
											onClick={(e) => {
												this.toggleMessages(e);
												this.closeBurgerMenu();
												this.handleNotificationClickForRestLinks();
											}}
										>
											{this.props.notificationCount
												.personalCount +
												this.props.notificationCount
													.teamsCount +
												this.props.notificationCount
													.groupCount >
												0 && (
												<div className="notification-count">
													{this.props
														.notificationCount
														.personalCount +
														this.props
															.notificationCount
															.teamsCount +
														this.props
															.notificationCount
															.groupCount}
												</div>
											)}
											<img src="/uploads/images/messages.svg" />
											<span>Messages</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
						{messagesVisible && !conversationVisible && (
							<Message
								retrieving={retrieving}
								users={users}
								teams={teams}
								groups={groups}
								tab={tab}
								searchUsersQuery={searchUsersQuery}
								searchTeamsQuery={searchTeamsQuery}
								searchGroupsQuery={searchGroupsQuery}
								onUpdateBoardsQuery={this.updateBoardsQuery}
								onToggleMessages={this.toggleMessages}
								onHandleMessageRequestsBtn={
									this.handleMessageRequestsBtn
								}
								notFriendUsers={notFriendUsers}
								getNotFriendUsers={this.getNotFriendUsers}
								onLoadConversation={this.loadConversation}
								onUpdateUsersQuery={this.updateUsersQuery}
								onSearchUsers={this.searchUsers}
								onSearchBoards={this.searchBoards}
								onChangeMsgTab={this.changeMsgTab}
								handleUserKeyDown={this.handleUserKeyDown}
								handleTeamKeyDown={this.handleTeamKeyDown}
							/>
						)}

						{messageRequestsVisible && (
							<MessageRequest
								onHandleMessageRequestsBtn={
									this.handleMessageRequestsBtn
								}
								notFriendUsers={notFriendUsers}
								notFriendLatestMsg={notFriendLatestMsg}
								onHandleMessageRequest={
									this.handleMessageRequest
								}
								onLoadConversation={this.loadConversation}
							/>
						)}

						{conversationVisible && (
							<Conversation
								updateMsgUnseenCountFromChild={
									this.updateMsgUnseenCountFromChild
								}
								conversationVisible={conversationVisible}
								receiver={receiver}
								senders={senders}
								imgsFile={imgsFile}
								imgs={imgs}
								message={message}
								conversations={conversations}
								socket={socket}
								tab={tab}
								isReceiverOnline={isReceiverOnline}
								onUpdateReceiverStatus={
									this.updateReceiverStatus
								}
								onCloseConversation={this.closeConversation}
								onUpdateConversation={this.updateConversation}
								onMsgReqViewAll={msgReqViewAll}
								onUpdateSenders={this.updateSenders}
								onMsgButtonPress={this.onMsgButtonPress}
								sendReaction={this.sendReaction}
								updateMessageReaction={
									this.updateMessageReaction
								}
								messageQueryChange={this.messageQueryChange}
								updateMessage={this.updateMessage}
								searchResults={this.state.searchResults}
								handleUserSelection={this.handleUserSelection}
								onOpenUploadMsgPicture={
									this.openUploadMsgPicture
								}
								onOpenPictureViewer={
									this.props.openPictureViewer
								}
								uploadMsgPictureRef={this.uploadMsgPictureRef}
								uploadMsgPictureToTemp={
									this.uploadMsgPictureToTemp
								}
								onUpdateMsgUnseenCount={
									this.updateMsgUnseenCount
								}
								removePhoto={this.removePhoto}
								sendingMessage={this.state.sendingMessage}
							/>
						)}
						{notificationVisible && (
							<Notifications
								users={users}
								teams={teams}
								groups={groups}
								tab={tab}
								onToggleMessages={this.toggleMessages}
								onLoadConversation={this.loadConversation}
								onChangeMsgTab={this.changeMsgTab}
								updateNotification={
									this.props.updateNotification
								}
								onHandleNotificationClickForRestLinks={
									this.handleNotificationClickForRestLinks
								}
							/>
						)}

						{this.props.showPostActivityFilter && (
							<PostActivityFilter />
						)}

						{this.props.showSearchActivityFilter && (
							<SearchActivityFilter />
						)}

						{this.state.burgerMenuVisible ? (
							<>
								<div className={`mnmenu ${isiOS ? "ios" : ""}`}>
									<div className="tphd">
										<div className="userthumb">
											<div className="userbx">
												<Link
													to={
														"/profile/" +
														this.props.user.userId
													}
													onClick={() => {
														this.closeBurgerMenu();
													}}
												>
													<Avatar
														url={"/uploads/user/"}
														state={
															this.props.user
																.profilePicture
														}
														alt={"Logo"}
													/>
												</Link>
											</div>
											<div>
												<Link
													to={
														"/profile/" +
														this.props.user.userId
													}
													onClick={() => {
														this.closeBurgerMenu();
													}}
												>
													{`${this.props.user.firstName} ${this.props.user.lastName}`}
												</Link>
											</div>
										</div>
									</div>
									<div className="mnuen">
										<ul>
											{/* <li>
                                                <a href='#'>
                                                    <span>
                                                        <img src='/uploads/images/recent.svg' />
                                                    </span>{' '}
                                                    Most Recent
                                                </a>
                                            </li> */}
											<li>
												<Link
													to={"/groups/"}
													onClick={() => {
														this.closeBurgerMenu();
													}}
												>
													<div className="meanu-count">
														<div>
															<span>
																<img src="/uploads/images/group.svg" />
															</span>{" "}
															Groups
														</div>
														<div>
															{this.props
																.groupsCount +
																this.props
																	.myGroupsCount >
																0 &&
																this.props
																	.groupsCount +
																	this.props
																		.myGroupsCount}
														</div>
													</div>
												</Link>
											</li>
											<li>
												<Link
													to={"/teams/"}
													onClick={() => {
														this.closeBurgerMenu();
													}}
												>
													<div className="meanu-count">
														<div>
															<span>
																<img src="/uploads/images/team.svg" />
															</span>{" "}
															Teams
														</div>
														<div>
															{this.props
																.teamsCount +
																this.props
																	.myTeamsCount >
																0 &&
																this.props
																	.teamsCount +
																	this.props
																		.myTeamsCount}
														</div>
													</div>
												</Link>
											</li>
											<li>
												<Link
													to={
														"/profile/" +
														this.props.user.userId +
														"/follow/followers"
													}
													onClick={() => {
														this.closeBurgerMenu();
													}}
												>
													<div className="meanu-count">
														<div>
															<span>
																<img src="/uploads/images/follow.svg" />
															</span>{" "}
															Your Followers{" "}
														</div>
														<div>
															{this.props.user
																.followers
																.length > 0 &&
																this.props.user
																	.followers
																	.length}
														</div>
													</div>
												</Link>
											</li>
											<li>
												<Link
													to={"/questions/own/"}
													onClick={() => {
														this.closeBurgerMenu();
													}}
												>
													<div className="meanu-count">
														<div>
															<span>
																<img src="/uploads/images/questions.svg" />
															</span>{" "}
															Your Questions
														</div>
														<div></div>
													</div>
												</Link>
											</li>
											<li>
												<Link
													to={"/events/"}
													onClick={() => {
														this.closeBurgerMenu();
													}}
												>
													<div className="meanu-count">
														<div>
															<span>
																<img src="/uploads/images/flag.svg" />
															</span>{" "}
															Events
														</div>
														<div>
															{this.props
																.eventsCount >
																0 &&
																this.props
																	.eventsCount}
														</div>
													</div>
												</Link>
											</li>
											<li>
												<Link
													to={"/saved/"}
													onClick={() => {
														this.closeBurgerMenu();
													}}
												>
													<div className="meanu-count">
														<div>
															<span>
																<img src="/uploads/images/bookmark.svg" />
															</span>{" "}
															Saved
														</div>
														<div>{}</div>
													</div>
												</Link>
											</li>
											{/* <li>
                                                <a href='#'>
                                                    <span>
                                                        <img src='/uploads/images/setting.svg' />
                                                    </span>{' '}
                                                    Settings
                                                </a>
                                            </li> */}
											<li onClick={this.logOutUser}>
												<a href="#">
													<span>
														<img src="/uploads/images/logout.svg" />
													</span>
													{" Logout "}
												</a>
											</li>
										</ul>
									</div>

									{/* <div className='mnmnu'>
                                        <p>
                                            <a className='btnspl' href='#'>
                                                Go To My Planner
                                            </a>
                                        </p>
                                        <span className='small text-center'>
                                            Powered by: Coaching Mate
                                        </span>
                                    </div> */}
								</div>
								<div
									className="blurField"
									onClick={this.closeBurgerMenu}
								></div>
							</>
						) : (
							<></>
						)}
					</>
				);
			} else {
				return (
					<div className="hdrsec">
						<ul>
							<li>
								<Link
									to={"/home"}
									className={
										this.props.location.pathname === "/home"
											? "selsec"
											: ""
									}
								>
									<img src="/uploads/images/home.svg" />
									<span>Home</span>
								</Link>
							</li>
							<li>
								<Link
									to={"/"}
									className={
										this.props.location.pathname === "/"
											? "header-button header-selected"
											: "header-button"
									}
								>
									W H A T ' S &nbsp; N E W
								</Link>
							</li>
							<li>
								<Link
									to={"/trending"}
									className={
										this.props.location.pathname ===
										"/trending"
											? "selsec"
											: ""
									}
								>
									<img src="/uploads/images/trending.svg" />
									<span>Trending</span>
								</Link>
							</li>
						</ul>
					</div>
				);
			}
		}
	}
}

const mapStateToProps = (state) => {
	return {
		userSignedIn: state.auth.userSignedIn,
		user: state.auth.user,
		showPostActivityFilter: state.auth.showPostActivityFilter,
		showSearchActivityFilter: state.auth.showSearchActivityFilter,
		notificationCount: state.auth.notificationCount,
		eventsCount: state.auth.eventsCount,
		groupsCount: state.auth.groupsCount,
		myGroupsCount: state.auth.myGroupsCount,
		myTeamsCount: state.auth.myTeamsCount,
		teamsCount: state.auth.teamsCount,
	};
};

const mapDispatchToProps = {
	updateEvents,
	updateGroups,
	updateGroupsAdmin,
	updateNotification,
	updateTeamsAdmin,
	updateTeams,
	logOutUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
