import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { hideViewSession, showAddSession } from '../../../actions';
import Modal from 'react-bootstrap/Modal';
import * as API from '../../../utils/api.js';
import renderHTML from 'react-render-html';
import ReactToPrint from 'react-to-print';
const ViewSession = (props) => {
	const [athleteLevel, setAthleteLevel] = useState('');
	const [distance, setDistance] = useState('');
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [perceivedEfforts, setPerceivedEfforts] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
	const [sessTime, setSessTime] = useState('');
	const [activityType, setActivityType] = useState('');
	const [unit, setUnit] = useState('');
	const [hours, setHours] = useState('');
	const [minutes, setMinutes] = useState('');
	const [rpeLoad, setRpeLoad] = useState('');
	const [keywords, setKeywords] = useState([]);
	const [programData, setProgramData] = useState([]);
	const [familyName, setFamilyName] = useState({});
	const [sessionImage, setSessionImage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [alertClass, setAlertClass] = useState('');
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		var data = {};
		data['sessionId'] = props.sessionId;
		data['clubId'] = props.club._id;

		API.getSessionById(data).then(sessionData => {
			setAthleteLevel(sessionData.session.athleteLevel);
			setDistance(sessionData.session.distance.$numberDecimal);
			setTitle(sessionData.session.title);
			setDescription(sessionData.session.description);
			setPerceivedEfforts(sessionData.session.perceivedEfforts);
			setSessTime(sessionData.session.sessTime);
			setActivityType(sessionData.session.activityType);
			setUnit(sessionData.session.unit);
			setHours(sessionData.session.hours);
			setMinutes(sessionData.session.minutes);
			setRpeLoad(sessionData.session.rpeLoad);
			setKeywords(sessionData.session.keywords);
			setProgramData(sessionData.programs);
			setFamilyName(sessionData.session.familyName);
			setSessionImage(sessionData.session.image);
			setLoading(false);
		});
	}, [props.sessionId, props.club._id]);

	const handleClose = () => {
		props.hideViewSession();
	};

	const EditSession = (e, id) => {
		e.preventDefault();
		props.showAddSession(id);
	};

	if (loading) {
		let keywordsList = [];
		let programLists = [];
		for (let i = 0; i < keywords.length; i++) {
			keywordsList.push(<p>{keywords[i]}</p>);
		}
		for (let i = 0; i < programData.length; i++) {
			programLists.push(<li>{programData[i].title}</li>);
		}
	}
	return (
		<Modal
			centered
			size="lg"
			show={true}
			onHide={handleClose}
			dialogClassName="modal-70w planner-dialog adsesn"
		>
			<Modal.Header closeButton>
				<Modal.Title>Session Details</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='contin clearfix'>
					{successMessage && <div className={alertClass + " alert"}>{successMessage}</div>}
					<div className='row'>
						<div className='col-md-8'>
							<div className="btn-group" role="group" aria-label="...">
								<button type="button" className={"btn btn-default elite " + (athleteLevel.indexOf('Elite') !== -1 ? 'active' : '')}>Elite</button>
								<button type="button" className={'btn btn-default advanced ' + (athleteLevel.indexOf('Advanced') !== -1 ? 'active' : '')}>Advanced</button>
								<button type="button" className={'btn btn-default intermediateadvanced ' + (athleteLevel.indexOf('Intermediate/Advanced') !== -1 ? 'active' : '')}>Int/Adv</button>
								<button type="button" className={'btn btn-default intermediate ' + (athleteLevel.indexOf('Intermediate') !== -1 ? 'active' : '')}>Intermediate</button>
								<button type="button" className={'btn btn-default lowintermediate ' + (athleteLevel.indexOf('Low/Intermediate') !== -1 ? 'active' : '')}>Low/Int</button>
								<button type="button" className={'btn btn-default novice ' + (athleteLevel.indexOf('Novice') !== -1 ? "active " : "")}>Novice</button>
							</div>
							<div className="media sec1">
								<div className="media-left">
									<a href="#">
										<img className="media-object max-width25" src={API.getServerUrl().apiURL + '/uploads/images/' + activityType.imgUrl} alt="" style={{ width: '35px' }}></img>
									</a>
								</div>
								<div className="media-body">
									<h4 className="media-heading"><strong>{activityType.title}: </strong>{title}</h4>
									<p className="sts"><span className="distance">{distance}</span> <span className="unit">{unit}</span>    <span>|</span>   <span className="hour">{hours}</span> hour <span className="minutes">{minutes}</span> min    <span>|</span>    Load <span className="rpe_load">{rpeLoad}</span></p>
								</div>
							</div>
							<div className="sec2">
								<h4>Rating Perceived Effort (RPE)</h4>
								<div className="strip-clr clearfix">
									<div style={{ width: parseInt(100 * (perceivedEfforts[9] / sessTime)) + '%', background: '#000000' }} ><span className="maximal-time text-line">{perceivedEfforts[9]}</span><span>Maximal</span></div>
									<div style={{ width: parseInt(100 * (perceivedEfforts[8] / sessTime)) + '%', background: '#330066' }} ><span className="harder-time text-line">{perceivedEfforts[8]}</span><span>9/10</span></div>
									<div style={{ width: parseInt(100 * (perceivedEfforts[7] / sessTime)) + '%', background: '#663399' }} ><span className="very-hard-time text-line">{perceivedEfforts[7]}</span><span>8/10</span></div>
									<div style={{ width: parseInt(100 * (perceivedEfforts[6] / sessTime)) + '%', background: '#cc3333' }} ><span className="hard-time text-line">{perceivedEfforts[6]}</span><span>Very Hard</span></div>
									<div style={{ width: parseInt(100 * (perceivedEfforts[5] / sessTime)) + '%', background: '#ff3333' }} ><span className="somewaht-hard text-line">{perceivedEfforts[5]}</span><span>6/10</span></div>
									<div style={{ width: parseInt(100 * (perceivedEfforts[4] / sessTime)) + '%', background: '#ff6633' }} ><span className="moderate-time text-line">{perceivedEfforts[4]}</span><span>Hard</span></div>
									<div style={{ width: parseInt(100 * (perceivedEfforts[3] / sessTime)) + '%', background: '#ff9933' }} ><span className="easy-time text-line">{perceivedEfforts[3]}</span><span>Some What Hard</span></div>
									<div style={{ width: parseInt(100 * (perceivedEfforts[2] / sessTime)) + '%', background: '#ffff00' }} ><span className="easier-time text-line">{perceivedEfforts[2]}</span><span>Moderate</span></div>
									<div style={{ width: parseInt(100 * (perceivedEfforts[1] / sessTime)) + '%', background: '#00ff00' }} ><span className="very-easy-time text-line">{perceivedEfforts[1]}</span><span>Easy</span></div>
									<div style={{ width: parseInt(100 * (perceivedEfforts[0] / sessTime)) + '%', background: '#00ffff' }} ><span className="very-very-easy-time text-line">{perceivedEfforts[0]}</span><span>Very Very Easy</span></div>
								</div>
							</div>
							<div className="sec4">
								<h4>Description</h4>
								{
									description && description.length > 0
										? description.map((des, index2) => (
											<React.Fragment key={index2}>
												{typeof des === 'object' ? (
													<>
														<span>
															{des.title}
														</span>
														<span>
															{des.descriptionList?.[0]}
															{/* dangerouslySetInnerHTML={{
																__html: des.descriptionList?.[0],
															}} */}
														</span>
													</>
												) : (
													<>
														{/* <span>dangerouslySetInnerHTML={{ __html: des }}
														</span> */}
														<span>{des}</span>
													</>
												)}
											</React.Fragment>
										))
										: null
								}
							</div>
							<div className="sec4" style={{ display: 'none' }}>
								<h4>Videos</h4>
								<p className="videos-sec"></p>
							</div>
						</div>
						<div className="col-md-4">
							<div>
								<h4>Session Status:</h4>
								<span id="cm2_session_status">None</span>
							</div>
							<div id="session-view-image" style={{ display: sessionImage === undefined || sessionImage === "" ? 'none' : '' }}>
								<img src={sessionImage === undefined || sessionImage === "" ? '' : API.getServerUrl().apiURL + '/uploads/session/' + sessionImage} style={{ width: '100%', marginTop: '10px' }} />
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);

};

const mapStateToProps = state => {
	return {
		sessionId: state.planner.modalsParams.id
	};
};

export default connect(mapStateToProps, { hideViewSession, showAddSession })(ViewSession);