import React from 'react';

export const PlannerToPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <div style={{ marginLeft: "50px" }}>
        {props.programSessions &&
          props.programSessions.map((planner, index) => (
            <div key={index}>
              <h1
                style={{
                  fontWeight: '600',
                  padding: '30px 0px 0px 0px',
                  fontSize: '34px',
                }}
              >
                {props.displayPlanner ? planner.planner_title : ''}
              </h1>
              <h3
                style={{ fontWeight: '600', fontSize: '24px' }}
              >
                {props.displayPrograms ? planner.program_title : ''}
              </h3>
              {planner.sessions &&
                planner.sessions.map((session, index2) => (
                  <div key={index2}>
                    <div>
                      <h5
                        style={{
                          fontWeight: '600',
                          padding: '30px 0px 20px 0px',
                          fontSize: '24px',
                        }}
                      >
                        {session.title}{' '}
                        <small
                          style={{
                            fontWeight: '600',
                            fontSize: '24px',
                          }}
                        >
                          {session.distance.$numberDecimal +
                            ' ' +
                            session.unit}{' '}
                          | {session.hours + ' hour' + ' ' + session.minutes + ' minutes'} | {'Load ' + session.rpeLoad}
                        </small>
                      </h5>
                    </div>
                    {session.description &&
                      session.description.description.map(
                        (des, index3) => (
                          <div key={index3}>
                            {typeof des === 'string' ? (
                              <span
                                style={{
                                  color: session.color,
                                  fontSize: '20px',
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: des,
                                }}
                              />
                            ) : (
                              <>
                                <span
                                  style={{
                                    color: session.color,
                                    fontSize: '20px',
                                  }}
                                >
                                  {des.title}
                                </span>
                                {des.descriptionList?.[0] && (
                                  <span
                                    style={{
                                      color: session.color,
                                      fontSize: '20px',
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: des.descriptionList[0],
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        )
                      )}
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
});

export default PlannerToPrint;
