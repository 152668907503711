import React from "react";
import { Link } from "react-router-dom";

const GoBackLink = ({ to, title }) => {
	return (
		<div className="frgtbxin" style={{ justifyContent: "space-around" }}>
			<Link to={to}>{title}</Link>
		</div>
	);
};

export default GoBackLink;
