import React from 'react';
export const ComponentToPrint = React.forwardRef((props, ref) => {
    console.log('props',props.programSessions);
    return (
        <div ref={ref}>
            {
                props.programSessions && props.programSessions.length > 0 ? (
                    props.programSessions.map((programs) => (
                        <div style={{ marginLeft: '50px' }}>
                            <h1 style={{ fontWeight: '600', padding: '30px 0px 20px 0px' }}>
                                {props.planner && props.planner.title ? props.planner.title : ''}
                            </h1>
                            <h2 style={{ fontWeight: '600' }}>
                                {programs.program_name}
                            </h2>

                            {programs && programs.sessions.length > 0 ? (
                               programs.sessions.map((session) => (
                                    <React.Fragment key={session.title}>
                                        <div style={{ padding: '20px 0px' }}>
                                            <h3 style={{ fontWeight: '600' }}>
                                                {session.title}{' '}
                                                <small style={{ fontWeight: '600' }}>
                                                    {session.distance.$numberDecimal +
                                                        ' ' +
                                                        session.unit}{' '}
                                                    | {session.hours + ' hour' + ' ' + session.minutes + ' minutes'} | {'Load ' + session.rpeLoad}
                                                </small>
                                            </h3>
                                        </div>
                                        {session.description && session.description.length > 0
                                            ? session.description.map((des, index2) => (
                                                <React.Fragment key={index2}>
                                                    {typeof des === 'object' ? (
                                                        <>
                                                            <span
                                                                style={{ color: session.color, fontSize: '20px' }}
                                                            >
                                                                {des.title}
                                                            </span>
                                                            <span
                                                                style={{ color: session.color, fontSize: '20px' }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: des.descriptionList?.[0],
                                                                }}
                                                            ></span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span
                                                                style={{ color: session.color, fontSize: '20px' }}
                                                                dangerouslySetInnerHTML={{ __html: des }}
                                                            ></span>
                                                        </>
                                                    )}
                                                </React.Fragment>
                                            ))
                                            : null}
                                    </React.Fragment>
                                ))
                            ) : (
                                ''
                            )}
                        </div>
                    ))
                ) : ''
            }

        </div>
    );
});

export default ComponentToPrint;