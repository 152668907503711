/**
 * =====================================
 * REACT COMPONENT CLASS
 * =====================================
 * @date created: 14 September 2019
 * @authors: Jay Parikh, Waqas Rehmani
 *
 * This file defines the Comment component. The class Comment
 * is where the component is defined.
 *
 * This is a component defined for reusability.
 *
 * This component shows a comment made on a post.
 *
 */

// Importing libraries for setup
import React, { Component, useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUser, getServerUrl, updateComment } from "../utils/api";
import { assignTimeAgo } from "../utils/helper";

// Importing icons and pictures
import profileBlank from "../assets/profile_blank.png";
import loading from "../assets/loading2.svg";
import CommentReply from "./CommentReply";
import Avatar from "./common/avatar";
import { v4 as uuidv4 } from "uuid";

class Comment extends Component {
	state = {
		commentUser: {},
		showReplies: false,
		reply: {
			replyId: "",
			userId: this.props.userId,
			description: "",
			time: new Date().toISOString(),
			likes: [],
			dislikes: [],
		},
		showRepliesLoading: true,
		editComment: false,
		editedComment: "",
		editedReply: "",
		loading: false,
	};

	componentDidMount() {
		if (this.props.comment.userId === this.props.currentUserId) {
			this.setState({
				commentUser: this.props.user,
			});
		} else {
			getUser(this.props.comment.userId, this.props.token)
				.then((user) => {
					this.setState({
						commentUser: user,
					});
				})
				.catch((error) => {
					console.error("Error fetching user data:", error);
				});
		}
	}
	// Adds/removes kudos for the comment and makes API call via App.js to edit comment.
	editComment = (e) => {
		e.preventDefault();
		let allComments = this.props.post.comments.filter(
			(c) => c.commentId !== this.props.comment.commentId
		);
		let currentComment = {
			commentId: this.props.comment.commentId,
			description: this.props.comment.description,
			userId: this.props.comment.userId,
			time: this.props.comment.time,
			replies: this.props.comment.replies,
			likes: this.props.comment.likes,
			dislikes: this.props.comment.dislikes,
		};
		if (currentComment.likes.includes(this.props.currentUserId)) {
			currentComment.likes = currentComment.likes.filter(
				(l) => l !== this.props.currentUserId
			);
		} else {
			currentComment.likes = [
				...currentComment.likes,
				this.props.currentUserId,
			];
		}
		allComments = [...allComments, currentComment];
		allComments.sort((a, b) => Date.parse(a.time) - Date.parse(b.time));
		let post = this.props.post;
		post.comments = allComments;
		this.props.createComment(
			this.props.post.postId,
			post.comments,
			this.props.user._id,
			this.props.user.userId
		);
	};

	// Makes API call via App.js to delete comment.
	deleteComment = async () => {
		if (window.confirm("Are you sure you wish to delete this comment?")) {
			const commentIdToDelete = this.props.comment.commentId;
			const updatedComments = this.props.post.comments.filter(
				(comment) => comment.commentId !== commentIdToDelete
			);
			const updatedPost = {
				...this.props.post,
				comments: updatedComments,
			};
			try {
				const updatedPostResponse = await updateComment(
					updatedPost.postId,
					updatedComments
				);

				// Call the onUpdateComments function to update the parent component's state
				this.props.onUpdateComments(updatedPostResponse);
			} catch (error) {
				console.error("Error updating comment:", error);
			}
		}
	};

	deleteReply = (commentId, replyId) => {
		if (window.confirm("Are you sure you wish to delete this reply?")) {
			let allComments = [...this.props.post.comments];

			const commentToUpdate = allComments.find(
				(comment) => comment.commentId === commentId
			);

			if (commentToUpdate) {
				commentToUpdate.replies = commentToUpdate.replies.filter(
					(reply) => reply.replyId !== replyId
				);
			}

			this.props.createComment(
				this.props.post.postId,
				allComments,
				this.props.user._id,
				this.props.user.userId
			);
		}
	};
	toggleReplies = () => {
		if (!this.props.showField) {
			this.props.onUpdateTrackShowField();
			this.setState({
				showRepliesLoading: false,
			});
		} else {
			this.props.onResetTrackShowField();
		}
	};
	handleChange = (e) => {
		const reply = this.state.reply;
		reply.description = e.target.value;
		reply.time = new Date().toISOString();
		this.setState({ reply });
	};
	onEnterPress = (e, commentId) => {
		// if (e.keyCode === 13 && !e.shiftKey) {
		// 	e.preventDefault();
		// 	this.createCommentReply(commentId);
		// }
	};
	// add reply to a comment
	createCommentReply = (commentId) => {
		const reply = this.state.reply;
		if (reply.description === "") return;
		const trimmedReply = reply.description.trim();
		if (trimmedReply === "") {
			this.setState({
				reply: { description: "" },
			});
			return;
		}
		let allComments = [...this.props.post.comments];
		const commentToUpdate = allComments.find(
			(comment) => comment.commentId === commentId
		);

		let newReply = {
			replyId: uuidv4(),
			userId: this.props.user.userId,
			description: this.state.reply.description,
			time: new Date().toISOString(),
			likes: [],
			dislikes: [],
		};
		commentToUpdate.replies.push(newReply);
		this.props
			.createComment(
				this.props.post.postId,
				allComments,
				this.props.user._id,
				this.props.user.userId
			)
			.then(() => {
				this.setState({
					reply: {
						replyId: "",
						userId: this.props.user.userId, // Use the user ID from props
						description: "",
						time: new Date().toISOString(),
						likes: [],
						dislikes: [],
					},
					loading: false,
				});
			});

		this.setState({ loading: true });
	};

	handleCommentUpdate = (e) => {
		this.setState({ editedComment: e.target.value });
	};
	showEditComment = (e) => {
		e.preventDefault();
		this.setState({
			editComment: true,
			editedComment: this.props.comment.description,
		});
	};
	updateComment = async (e) => {
		e.preventDefault();
		if (this.state.editedComment === "") {
			return;
		}
		let allComments = this.props.post.comments.filter(
			(c) => c.commentId !== this.props.comment.commentId
		);
		let currentComment = {
			commentId: this.props.comment.commentId,
			description: this.state.editedComment,
			userId: this.props.comment.userId,
			time: this.props.comment.time,
			replies: this.props.comment.replies,
			likes: this.props.comment.likes,
			dislikes: this.props.comment.dislikes,
			editTime: Date.now,
		};
		allComments = [...allComments, currentComment];
		allComments.sort((a, b) => Date.parse(a.time) - Date.parse(b.time));
		let post = await updateComment(this.props.post.postId, allComments);
		this.props.onUpdateComments(post);
		this.setState({ editComment: false });
	};
	updateReply = (commentId, replyId, updatedReply) => {
		let allComments = [...this.props.post.comments];

		const commentToUpdate = allComments.find(
			(comment) => comment.commentId === commentId
		);

		if (commentToUpdate) {
			const replyToUpdate = commentToUpdate.replies.find(
				(reply) => reply.replyId === replyId
			);

			if (replyToUpdate) {
				Object.assign(replyToUpdate, updatedReply);
			}
		}

		this.props.createComment(
			this.props.post.postId,
			allComments,
			this.props.user._id,
			this.props.user.userId
		);
	};

	// Render method for Comment
	render() {
		// Formatting time to be viewed in the Comment component
		let unformattedTime = new Date(Date.parse(this.props.comment.time));
		let formattedTime =
			unformattedTime.getHours() +
			":" +
			unformattedTime.getMinutes() +
			" " +
			unformattedTime.getDate() +
			"/" +
			unformattedTime.getMonth() +
			"/" +
			unformattedTime.getFullYear();
		return (
			<div className="comntbx">
				<div className="usrtop">
					<div className="row">
						<div className="col-2">
							<div className="userthumb">
								<a className="userbx">
									<Avatar
										url={"/uploads/user/"}
										state={
											this.state.commentUser
												.profilePicture
										}
										alt={" profile picture"}
									/>
								</a>
							</div>
						</div>
						<div className="col-10 nopad pt-1">
							<Link to={"/profile/" + this.props.comment.userId}>
								{`${this.state.commentUser.firstName} 
                                ${this.state.commentUser.lastName}`}
							</Link>
							<span className="small pstim">
								{assignTimeAgo(this.props.comment.time)}
							</span>
						</div>
					</div>
				</div>
				<div className="cmntxt">
					<p>
						{this.state.editComment ? (
							<span>
								<input
									type="text"
									className="form-control"
									value={this.state.editedComment}
									onChange={this.handleCommentUpdate}
								/>
								<a
									style={{ cursor: "pointer" }}
									onClick={this.updateComment}
								>
									Update
								</a>
							</span>
						) : (
							this.props.comment.description
						)}
					</p>
					{this.props.comment.userId === this.props.currentUserId &&
						!this.state.editComment && (
							<p className="text-right">
								<a
									style={{ cursor: "pointer" }}
									onClick={this.showEditComment}
								>
									Edit
								</a>
								<span className="txtgry">
									&nbsp; &nbsp; | &nbsp; &nbsp;
								</span>
								<a
									style={{ cursor: "pointer" }}
									onClick={this.deleteComment}
								>
									Delete
								</a>
							</p>
						)}
				</div>
				<hr />
				<div className="rplybx row">
					<div className="col mb-2 text-left">
						<span>
							{this.props.comment.replies
								? `${this.props.comment.replies.length} Replies`
								: "0 Reply"}
						</span>{" "}
					</div>
					<div className="col mb-2 text-right">
						<a
							onClick={this.toggleReplies}
							style={{ cursor: "pointer" }}
						>
							{!this.props.showField ? "Reply" : "Close"}
						</a>
					</div>

					{this.props.showField &&
						this.props.comment.replies &&
						(this.state.showRepliesLoading ? (
							<div>
								<img
									width={22}
									height={22}
									src={loading}
									alt=""
								/>
							</div>
						) : (
							this.props.comment.replies.length > 0 &&
							this.props.comment.replies.map((reply, idx) => (
								<CommentReply
									key={idx}
									reply={reply}
									onUpdateReply={this.updateReply}
									onDeleteReply={() =>
										this.deleteReply(
											this.props.comment.commentId,
											reply.replyId
										)
									}
									commentId={this.props.comment.commentId}
									replyId={reply.replyId}
									token={this.props.token}
									profileBlank={profileBlank}
									editable={
										reply.userId === this.props.user.userId
									}
								/>
							))
						))}

					{this.props.hasReplyCommentPermission &&
						this.props.showField && (
							<div
								className={`${
									window.matchMedia("(max-width: 500px)")
										.matches
										? "wrtcmnt btmfld mt-2"
										: "col-12 replybxin new-comment-container"
								}`}
								style={{ zIndex: "10" }}
							>
								<input
									placeholder="Write a reply...."
									type="text"
									id={`writeReplyField${this.props.comment.commentId}`}
									className="form-control"
									onChange={this.handleChange}
									value={this.state.reply.description}
									// onKeyDown={this.onEnterPress}
								/>

								<a
									onClick={() =>
										this.createCommentReply(
											this.props.comment.commentId
										)
									}
									className={`${
										window.matchMedia("(max-width: 500px)")
											? "sndreplybtn"
											: "sndbtn"
									}`}
								></a>
							</div>
						)}
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		currentUserId: state.auth.user.userId,
		token: state.auth.token,
	};
};
export default connect(mapStateToProps, {})(Comment);
