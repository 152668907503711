import React, { useState, useEffect, useCallback, memo } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import loading from "../assets/loading.svg";
import profileBlank from "../assets/profile_blank.png";
import {
	getServerUrl,
	clearUserNotifications,
	mobileQuery,
	clearSpecificNotifications,
} from "../utils/api";
import { assignTimeAgo } from "../utils/helper";
import Avatar from "../components/common/avatar";

const NotificationItem = memo(
	({
		notification,
		openChat,
		removeNotification,
		onHandleNotificationClickForRestLinks,
	}) => {
		if (notification.action === "comment") {
			return (
				<div className="usrtop">
					<Link
						className="row"
						onClick={() => {
							removeNotification(notification.id);
							onHandleNotificationClickForRestLinks();
						}}
						to={"/post/" + notification.postId}
					>
						<div className="col-2 f12">
							<div className="userthumb">
								<span className="userbx">
									<Avatar
										url={"/uploads/user/"}
										state={notification.user.profilePicture}
										alt={"cover photo"}
									/>
								</span>
							</div>
						</div>
						<div className="col-10">
							<span>
								{notification.user.firstName +
									" " +
									notification.user.lastName}
							</span>
							<span className="small pstim">
								{assignTimeAgo(notification.date_notified)}
							</span>
							<p className="f12 mt-2 mb-0">
								Commented on your post
							</p>
						</div>
					</Link>
				</div>
			);
		} else if (notification.action === "followed") {
			return (
				<div className="usrtop">
					<Link
						className="row"
						onClick={() => {
							removeNotification(notification.id);
							onHandleNotificationClickForRestLinks();
						}}
						to={"/profile/" + notification.user.userId}
					>
						<div className="col-2 f12">
							<div className="userthumb">
								<span
									to={"/profile/" + notification.user.userId}
									className="userbx"
								>
									<Avatar
										url={"/uploads/user/"}
										state={notification.user.profilePicture}
										alt={"cover photo"}
									/>
								</span>
							</div>
						</div>
						<div className="col-10">
							<span>
								{notification.user.firstName +
									" " +
									notification.user.lastName}
							</span>
							<span className="small pstim">
								{assignTimeAgo(notification.date_notified)}
							</span>
							<p className="mt-2 mb-0 f12">
								Started Following You
							</p>
						</div>
					</Link>
				</div>
			);
		} else if (notification.action === "answer") {
			return (
				<div className="usrtop">
					<Link
						className="row"
						onClick={() => {
							removeNotification(notification.id);
							onHandleNotificationClickForRestLinks();
						}}
						to={"/answers/" + notification.postId}
					>
						<div className="col-2 f12">
							<div className="userthumb">
								<span className="userbx">
									<Avatar
										url={"/uploads/user/"}
										state={notification.user.profilePicture}
										alt={"cover photo"}
									/>
								</span>
							</div>
						</div>
						<div className="col-10">
							<span>
								{notification.user.firstName +
									" " +
									notification.user.lastName}
							</span>
							<span className="small pstim">
								{assignTimeAgo(notification.date_notified)}
							</span>
							<p className=" mt-2 mb-0 f12">
								Answered on your question
							</p>
						</div>
					</Link>
				</div>
			);
		} else if (notification.action === "newMessage") {
			return (
				<div className="usrtop">
					<div
						className="row"
						onClick={(e) => {
							openChat(
								e,
								notification.user,
								notification.type,
								notification?.receiver
							);
							removeNotification(notification.id);
							onHandleNotificationClickForRestLinks();
						}}
					>
						<div className="col-2 f12">
							<div className="userthumb">
								<a className="userbx">
									<Avatar
										url={"/uploads/user/"}
										state={notification.user.profilePicture}
										alt={"cover photo"}
									/>
								</a>
							</div>
						</div>
						<div className="col-10">
							<a to={"/profile/" + notification.user.userId}>
								{notification.user.firstName +
									" " +
									notification.user.lastName}
							</a>
							<span className="small pstim">
								{assignTimeAgo(notification.date_notified)}
							</span>
							<p className="mt-2 mb-0 f12">
								{notification.type === "personal"
									? `send you a text message`
									: `mention you in ${notification.type} chat`}
							</p>
						</div>
					</div>
				</div>
			);
		} else if (notification.action === "messagesReaction") {
			return (
				<div className="usrtop">
					<div
						className="row"
						onClick={(e) => {
							openChat(
								e,
								notification.user,
								notification.type,
								notification?.receiver
							);
							removeNotification(notification.id);
							onHandleNotificationClickForRestLinks();
						}}
					>
						<div className="col-2 f12">
							<div className="userthumb">
								<a className="userbx">
									<Avatar
										url={"/uploads/user/"}
										state={notification.user.profilePicture}
										alt={"cover photo"}
									/>
								</a>
							</div>
						</div>
						<div className="col-10">
							<a>
								{notification.user.firstName +
									" " +
									notification.user.lastName}
							</a>
							<span className="small pstim">
								{assignTimeAgo(notification.date_notified)}
							</span>
							<p className="mt-2 mb-0 f12">
								react on your message
							</p>
						</div>
					</div>
				</div>
			);
		}
		return null; // Or a fallback component if none of the actions match
	}
);

const ClearNotificationsButton = memo(({ onClick }) => {
	return (
		<div style={{ padding: "10px" }}>
			<hr />
			<p className="f12">
				<a style={{ cursor: "pointer" }} onClick={onClick}>
					Clear Notifications
				</a>
			</p>
		</div>
	);
});

const Notifications = ({
	user,
	notificationCount,
	teams,
	groups,
	onChangeMsgTab,
	onLoadConversation,
	updateNotification,
	onHandleNotificationClickForRestLinks,
}) => {
	const [loadingState, setLoadingState] = useState(true);
	const [isMounted, setIsMounted] = useState(true);

	useEffect(() => {
		setLoadingState(false);
		setIsMounted(true);

		return () => {
			setIsMounted(false);
		};
	}, []);

	const openChatCallback = useCallback(
		(e, user, type, receiver) => {
			let chatType = "";
			let chatData = {};

			if (type === "personal") {
				chatType = "people";
				chatData = {
					name: `${user.firstName} ${user.lastName}` || "",
					profilePicture: user.profilePicture || "",
					_id: user._id,
					userId: user.userId || "",
				};
			} else if (type === "group") {
				chatType = "groups";
				const groupsNotification = groups.find(
					(item) => item._id === receiver
				);
				chatData = {
					name: groupsNotification.title || "",
					profilePicture: groupsNotification.profilePicture || "",
					_id: groupsNotification._id,
					type: "group",
				};
			} else if (type === "team") {
				chatType = "teams";
				const teamNotification = teams.find(
					(item) => item._id === receiver
				);
				chatData = {
					name: teamNotification.title || "",
					profilePicture: teamNotification.profilePicture || "",
					_id: teamNotification._id,
					type: "team",
				};
			}

			if (chatType) {
				e.persist();
				e.preventDefault();
				onChangeMsgTab(chatType, async () => {
					await onLoadConversation(e, chatData._id, chatData);
					const updatedNotifications =
						notificationCount.notification.filter(
							(notification) =>
								notification.id !== notification.Id
						);

					if (isMounted) {
						const updatedNotifications =
							notificationCount.notification.filter(
								(notification) =>
									notification.id !== notification.Id
							);
						updateNotification({
							...notificationCount,
							notification: updatedNotifications,
						});
					}
				});
			}
		},
		[
			isMounted,
			onChangeMsgTab,
			onLoadConversation,
			notificationCount,
			updateNotification,
		]
	);

	const removeNotification = useCallback(
		(notificationId) => {
			clearSpecificNotifications(user.userId, notificationId)
				.then((resp) => {
					if (isMounted && resp.success === true) {
						setLoadingState(false);
						updateNotification({
							...notificationCount,
							notification: notificationCount.notification.filter(
								(notification) =>
									notification.id !== notificationId // <-- Use "id" property instead of "_id"
							),
						});
					} else {
						alert("Error fetching data, please try again");
					}
				})
				.catch((error) => {
					console.error("Error removing notification:", error);
					alert("Error fetching data, please try again");
				});
			setLoadingState(true);
		},
		[isMounted, user.userId, notificationCount, updateNotification]
	);

	const clearUserNotificationsCallback = useCallback(() => {
		clearUserNotifications(user.userId)
			.then((resp) => {
				if (isMounted && resp.success === true) {
					setLoadingState(false);
					updateNotification({
						...notificationCount,
						notification: [],
					});
				} else {
					alert("Error fetching data, please try again");
				}
			})
			.catch((error) => {
				console.error("Error removing notification:", error);
				alert("Error fetching data, please try again");
			});
		setLoadingState(true);
	}, [isMounted, user.userId, notificationCount, updateNotification]);

	let is_mobile = window.matchMedia(mobileQuery).matches;
	if (loadingState) {
		return (
			<div
				className="profile-container-loading"
				style={{
					height: "93vh",
					position: "fixed",
					zIndex: 9,
					top: "52px",
				}}
			>
				<img src={loading} alt="" />
			</div>
		);
	}
	const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

	return (
		<div
			className={
				is_mobile
					? isiOS
						? "outbx-fix-ios"
						: "outbx-fix"
					: "container cntntbx"
			}
		>
			<div className={is_mobile ? "" : "row"}>
				<div className={"container cntntbx"}>
					<div className={"row"}>
						<div className={"col-md-8 col-lg-9"}>
							<div className="ntfbx">
								{notificationCount.notification.length > 0 ? (
									notificationCount.notification.map(
										(notification) => (
											<div
												key={
													"notification" +
													notification.id
												}
												className="pplsrch grpsrch"
											>
												<NotificationItem
													notification={notification}
													openChat={openChatCallback}
													removeNotification={
														removeNotification
													}
													onHandleNotificationClickForRestLinks={
														onHandleNotificationClickForRestLinks
													}
												/>
											</div>
										)
									)
								) : (
									<div className={"feed-container-empty"}>
										No notifications found
									</div>
								)}
								{notificationCount.notification.length > 0 && (
									<ClearNotificationsButton
										onClick={clearUserNotificationsCallback}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		notificationCount: state.auth.notificationCount,
	};
};

export default withRouter(connect(mapStateToProps, {})(Notifications));
