import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {Form, ButtonToolbar, OverlayTrigger, Button, Popover, } from 'react-bootstrap';
import LeftSidebar from './LeftSidebar';
import { getAthletePlanner } from '../../utils/api.js';
import { getUserCoach, getGroupLevels } from "../../actions";
import Select from 'react-select';

const AthleteProfile = (props) => {
    const [planner, setPlanner] = useState(null);
    const [groups, setGroups] = useState(null);
    const [groupLevels, setgroupLevels] = useState(null);
    const [club, setClub] = useState('');
    const [loading, setLoading] = useState(true);
    const [showPopover, setShowPopover] = useState(false); // State to control popover visibility
    useEffect(() => {
        getAthletePlanner(props.clubSlug, props.user._id)
            .then(result => {
                if (result.club === null) {
                    setClub(null);
                } else if (result.planner === null) {
                    setPlanner(null);
                } else {
                    setClub(result.club);
                    setPlanner(result.planner);
                }
                setLoading(false);
            });
    }, []);
 

    let ref = React.useRef(null);

    const popover = (
      <Popover id="popover-basic">
         
        <Popover.Content>
            <p className='text-danger'><strong>Who will see this information?</strong></p>
            <div>
            <p className='pr-2 mb-0'><b>Date of Birth</b></p>
            <p className='d-flex pl-3 mb-0'>
            <span className='pr-4'>
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
            <label class="form-check-label small" for="flexRadioDefault1"> Everyone </label>
            </span>
            <span className='pr-4'>
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
            <label class="form-check-label small" for="flexRadioDefault2"> Members  </label>    
            </span>&nbsp;&nbsp;
            <span className='pr-4'>
            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3"/>
            <label class="form-check-label small" for="flexRadioDefault3"> Only Me </label>   
            </span>     
            </p>    
            </div> 
            <hr/>
            <div>
            <p className='pr-2 mb-0'><b>Gender</b></p>
            <p className='d-flex pl-3 mb-0'>
            <span className='pr-4'>
            <input class="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault4"/>
            <label class="form-check-label small" for="flexRadioDefault4"> Everyone </label>
            </span>
            <span className='pr-4'>
            <input class="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault5"/>
            <label class="form-check-label small" for="flexRadioDefault5"> Members  </label>    
            </span>&nbsp;&nbsp;
            <span className='pr-4'>
            <input class="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault6"/>
            <label class="form-check-label small" for="flexRadioDefault6"> Only Me </label>   
            </span>     
            </p>    
            </div> 
            <hr/>
            <div>
            <p className='pr-2 mb-0'><b>T-Shirt Size</b></p>
            <p className='d-flex pl-3 mb-0'>
            <span className='pr-4'>
            <input class="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault7"/>
            <label class="form-check-label small" for="flexRadioDefault7"> Everyone </label>
            </span>
            <span className='pr-4'>
            <input class="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault8"/>
            <label class="form-check-label small" for="flexRadioDefault8"> Members  </label>    
            </span>&nbsp;&nbsp;
            <span className='pr-4'>
            <input class="form-check-input" type="radio" name="flexRadioDefault2" id="flexRadioDefault9"/>
            <label class="form-check-label small" for="flexRadioDefault9"> Only Me </label>   
            </span>     
            </p>    
            </div> 
            <hr/>
            <div className="mt-3 mb-1">
            <Button onClick={() => setShowPopover(!showPopover)} size="sm"  variant="outline-dark"  className="pt-0 pb-0"> Done </Button>
            </div>
        </Popover.Content>
      </Popover>
    );
      
    return (
        <>
            <div className="container-large clearfix">
                    <div id="wrapper" className="row athlete-dashboard athlete-profile">
                    <LeftSidebar club={club} planner={planner} groupLevels={groupLevels} clubSlug={props.clubSlug} groups={groups} />
                    <div className='col nopad mt-5 edtprfl'>
                    <div className="head-heading mngrphd d-flex justify-content-between">
                    <h4>Edit Profile</h4>
                    <p className="small text-danger ntfytxt">Fields Marked with &nbsp;&nbsp; * &nbsp;&nbsp; are required.</p>
                    </div>     
                    <Form>
                    <div className='row edtprflin'>
                    <div className='col-12 mb-3  d-flex justify-content-between'>
                    <h4>Personal Information</h4>
                    <div className='text-right d-flex'>
                    {/* <p className='small pr-3 mb-0 text-success'style={{position:"relative", top:"9px"}}>Privacy set to be seen for <b>Everyone.</b> </p>  */}
                    <OverlayTrigger
                    show={showPopover} // Pass the visibility state to the OverlayTrigger component
                    onToggle={() => setShowPopover(!showPopover)} // Toggle the visibility state
                    ref={r => (ref = r)}
                    container={ref.current}
                    trigger="click"
                    placement="auto"
                    overlay={popover}
                    rootClose    >
                    <Button className='btn btn-simple btn-outline-dark' variant="white">View and Set Privacy</Button>
                    </OverlayTrigger> 
                    </div> 
                    </div>    
                    <div className='col-6'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>First Name<span>*</span>: </Form.Label>
                    <Form.Control type="text" placeholder="Type Your First Name..." />
                    </Form.Group>    
                    </div> 
                    <div className='col-6'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Last Name<span>*</span>: </Form.Label>
                    <Form.Control type="text" placeholder=" Type Your Last Name... " />
                    </Form.Group>    
                    </div> 
                    <div className='col-2'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Date Of Birth<span>*</span>: </Form.Label>
                    <Select className="basic-single" classNamePrefix="select" placeholder="Day"/>
                    </Form.Group>    
                    </div> 
                    <div className='col-2'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>&nbsp;</Form.Label>
                    <Select className="basic-single" classNamePrefix="select" placeholder="Month"/>
                    </Form.Group>    
                    </div>  
                    <div className='col-2'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>&nbsp;</Form.Label>
                    <Select className="basic-single" classNamePrefix="select" placeholder="Year"/>
                    </Form.Group>    
                    </div> 
                    <div className='col-3'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Select Your Gender<span>*</span>:</Form.Label>
                    <Select className="basic-single" classNamePrefix="select"/>
                    </Form.Group>    
                    </div>
                    <div className='col-3'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>T-Shirt Size:</Form.Label>
                    <Select className="basic-single" classNamePrefix="select" placeholder="Select Size"/>
                    </Form.Group>    
                    </div>
                    <div className='col-6'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Employer:</Form.Label>
                    <Form.Control type="text" placeholder="Your Employer Name..." />
                    </Form.Group>    
                    </div>      
                    </div>   

                    <div className='row edtprflin'>
                    <div className='col-12 mb-3  d-flex justify-content-between '>
                    <h4>Address Information</h4> 
                    <p className='small'>Set Privacy</p>   
                     
                    </div>    
                    <div className='col-6'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Street Address<span>*</span>:</Form.Label>
                    <Form.Control type="text" placeholder="Type Your Street Address Here..." />
                    </Form.Group>    
                    </div> 
                    <div className='col-6'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Street Address 2: </Form.Label>
                    <Form.Control type="text" placeholder="Type Your Secondary Address Here..." />
                    </Form.Group>    
                    </div> 
                    <div className='col-3'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>City<span>*</span>:</Form.Label>
                    <Form.Control type="text" placeholder="Type Your City Name..." />	
                    </Form.Group>    
                    </div> 
                    <div className='col-3'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>State<span>*</span>:</Form.Label>
                    <Form.Control type="text" placeholder="Type Your State Name..." />
                    </Form.Group>    
                    </div>  
                    <div className='col-3'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Post Code<span>*</span>:</Form.Label>
                    <Form.Control type="text" placeholder="Enter Your Post Code" />
                    </Form.Group>    
                    </div> 
                    <div className='col-3'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Nearest Trialliance Center:</Form.Label>
                    <Select className="basic-single" classNamePrefix="select" placeholder="Select Size"/>
                    </Form.Group>    
                    </div>  
                    </div>  

                    <div className='row edtprflin'>
                    <div className='col-12 mb-3 d-flex justify-content-between  '>
                    <h4>Contact Information</h4>
                    <p className='small'>Set Privacy</p>  
                        
                    </div>    
                     
                    <div className='col-4'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Your Contact Number<span>*</span>:</Form.Label>
                    <Form.Control type="text" placeholder="Phone or Mobile Number" />
                    </Form.Group>    
                    </div> 
                    <div className='col-4'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Emergency Contact Number<span>*</span>:</Form.Label>
                    <Form.Control type="text" placeholder="Phone or Mobile Number" />
                    </Form.Group>    
                    </div> 
                    <div className='col-4'>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Relationship With You:</Form.Label>
                    <Form.Control type="text" placeholder="i.e Friend or Brother" />
                    </Form.Group>    
                    </div>        
                    </div>

                    <div className='row edtprflin mb-4'>
                        <div className='col-12 mb-3'>
                         <button className='btn btn-primary'>Save Profile Information</button>    
                        </div>     
                    </div>
                    </Form>
                    </div> 
                     
                </div>
            </div>

            
        </>

    );

};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        userCoach: state.planner.userCoach,
        groupLevels: state.planner.groupLevels,
    };
};

export default connect(mapStateToProps, { getUserCoach, getGroupLevels })(AthleteProfile);
