import React, { Component } from "react";
import { connect } from "react-redux";
import { updateShowPostFilter, applyPostFilter } from "../actions";
import Modal from "react-bootstrap/Modal";

class PostActivityFilter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			interest: props.interest,
		};
	}

	handleChangeInterests = () => {
		let interest = [];

		if (this.cbSwimming.checked) interest = [...interest, "Swim"];
		if (this.cbRunning.checked) interest = [...interest, "Run"];
		if (this.cbWalking.checked) interest = [...interest, "Walk"];
		if (this.cbCycling.checked) interest = [...interest, "Bike"];
		if (this.cbLifting.checked) interest = [...interest, "Strength"];
		if (this.cbFlexibility.checked) interest = [...interest, "Flexibility"];
		if (this.cbRecovery.checked) interest = [...interest, "Recovery"];
		if (this.cbDuathlon.checked) interest = [...interest, "Duathlon"];
		if (this.cbTriathlon.checked) interest = [...interest, "Triathlon"];

		this.setState({ interest });
	};

	closeModal = () => {
		this.props.updateShowPostFilter(false);
	};

	applyFilter = () => {
		if (this.state.interest.length > 0)
			this.props.applyPostFilter(this.state.interest);
		else alert("Please select at least one interest");
	};

	render() {
		let interest = this.state.interest;
		return (
			<Modal
				onHide={this.closeModal}
				size="lg"
				show={true}
				centered
				aria-labelledby="contained-modal-title-vcenter"
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="selactvty">
						<div className="lgnrgstr wizard-step-container">
							<h6>Select Your Interest</h6>
							<div className="row">
								<div className="col">
									<label className="form-check">
										<input
											type="checkbox"
											aria-label="swimming"
											className="form-check-input"
											value="Swim"
											onChange={
												this.handleChangeInterests
											}
											ref={(val) =>
												(this.cbSwimming = val)
											}
											checked={
												interest.includes("Swim")
													? true
													: false
											}
										/>
										<img src="/uploads/images/swimming.svg" />{" "}
										Swimming
									</label>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<label className="form-check">
										<input
											type="checkbox"
											aria-label="running"
											className="form-check-input"
											value="Run"
											onChange={
												this.handleChangeInterests
											}
											ref={(val) =>
												(this.cbRunning = val)
											}
											checked={
												interest.includes("Run")
													? true
													: false
											}
										/>
										<img src="/uploads/images/run.svg" />{" "}
										Running
									</label>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<label className="form-check">
										<input
											type="checkbox"
											aria-label="walking"
											className="form-check-input"
											value="Walk"
											onChange={
												this.handleChangeInterests
											}
											ref={(val) =>
												(this.cbWalking = val)
											}
											checked={
												interest.includes("Walk")
													? true
													: false
											}
										/>
										<img src="/uploads/images/walk.svg" />{" "}
										Walking
									</label>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<label className="form-check">
										<input
											type="checkbox"
											aria-label="cycling"
											className="form-check-input"
											value="Bike"
											onChange={
												this.handleChangeInterests
											}
											ref={(val) =>
												(this.cbCycling = val)
											}
											checked={
												interest.includes("Bike")
													? true
													: false
											}
										/>
										<img src="/uploads/images/cycling.svg" />{" "}
										Cycling
									</label>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<label className="form-check">
										<input
											type="checkbox"
											aria-label="lifting"
											className="form-check-input"
											value="Strength"
											onChange={
												this.handleChangeInterests
											}
											ref={(val) =>
												(this.cbLifting = val)
											}
											checked={
												interest.includes("Strength")
													? true
													: false
											}
										/>
										<img src="/uploads/images/lifting.svg" />{" "}
										Weight Lifting
									</label>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<label className="form-check">
										<input
											type="checkbox"
											aria-label="flexibility"
											className="form-check-input"
											value="Flexibility"
											onChange={
												this.handleChangeInterests
											}
											ref={(val) =>
												(this.cbFlexibility = val)
											}
											checked={
												interest.includes("Flexibility")
													? true
													: false
											}
										/>
										<img src="/uploads/images/flexibility.svg" />{" "}
										Flexibility
									</label>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<label className="form-check">
										<input
											type="checkbox"
											aria-label="recovery"
											className="form-check-input"
											value="Recovery"
											onChange={
												this.handleChangeInterests
											}
											ref={(val) =>
												(this.cbRecovery = val)
											}
											checked={
												interest.includes("Recovery")
													? true
													: false
											}
										/>
										<img src="/uploads/images/recovery.svg" />{" "}
										Recovery
									</label>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<label className="form-check">
										<input
											type="checkbox"
											aria-label="duathlon"
											className="form-check-input"
											value="Duathlon"
											onChange={
												this.handleChangeInterests
											}
											ref={(val) =>
												(this.cbDuathlon = val)
											}
											checked={
												interest.includes("Duathlon")
													? true
													: false
											}
										/>
										<img src="/uploads/images/dualthion.svg" />{" "}
										Duathlon
									</label>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<label className="form-check">
										<input
											type="checkbox"
											aria-label="triathlon"
											className="form-check-input"
											value="Triathlon"
											onChange={
												this.handleChangeInterests
											}
											ref={(val) =>
												(this.cbTriathlon = val)
											}
											checked={
												interest.includes("Triathlon")
													? true
													: false
											}
										/>
										<img src="/uploads/images/trithalion.svg" />{" "}
										Triathlon
									</label>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-12 text-center">
								<button onClick={this.applyFilter}>
									Filter Activity
								</button>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		interest: state.auth.activityFilter,
	};
};

export default connect(mapStateToProps, {
	updateShowPostFilter,
	applyPostFilter,
})(PostActivityFilter);
