import React from "react";
import { Link } from "react-router-dom";
import goBack from "../../../assets/goBack.png";
export default function Recovered() {
	return (
		<div className="container">
			<section className="h-screen d-flex align-items-center">
				<div className="px-6 h-full text-gray-800">
					<div className="d-flex justify-content-center justify-content-lg-between flex-wrap h-full g-6">
						<div className="flex-grow-0 flex-shrink-1 md:flex-shrink-0 flex-basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0">
							<img
								src={goBack}
								className="w-100"
								alt="Sample image"
							/>
						</div>
						<div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
							<form>
								<div className="d-flex flex-row justify-center justify-lg-start">
									<h4 className="text-2xl font-bold mb-0 mr-4 text-dark">
										Password successfully set
									</h4>
								</div>

								<div className="d-flex align-items-center my-4 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
									<h4 className="mr-1">Login</h4>
									<Link to="/signInUser">click me</Link>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
