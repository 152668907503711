import React from "react";
import { connect } from "react-redux";
import { isUserloggedIn } from "./actions";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/main.css";
import "./styles/main.scss";
import "./styles/social-style.css";
import * as API from "./utils/api";
import CoachRoutes from "./CoachRoutes";

import loading from "./assets/loading3.svg";
// Importing Components
import Header from "./components/Header";
import PopUpMessage from "./components/PopUpMessage";
import PictureViewer from "./components/PictureViewer";

// Importing Screen Components
import Profile from "./screens/Profile";
import PostDetail from "./screens/PostDetail";
import NewPost from "./screens/NewPost";
import NewEvent from "./screens/NewEvent";
import EditPost from "./screens/EditPost";
import EditQuestion from "./screens/EditQuestion";
import CreateEvent from "./screens/CreateEvent";
import CreateTeamEvent from "./screens/CreateTeamEvent";
import EditEvent from "./screens/EditEvent";
import EditPhoto from "./screens/EditPhoto";
import EditProfile from "./screens/EditProfile";
import Home from "./screens/Home";
import SavedContent from "./screens/SavedContent";
import SignInUser from "./screens/SignInUser";
import SignUpUser from "./screens/SignUpUser";
import FirstTimeSignUp from "./screens/FirstTimeSignUp";
import Trending from "./screens/Trending";
import WhatsNew from "./screens/WhatsNew";
import Event from "./screens/Event";
import Events from "./screens/Events.js";
import TeamEvent from "./screens/TeamEvent";
import CreateGroup from "./screens/CreateGroup";
import Group from "./screens/Group";
import EditGroup from "./screens/EditGroup";
import Teams from "./screens/Teams";
import Team from "./screens/Team";
import TeamMemberships from "./screens/TeamMemberships";
import AdminTeam from "./screens/AdminTeam";
import TeamAdmin from "./screens/TeamAdmin";
import SplashSocial from "./screens/SplashSocial";
import Login from "./screens/Login";
import Register from "./screens/Register";
import DesktopDesign from "./screens/DesktopDesign";
import DesktopTrending from "./screens/DesktopTrending";
import AdminGroup from "./screens/AdminGroup";
import Groups from "./screens/Groups";
import Search from "./screens/Search";
import Answer from "./screens/Answer";
import UserPhotos from "./screens/UserPhotos";
import UserFollowers from "./screens/UserFollowers";
import OwnQuestions from "./screens/OwnQuestions";
import Dropped from "./components/coach/Dropped";
import UserSubScribeMemberShips from "./screens/UserSubScribeMemberShips";
import Notifications from "./screens/Notifications";
import Messages from "./screens/Messages";
import TeamGroups from "./screens/TeamGroups";
import ForgetPassword from "./screens/ForgetPassword";
import NewPAssword from "./components/resetPassword/newPassword";
import Recovered from "./components/resetPassword/recovered";
import LayoutWrapper from "./components/layout";
import ProtectedRoute from "./components/ProtectedRoute";
class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			posts: [],
			groups: [],
			events: [],
			questions: [],
			pictureViewerOptions: {
				visible: false,
				image: "",
			},
			popupOptions: {
				popupVisible: false,
				buttonMessage: "",
				popupMessage: "",
				popupRedirect: "",
			},
			popupTwoButtonOptions: {
				popupVisible: false,
				popupMessage: "",
				buttonMessage1: "",
				popupRedirect1: "",
				buttonMessage2: "",
				popupRedirect2: "",
			},
			imagesCache: {},
			burgerMenuVisible: false,
			conversationVisible: false,
		};
	}

	componentDidMount() {
		// ComponentDidMount is called after the component mounts. It's called right after the constructor
		// has finished doing what it is supposed to do.

		//this.getAllPosts();

		// The following piece of code checks whether a user was already logged in and disables the loading.
		this.props.isUserloggedIn();

		const isiOS = () => {
			return /iPhone|iPad|iPod/i.test(navigator.userAgent);
		};

		if (isiOS()) {
			document.body.classList.add("ios-top-margin");
		}
	}

	componentWillUnmount() {
		// Remove the class "ios-top-margin" from the body when the component unmounts
		document.body.classList.remove("ios-top-margin");
	}

	// ===============================================================
	// CRUD functions for Posts
	// ===============================================================

	// Function to get posts to populate the Home feed
	getAllPosts = () => {
		API.getAllPosts().then((posts) => {
			this.setState({
				posts,
			});
		});
		API.getAllQuestions().then((questions) => {
			this.setState({
				questions,
			});
		});
	};

	// Function to create a posts and updates the application state with the new post.
	createPost = (newPost) => {
		const data = {
			postId: newPost.postId,
			userId: newPost.userId,
			isQuestion: newPost.isQuestion,
			type: newPost.type,
			description: newPost.description,
			interest: newPost.interest,
			time: newPost.time,
			role: newPost.role,
			section: newPost.section,
			imgFileName:
				newPost.imgFileName.length > 0 ? newPost.imgFileName : [],
			videoFileName:
				newPost.videoFileName.length > 0 ? newPost.videoFileName : [],
			status: newPost.status,
		};

		let promise = new Promise((resolve) => {
			axios
				.post(
					API.getServerUrl().apiURL + "/posts",
					JSON.stringify(data),
					{
						onUploadProgress: (ProgressEvent) => {
							this.setState({
								loaded: Math.ceil(
									(ProgressEvent.loaded /
										ProgressEvent.total) *
										100
								),
							});
						},
						headers: { "Content-Type": "application/json" },
					}
				)
				.then((res) => {
					if (res.status === 200) {
						let postToAdd = res.data;

						if (postToAdd.section.type === "users") {
							this.setState(
								(previousState) => {
									if (newPost.isQuestion) {
										let sortedQuestions =
											previousState.questions;
										sortedQuestions.push(postToAdd);
										sortedQuestions.sort(
											(a, b) =>
												Date.parse(b.time) -
												Date.parse(a.time)
										);
										return {
											questions: sortedQuestions,
										};
									} else {
										let sortedPosts = previousState.posts;
										sortedPosts.push(postToAdd);
										sortedPosts.sort(
											(a, b) =>
												Date.parse(b.time) -
												Date.parse(a.time)
										);
										return {
											posts: sortedPosts,
										};
									}
								},
								() => {
									resolve(true);
								}
							);
						} else {
							resolve(postToAdd);
						}
					}
				});
		});

		return promise;
	};

	// Function to create a comment in a post and updates the application state with the edited post.
	createComment = (postId, postComment, userid, userId) => {
		let promise = new Promise((resolve, reject) => {
			API.createComment(postId, postComment, userid, userId).then(
				(post) => {
					if (post.postId) {
						if (post.section.type === "users") {
							this.setState(
								(previousState) => {
									if (post.isQuestion) {
										let sortedQuestions =
											previousState.questions.filter(
												(p) => p.postId !== postId
											);
										sortedQuestions.push(post);
										sortedQuestions.sort(
											(a, b) =>
												Date.parse(b.time) -
												Date.parse(a.time)
										);
										return {
											questions: sortedQuestions,
										};
									} else {
										let sortedPosts =
											previousState.posts.filter(
												(p) => p.postId !== postId
											);
										sortedPosts.push(post);
										sortedPosts.sort(
											(a, b) =>
												Date.parse(b.time) -
												Date.parse(a.time)
										);
										return {
											posts: sortedPosts,
										};
									}
								},
								() => {
									resolve(true);
								}
							);
						} else {
							resolve(post);
						}
					} else {
						reject(false);
					}
				}
			);
		});
		return promise;
	};

	// Function to edit a post
	editPost = (postId, edited) => {
		let promise = new Promise((resolve, reject) => {
			API.editPost(postId, edited).then((post) => {
				if (post.postId) {
					if (post.section.type === "users") {
						this.setState(
							(previousState) => {
								if (post.isQuestion) {
									let sortedQuestions =
										previousState.questions.filter(
											(p) => p.postId !== postId
										);
									sortedQuestions.push(post);
									sortedQuestions.sort(
										(a, b) =>
											Date.parse(b.time) -
											Date.parse(a.time)
									);
									return {
										questions: sortedQuestions,
									};
								} else {
									let sortedPosts =
										previousState.posts.filter(
											(p) => p.postId !== postId
										);
									sortedPosts.push(post);
									sortedPosts.sort(
										(a, b) =>
											Date.parse(b.time) -
											Date.parse(a.time)
									);
									return {
										posts: sortedPosts,
									};
								}
							},
							() => {
								resolve(true);
							}
						);
					} else {
						resolve(post);
					}
				} else {
					reject(false);
				}
			});
		});
		return promise;
	};

	// Function to delete a post and updates the application state.
	deletePost = (postId) => {
		let promise = new Promise((resolve, reject) => {
			API.deletePost(postId).then((post) => {
				if (post) {
					this.setState((previousState) => ({
						posts: previousState.posts.filter(
							(p) => p.postId !== postId
						),
					}));
					resolve(post);
				} else {
					reject(false);
				}
			});
		});
		return promise;
	};

	// ===============================================================
	// CRUD functions for KUDOS
	// ===============================================================

	changeKudos = (postId, kudos) => {
		let promise = new Promise((resolve, reject) => {
			API.changeKudos(postId, kudos).then((post) => {
				if (post.postId) {
					if (post.section.type === "users") {
						this.setState((previousState) => {
							let sortedPosts = previousState.posts.filter(
								(p) => p.postId !== postId
							);
							sortedPosts.push(post);
							sortedPosts.sort(
								(a, b) =>
									Date.parse(b.time) - Date.parse(a.time)
							);
							return {
								posts: sortedPosts,
							};
						});
						resolve(true);
					} else {
						resolve(post);
					}
				} else {
					reject(false);
				}
			});
		});
		return promise;
	};

	// ===============================================================
	// Popup functions
	// ===============================================================
	showPopup = (popupMessage, buttonMessage, popupRedirect) => {
		this.setState({
			popupOptions: {
				popupVisible: true,
				buttonMessage,
				popupMessage,
				popupRedirect,
			},
		});
	};

	closePopup = () => {
		this.setState({
			popupOptions: {
				popupVisible: false,
				buttonMessage: "",
				popupMessage: "",
				popupRedirect: "",
			},
		});
	};

	// ===============================================================
	// Picture Viewer functions
	// ===============================================================

	openPictureViewer = (image) => {
		this.setState({
			pictureViewerOptions: {
				visible: true,
				image,
			},
		});
	};

	closePictureViewer = () => {
		this.setState({
			pictureViewerOptions: {
				visible: false,
				image: "",
			},
		});
	};

	// ===============================================================
	// Groups functions
	// ===============================================================

	createGroup = (groupDetails) => {
		let promise = new Promise((resolve, reject) => {
			API.createGroup(groupDetails).then((group) => {
				if (group.groupId) {
					resolve(true);
				} else {
					reject(false);
				}
			});
		});

		return promise;
	};

	// ===============================================================
	// Events functions
	// ===============================================================

	createEvent = (newEventDetails) => {
		let promise = new Promise((resolve, reject) => {
			API.createEvent(newEventDetails).then((event) => {
				if (event.eventId) {
					resolve(true);
				} else {
					reject(false);
				}
			});
		});
		return promise;
	};

	editEvent = (eventId, edited) => {
		let promise = new Promise((resolve, reject) => {
			API.editEvent(eventId, edited).then((event) => {
				if (event.eventId) {
					resolve(true);
				} else {
					reject(false);
				}
			});
		});
		return promise;
	};

	// toggle burger menu
	toggleBurgerMenu = () => {
		this.setState((prevState) => ({
			burgerMenuVisible: !prevState.burgerMenuVisible,
		}));
	};

	// close burger menu
	closeBurgerMenu = () => {
		this.setState({
			burgerMenuVisible: false,
		});
	};

	toggleConversation = (action) => {
		switch (action) {
			case "close":
				this.setState({ conversationVisible: false });
				break;
			case "open":
				this.setState({ conversationVisible: true });
				break;
			default:
				break;
		}
	};

	render() {
		let isMobile = window.matchMedia(API.mobileQuery).matches;
		if (!this.props.loading) {
			return (
				<Router>
					<PopUpMessage
						popupVisible={this.state.popupOptions.popupVisible}
						popupMessage={this.state.popupOptions.popupMessage}
						buttonMessage={this.state.popupOptions.buttonMessage}
						popupRedirect={this.state.popupOptions.popupRedirect}
					/>
					<PictureViewer
						closePictureViewer={this.closePictureViewer}
						visible={this.state.pictureViewerOptions.visible}
						image={this.state.pictureViewerOptions.image}
					/>
					<Route path="/" component={CoachRoutes} />
					<Route
						path="/"
						render={(props) => (
							<LayoutWrapper
								isMobile={isMobile}
								burgerMenuVisible={this.state.burgerMenuVisible}
								conversationVisible={
									this.state.conversationVisible
								}
								onToggleBurgerMenu={this.toggleBurgerMenu}
								onCloseBurgerMenu={this.closeBurgerMenu}
								onHandleConversation={this.toggleConversation}
								openPictureViewer={this.openPictureViewer}
								userSignedIn={this.props.userSignedIn}
								firstTimeLogin={this.props.firstTimeLogin}
							>
								{" "}
								<Route
									exact
									path={"/createEvent"}
									render={() => (
										<CreateEvent
											createEvent={this.createEvent}
											showPopup={this.showPopup}
										/>
									)}
								/>
								<Route
									exact
									path={"/editEvent/:eventId"}
									render={(props) => (
										<EditEvent
											key={props.match.params.eventId}
											eventId={props.match.params.eventId}
											editEvent={this.editEvent}
											showPopup={this.showPopup}
										/>
									)}
								/>
								<Route
									exact
									path={"/createEvent/:groupId"}
									render={(props) => (
										<CreateEvent
											key={props.match.params.groupId}
											createEvent={this.createEvent}
											showPopup={this.showPopup}
										/>
									)}
								/>
								<Route
									exact
									path={"/createTeamEvent/:teamSlug"}
									render={(props) => (
										<CreateTeamEvent
											key={props.match.params.teamSlug}
										/>
									)}
								/>
								{/* Add more routes */}
								<Route
									exact
									path={"/events/create"}
									render={() => <NewEvent />}
								/>
								{/* not working */}
								<Route
									exact
									path={"/event/:eventId"}
									render={(props) => (
										<Event
											key={props.match.params.eventId}
											eventId={props.match.params.eventId}
											createPost={this.createPost}
											showPopup={this.showPopup}
											editPost={this.editPost}
											deletePost={this.deletePost}
											createComment={this.createComment}
											changeKudos={this.changeKudos}
											openPictureViewer={
												this.openPictureViewer
											}
										/>
									)}
								/>
								<Route
									exact
									path={"/events/"}
									render={() => <Events type={"all"} />}
								/>
								{/* not working */}
								<Route
									exact
									path={"/teamEvents/:teamEventSlug"}
									render={(props) => (
										<TeamEvent
											key={
												props.match.params.teamEventSlug
											}
										/>
									)}
								/>
								<Route
									exact
									path={["/trending", "/trending/:tab"]}
									render={(props) => (
										<Trending
											tab={props.match.params.tab}
										/>
									)}
								/>
								<Route
									exact
									path={"/home"}
									render={() => {
										if (this.props.userSignedIn) {
											return (
												<Home
													posts={this.state.posts}
													showPopup={this.showPopup}
													createPost={this.createPost}
													editPost={this.editPost}
													deletePost={this.deletePost}
													createComment={
														this.createComment
													}
													changeKudos={
														this.changeKudos
													}
													groups={this.state.groups}
													openPictureViewer={
														this.openPictureViewer
													}
													isQuestionPage={false}
												/>
											);
										} else {
											return (
												<div className="app-container">
													<SignInUser />
												</div>
											);
										}
									}}
								/>
								<Route
									exact
									path={"/questions"}
									render={() => (
										<Home
											posts={this.state.questions}
											showPopup={this.showPopup}
											createPost={this.createPost}
											editPost={this.editPost}
											deletePost={this.deletePost}
											createComment={this.createComment}
											changeKudos={this.changeKudos}
											groups={this.state.groups}
											openPictureViewer={
												this.openPictureViewer
											}
											isQuestionPage={true}
										/>
									)}
								/>
								<Route
									exact
									path={"/questions/own"}
									render={() => (
										<OwnQuestions
											createComment={this.createComment}
											changeKudos={this.changeKudos}
											openPictureViewer={
												this.openPictureViewer
											}
										/>
									)}
								/>
								<Route
									exact
									path="/profile/:userid"
									render={(props) => (
										<Profile
											editPost={this.editPost}
											deletePost={this.deletePost}
											showPopup={this.showPopup}
											createComment={this.createComment}
											changeKudos={this.changeKudos}
											openPictureViewer={
												this.openPictureViewer
											}
										/>
									)}
								/>
								<Route
									exact
									path="/posts/create"
									render={() => (
										<NewPost
											createPost={this.createPost}
											section={{ type: "users" }}
											isQuestion=""
										/>
									)}
								/>
								<Route
									exact
									path="/questions/create"
									render={() => (
										<NewPost
											createPost={this.createPost}
											section={{ type: "users" }}
											isQuestion={true}
										/>
									)}
								/>
								<Route
									exact
									path="/answers/:questionId"
									render={(props) => (
										<Answer
											key={props.match.params.questionId}
											{...props}
											createAnswer={this.createComment}
											openPictureViewer={
												this.openPictureViewer
											}
										/>
									)}
								/>
								<Route
									exact
									path="/post/:postId"
									render={(props) => (
										<PostDetail
											key={props.match.params.postId}
											editPost={this.editPost}
											deletePost={this.deletePost}
											showPopup={this.showPopup}
											createComment={this.createComment}
											changeKudos={this.changeKudos}
											openPictureViewer={
												this.openPictureViewer
											}
											hasReplyCommentPermission={true}
										/>
									)}
								/>
								{/* same */}
								<Route
									exact
									path="/post/:postId/view"
									render={(props) => (
										<PostDetail
											key={props.match.params.postId}
											editPost={this.editPost}
											deletePost={this.deletePost}
											showPopup={this.showPopup}
											createComment={this.createComment}
											changeKudos={this.changeKudos}
											openPictureViewer={
												this.openPictureViewer
											}
											hasReplyCommentPermission=""
										/>
									)}
								/>
								<Route
									exact
									path="/post/:postId/edit"
									render={(props) => (
										<EditPost
											key={props.match.params.postId}
										/>
									)}
								/>
								<Route
									exact
									path="/question/:questionId/edit"
									render={(props) => (
										<EditQuestion
											key={props.match.params.questionId}
										/>
									)}
								/>
								<Route
									path="/profile/:userid/edit"
									render={(props) => (
										<EditProfile
											key={props.match.params.userid}
											userId={props.match.params.userid}
										/>
									)}
								/>
								<Route
									path={"/profile/:userid/editPhoto"}
									render={(props) => (
										<EditPhoto
											userId={props.match.params.userid}
										/>
									)}
								/>
								<Route
									path={"/profile/:userid/follow/:tabKey"}
									render={(props) => (
										<UserFollowers
											// key={
											// 	props.match.params.userid +
											// 	"-follow-" +
											// 	props.match.params.tabKey
											// }
											userId={props.match.params.userid}
										/>
									)}
								/>
								<Route
									path={"/profile/:userid/photos"}
									render={(props) => (
										<UserPhotos
											key={props.match.params.userid}
											userId={props.match.params.userid}
										/>
									)}
								/>
								{/* not in use */}
								<Route
									path={"/createGroup"}
									render={() => (
										<CreateGroup
											createGroup={this.createGroup}
											showPopup={this.showPopup}
										/>
									)}
								/>
								<Route
									exact
									path="/group/:groupSlug"
									render={(props) => (
										<Group
											key={props.match.params.groupSlug}
											groupSlug={
												props.match.params.groupSlug
											}
											editPost={this.editPost}
											deletePost={this.deletePost}
											showPopup={this.showPopup}
											createPost={this.createPost}
											createComment={this.createComment}
											changeKudos={this.changeKudos}
											openPictureViewer={
												this.openPictureViewer
											}
										/>
									)}
								/>
								<Route
									exact
									path="/group/:groupSlug/events"
									render={(props) => (
										<Events
											key={props.match.params.groupSlug}
											groupSlug={
												props.match.params.groupSlug
											}
											type={"group"}
										/>
									)}
								/>
								<Route
									exact
									path="/group/:groupSlug/admin"
									render={(props) => (
										<AdminGroup
											key={props.match.params.groupSlug}
											groupSlug={
												props.match.params.groupSlug
											}
										/>
									)}
								/>
								{/* not in use */}
								<Route
									exact
									path="/group/:groupId/edit"
									render={(props) => (
										<EditGroup
											key={props.match.params.groupId}
											showPopup={this.showPopup}
										/>
									)}
								/>
								<Route
									exact
									path="/teams/"
									render={() => <Teams />}
								/>
								<Route
									exact
									path="/team/:teamSlug"
									render={(props) => (
										<Team
											key={props.match.params.teamSlug}
											teamSlug={
												props.match.params.teamSlug
											}
											editPost={this.editPost}
											deletePost={this.deletePost}
											showPopup={this.showPopup}
											changeKudos={this.changeKudos}
											openPictureViewer={
												this.openPictureViewer
											}
											createComment={this.createComment}
											isQuestionPage=""
										/>
									)}
								/>
								<Route
									exact
									path="/team/:teamSlug/admin"
									render={(props) => (
										<AdminTeam
											key={props.match.params.teamSlug}
											teamSlug={
												props.match.params.teamSlug
											}
										/>
									)}
								/>
								<Route
									exact
									path="/team/:teamSlug/memberships"
									render={(props) => (
										<TeamMemberships
											key={props.match.params.teamSlug}
											teamSlug={
												props.match.params.teamSlug
											}
										/>
									)}
								/>
								<Route
									exact
									path="/team/:teamSlug/events"
									render={(props) => (
										<Events
											key={props.match.params.teamSlug}
											teamSlug={
												props.match.params.teamSlug
											}
											type={"team"}
										/>
									)}
								/>
								<Route
									exact
									path="/team/:teamSlug/groups"
									render={(props) => (
										<TeamGroups
											key={props.match.params.teamSlug}
											teamSlug={
												props.match.params.teamSlug
											}
										/>
									)}
								/>
								<Route
									exact
									path="/team/:teamSlug/post/create"
									render={(props) => (
										<NewPost
											key={props.match.params.teamSlug}
											createPost={this.createPost}
											section={{
												type: "teams",
												id: props.match.params.teamSlug,
											}}
											notGeneral={true}
										/>
									)}
								/>
								<Route
									exact
									path="/group/:groupSlug/post/create"
									render={(props) => (
										<NewPost
											key={props.match.params.groupSlug}
											createPost={this.createPost}
											section={{
												type: "groups",
												id: props.match.params
													.groupSlug,
											}}
											notGeneral={true}
										/>
									)}
								/>
								<Route
									exact
									path="/groups/"
									render={() => <Groups />}
								/>
								<Route
									exact
									path="/groups/add/:teamSlug"
									render={(props) => (
										<Groups
											key={props.match.params.teamSlug}
											teamSlug={
												props.match.params.teamSlug
											}
										/>
									)}
								/>
								<Route
									path={"/saved"}
									render={() => (
										<SavedContent
											editPost={this.editPost}
											deletePost={this.deletePost}
											showPopup={this.showPopup}
											createComment={this.createComment}
											changeKudos={this.changeKudos}
											openPictureViewer={
												this.openPictureViewer
											}
										/>
									)}
								/>
								<Route
									exact
									path="/team/:teamSlug/admin/membership/:membershipSlug"
									render={(props) => (
										<UserSubScribeMemberShips
											key={
												props.match.params.teamSlug +
												"admin1" +
												props.match.params
													.memberShipSlug
											}
											teamSlug={
												props.match.params.teamSlug
											}
											memberShipSlug={
												props.match.params
													.membershipSlug
											}
										/>
									)}
								/>
								<Route
									path={[
										"/search",
										"/search/posts",
										"/search/teams",
										"/search/users",
										"/search/questions",
										"/search/events",
										"/search/groups",
									]}
									render={() => (
										<Search
											isMobile={isMobile}
											openPictureViewer={
												this.openPictureViewer
											}
										/>
									)}
								/>
								<Route
									exact
									path="/notifications/"
									render={() => <Notifications />}
								/>
								<Route
									exact
									path="/messages/"
									render={() => <Messages />}
								/>
							</LayoutWrapper>
						)}
					/>
					<Route exact path={"/drp"} render={() => <Dropped />} />
					<Route
						exact
						path="/TeamAdmin/"
						render={() => <TeamAdmin />}
					/>
					<Route
						exact
						path="/SplashSocial/"
						render={() => <SplashSocial />}
					/>
					{/* <Route exact path='/Login/' render={() => <Login />} /> */}
					<Route
						exact
						path="/Register/"
						render={() => <Register />}
					/>
					<Route
						exact
						path="/DesktopDesign/"
						render={() => <DesktopDesign />}
					/>
					<Route
						exact
						path="/DesktopTrending/"
						render={() => <DesktopTrending />}
					/>
					<Route
						exact
						path={"/signupUser"}
						render={() => (
							<div className="app-container">
								<SignUpUser />
							</div>
						)}
					/>
					<Route
						exact
						path={"/forget-password"}
						render={() => (
							<div className="app-container">
								<ForgetPassword />
							</div>
						)}
					/>
					<Route
						exact
						path={"/new-password"}
						render={() => (
							<div className="app-container">
								<NewPAssword />
							</div>
						)}
					/>
					<Route
						exact
						path={"/recovered"}
						render={() => (
							<div className="app-container">
								<Recovered />
							</div>
						)}
					/>

					<Route exact path="/first-time-login">
						<div className="app-container">
							<FirstTimeSignUp />
						</div>
					</Route>
					<Route
						exact
						path={"/signInUser"}
						render={() => (
							<div className="app-container">
								<SignInUser />
							</div>
						)}
					/>
					<Route
						exact
						path={"/"}
						render={() =>
							!isMobile ? <WhatsNew /> : <SplashSocial />
						}
					/>
				</Router>
			);
		} else {
			return (
				<div className="profile-container-loading">
					<img src={loading} alt="" />
				</div>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		userSignedIn: state.auth.userSignedIn,
		firstTimeLogin: state.auth.firstTimeLogin,
		loading: state.auth.loading,
	};
};

export default connect(mapStateToProps, { isUserloggedIn })(App);
