import React from "react";
import Header from "../Header";

const LayoutWrapper = ({
	isMobile,
	children,
	burgerMenuVisible,
	conversationVisible,
	onToggleBurgerMenu,
	onCloseBurgerMenu,
	onHandleConversation,
	openPictureViewer,
	userSignedIn,
	firstTimeLogin,
}) => {
	const containerClassName = !isMobile ? "outbx desktop" : "app-container";
	const menuContainerClassName =
		burgerMenuVisible || conversationVisible ? " noscroll" : "";
	const componentContainerClassName = burgerMenuVisible ? " blur" : "";
	console.log("firstTimeLogin", firstTimeLogin);
	return (
		<div
			className={`${
				!firstTimeLogin && userSignedIn && containerClassName
			} ${menuContainerClassName}`}
		>
			{isMobile && !firstTimeLogin && userSignedIn && (
				<Header
					onToggleBurgerMenu={onToggleBurgerMenu}
					onCloseBurgerMenu={onCloseBurgerMenu}
					onHandleConversation={onHandleConversation}
					openPictureViewer={openPictureViewer}
				/>
			)}
			<div className={`${componentContainerClassName}`}>{children}</div>
		</div>
	);
};

export default LayoutWrapper;
