import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AddProgram from "./modals/add-program";
import AddPlanner from "./modals/add-planner";
import EditExercise from "./modals/edit-exercise";
import AddExercise from "./modals/add-exercise";
import EditProgram from "./modals/edit-programs";

import SessionDesc from "./modals/sessiondescription";
import EditSessionTime from "./modals/edit-session-time";
import ShowStrengthSession from "./modals/view-strength-session";
import AddSession from "./modals/add-session";
import ViewSession from "./modals/view-sessions";
import ViewEx from "./modals/viewExercise";
import LoadPrograms from "./modals/loadPrograms";
import PlannerAssignment from "./modals/planner-assignment";
import { Card, Button } from "react-bootstrap";
import PrepareAddSession from "./modals/prepare-add-session";
import EditPlaner from "./modals/edit-planner";
import ShowPlannerGraph from "./modals/planner-graph";
import PrintProgramSessions from "./modals/print-program-sessions";

import {
	programAddSession,
	loadPlanners,
	loadSessions,
	showAddPlanner,
	showAddProgram,
	showAddExercise,
	showAddSession,
	prepareAddSession,
	logOutUser,
} from "../../actions";
import { getUserByRole, loadPlannerUserGroups } from "../../utils/api.js";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Accordion from "react-bootstrap/Accordion";
class LeftSidebar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			athletes: [],
			athleteArray: [],
			searchkeyword: "",
			groups: [],
			groupsArray: [],
			type: "Athlete",
		};

		this.loadPlannerVal = "";
		this.handleAddPlanner = this.handleAddPlanner.bind(this);
		this.handleAddProgram = this.handleAddProgram.bind(this);
		this.handleAddExercise = this.handleAddExercise.bind(this);
		this.handleAddSession = this.handleAddSession.bind(this);
		this.selectLoadPlanner = this.selectLoadPlanner.bind(this);
		this.handleLoadPlanner = this.handleLoadPlanner.bind(this);
	}
	componentDidMount() {
		this.props.loadPlanners(this.props.club._id);
		  getUserByRole(this.props.club._id).then((response) => {
			if (response.success== true) {
				this.setState({athletes:response.modifiedMemberships,athleteArray:response.modifiedMemberships});
			} else {
				console.log(response.status,response.msg);
			}
		});
		loadPlannerUserGroups(this.props.club._id).then((response) => {
			//if (response.success== true) {
				this.setState({groups:response,groupsArray:response});
			//} else {
				//console.log(response.status,response.msg);
			//}
		});
	}

	displayPlannersSelect(planner, ind) {
		if (ind === 0) {
			//this.setState({loadPlannerVal: planner.slug});
			this.loadPlannerVal = planner.slug;
		}
		return (
			<option
				key={"load-planner-sidebar" + planner._id}
				value={planner.slug}
			>
				{planner.title}
			</option>
		);
	}

	displayPlannersEdit(planner) {
		return (
			<a
				href="edit-planner"
				key={"edit-planner-" + planner._id}
				onClick={(e) => this.handleAddPlanner(e, planner._id)}
			>
				{planner.title}
			</a>
		);
	}

	displaySessionsEdit(session) {
		return (
			<a
				href="edit-session"
				key={"edit-session-" + session._id}
				onClick={(e) => this.programAddSession(e, session._id)}
			>
				{session.title}
			</a>
		);
	}

	programAddSession(e, id) {
		e.preventDefault();
		this.props.programAddSession(id);
	}

	handleAddPlanner(e, id) {
		e.preventDefault();
		this.props.showAddPlanner(id);
	}

	handleAddProgram(e) {
		e.preventDefault();
		this.props.showAddProgram();
	}

	handleAddExercise(e) {
		e.preventDefault();
		this.props.showAddExercise();
	}

	handleAddSession(e, id) {
		e.preventDefault();
		this.props.showAddSession(id);
		//hf
		//this.props.prepareAddSession(id);
	}

	selectLoadPlanner(e) {
		//this.setState({loadPlannerVal: e.target.value});
		this.loadPlannerVal = e.target.value;
	}

	handleLoadPlanner(e) {
		e.preventDefault();
		this.props.history.push(
			"/team/" + this.props.club.slug + "/planner/" + this.loadPlannerVal
		);
		window.location.reload();
	}

	leftSidebarChange(e) {
		e.preventDefault();
		this.props.leftSidebarChange();
	}
	handleLogOut = () => {
		if (window.confirm("Are you sure you wish to log out?")) {
			this.props.logOutUser();
			this.props.history.push("/home");
			window.location.reload();
		}
	};
	handleAthleteSearch = (e) => {
		let inputValue = e.target.value;
		if (inputValue.length >= 2) {
			let dataArray = this.state.athleteArray;
			const filteredArray = dataArray.filter((item) =>
				item.userId.username
					.toLowerCase()
					.includes(inputValue.toLowerCase())
			);
			this.setState({ athletes: filteredArray });
		} else if (inputValue.length <= 0) {
			let orignalAthArray = this.state.athleteArray;
			this.setState({ athletes: orignalAthArray });
		}
	};
	handleGroupSearch = (e) => {
		let inputValue = e.target.value;
		if (inputValue.length >= 2) {
			let dataArray = this.state.groupsArray;
			const filteredArray = dataArray.filter((item) =>
				item.memberShiptitle
					.toLowerCase()
					.includes(inputValue.toLowerCase())
			);
			this.setState({ groups: filteredArray });
		} else if (inputValue.length <= 0) {
			let orignalAthArray = this.state.groupsArray;
			this.setState({ groups: orignalAthArray });
		}
	};
	render() {
		let popup;
		if (this.props.modals.addPlanner)
			popup = <AddPlanner club={this.props.club} />;
		else if (this.props.modals.addProgram)
			popup = <AddProgram club={this.props.club} />;
		// hf
		else if (this.props.modals.prepareAddSession)
			popup = <PrepareAddSession club={this.props.club} />;
		else if (this.props.modals.addSession)
			popup = <AddSession club={this.props.club} />;
		else if (this.props.modals.addExercise)
			popup = <AddExercise club={this.props.club} />;
		else if (this.props.modals.editExercise)
			popup = <EditExercise club={this.props.club} />;
		else if (this.props.modals.editProgram)
			popup = <EditProgram club={this.props.club} />;
		else if (this.props.modals.viewExercise)
			popup = <ViewEx club={this.props.club} />;
		else if (this.props.modals.sessionDesc)
			popup = <SessionDesc club={this.props.club} />;
		else if (this.props.modals.viewSessions)
			popup = <ViewSession club={this.props.club} />;
		else if (this.props.modals.loadPrgrams)
			popup = <LoadPrograms club={this.props.club} />;
		else if (this.props.modals.editSessionTime)
			popup = <EditSessionTime club={this.props.club} />;
		else if (this.props.modals.showStrengthSession)
			popup = <ShowStrengthSession club={this.props.club} />;
		else if (this.props.modals.plannerAssignment)
			popup = <PlannerAssignment club={this.props.club} />;
		else if (this.props.modals.showPlanerModal)
			popup = <EditPlaner club={this.props.club} />;
		else if (this.props.modals.showPlannerGraph)
			popup = <ShowPlannerGraph club={this.props.club} />;
		else if (this.props.modals.showPrintSessions)
			popup = <PrintProgramSessions club={this.props.club} />;

		return (
			<div
				id="sidebar-wrapper-left"
				className={
					"sidebarleft" +
					(this.props.sidebarDisplay ? "" : " sidebarLeftHide")
				}
			>
				{popup}

				<div className="wrapin">
					<div className="wrapin-inner">
						<a
							href="#menu-toggle-left"
							className="common-style menu-toggle-left"
							onClick={(e) => this.leftSidebarChange(e)}
						>
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
						</a>
						<div className="profile clearfix">
							<Link
								to={
									"/team/" +
									this.props.club.slug +
									"/coach-dashboard/"
								}
							>
								<div className="profile_pic">
									<img
										src={
											this.props.user?.profilePicture ==
											""
												? "/uploads/images/dummy.png"
												: "/uploads/images/" +
												  this.props.user
														?.profilePicture
										}
										className="avatar"
										width="50"
										height="50"
										alt=""
									/>
								</div>
							</Link>
							<div className="profile_info">
								<h2>
									{this.props.user?.firstName +
										" " +
										this.props.user?.lastName}
								</h2>
								<Dropdown>
									<Dropdown.Toggle
										variant="secondary"
										id="dropdown-basic"
									>
										<i
											className="fa fa-circle"
											aria-hidden="true"
										></i>
										<i
											className="fa fa-circle"
											aria-hidden="true"
										></i>
										<i
											className="fa fa-circle"
											aria-hidden="true"
										></i>
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<Dropdown.Item
											as={Link}
											to={
												"/profile/" +
												this.props.user?.userId +
												"/edit"
											}
										>
											Edit Profile
										</Dropdown.Item>
										<Dropdown.Item as={Link} to={"/home/"}>
											Account Settings
										</Dropdown.Item>
										<Dropdown.Item
											as={Link}
											to={
												"/profile/" +
												this.props.user?.userId +
												"/edit"
											}
										>
											Change Password
										</Dropdown.Item>
										<Dropdown.Item
											onClick={this.handleLogOut}
										>
											Logout
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
								{/* {<a href="" className="edit-profile dropdown-toggle" data-toggle="dropdown">
									<i className="fa fa-circle" aria-hidden="true"></i>
									<i className="fa fa-circle" aria-hidden="true"></i>
									<i className="fa fa-circle" aria-hidden="true"></i>
									<ul className="dropdown-menu custom-drop" role="menu">
										<li><a href="">Edit Profile</a></li>
										<li><a href="">Account Settings</a></li>
										<li><a href="">Change Password</a></li>
										<li><a href="">Logout</a></li>
									</ul>
								</a>} */}
							</div>
						</div>
						<hr />
						<ul className="nav side-menu">
							<li>
								<Link
									to={
										"/team/" +
										this.props.club.slug +
										"/coach-dashboard/"
									}
								>
									Coach Dashboard
								</Link>
							</li>
							<li>
								<Link
									to={
										"/team/" +
										this.props.club.slug +
										"/private-messages/"
									}
								>
									Messages <span className=""></span>
								</Link>
							</li>
						</ul>
						<div className="bgbtn">
							<DropdownButton
								variant="coaching-mate"
								size="sm"
								id="dropdown-add"
								title="Add"
							>
								<Dropdown.Item
									onClick={(e) =>
										this.handleAddPlanner(e, null)
									}
								>
									Add Planner
								</Dropdown.Item>
								<Dropdown.Divider />
								<Dropdown.Item onClick={this.handleAddProgram}>
									Add Program
								</Dropdown.Item>
								<Dropdown.Divider />
								<Dropdown.Item onClick={this.handleAddExercise}>
									Add Exercise
								</Dropdown.Item>
								<Dropdown.Divider />
								<Dropdown.Item
									onClick={(e) =>
										this.handleAddSession(e, null)
									}
								>
									Add Session
								</Dropdown.Item>
								<Dropdown.Divider />

								<Dropdown.Item>
									<Link
										to={
											"/team/" +
											this.props.club.slug +
											"/strength-session"
										}
									>
										Add Strength Session{" "}
									</Link>
								</Dropdown.Item>
							</DropdownButton>
						</div>
						<div style={{ marginTop: 10 }}>
							Load Planner
							<form method="post" action="/planner/">
								<div className="styled-select top-one">
									<select
										name="load-planner"
										id="load-planner-sidebar"
										style={{ color: "#333333" }}
										onChange={this.selectLoadPlanner}
									>
										{this.props.planners.map(
											this.displayPlannersSelect.bind(
												this
											)
										)}
									</select>
								</div>
								<div
									className="create-planner-btn"
									style={{ marginTop: 10 }}
								>
									<input
										type="button"
										className="loadplannerbtn"
										name=""
										value="Load Planner"
										onClick={(e) =>
											this.handleLoadPlanner(e)
										}
									/>
								</div>
							</form>
						</div>
						<Tabs
							defaultActiveKey="home"
							transition={false}
							id="noanim-tab-example"
							className="mb-3"
						>
							<Tab eventKey="home" title="Athletes">
								<div className="srchbx">
									<input
										id="athlete-search"
										type="text"
										className="form-control"
										placeholder="Search Athletes"
										onChange={this.handleAthleteSearch}
									/>
								</div>
								<ul id="ul_sidebar_athlete_tab">
									{this.state.athletes &&
										this.state.athletes.length > 0 &&
										this.state.athletes.map((athlete) => (
											<li
												key={athlete.userId}
												className="user_status_pink user_status_all"
											>
												<div className="single-list">
													<div className="athlete-img">
														<a href="/athlete-profile/">
															<img
																src={
																	athlete
																		.userId
																		.profilePicture ==
																	""
																		? "/uploads/images/dummy.png"
																		: "/uploads/images/" +
																		  athlete
																				.userId
																				.profilePicture
																}
																className="avatar"
																width="30"
																height="30"
																alt=""
															/>
														</a>
													</div>
													<div className="athlete-name">
														<a href="/athlete-profile/">{`${athlete.userId.username}`}</a>
													</div>
													<Dropdown>
														<Dropdown.Toggle
															variant="secondary"
															id="dropdown-basic"
														>
															<i
																className="fa fa-circle"
																aria-hidden="true"
															></i>
															<i
																className="fa fa-circle"
																aria-hidden="true"
															></i>
															<i
																className="fa fa-circle"
																aria-hidden="true"
															></i>
														</Dropdown.Toggle>

														<Dropdown.Menu>
															<Dropdown.Item
																as={Link}
																to={
																	"/profile/" +
																	athlete
																		.userId
																		.userId
																}
															>
																View Profile
															</Dropdown.Item>
															<Dropdown.Item
																as={Link}
																to={"/home/"}
															>
																Send Message
															</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</div>
											</li>
										))}
								</ul>
							</Tab>
							<Tab eventKey="profile" title="Groups">
								<div className="srchbx">
									<input
										id="athlete-search"
										type="text"
										className="form-control"
										placeholder="Search Groups"
										onChange={this.handleGroupSearch}
									/>
								</div>

								<Accordion defaultActiveKey="0">
									{this.state.groups &&
										this.state.groups.length > 0 &&
										this.state.groups.map((group, idx) => (
											<Card key={idx}>
												<Card.Header>
													<Accordion.Toggle
														as={Button}
														variant="link"
														eventKey="{group.memberShipId}"
													>
														<img
															src={
																group.logo == ""
																	? "/uploads/images/dummy.png"
																	: "/uploads/images/" +
																	  group.logo
															}
															className="avatar"
															width="30"
															height="30"
															alt=""
														/>
														<p>
															{`${group.memberShiptitle}`}{" "}
															(
															{group.users.length}
															)
														</p>
													</Accordion.Toggle>
												</Card.Header>
												{group.users &&
													group.users.length > 0 &&
													group.users.map(
														(user, idx) => (
															<Accordion.Collapse
																eventKey="{group.memberShipId}"
																key={idx}
															>
																<Card.Body>
																	<img
																		src={
																			group.logo ==
																			""
																				? "/uploads/images/dummy.png"
																				: "/uploads/images/" +
																				  group.logo
																		}
																		className="avatar"
																		width="30"
																		height="30"
																		alt=""
																	/>
																	<p>
																		{user?.firstName +
																			" " +
																			user?.lastName}
																	</p>
																	<Dropdown>
																		<Dropdown.Toggle
																			variant="secondary"
																			id="dropdown-basic"
																		>
																			<i
																				className="fa fa-circle"
																				aria-hidden="true"
																			></i>
																			<i
																				className="fa fa-circle"
																				aria-hidden="true"
																			></i>
																			<i
																				className="fa fa-circle"
																				aria-hidden="true"
																			></i>
																		</Dropdown.Toggle>

																		<Dropdown.Menu>
																			<Dropdown.Item
																				as={
																					Link
																				}
																				to={
																					"/profile/" +
																					user?.userId
																				}
																			>
																				View
																				Profile
																			</Dropdown.Item>
																			<Dropdown.Item
																				as={
																					Link
																				}
																				to={
																					"/home/"
																				}
																			>
																				Send
																				Message
																			</Dropdown.Item>
																		</Dropdown.Menu>
																	</Dropdown>
																</Card.Body>
															</Accordion.Collapse>
														)
													)}
											</Card>
										))}
									{/* // <Card>
									// 	<Card.Header>
									// 	<Accordion.Toggle as={Button} variant="link" eventKey="1">
									// 		Click me!
									// 	</Accordion.Toggle>
									// 	</Card.Header>
									// 	<Accordion.Collapse eventKey="1">
									// 	<Card.Body>Hello! I'm another body</Card.Body>
									// 	</Accordion.Collapse>
									// </Card> */}
								</Accordion>
								{/* <Accordion defaultActiveKey="0">
								{this.state.groups && this.state.groups.length > 0 && this.state.groups.map(group => (
									<Card key={group.memberShipId}>
										<Card.Header>
										<Accordion.Toggle as={Button} variant="link" eventKey={group.memberShipId}>
										<img src={group.logo == '' ? '/uploads/images/dummy.png' : '/uploads/images/'+group.logo} className="avatar" width="30" height="30" alt="" />{`${group.memberShiptitle}`}
										</Accordion.Toggle>
										</Card.Header>
										{group.users && group.users.length > 0 && group.users.map(user => (
										<Accordion.Collapse eventKey={user?._id}>
											<Card.Body>{user?.firstName+' '+user?.lastName}</Card.Body>
										</Accordion.Collapse>
										))}
									</Card>
									))}
									
									</Accordion> */}
								{/* <ul id="ul_sidebar_athlete_tab">
									{this.state.groups && this.state.groups.length > 0 && this.state.groups.map(group => (
										<li key={group._id} className="user_status_pink user_status_all">
											<div className="single-list">
												<div className="athlete-img"><a href="/athlete-profile/"><img src={group.logo == '' ? '/uploads/images/dummy.png' : '/uploads/images/'+group.logo} className="avatar" width="30" height="30" alt="" /></a></div>
												<div className="athlete-name"><a href="/athlete-profile/">{`${group.title}`}</a></div>
												
											</div>
										</li>
										))}
									</ul> */}
							</Tab>
						</Tabs>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		modals: state.planner.modals,
		error: state.auth.error,
		loading: state.auth.loading,
		planners: state.planner.planners,
		sessions: state.planner.sessions,
	};
};

export default withRouter(
	connect(mapStateToProps, {
		programAddSession,
		loadPlanners,
		loadSessions,
		showAddPlanner,
		showAddProgram,
		showAddExercise,
		showAddSession,
		prepareAddSession,
		logOutUser,
	})(LeftSidebar)
);
