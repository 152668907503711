import React, { useState, useEffect } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Feed from "../components/Feed";
import LeftSideBar from "../components/LeftSideBar";

import { getTeamSocial, getPostByTeam, mobileQuery } from "../utils/api";

import Avatar from "../components/common/avatar";
import Loader from "../components/common/loader";

const Team = (props) => {
	const [team, setTeam] = useState({
		title: "",
		slug: "",
		description: "",
		postReqApproval: true,
		postRestriction: "",
	});
	const [posts, setPosts] = useState([]);
	const [groups, setGroups] = useState({});

	const [isAdmin, setIsAdmin] = useState(false);
	const [isAdminOrJoined, setIsAdminOrJoined] = useState(false);
	const [loading, setLoading] = useState(true);
	const [redirect] = useState(false);
	const [showEllipsisOpt] = useState(false);

	useEffect(() => {
		getTeamData();
	}, [props.teamSlug, props.user._id]);

	const getTeamData = async () => {
		try {
			const resp = await getTeamSocial(props.teamSlug, props.user._id);
			if (resp.success === true) {
				setTeam(resp.team);
				setGroups(resp.groups);
				setIsAdmin(resp.isAdmin);
				setIsAdminOrJoined(resp.isAdminOrJoined);
				setLoading(false);

				const res = await getPostByTeam(resp.team.slug);
				setPosts(res.posts);
			} else {
				alert("Error fetching data, please try again");
			}
		} catch (error) {
			alert("Error fetching data, please try again");
		}
	};

	const deletePost = (postId) => {
		props.deletePost(postId);
		getTeamData();
	};

	const changeKudos = async (postId, kudos) => {
		try {
			const post = await props.changeKudos(postId, kudos);
			if (post.postId) {
				setPosts((previousState) => {
					let sortedPosts = previousState.filter(
						(p) => p.postId !== postId
					);
					sortedPosts.push(post);
					sortedPosts.sort(
						(a, b) => Date.parse(b.time) - Date.parse(a.time)
					);
					return sortedPosts;
				});
				return true;
			} else {
				return false;
			}
		} catch (error) {
			return false;
		}
	};

	const createComment = async (postId, postComment) => {
		try {
			const post = await props.createComment(
				postId,
				postComment,
				props.user._id,
				props.user.userId
			);
			if (post.postId) {
				setPosts((previousState) => {
					let sortedPosts = previousState.filter(
						(p) => p.postId !== postId
					);
					sortedPosts.push(post);
					sortedPosts.sort(
						(a, b) => Date.parse(b.time) - Date.parse(a.time)
					);
					return sortedPosts;
				});
				return true;
			} else {
				return false;
			}
		} catch (error) {
			return false;
		}
	};

	const postRestriction = () => {
		return ["allowAll", "allowMembers", "onlyAdmin"].includes(
			team.postRestriction
		);
	};
	const isMobile = window.matchMedia(mobileQuery).matches;
	if (redirect) {
		return <Redirect push to="/home" />;
	}
	if (loading) {
		return <Loader loading={loading} styled={false} />;
	}

	if (team.status === "remove") {
		return (
			<div className="outbx">
				<div className="boxmenu">
					Team is removed, <Link to={"/teams/"}> go to teams</Link>
				</div>
			</div>
		);
	}

	if (!props.userSignedIn) {
		return (
			<div className="home-container-no-user">
				<h2>You must login to see your feed.</h2>
				<div>
					<Link to="/signInUser">Log In</Link>
					<Link to="/signupUser">SignUp</Link>
				</div>
			</div>
		);
	}
	return (
		<>
			<div className={isMobile ? "outbx" : "container cntntbx"}>
				<div className={isMobile ? "" : "row"}>
					{isMobile && (
						<div className="teams-container">
							<Link to={"/teams/"} className="backbtn">
								{" "}
							</Link>
							<h6>{team.title}</h6>
						</div>
					)}
					{!isMobile && (
						<div className="col-3">
							<LeftSideBar
								team={team}
								isAdmin={isAdmin}
								isAdminOrJoined={isAdminOrJoined}
							/>
						</div>
					)}
					<div className={isMobile ? "" : "col-9"}>
						<div className="topbanner bxshadow">
							<div className="post-img-parent">
								<Avatar
									url={"/uploads/team/"}
									state={team.coverPhoto}
									alt={"team cover photo"}
									className={"post-img"}
								/>
							</div>

							<div className="row bnrin1">
								<div className="col-md-12 col-lg-5">
									<h3>{team.title}</h3>
								</div>
								<div className="col-md-12 col-lg-7 bnrin2">
									<div className="row">
										<div className="col-6 mbrcnt">
											{" "}
											<span>{`${
												team.membersCount
											} member${
												team.membersCount > 1 ? "s" : ""
											}`}</span>
											{isAdmin && (
												<span>
													<Link
														to={`/team/${team.slug}/admin`}
														className="badge badge-success"
													>
														Admin
													</Link>
												</span>
											)}
											{!isAdmin && (
												<span>
													<Link
														to={{
															pathname: `/team/${team.slug}/memberships`,
															state: {
																teamStatus:
																	team.status,
																isAdminOrJoined:
																	isAdminOrJoined,
															},
														}}
														className="memberShip-btn"
													>
														View Membership Plans
													</Link>
												</span>
											)}
											{/* <span className='mropt' onClick={this.toggleOpt}>
                                            <img src='/uploads/images/dots.png' />{' '}
                                            Options
                                        </span> */}
											{showEllipsisOpt && (
												<div className="ovrmnu">
													<ul>
														<li>
															<a href="#">
																Share Team
															</a>
														</li>
														{isAdmin && (
															<li>
																<a
																	href="#"
																	onClick={() =>
																		props.history.push(
																			`/createTeamEvent/${team.slug}`
																		)
																	}
																>
																	Create Event
																</a>
															</li>
														)}
													</ul>{" "}
												</div>
											)}
										</div>
										<div className="col-6 text-right">
											<Link
												to={{
													pathname: `/team/${team.slug}/events/`,
													state: {
														teamStatus: team.status,
													},
												}}
												className="btn"
											>
												View Events
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-10 grplist">
								<Link to={"/team/" + team.slug + "/groups/"}>
									{groups.length > 0 && groups.length}
									<span className="sbinf">
										{groups.length > 0
											? groups.length === 1
												? "Sub Group"
												: "Sub Groups"
											: "No Sub Group"}
									</span>
								</Link>
								{isAdmin && (
									<span className="grpicn addgrp">
										<Link to={"/groups/add/" + team.slug}>
											+
										</Link>
									</span>
								)}
							</div>
						</div>
						{team.status !== "suspend" &&
							props.user._id !== undefined &&
							team.banned !== undefined &&
							!team.banned.includes(props.user._id) &&
							(team.postRestriction === "allowAll" ||
								(team.postRestriction === "allowMembers" &&
									isAdminOrJoined) ||
								(team.postRestriction === "onlyAdmin" &&
									isAdmin)) && (
								<div className="wrtpost">
									<div className="userthumb">
										<a href="#" className="userbx">
											<Avatar
												url={"/uploads/user/"}
												state={
													props.user.profilePicture
												}
												alt={"user profile picture"}
											/>
										</a>

										<Link
											to={`/team/${team.slug}/post/create`}
										>
											Post Something...
										</Link>
									</div>
								</div>
							)}

						{posts && (
							<Feed
								posts={posts}
								editPost={props.editPost}
								deletePost={deletePost}
								showPopup={props.showPopup}
								notGeneral={true}
								changeKudos={changeKudos}
								openPictureViewer={props.openPictureViewer}
								createComment={createComment}
								isQuestionPage={props.isQuestionPage}
								hasActionPermission={
									team.banned !== undefined &&
									!team.banned.includes(props.user._id) &&
									postRestriction() &&
									team.status !== "suspend"
								}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		userSignedIn: state.auth.userSignedIn,
		user: state.auth.user,
	};
};

export default withRouter(connect(mapStateToProps, {})(Team));
