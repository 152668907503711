import React, { useEffect, useRef, useState } from "react";
import "../../../styles/customInput.css";

const InputField = ({
	value,
	onChange,
	updateMessage,
	sendMessage,
	handleUserSelection,
	searchResults,
	handleEmojiPickerToggle,
	onOpenUploadMsgPicture,
	uploadMsgPictureRef,
	uploadMsgPictureToTemp,
	scrollToLatest,
}) => {
	const inputRef = useRef(null);
	const [hasMounted, setHasMounted] = useState(false);
	const [valText, setValText] = useState(value);
	const [loading, setLoading] = useState(false);

	const setFocus = () => {
		inputRef.current.focus();
	};

	const handleInput = (e) => {
		setValText(e.target.value);
		onChange(e);
		updateMessage(e.target.value);
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			e.preventDefault(); // Prevent adding a new line
			if (loading) {
				return;
			}
			setLoading(true);
			sendMessage();
			setLoading(false);
		}
	};
	const sendingMessage = (e) => {
		if (loading) {
			return;
		}
		setLoading(true);
		sendMessage();
		setLoading(false);
	};

	const movePointerToEnd = () => {
		const range = document.createRange();
		const selection = window.getSelection();
		range.selectNodeContents(inputRef.current);
		range.collapse(false);
		selection.removeAllRanges();
		selection.addRange(range);
		setFocus();
	};
	const handleUserSelected = (user) => {
		handleUserSelection(user);
		setHasMounted(true);
	};

	useEffect(() => {
		setValText(value);
		if (hasMounted) {
			movePointerToEnd();
			setHasMounted(false);
		}
	}, [value, hasMounted]);

	const renderSearchResults = () => {
		if (searchResults?.length > 0) {
			return (
				<ul className="search-results-for-users-ul">
					{searchResults.map((user, idx) => (
						<li
							className="search-results-for-users-li"
							key={idx}
							onClick={() => handleUserSelected(user)}
						>
							{user.firstName
								? user.firstName + " " + user.lastName
								: user.name}
						</li>
					))}
				</ul>
			);
		}
		return null;
	};

	return (
		<div onClick={setFocus}>
			{renderSearchResults()}
			<div>
				<div className="w-input-text-group">
					<input
						id="w-input-container"
						className="w-input-container"
						contentEditable
						ref={inputRef}
						value={valText}
						placeholder="Write Message"
						onChange={(e) => handleInput(e)}
						onKeyDown={handleKeyDown}
					/>
				</div>
			</div>
			<div>
				<button
					className="button"
					onClick={handleEmojiPickerToggle}
					style={{ right: "75px" }}
				>
					😊
				</button>
				<button
					className="button"
					onClick={onOpenUploadMsgPicture}
					style={{ right: "39px" }}
				>
					<img src="/uploads/images/attach.svg" />
					<input
						style={{
							display: "none",
						}}
						type="file"
						ref={uploadMsgPictureRef}
						onChange={() => {
							uploadMsgPictureToTemp();
							scrollToLatest();
						}}
						onClick={(e) => (e.target.value = null)}
					/>
				</button>
				{
					<button
						className="button"
						onClick={(e) => sendingMessage(e)}
					>
						<img src="/uploads/images/send.svg" />
					</button>
				}
			</div>
		</div>
	);
};

export default InputField;
