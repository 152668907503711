import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs, Tab, Form } from "react-bootstrap";
import {
	teamUpload,
	teamAdd,
	loadUserTeams,
	getTeams,
	teamAddMember,
	memberRemoveTeam,
	teamRemoveRequest,
	getServerUrl,
} from "../utils/api.js";
import LeftSideBar from "../components/LeftSideBar";
import GoogleLocationSearch from "../components/GoogleLocationSearch";
import UploadAvatar from "../components/common/uploadAvatar/index.js";
import Loader from "../components/common/loader/index.js";
import { updateTeams, updateTeamsAdmin, updateUserTeams } from "../actions";
import Avatar from "../components/common/avatar/index.js";
import DocumentPreview from "../components/common/documentPreview/index.js";
import LoadButton from "../components/common/button/index.js";
import TabToRemove from "../components/common/tabToRemove/index.js";

class Teams extends Component {
	constructor(props) {
		super(props);

		this.state = {
			team: {
				title: "",
				description: "",
				address: "",
				addressCity: "",
				addressCountry: "",
				lat: 0,
				lng: 0,
				activityType: "Running",
				logo: "",
				coverPhoto: "",
				addressCity: "",
				addressCountry: "",
				documents: [],
			},
			addedState: false,
			teams: [],
			myTeams: [],
			joinedTeams: [],
			userTeams: this.props.user.teams,
			userTeamRequests: this.props.user.teamRequests,
			loading: true,
			activeTab: "Teams",
		};

		this.uploadLogoRef = React.createRef();
		this.uploadCoverPhotoRef = React.createRef();
		this.uploadDocumentsRef = React.createRef();

		this.handleTitle = this.handleTitle.bind(this);
		this.handleDescription = this.handleDescription.bind(this);
		this.handleAddress = this.handleAddress.bind(this);
		this.handleActivityType = this.handleActivityType.bind(this);
	}

	componentDidMount() {
		loadUserTeams(this.props.user._id).then((resp) => {
			if (resp.success === true) {
				this.props.updateTeamsAdmin(resp.myTeams.length);
				this.props.updateTeams(resp.joinedTeams.length);
				this.setState({
					myTeams: resp.myTeams,
					joinedTeams: resp.joinedTeams,
					loading: false,
				});
			} else this.setState({ loading: false });
		});
	}

	tabChanged = (key) => {
		this.setState({ activeTab: key });

		if (key === "Explore") {
			getTeams(this.props.user._id).then((resp) => {
				if (resp.success === true)
					this.setState({
						teams: resp.teams,
						userTeams: resp.userTeams,
						userTeamRequests: resp.userTeamRequests,
						loading: false,
						addedState: true,
					});
				else this.setState({ loading: false, addedState: true });
			});
			this.setState({ loading: true });
		} else if (key === "Teams") {
			loadUserTeams(this.props.user._id).then((resp) => {
				if (resp.success === true) {
					this.props.updateTeamsAdmin(resp.myTeams.length);
					this.props.updateTeams(resp.joinedTeams.length);

					this.setState({
						myTeams: resp.myTeams,
						joinedTeams: resp.joinedTeams,
						loading: false,
						addedState: true,
					});
				} else this.setState({ loading: false, addedState: true });
			});
		} else if (key === "Create New") {
			this.setState({ addedState: false });
		}
	};

	handleTitle = (e) => {
		this.setState({ team: { ...this.state.team, title: e.target.value } });
	};

	handleDescription = (e) => {
		this.setState({
			team: { ...this.state.team, description: e.target.value },
		});
	};

	handleAddress = (e) => {
		this.setState({
			team: { ...this.state.team, address: e.target.value },
		});
	};

	handleAddressCity = (e) => {
		this.setState({
			team: { ...this.state.team, addressCity: e.target.value },
		});
	};

	handleAddressCountry = (e) => {
		this.setState({
			team: { ...this.state.team, addressCountry: e.target.value },
		});
	};
	handleGoogleAddress = (address, lat, lng) => {
		this.setState({ team: { ...this.state.team, address, lat, lng } });
	};

	handleActivityType = (e) => {
		this.setState({
			team: { ...this.state.team, activityType: e.target.value },
		});
	};

	openUploadLogo = () => {
		this.uploadLogoRef.current.click();
	};

	openUploadCoverPhoto = () => {
		this.uploadCoverPhotoRef.current.click();
	};

	openUploadDocuments = () => {
		this.uploadDocumentsRef.current.click();
	};

	updateLogo = (ing) => {
		this.setState((prevState) => ({
			team: {
				...prevState.team,
				logo: ing,
			},
		}));
		this.uploadLogoRef.current.value = null;
	};

	uploadCoverPhoto = (e) => {
		let teamCoverPhoto = this.uploadCoverPhotoRef.current.files[0];
		let fileTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

		if (teamCoverPhoto === undefined) {
			alert("Please select image file to upload");
		} else if (fileTypes.indexOf(teamCoverPhoto.type) === -1) {
			alert("Please select file type of JPEG, JPG, PNG or GIF");
		} else {
			const Imagedata = new FormData();
			Imagedata.append("teamUpload", teamCoverPhoto);
			teamUpload(Imagedata).then((imgUpload) => {
				this.setState({
					loading: false,
					team: {
						...this.state.team,
						coverPhoto: imgUpload.filename,
					},
				});
			});
			this.setState({ loading: true });
		}
	};

	uploadDocuments = (e) => {
		let teamDocuments = this.uploadDocumentsRef.current.files[0];
		let fileTypes = [
			"image/jpeg",
			"image/jpg",
			"image/png",
			"image/gif",
			"application/pdf",
			"application/msword",
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		];

		if (teamDocuments === undefined) {
			alert("Please select image file to upload");
		} else if (fileTypes.indexOf(teamDocuments.type) === -1) {
			alert(
				"Please select file type of JPEG, JPG, PNG, GIF, PDF or Microsoft Word DOC"
			);
		} else {
			const Imagedata = new FormData();
			Imagedata.append("teamUpload", teamDocuments);
			teamUpload(Imagedata).then((imgUpload) => {
				let documents = this.state.team.documents;
				documents.push(imgUpload.filename);
				this.setState({
					loading: false,
					team: { ...this.state.team, documents },
				});
			});
			this.setState({ loading: true });
		}
	};

	addTeam = async (e) => {
		e.preventDefault();

		if (this.state.team.title === "") {
			alert("Enter team title");
			return;
		} else if (this.state.team.description === "") {
			alert("Enter team description");
			return;
		} else if (this.state.team.address === "") {
			alert("Enter team address");
			return;
		} else if (this.state.team.addressCity === "") {
			alert("Enter team city");
			return;
		} else if (this.state.team.addressCountry === "") {
			alert("Select team country");
			return;
		} else if (this.state.team.activityType === "") {
			alert("Select team activity Type");
			return;
		} else if (this.state.team.coverPhoto === "") {
			alert("Upload team cover photo");
			return;
		} else if (this.state.team.logo === "") {
			alert("Upload team logo");
			return;
		} else {
			if (this.state.loading) {
				return;
			}
			this.setState({ loading: true });

			let team = this.state.team;
			let slugify = require("slugify");
			team.slug = slugify(team.title, {
				replacement: "-",
				remove: null,
				lower: true,
			});
			team.creatorId = this.props.user._id;
			await teamAdd(team).then((resp) => {
				if (resp.success == false) {
					this.setState({ loading: false });
					alert(resp.msg);
				} else {
					alert(resp.msg);
					this.setState({
						loading: false,
						myTeams: resp.myTeams,
						team: {
							title: "",
							description: "",
							address: "",
							lat: 0,
							lng: 0,
							activityType: "Running",
							logo: "",
							coverPhoto: "",
							addressCity: "",
							addressCountry: "",
							documents: [],
							addedState: true,
						},
					});
					this.uploadLogoRef.current.value = null;
					this.uploadCoverPhotoRef.current.value = null;
					this.uploadDocumentsRef.current.value = null;
					this.tabChanged("Teams");
				}
			});
		}
	};

	joinTeam = (e, teamId, teamType) => {
		e.preventDefault();
		teamAddMember(teamId, this.props.user._id, teamType).then((resp) => {
			alert(resp.msg);
			if (resp.success === true) {
				this.setState({
					teams: resp.teams,
					joinedTeams: resp.joinedTeams,
					userTeams: resp.userTeams,
					userTeamRequests: resp.userTeamRequests,
					loading: false,
				});
				this.props.updateUserTeams(
					resp.userTeams,
					resp.userTeamRequests
				);
			} else this.setState({ loading: false });
		});
		this.setState({ loading: true });
	};

	leaveTeam = (e, teamId) => {
		e.preventDefault();
		memberRemoveTeam(teamId, this.props.user._id).then((resp) => {
			alert(resp.msg);
			if (resp.success === true) {
				this.setState({
					teams: resp.teams,
					joinedTeams: resp.joinedTeams,
					userTeams: resp.userTeams,
					userTeamRequests: resp.userTeamRequests,
					loading: false,
				});
				this.props.updateUserTeams(
					resp.userTeams,
					resp.userTeamRequests
				);
			} else this.setState({ loading: false });
		});
		this.setState({ loading: true });
	};

	removeTeamRequest = (e, teamId) => {
		e.preventDefault();
		teamRemoveRequest(teamId, this.props.user._id).then((resp) => {
			alert(resp.msg);
			if (resp.success == true) {
				this.setState({
					teams: resp.teams,
					joinedTeams: resp.joinedTeams,
					userTeams: resp.userTeams,
					userTeamRequests: resp.userTeamRequests,
					loading: false,
				});
				this.props.updateUserTeams(
					resp.userTeams,
					resp.userTeamRequests
				);
			} else this.setState({ loading: false });
		});
		this.setState({ loading: true });
	};

	removePhoto = () => {
		this.setState({
			team: {
				...this.state.team,
				coverPhoto: "",
			},
		});
		this.uploadCoverPhotoRef.current.value = null;
	};

	removeDocument = (filename) => {
		const updatedDocuments = this.state.team.documents.filter(
			(doc) => doc.filename !== filename
		);
		this.setState({
			team: {
				...this.state.team,
				documents: updatedDocuments,
			},
		});
	};

	render() {
		let is_mobile = window.matchMedia("(max-width: 500px)").matches;

		return (
			<div className={is_mobile ? "outbx" : "container cntntbx"}>
				<Loader loading={this.state.loading} />
				<div className={is_mobile ? "" : "row"}>
					{is_mobile ? null : (
						<div className="col-3">
							<LeftSideBar />
						</div>
					)}

					<div className={is_mobile ? "" : "col-9"}>
						<Tabs
							fill
							defaultActiveKey={this.state.activeTab}
							activeKey={this.state.activeTab}
							id="uncontrolled-tab-example"
							onSelect={(k) => this.tabChanged(k)}
						>
							<Tab eventKey="Explore" title="Explore">
								<div
									className={
										is_mobile
											? "main-container"
											: "secteams"
									}
								>
									<h3>Explore Teams</h3>
									<div className={is_mobile ? "" : "row"}>
										{this.state.teams.map((team) => (
											<div
												key={"teams-" + team._id}
												className={
													is_mobile
														? "club-box"
														: "col-lg-6 col-md-12 clubbxsrch"
												}
											>
												<div
													className={
														is_mobile
															? ""
															: "bxshadow teambxim"
													}
												>
													<div className="row">
														<div
															className={
																is_mobile
																	? "col-2 pr-0"
																	: "col-4"
															}
														>
															<Link
																to={
																	"/team/" +
																	team.slug
																}
															>
																<Avatar
																	url={
																		"/uploads/team/"
																	}
																	state={
																		team.logo
																	}
																	alt={
																		"Team Logo"
																	}
																/>
															</Link>
														</div>
														{is_mobile ? (
															<div className="col-7">
																<h5
																	style={{
																		display:
																			"block",
																		width: "100%",
																	}}
																>
																	<Link
																		to={
																			"/team/" +
																			team.slug
																		}
																	>
																		{
																			team.title
																		}
																	</Link>
																</h5>
																<small>
																	{
																		team.membersCount
																	}{" "}
																	members
																</small>
															</div>
														) : (
															<div className="col-8 pl-0">
																<Link
																	to={
																		"/team/" +
																		team.slug
																	}
																>
																	{team.title}
																</Link>
																<span className="small pstim">
																	{
																		team.membersCount
																	}{" "}
																	members
																</span>
																<p>
																	<span>
																		{!this.state.userTeams.includes(
																			team._id
																		) &&
																			!this.state.userTeamRequests.includes(
																				team._id
																			) && (
																				<a
																					className="f14 btn"
																					href=""
																					onClick={(
																						e
																					) =>
																						this.joinTeam(
																							e,
																							team._id,
																							team.type
																						)
																					}
																				>
																					Join
																				</a>
																			)}
																		{this.state.userTeams.includes(
																			team._id
																		) && (
																			<a
																				className="f14 btn"
																				href=""
																				onClick={(
																					e
																				) =>
																					this.leaveTeam(
																						e,
																						team._id
																					)
																				}
																			>
																				Leave
																			</a>
																		)}
																		{this.state.userTeamRequests.includes(
																			team._id
																		) && (
																			<a
																				className="f14 btn"
																				href="#"
																				// onClick={(
																				// 	e
																				// ) =>
																				// 	this.removeTeamRequest(
																				// 		e,
																				// 		team._id
																				// 	)
																				// }
																			>
																				Pending
																			</a>
																		)}
																	</span>
																</p>
															</div>
														)}
														{is_mobile ? (
															<div className="col-3 pl-0 pr-1 text-right">
																{!this.state.userTeams.includes(
																	team._id
																) &&
																	!this.state.userTeamRequests.includes(
																		team._id
																	) && (
																		<a
																			className="btn"
																			href=""
																			onClick={(
																				e
																			) =>
																				this.joinTeam(
																					e,
																					team._id,
																					team.type
																				)
																			}
																		>
																			Join
																		</a>
																	)}
																{this.state.userTeams.includes(
																	team._id
																) && (
																	<a
																		className="btn"
																		href=""
																		onClick={(
																			e
																		) =>
																			this.leaveTeam(
																				e,
																				team._id
																			)
																		}
																	>
																		Leave
																	</a>
																)}
																{this.state.userTeamRequests.includes(
																	team._id
																) && (
																	<a
																		className="btn"
																		href="#"
																		// onClick={(
																		// 	e
																		// ) =>
																		// 	this.removeTeamRequest(
																		// 		e,
																		// 		team._id
																		// 	)
																		// }
																	>
																		Pending
																	</a>
																)}
															</div>
														) : null}
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</Tab>
							<Tab eventKey="Teams" title="Teams">
								<div
									className={
										is_mobile
											? "main-container"
											: "secteams"
									}
								>
									<h3>Your Teams</h3>
									<div className={is_mobile ? "" : "row"}>
										{this.state.myTeams.map((team) => (
											<div
												key={"my-teams-" + team._id}
												className={
													is_mobile
														? "club-box"
														: "col-lg-6 col-md-12 clubbxsrch"
												}
											>
												<div
													className={
														is_mobile
															? ""
															: "bxshadow teambxim"
													}
												>
													<div className="row">
														<div
															className={
																is_mobile
																	? "col-2 pr-0"
																	: "col-4"
															}
														>
															<Link
																to={
																	"/team/" +
																	team.slug
																}
															>
																<Avatar
																	url={
																		"/uploads/team/"
																	}
																	state={
																		team.logo
																	}
																	alt={
																		"Team Logo"
																	}
																/>
															</Link>
														</div>
														{is_mobile ? (
															<div className="col-7">
																<h5
																	style={{
																		display:
																			"block",
																		width: "100%",
																	}}
																>
																	<Link
																		to={
																			"/team/" +
																			team.slug
																		}
																	>
																		{
																			team.title
																		}
																	</Link>
																</h5>
																<small>
																	{
																		team.membersCount
																	}{" "}
																	members
																</small>
															</div>
														) : (
															<div className="col-8 pl-0">
																<Link
																	to={
																		"/team/" +
																		team.slug
																	}
																>
																	{team.title}
																</Link>
																<span className="small pstim">
																	{
																		team.membersCount
																	}{" "}
																	members
																</span>
																<p>
																	<span>
																		<Link
																			to={
																				"/team/" +
																				team.slug +
																				"/admin"
																			}
																			className="f14 btn"
																		>
																			Admin
																		</Link>
																	</span>
																</p>
															</div>
														)}
														{is_mobile ? (
															<div className="col-3 pl-0 pr-1 text-right">
																<Link
																	to={
																		"/team/" +
																		team.slug +
																		"/admin"
																	}
																	className="btn"
																>
																	Admin
																</Link>
															</div>
														) : null}
													</div>
												</div>
											</div>
										))}
									</div>

									<h3>Teams You Have Joined</h3>
									<div className={is_mobile ? "" : "row"}>
										{this.state.joinedTeams.map((team) => (
											<div
												key={"my-teams-" + team._id}
												className={
													is_mobile
														? "club-box"
														: "col-lg-6 col-md-12 clubbxsrch"
												}
											>
												<div
													className={
														is_mobile
															? ""
															: "bxshadow teambxim"
													}
												>
													<div className="row">
														<div
															className={
																is_mobile
																	? "col-2"
																	: "col-4"
															}
														>
															<Link
																to={
																	"/team/" +
																	team.slug
																}
															>
																<Avatar
																	url={
																		"/uploads/team/"
																	}
																	state={
																		team.logo
																	}
																	alt={
																		"Team Logo"
																	}
																/>
															</Link>
														</div>
														{is_mobile ? (
															<div className="col-7 pr-0">
																<h5
																	style={{
																		display:
																			"block",
																		width: "100%",
																	}}
																>
																	<Link
																		to={
																			"/team/" +
																			team.slug
																		}
																	>
																		{
																			team.title
																		}
																	</Link>
																</h5>
																<small>
																	{
																		team.membersCount
																	}{" "}
																	members
																</small>
															</div>
														) : (
															<div className="col-8 pl-0">
																<Link
																	to={
																		"/team/" +
																		team.slug
																	}
																>
																	{team.title}
																</Link>
																<span className="small pstim">
																	{
																		team.membersCount
																	}{" "}
																	members
																</span>
																<p>
																	<span>
																		<a
																			className="f14 btn"
																			href=""
																			onClick={(
																				e
																			) =>
																				this.leaveTeam(
																					e,
																					team._id
																				)
																			}
																		>
																			Leave
																		</a>
																	</span>
																</p>
															</div>
														)}

														{is_mobile ? (
															<div className="col-3 pl-0 pr-1 text-right">
																{team.administrators.includes(
																	this.props
																		.user
																		._id
																) ? (
																	<Link
																		to={
																			"/team/" +
																			team.slug +
																			"/admin"
																		}
																		className="btn"
																	>
																		Admin
																	</Link>
																) : team.moderators.includes(
																		this
																			.props
																			.user
																			._id
																  ) ? (
																	<Link
																		to={
																			"/team/" +
																			team.slug +
																			"/admin"
																		}
																		className="btn"
																	>
																		Moderator
																	</Link>
																) : team.coaches.includes(
																		this
																			.props
																			.user
																			._id
																  ) ? (
																	<Link
																		to={
																			"/team/" +
																			team.slug +
																			"/admin"
																		}
																		className="btn"
																	>
																		Coach
																	</Link>
																) : (
																	<a
																		className="btn"
																		href=""
																		onClick={(
																			e
																		) =>
																			this.leaveTeam(
																				e,
																				team._id
																			)
																		}
																	>
																		Leave
																	</a>
																)}
															</div>
														) : null}
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</Tab>
							<Tab eventKey="Create New" title="Create New">
								<div className="main-container createnew upload">
									<h3>Create Team</h3>
									<div className="userthumb">
										<span className="userbx">
											<Avatar
												url={"/uploads/user/"}
												state={
													this.props.user
														.profilePicture
												}
												alt={"Profile Pic"}
											/>
										</span>
										<span>
											{this.props.user.firstName +
												" " +
												this.props.user.lastName}
										</span>
									</div>
									<form>
										<Form.Group controlId="formTeamTitle">
											<Form.Label>Title *</Form.Label>
											<Form.Control
												type="text"
												name="title"
												placeholder="Team title *"
												value={this.state.team.title}
												onChange={this.handleTitle}
											/>
										</Form.Group>

										<Form.Group controlId="formTeamDescription">
											<Form.Label>About *</Form.Label>
											<Form.Control
												as="textarea"
												placeholder="About Team *"
												rows={3}
												value={
													this.state.team.description
												}
												onChange={
													this.handleDescription
												}
											/>
										</Form.Group>

										<Form.Group controlId="formTeamAddress">
											<Form.Label>Location *</Form.Label>
											<Form.Control
												type="text"
												placeholder="Address"
												onChange={this.handleAddress}
												value={this.state.team.address}
											/>
										</Form.Group>
										<Form.Group controlId="formTeamDescription">
											<Form.Label>City *</Form.Label>
											<Form.Control
												type="text"
												placeholder="City"
												onChange={
													this.handleAddressCity
												}
												value={
													this.state.team.addressCity
												}
											/>
										</Form.Group>
										<Form.Group controlId="formTeamDescription">
											<Form.Label>Country *</Form.Label>
											<Form.Control
												as="select"
												onChange={
													this.handleAddressCountry
												}
												value={
													this.state.team
														.addressCountry
												}
											>
												<option value="">
													Country
												</option>
												<option value="Australia">
													Australia
												</option>
												<option value="Pakistan">
													Pakistan
												</option>
											</Form.Control>
										</Form.Group>

										<Form.Group controlId="formTeamActivityType">
											<Form.Label>
												Activity Type *
											</Form.Label>
											<Form.Control
												as="select"
												onChange={
													this.handleActivityType
												}
												value={
													this.state.team.activityType
												}
											>
												<option value="Swim">
													Swimming
												</option>
												<option value="Run">
													Running
												</option>
												<option value="Walk">
													Walking
												</option>
												<option value="Bike">
													Cycling
												</option>
												<option value="Strength">
													Weight Lifting
												</option>

												<option value="Flexibility">
													Flexibility
												</option>
												<option value="Recovery">
													Recovery
												</option>
												<option value="Duathlon">
													Duathlon
												</option>
												<option value="Triathlon">
													Triathlon
												</option>
											</Form.Control>
										</Form.Group>
										<TabToRemove
											title={"Cover Photo *"}
											tabTo={"Cover Photo Tab to remove"}
											removePhoto={this.removePhoto}
											logo={this.state.team.coverPhoto}
											upload={"/uploads/temp/"}
										/>
										<Form.Group>
											<a
												className="btn button"
												onClick={() =>
													this.openUploadCoverPhoto()
												}
											>
												Upload Cover Photo
											</a>
											<input
												type="file"
												ref={this.uploadCoverPhotoRef}
												onChange={() =>
													this.uploadCoverPhoto()
												}
												style={{ display: "none" }}
											/>
										</Form.Group>
										<Form.Group>
											<Form.Label>Logo *</Form.Label>
											<UploadAvatar
												uploadLogoRef={
													this.uploadLogoRef
												}
												openUploadLogo={
													this.openUploadLogo
												}
												updateLogo={this.updateLogo}
												uploadType={"teamUpload"}
												uploadAPI={teamUpload}
												a_style={"btn button"}
												btn_text={
													"Upload Logo (Square, min 200 width)"
												}
												addedState={
													this.state.addedState
												}
											/>
										</Form.Group>
										<Form.Group>
											<Form.Label>Document</Form.Label>
											<div
												style={{
													border:
														!this.state.team
															.coverPhoto &&
														"1px solid #e4e6e7",
													borderRadius: "9px",
													padding:
														!this.state.team
															.coverPhoto &&
														"10px",
													minHeight: "40px",
												}}
											>
												{this.state.team.documents && (
													<div className="document-preview">
														{this.state.team.documents.map(
															(
																document,
																index
															) => (
																<DocumentPreview
																	key={index}
																	file={
																		document
																	}
																	removeDocument={
																		this
																			.removeDocument
																	}
																/>
															)
														)}
													</div>
												)}
											</div>
										</Form.Group>
										<Form.Group>
											<a
												className="btn button"
												onClick={() =>
													this.openUploadDocuments()
												}
											>
												Upload Documents
											</a>
											<input
												type="file"
												ref={this.uploadDocumentsRef}
												onChange={() =>
													this.uploadDocuments()
												}
												style={{ display: "none" }}
											/>
										</Form.Group>

										<Form.Group>
											<LoadButton
												submitting={this.state.loading}
												title={"Create Team"}
												btnClass={
													"button subbtn btn-save"
												}
												titleClass={"text-white"}
												onClick={this.addTeam}
											/>
										</Form.Group>
									</form>
								</div>
							</Tab>
						</Tabs>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	};
};
const mapDispatchToProps = {
	updateTeamsAdmin,
	updateTeams,
	updateUserTeams,
};

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
