import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUser, getServerUrl } from "../utils/api";
import Avatar from "../components/common/avatar";

function UserPhotos({ token, user }) {
	const history = useHistory();
	const { userid } = useParams();
	const [photos, setPhotos] = useState([]);

	useEffect(() => {
		getUser(userid, token).then((user) => {
			if (user.userId) {
				setPhotos(user.photos);
			}
		});
	}, [userid, token]);

	return (
		<>
			<div className="teams-container">
				<a
					onClick={() => history.push("/profile/" + userid)}
					className="backbtn"
				></a>
				<h6>
					Photos
					{user.userId === userid && (
						<Link
							className="pushright createbtn f14"
							to={"/profile/" + user.userId + "/editPhoto"}
							style={{ cursor: "pointer" }}
						>
							Upload
						</Link>
					)}
				</h6>
			</div>

			<div className="usrphto ovrphts">
				<div className="phtoglry">
					<div className="row">
						{photos.map((photo, index) => (
							<div
								key={`photo-${photo}-${index}`}
								className="col-4 nopad"
							>
								<Avatar
									url={"/uploads/user/"}
									state={photo}
									alt={"Logo"}
									style={{
										width: "100px",
										height: "100px",
										borderRadius: "100%",
									}}
								/>
							</div>
						))}

						<div className="col-12 mt-2">
							<a
								className="btnbig"
								onClick={() =>
									history.push("/profile/" + user.userId)
								}
								style={{ cursor: "pointer" }}
							>
								Go Back to Profile
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		token: state.auth.token,
		user: state.auth.user,
	};
};

export default connect(mapStateToProps, {})(UserPhotos);
