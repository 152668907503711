import React from "react";

const DropDown = ({ options, onOptionSelect }) => {
	return (
		<div className="opetion-menu">
			{options.map((opt, idx) => (
				<span key={idx} onClick={() => onOptionSelect(opt.func)}>
					{opt.name}
				</span>
			))}
		</div>
	);
};

export default DropDown;
