import React, { Component } from "react";
import {
	getServerUrl,
	retrieveUserConversation,
	retrieveBoardConversation,
	updateMessages,
} from "../../utils/api";
import { connect } from "react-redux";
import { assignTimeAgo } from "../../utils/helper";

import { MdClose } from "react-icons/md";
import profileBlank from "../../assets/profile_blank.png";

class PrivateMessageConversation extends Component {
	constructor(props) {
		super(props);
		this.scrollRef = React.createRef();
	}

	componentDidMount() {
		// receive message from the receiver socket
		this.props.socket &&
			this.props.socket.on("getMessage", (data) => {
				if (data.type) {
					if (
						this.props.receiver &&
						!this.props.receiver._id
							.toString()
							.localeCompare(data.fromId.toString())
					) {
						const receiverId = this.props.user._id;

						// update message if the current chat is the sender of the new message
						if (data.type === "people") {
							retrieveUserConversation(
								data.fromId,
								receiverId
							).then(async (res) => {
								if (res.success) {
									const messages = await this.addUniqReader(
										res.messages,
										receiverId.toString()
									);

									this.props.onUpdateConversation([
										...messages,
									]);

									// update the sender online status
									res.isReceiverOnline &&
										this.props.onUpdateReceiverStatus(
											"online"
										);
								}
							});
						} else if (
							data.type === "team" ||
							data.type === "group"
						) {
							retrieveBoardConversation(
								this.props.user._id,
								this.props.receiver._id,
								data.type
							).then((res) => {
								if (res.success) {
									this.props.onUpdateSenders([
										...res.senders,
									]);
									this.props.onUpdateConversation([
										...res.messages,
									]);
								}
							});
						}
					}
					// +1 message unseen count
					// condition: message not from current chat
					else {
						this.props.onUpdateMsgUnseenCount(
							data.fromId,
							data.type
						);
					}
				}
			});

		// scroll conversation to the bottom smoothly when first mounted
		this.scrollToLatest();
	}

	componentWillUnmount() {
		// unsubscribe get message socket (avoid duplication of listeners)
		this.props.socket && this.props.socket.off("getMessage");
	}

	componentDidUpdate(prevProps, _prevState) {
		// scroll conversation to the bottom smoothly
		// trigger condition: load conversation & send message
		if (prevProps.conversations !== this.props.conversations) {
			this.scrollToLatest();
		}
	}

	hasNewReader = (originalReaders, updatedReaders) => {
		return !updatedReaders.every((reader) =>
			originalReaders.includes(reader)
		);
	};

	addUniqReader = async (messages, readerId) => {
		let uniqMsgs = [];
		let updateReaderMsgs = [];

		for (let msg of messages) {
			// retain message info if current user is not the receiver of the message
			if (msg.receiver.toString().localeCompare(readerId)) {
				uniqMsgs.push(msg);
			} else {
				if (msg.read && msg.read.length > 0) {
					const uniqReadList = {
						...msg,
						read: Array.from(new Set([...msg.read, readerId])),
					};

					if (this.hasNewReader(msg.read, uniqReadList.read)) {
						updateReaderMsgs.push(uniqReadList);
					}

					uniqMsgs.push(uniqReadList);
				} else {
					const updatedReaderMsg = { ...msg, read: [readerId] };
					uniqMsgs.push(updatedReaderMsg);

					updateReaderMsgs.push(updatedReaderMsg);
				}
			}
		}

		await updateMessages(updateReaderMsgs);

		return uniqMsgs;
	};

	scrollToLatest = () => {
		return this.scrollRef.current?.scrollIntoView({
			behavior: "smooth",
		});
	};

	render() {
		const {
			receiver,
			senders,
			imgsFile,
			imgs,
			conversations,
			message,
			user,
			tab,
			uploadMsgPictureRef,
			uploadMsgPictureToTemp,
			onCloseConversation,
			onMsgEnterPress,
			onMsgButtonPress,
			onOpenUploadMsgPicture,
			isReceiverOnline,
			onOpenPictureViewer,
			removePhoto,
			sendingMessage,
			handleUserSelection,
			messageQueryChange,
			searchResults,
		} = this.props;

		return (
			<>
				<div className="cnvrsbx">
					<div className="msghdr">
						<h5>
							Conversation Between{" "}
							<span className="grntxt">You</span> and{" "}
							<span className="grntxt">{receiver.name}</span>
						</h5>
						<span
							className="close"
							onClick={() => onCloseConversation(receiver._id)}
						>
							×
						</span>
					</div>

					<div
						style={{
							overflowY: "auto",
							overflowX: "hidden",
							height: `${imgsFile.length > 0 ? "65%" : "87.5%"}`,
						}}
					>
						{conversations.length > 0 ? (
							conversations.map((m, index) => (
								<div
									key={`conversation-${m._id}`}
									ref={this.scrollRef}
								>
									<div
										className={`usrmsgbx ${
											!m.sender
												.toString()
												.localeCompare(
													this.props.user._id
												)
												? "msgyou"
												: ""
										}`}
									>
										<div className="usrtop">
											<div className="row">
												<div className="col-2 f12">
													<div className="userthumb">
														<a className="userbx">
															<img
																src={
																	!m.sender
																		.toString()
																		.localeCompare(
																			this
																				.props
																				.user
																				._id
																		)
																		? user.profilePicture
																			? `${
																					getServerUrl()
																						.apiURL
																			  }/uploads/user/${
																					user.profilePicture
																			  }`
																			: profileBlank
																		: receiver.profilePicture
																		? `${
																				getServerUrl()
																					.apiURL
																		  }/uploads/user/${
																				receiver.profilePicture
																		  }`
																		: profileBlank
																}
															/>
														</a>
													</div>
												</div>
												<div className="col-10 nopad mt-2">
													<a>
														{!m.sender
															.toString()
															.localeCompare(
																this.props.user
																	._id
															)
															? "You"
															: tab === "teams" ||
															  tab === "groups"
															? `${senders[index].firstName} ${senders[index].lastName}`
															: receiver.name}
													</a>
													<span className="small pstim">
														{assignTimeAgo(
															m.createdAt
														)}
													</span>
												</div>
											</div>
										</div>
										<p className="f14">{m.message}</p>
										{m.imgFileName.length > 0 && (
											<div className="msgwhtimgbx">
												{m.imgFileName.map(
													(imgFileName, index) => (
														<img
															key={imgFileName}
															height="100%"
															width="47.5%"
															src={`${
																getServerUrl()
																	.apiURL
															}/uploads/message/${imgFileName}`}
															onClick={() =>
																onOpenPictureViewer(
																	`${
																		getServerUrl()
																			.apiURL
																	}/uploads/message/${imgFileName}`
																)
															}
															style={{
																cursor: "pointer",
																border: "1px solid #D3D3D3",
																marginRight: `${
																	(index +
																		1) %
																		2 ===
																	0
																		? "0"
																		: "5%"
																}`,
																marginTop: `${
																	index + 1 >
																	2
																		? "15px"
																		: ""
																}`,
															}}
														/>
													)
												)}
											</div>
										)}
									</div>

									<div className="msgwrtbx">
										{searchResults?.length > 0 && (
											<ul className="search-results-for-users-ul">
												{searchResults.map(
													(user, idx) => (
														<li
															className="search-results-for-users-li"
															key={idx}
															onClick={() =>
																handleUserSelection(
																	user
																)
															}
														>
															{user.firstName
																? user.firstName +
																  " " +
																  user.lastName
																: user.name}
														</li>
													)
												)}
											</ul>
										)}
										<h5>
											Write Message To{" "}
											<span className="grntxt">
												"{receiver.name}"
											</span>
										</h5>

										{imgs.length > 0 && (
											<div className="msgwhtimgbx">
												{imgs.map(
													(imgFileName, index) => (
														<span key={imgFileName}>
															<img
																height="100%"
																width="100%"
																src={`${
																	getServerUrl()
																		.apiURL
																}/uploads/temp/${imgFileName}`}
																onClick={() =>
																	onOpenPictureViewer(
																		`${
																			getServerUrl()
																				.apiURL
																		}/uploads/temp/${imgFileName}`
																	)
																}
																style={{
																	cursor: "pointer",
																	border: "1px solid #D3D3D3",
																	marginRight: `${
																		(index +
																			1) %
																			2 ===
																		0
																			? "0"
																			: "5%"
																	}`,
																	marginLeft: `${
																		(index +
																			1) %
																			2 !==
																		0
																			? "0"
																			: "5%"
																	}`,
																	marginTop: `${
																		index +
																			1 >
																		2
																			? "7px"
																			: "0"
																	}`,
																}}
															/>
															<MdClose
																onClick={() =>
																	removePhoto(
																		imgFileName
																	)
																}
															/>
														</span>
													)
												)}
											</div>
										)}

										<div className="form-group">
											<input
												placeholder="Write Message"
												type="text"
												className="form-control"
												value={message}
												onChange={messageQueryChange}
												onKeyDown={(e) =>
													onMsgEnterPress(e, {
														...m,
														sender: user._id,
														receiver: receiver._id,
														message: message,
														imgFileName: imgs,
													})
												}
											/>
											<button
												className="button"
												onClick={onOpenUploadMsgPicture}
												style={{ right: "39px" }}
											>
												<img src="/uploads/images/attach.svg" />
												<input
													style={{
														display: "none",
													}}
													type="file"
													ref={uploadMsgPictureRef}
													onChange={() => {
														uploadMsgPictureToTemp();
														this.scrollToLatest();
													}}
													onClick={(e) =>
														(e.target.value = null)
													}
												/>
											</button>
											{!sendingMessage && (
												<button
													className="button"
													onClick={(e) =>
														onMsgButtonPress({
															...m,
															sender: user._id,
															receiver:
																receiver._id,
															message: message,
															imgFileName: imgs,
														})
													}
												>
													<img src="/uploads/images/send.svg" />
												</button>
											)}
										</div>
									</div>
								</div>
							))
						) : (
							<>
								{conversations.map((m, index) => (
									<div
										key={`conversation-${m._id}`}
										ref={this.scrollRef}
									>
										<div
											className={`usrmsgbx ${
												!m.sender
													.toString()
													.localeCompare(
														this.props.user._id
													)
													? "msgyou"
													: ""
											}`}
										>
											<div className="usrtop">
												<div className="row">
													<div className="col-2 f12">
														<div className="userthumb">
															<a className="userbx">
																<img
																	src={
																		!m.sender
																			.toString()
																			.localeCompare(
																				this
																					.props
																					.user
																					._id
																			)
																			? user.profilePicture
																				? `${
																						getServerUrl()
																							.apiURL
																				  }/uploads/user/${
																						user.profilePicture
																				  }`
																				: profileBlank
																			: receiver.profilePicture
																			? `${
																					getServerUrl()
																						.apiURL
																			  }/uploads/user/${
																					receiver.profilePicture
																			  }`
																			: profileBlank
																	}
																/>
															</a>
														</div>
													</div>
													<div className="col-10 nopad mt-2">
														<a>
															{!m.sender
																.toString()
																.localeCompare(
																	this.props
																		.user
																		._id
																)
																? "You"
																: tab ===
																		"teams" ||
																  tab ===
																		"groups"
																? `${senders[index].firstName} ${senders[index].lastName}`
																: receiver.name}
														</a>
														<span className="small pstim">
															{assignTimeAgo(
																m.createdAt
															)}
														</span>
													</div>
												</div>
											</div>
											<p className="f14">{m.message}</p>

											{m.imgFileName.length > 0 && (
												<div className="msgwhtimgbx">
													{m.imgFileName.map(
														(
															imgFileName,
															index
														) => (
															<img
																key={
																	imgFileName
																}
																height="100%"
																width="47.5%"
																src={`${
																	getServerUrl()
																		.apiURL
																}/uploads/message/${imgFileName}`}
																style={{
																	cursor: "pointer",
																	border: "1px solid #D3D3D3",
																	marginRight: `${
																		(index +
																			1) %
																			3 ===
																		0
																			? "0"
																			: "5%"
																	}`,
																	marginTop: `${
																		index +
																			1 >
																		3
																			? "15px"
																			: ""
																	}`,
																}}
															/>
														)
													)}
												</div>
											)}
										</div>
									</div>
								))}

								<div
									className="msgwrtbx"
									style={{
										height: `${
											imgsFile.length > 0
												? "30%"
												: "14.5%"
										}`,
									}}
								>
									<h5>
										Write Message To{" "}
										<span className="grntxt">
											"{receiver.name}"
										</span>
									</h5>

									{imgs.length > 0 && (
										<div className="msgwhtimgbx">
											{imgs.map((imgFileName, index) => (
												<span key={imgFileName}>
													<img
														height="100%"
														width="100%"
														src={`${
															getServerUrl()
																.apiURL
														}/uploads/temp/${imgFileName}`}
														onClick={() =>
															onOpenPictureViewer(
																`${
																	getServerUrl()
																		.apiURL
																}/uploads/temp/${imgFileName}`
															)
														}
														style={{
															cursor: "pointer",
															border: "1px solid #D3D3D3",
															marginRight: `${
																(index + 1) %
																	2 ===
																0
																	? "0"
																	: "5%"
															}`,
															marginLeft: `${
																(index + 1) %
																	2 !==
																0
																	? "0"
																	: "5%"
															}`,
															marginTop: `${
																index + 1 > 2
																	? "7px"
																	: "0"
															}`,
														}}
													/>
													<MdClose
														onClick={() =>
															removePhoto(
																imgFileName
															)
														}
													/>
												</span>
											))}
										</div>
									)}

									<div className="form-group">
										<input
											placeholder="Write Message"
											type="text"
											className="form-control"
											value={message}
											onChange={messageQueryChange}
											onKeyDown={(e) =>
												onMsgEnterPress(e, {
													sender: this.props.user._id,
													receiver: receiver._id,
													type: receiver.type
														? receiver.type
														: tab === "people"
														? "users"
														: "",
													message: message,
													imgFileName: imgs,
												})
											}
										/>
										<button
											className="button"
											onClick={onOpenUploadMsgPicture}
											style={{ right: "50px" }}
										>
											<img src="/uploads/images/attach.svg" />
											<input
												style={{ display: "none" }}
												type="file"
												ref={uploadMsgPictureRef}
												onChange={() => {
													uploadMsgPictureToTemp();
													this.scrollToLatest();
												}}
												onClick={(e) =>
													(e.target.value = null)
												}
											/>
										</button>
										{!sendingMessage && (
											<button
												className="button"
												onClick={(e) =>
													onMsgButtonPress({
														sender: this.props.user
															._id,
														receiver: receiver._id,
														type: receiver.type
															? receiver.type
															: tab === "people"
															? "users"
															: "",
														message: message,
														imgFileName: imgs,
													})
												}
											>
												<img src="/uploads/images/send.svg" />
											</button>
										)}
									</div>
								</div>
							</>
						)}
					</div>
				</div>

				<div className="cnvrsusrsbx">
					<div className="cnvrsusrscontainer">
						<div className="cnvrsusrimg">
							<img
								src={
									receiver.profilePicture
										? `${
												getServerUrl().apiURL
										  }/uploads/user/${
												receiver.profilePicture
										  }`
										: profileBlank
								}
								className="cnvrsusrimg"
							/>
							{tab === "people" && isReceiverOnline && (
								<div className="cnvrusronlinebadge"></div>
							)}
						</div>
						<div className="cnvrsusrname">{receiver.name}</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	};
};

export default connect(mapStateToProps, {})(PrivateMessageConversation);
