import React, { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import { GoogleMap, LoadScript, Autocomplete } from "@react-google-maps/api";
import keysConfig from "../config/keys";

const libraries = ["places"];

function GoogleLocationSearch(props) {
	const [query, setQuery] = useState(props.initialValue || "");

	const autoCompleteRef = useRef(null);

	const handlePlaceSelect = async () => {
		const addressObject = autoCompleteRef.current.getPlace();
		if (addressObject) {
			const query = addressObject.formatted_address;
			setQuery(query);

			const lat = addressObject.geometry.location.lat();
			const lng = addressObject.geometry.location.lng();
			props.handleGoogleAddress(query, lat, lng);
		} else {
			// If no address is selected but query is entered manually
			props.handleGoogleAddress(query, null, null);
		}
	};

	return (
		<div className="search-location-input">
			<Autocomplete
				onLoad={(autoComp) => (autoCompleteRef.current = autoComp)}
				onPlaceChanged={handlePlaceSelect}
			>
				<Form.Control
					type="text"
					placeholder="Enter address....."
					onChange={(event) => {
						setQuery(event.target.value);
						// props.handleGoogleAddress(event.target.value, 0, 0);
					}}
					value={query}
				/>
			</Autocomplete>
		</div>
	);
}

export default GoogleLocationSearch;
