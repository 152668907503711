import React from "react";

const ReactionPopup = ({ reaction, selectedEmoji, msgID }) => {
	return (
		<div className="reaction-emoji">
			{reaction.map((emoji, idx) => (
				<span key={idx} onClick={() => selectedEmoji(emoji, msgID)}>
					{emoji}
				</span>
			))}
		</div>
	);
};

export default ReactionPopup;
