import React, { useState, useEffect } from "react";
import CropperModal from "./CropperModal";
import "./Cropper.scss";

const UploadAvatar = ({
	uploadLogoRef,
	openUploadLogo,
	updateLogo,
	a_style,
	btn_text,
	displayLogo,
	uploadType,
	uploadAPI,
	addedState,
	disable,
}) => {
	const [src, setSrc] = useState(null);
	const [preview, setPreview] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);

	const handleInputClick = (e) => {
		e.preventDefault();
		openUploadLogo();
	};

	const handleImgChange = (e) => {
		e.preventDefault();
		setSrc(URL.createObjectURL(e.target.files[0]));
		setModalOpen(true);
	};

	useEffect(() => {
		setPreview(null);
	}, [displayLogo]);
	return (
		<>
			<main className="container">
				{!disable && (
					<div className="img-view">
						<div className="img-container">
							{displayLogo ? (
								displayLogo
							) : (
								<img
									src={
										preview ||
										" https://www.signivis.com/img/custom/avatars/member-avatar-01.png"
									}
									alt=""
									width="100"
									height="100"
								/>
							)}
						</div>
					</div>
				)}
				<CropperModal
					modalOpen={modalOpen}
					src={src}
					setPreview={setPreview}
					setModalOpen={setModalOpen}
					updateLogo={updateLogo}
					uploadType={uploadType}
					uploadAPI={uploadAPI}
				/>
				<a className={a_style} onClick={handleInputClick}>
					{btn_text}
				</a>
				<input
					type="file"
					style={{ display: "none" }}
					accept="image/*"
					ref={uploadLogoRef}
					onChange={handleImgChange}
				/>
			</main>
		</>
	);
};

export default UploadAvatar;
