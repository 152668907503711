import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Post from "../components/Post";
import Comment from "../components/Comment";
import NewComment from "../components/NewComment";
import loader from "../assets/loading.svg";
import * as API from "../utils/api";

const PostDetail = (props) => {
	const [post, setPost] = useState({});
	const [loading, setLoading] = useState(true);
	const [trackShowField, setTrackShowField] = useState([]);
	const [commentOwners, setCommentOwners] = useState({});

	useEffect(() => {
		API.getPost(props.match.params.postId).then((res) => {
			let post = res.post;
			let commentOwners = res.owners;
			if (post.postId) {
				const trackShowField = new Array(post.comments.length).fill(
					false
				);
				setPost(post);
				setCommentOwners(commentOwners);
				setLoading(false);
				setTrackShowField(trackShowField);
			}
		});
	}, [props.match.params.postId]);

	const updateComments = (post) => {
		setPost(post);
	};

	const updateShowField = (pos) => {
		const updatedTrackShowField = new Array(post.comments.length).fill(
			false
		);
		updatedTrackShowField[pos] = true;
		setTrackShowField(updatedTrackShowField);
	};

	const resetShowField = () => {
		const updatedTrackShowField = new Array(post.comments.length).fill(
			false
		);
		setTrackShowField(updatedTrackShowField);
	};
	const deletePost = (postId) => {
		props.deletePost(postId);
		window.history.back();
	};

	const isMobile = window.matchMedia("(max-width: 500px)").matches;

	return (
		<div
			className={`${
				isMobile ? "feed-container" : "post-details-container cntntbx"
			}`}
		>
			{loading ? (
				<div className="profile-container-loading">
					<img src={loader} alt="" />
				</div>
			) : (
				<>
					{isMobile && (
						<div className="teams-container">
							<a
								href="#"
								className="backbtn"
								onClick={(e) => {
									e.preventDefault();
									window.history.back();
								}}
							>
								{" "}
							</a>
							<h6>Full Post View</h6>
						</div>
					)}
					<Post
						post={post}
						editPost={props.editPost}
						deletePost={deletePost}
						showPopup={props.showPopup}
						notGeneral={true}
						showPopupTwoButton={props.showPopupTwoButton}
						createComment={props.createComment}
						changeKudos={props.changeKudos}
						openPictureViewer={props.openPictureViewer}
						hasActionPermission={props.hasReplyCommentPermission}
						isFullView={true}
					/>
					<div className="comntsec mb-4 mt-0">
						<h6>Comments</h6>
						{post.comments?.map((comment, index) => (
							<Comment
								key={index}
								post={post}
								createComment={props.createComment}
								comment={comment}
								commentOwners={commentOwners}
								onUpdateComments={updateComments}
								onUpdateTrackShowField={() =>
									updateShowField(index)
								}
								onResetTrackShowField={resetShowField}
								showField={trackShowField[index]}
								hasReplyCommentPermission={
									props.hasReplyCommentPermission
								}
							/>
						))}
						{props.hasReplyCommentPermission && (
							<NewComment
								post={post}
								createComment={props.createComment}
								onUpdateComments={updateComments}
							/>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default withRouter(PostDetail);
