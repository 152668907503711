import React from "react";
import load from "../../../assets/loading.svg";

const Loader = React.memo(({ loading, styled = true }) => {
	if (!loading) {
		return null;
	}

	const additionalStyles = styled
		? {
				position: "fixed",
				height: "100%",
				backgroundColor: "lightgray",
				opacity: "0.8",
				zIndex: "9",
		  }
		: {};

	return (
		<div className="profile-container-loading" style={additionalStyles}>
			<img src={load} alt="Loader" />
		</div>
	);
});

export default Loader;
