import React from "react";

const Messages = () => {
	const isMobile = window.matchMedia("(max-width: 500px)").matches;

	return (
		<div className={isMobile ? "outbx" : "container cntntbx"}>
			<div className={isMobile ? "" : "row"}>
				{isMobile ? (
					<div className="teams-container">
						<a
							href="#"
							className="backbtn"
							onClick={(e) => {
								e.preventDefault();
								window.history.back();
							}}
						/>
						<h6>Notifications</h6>
					</div>
				) : null}
				<div className={"container cntntbx"}>
					<div className={"row"}>
						<div className={"col-md-8 col-lg-9"}>
							<div className={"boxmenu"}>No messages found</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Messages;
