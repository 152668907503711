import React from 'react';

const PrintViewStrengthSessions = React.forwardRef((props, ref) => {
    return (
        <div ref={ref} style={{width:"95%",margin:"auto"}}>
            <div className="sectn-container-wrppr">
                <h4 style={{ fontFamily: 'Helvetica, Arial, sans-serif', padding: '20px 0',  paddingTop: 0, marginTop: '10px', textAlign: 'center', fontSize: '28px', borderBottom: '3px solid #ccc' }}>
                    {props.SessionTitle ? props.SessionTitle : null}
                </h4>
                {props.strengthSessionExercise && props.strengthSessionExercise.map((activities, index) => (
                    <div className="prnt-headng-contnr" key={activities.id}>
                        <h5 style={{ fontFamily: 'Helvetica, Arial, sans-serif', marginBottom: '0px', fontSize: '18px', fontWeight: 'normal', padding: '0' }}> <br/>
                            Exercise: {index + 1}
                        </h5>
                        <p style={{ fontFamily: 'Helvetica, Arial, sans-serif', marginBottom: '0px', fontSize: '16px', padding: '0' }}>
                            <b style={{ fontWeight: 'bold', marginBottom: '0' }}>{activities.data}</b>
                        </p>
                    </div>
                ))}
            </div>
            <h4 style={{ fontFamily: 'Helvetica, Arial, sans-serif', padding: '20px 0', textAlign: 'left', fontSize: '18px', borderBottom: '1px solid #ccc', fontWeight: 'bold' }}>Workout Dates</h4><br/>
                                                   
            {props.strengthSessionExercise && props.strengthSessionExercise.map((activities, index2) => (
                <div key={index2}>
                     <p style={{ fontFamily: 'Helvetica, Arial, sans-serif', marginBottom: '0px', fontSize: '16px', padding: '0' }}>
                            <b style={{ fontWeight: 'bold', marginBottom: '0' }}>{activities.data}</b>
                        </p>
                    <table className="table" style={{ border: '3px solid #ddd', borderBottom: 0 }}>
                        <thead>
                            <tr>
                                <th width="150" style={{ fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center', borderRight: '3px solid #ddd', borderBottom: '3px solid #ddd', padding: '4px', lineHeight: '1.42857143', color: '#333', fontSize: '16px', fontWeight: 'normal' }}>Dates</th>
                                <th width="80" style={{ fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center', borderRight: '3px solid #fff', borderBottom: '3px solid #ddd', padding: '4px', lineHeight: '1.42857143', color: '#333', fontSize: '16px', fontWeight: 'normal' }}>Sets(s)</th>
                                <th width="150" style={{ fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center', borderRight: '3px solid #ddd', borderBottom: '3px solid #ddd', padding: '4px', lineHeight: '1.42857143', color: '#333', fontSize: '16px', fontWeight: 'normal' }}>Unit A: {activities.unit_a_value}</th>
                                <th width="150" style={{ fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center', borderRight: '3px solid #ddd', borderBottom: '3px solid #ddd', padding: '4px', lineHeight: '1.42857143', color: '#333', fontSize: '16px', fontWeight: 'normal' }}>Unit B: {activities.unit_b_value}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.sessionDates && props.sessionDates.map((q, index3) => (
                               <>
                                <tr key={index3}>
                                    <td width="150" style={{ fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center', borderRight: '3px solid #ddd', borderBottom: '3px solid #ddd', padding: '4px', lineHeight: '1.42857143', color: '#333', fontSize: '16px', fontWeight: 'normal', background: '#f5f5f5' }}> {q.daterr}</td>
                                    <td width="80" style={{ fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center', borderRight: '3px solid #ddd', borderBottom: '3px solid #ddd', padding: '4px', lineHeight: '1.42857143', color: '#333', fontSize: '16px', fontWeight: 'normal', background: '#f5f5f5' }}>{q.exerciseData[activities.exerAssocId].sets} </td>
                                    <td width="80" style={{ fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center', borderRight: '3px solid #ddd', borderBottom: '3px solid #ddd', padding: '4px', lineHeight: '1.42857143', color: '#333', fontSize: '16px', fontWeight: 'normal', background: '#f5f5f5' }}>{q.exerciseData[activities.exerAssocId].unita}</td>
                                    <td width="150" style={{ fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center', borderRight: '3px solid #ddd', borderBottom: '3px solid #ddd', padding: '4px', lineHeight: '1.42857143', color: '#333', fontSize: '16px', fontWeight: 'normal', background: '#f5f5f5' }}>{q.exerciseData[activities.exerAssocId].unitb} </td>
 
                                </tr>
                                <tr key={index3}>
                                    <td width="150" style={{ fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center', borderRight: '3px solid #ddd', borderBottom: '3px solid #ddd', padding: '4px', lineHeight: '1.42857143', color: '#333', fontSize: '16px', fontWeight: 'normal', background: '#f5f5f5' }}> {q.daterr}</td>
                                    <td width="80" style={{ fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center', borderRight: '3px solid #ddd', borderBottom: '3px solid #ddd', padding: '4px', lineHeight: '1.42857143', color: '#333', fontSize: '16px', fontWeight: 'normal', background: '#f5f5f5' }}>{q.exerciseData[activities.exerAssocId].sets1} </td>
                                    <td width="80" style={{ fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center', borderRight: '3px solid #ddd', borderBottom: '3px solid #ddd', padding: '4px', lineHeight: '1.42857143', color: '#333', fontSize: '16px', fontWeight: 'normal', background: '#f5f5f5' }}>{q.exerciseData[activities.exerAssocId].unita1}</td>
                                    <td width="150" style={{ fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center', borderRight: '3px solid #ddd', borderBottom: '3px solid #ddd', padding: '4px', lineHeight: '1.42857143', color: '#333', fontSize: '16px', fontWeight: 'normal', background: '#f5f5f5' }}>{q.exerciseData[activities.exerAssocId].unitb1} </td>
                                </tr>
                                <tr key={index3}>
                                    <td width="150" style={{ fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center', borderRight: '3px solid #ddd', borderBottom: '3px solid #ddd', padding: '4px', lineHeight: '1.42857143', color: '#333', fontSize: '16px', fontWeight: 'normal', background: '#f5f5f5' }}> {q.daterr}</td>
                                    <td width="80" style={{ fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center', borderRight: '3px solid #ddd', borderBottom: '3px solid #ddd', padding: '4px', lineHeight: '1.42857143', color: '#333', fontSize: '16px', fontWeight: 'normal', background: '#f5f5f5' }}>{q.exerciseData[activities.exerAssocId].sets2} </td>
                                    <td width="80" style={{ fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center', borderRight: '3px solid #ddd', borderBottom: '3px solid #ddd', padding: '4px', lineHeight: '1.42857143', color: '#333', fontSize: '16px', fontWeight: 'normal', background: '#f5f5f5' }}>{q.exerciseData[activities.exerAssocId].unita2}</td>
                                    <td width="150" style={{ fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center', borderRight: '3px solid #ddd', borderBottom: '3px solid #ddd', padding: '4px', lineHeight: '1.42857143', color: '#333', fontSize: '16px', fontWeight: 'normal', background: '#f5f5f5' }}>{q.exerciseData[activities.exerAssocId].unitb2} </td>
                                </tr>
                                <tr key={index3}>
                                    <td width="150" style={{ fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center', borderRight: '3px solid #ddd', borderBottom: '3px solid #ddd', padding: '4px', lineHeight: '1.42857143', color: '#333', fontSize: '16px', fontWeight: 'normal', background: '#f5f5f5' }}> {q.daterr}</td>
                                    <td width="80" style={{ fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center', borderRight: '3px solid #ddd', borderBottom: '3px solid #ddd', padding: '4px', lineHeight: '1.42857143', color: '#333', fontSize: '16px', fontWeight: 'normal', background: '#f5f5f5' }}>{q.exerciseData[activities.exerAssocId].sets3} </td>
                                    <td width="80" style={{ fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center', borderRight: '3px solid #ddd', borderBottom: '3px solid #ddd', padding: '4px', lineHeight: '1.42857143', color: '#333', fontSize: '16px', fontWeight: 'normal', background: '#f5f5f5' }}>{q.exerciseData[activities.exerAssocId].unita3}</td>
                                    <td width="150" style={{ fontFamily: 'Helvetica, Arial, sans-serif', textAlign: 'center', borderRight: '3px solid #ddd', borderBottom: '3px solid #ddd', padding: '4px', lineHeight: '1.42857143', color: '#333', fontSize: '16px', fontWeight: 'normal', background: '#f5f5f5' }}>{q.exerciseData[activities.exerAssocId].unitb3} </td>
                                   
                                </tr>
                               </> 
                            ))}
                        </tbody>
                    </table>
                </div>
            ))}
        </div>
    );
});

export default PrintViewStrengthSessions;
