import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import LeftSidebar from './LeftSidebar';
import {Form, ButtonToolbar, OverlayTrigger, Button, Popover, } from 'react-bootstrap';
import { getAthletePlanner } from '../../utils/api.js';
import { getUserCoach, getGroupLevels } from "../../actions";
import Select from 'react-select';
import sportSBanner from "../../assets/sports-banner.png";


const exType = [
    { value: 'Walking', label: 'Walking' },
    { value: 'Jogging/Running', label: 'Jogging/Running' },
    { value: 'Cycling', label: 'Cycling' },
    { value: 'Swimming', label: 'Swimming' },
    { value: 'Gym/Strength Training', label: 'Gym/Strength Training' },
    { value: 'None', label: 'None' },
  ]
  const medCondition = [
    { value: 'None', label: 'None' },
    { value: 'Lower Back', label: 'Lower Back' },
    { value: 'Hip', label: 'Hip' },
    { value: 'Thigh', label: 'Thigh' },
    { value: 'Knee', label: 'Knee' },
    { value: 'Leg', label: 'Leg' },
    { value: 'Ankle/Foot', label: 'Ankle/Foot' },
    { value: 'Upper Back', label: 'Upper Back' },
    { value: 'Neck', label: 'Neck' },
    { value: 'Shoulder', label: 'Shoulder' },
    { value: 'Elbow', label: 'Elbow' },
    { value: 'Wrist/Hand', label: 'Wrist/Hand' },     
  ]
  const medInjury = [
    { value: 'None', label: 'None' },
    { value: 'Lower Back', label: 'Lower Back' },
    { value: 'Hip', label: 'Hip' },
    { value: 'Thigh', label: 'Thigh' },
    { value: 'Knee', label: 'Knee' },
    { value: 'Leg', label: 'Leg' },
    { value: 'Ankle/Foot', label: 'Ankle/Foot' },
    { value: 'Upper Back', label: 'Upper Back' },
    { value: 'Neck', label: 'Neck' },
    { value: 'Shoulder', label: 'Shoulder' },
    { value: 'Elbow', label: 'Elbow' },
    { value: 'Wrist/Hand', label: 'Wrist/Hand' },     
  ]

const AthleteProfile = (props) => {
    const [planner, setPlanner] = useState(null);
    const [groups, setGroups] = useState(null);
    const [groupLevels, setgroupLevels] = useState(null);
    const [club, setClub] = useState('');
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getAthletePlanner(props.clubSlug, props.user._id)
            .then(result => {
                if (result.club === null) {
                    setClub(null);
                } else if (result.planner === null) {
                    setPlanner(null);
                } else {
                    setClub(result.club);
                    setPlanner(result.planner);
                }
                setLoading(false);
            });
    }, []);
    return (
        <>
            <div className="container-large clearfix">
                <div id="wrapper" className=" row athlete-dashboard athlete-profile">
                    <LeftSidebar club={club} planner={planner} groupLevels={groupLevels} clubSlug={props.clubSlug} groups={groups} />
                    <div className='col nopad mt-5 edtprfl'>
                    <div class="head-heading mngrphd d-flex justify-content-between">
                    <h4>Health History</h4>
                    <p className="  text-danger ntfytxt"><b>Note:</b> All the information provided here will be visible to your coach only.</p>
                    </div>  
                    <Form>

                    <div className='row edtprflin'>

                    
                    <div className='topbnrhst'>
                    <img src={sportSBanner} alt=""/>
                    </div> 
                          
                    <div className='col-12 mb-3 justify-content-between'> 

                   

                    <div className='row optnbx'>
                    <div className='col-1'><h3>1</h3></div>
                    <div className='col-6'><h4>Do you give permission to Tri-Alliance coaches to use your personal information for coaching coordination purposes?</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Select Option<span>*</span>: </Form.Label>
                    <Select className="basic-single" classNamePrefix="select" placeholder="Select"/>
                    </Form.Group>  
                    </div> 
                    </div>
                    
                    <div className='row optnbx'>
                    <div className='col-1'><h3>2</h3></div>
                    <div className='col-6'><h4>Which of the following forms of exercise/physical activity do you regularly participate in?</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Select Option<span>*</span>: </Form.Label>
                    <Select isMulti  className="basic-multi-select mb-2"classNamePrefix="select" options={exType} /> 
                    <Form.Label>If any other, Please decribe here. </Form.Label>
                    <Form.Control type="text" placeholder="Type here..." />
                    </Form.Group>  
                    </div> 
                    </div>  
                    
                    <div className='row optnbx'>
                    <div className='col-1'><h3>3</h3></div>
                    <div className='col-6'><h4>How motivated are you to perform regular exercise/physical activity on a scale from <br/> 0 to 10?</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Select Option<span>*</span>: </Form.Label>
                    <Select className="basic-single mb-2" classNamePrefix="select" placeholder="Select"/> 
                    </Form.Group>  
                    </div> 
                    </div> 
                    
                    <div className='row optnbx'>
                    <div className='col-1'><h3>4</h3></div>
                    <div className='col-6'><h4>If you answered less than 5 on the previous question, what are your biggest barriers to participating in regular exercise?</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Select Option<span>*</span>: </Form.Label>
                    <Select className="basic-single mb-2" classNamePrefix="select" placeholder="Select"/>
                    <Form.Label>If any other, Please decribe here. </Form.Label>
                    <Form.Control type="text" placeholder="Type here..." />
                    </Form.Group>  
                    </div> 
                    </div>  
                    
                    <div className='row optnbx'>
                    <div className='col-1'><h3>5</h3></div>
                    <div className='col-6'><h4>Have you ever participated in a triathlon and/or received services provided by a triathlon club ?</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Select Option<span>*</span>: </Form.Label>
                    <Select className="basic-single mb-2" classNamePrefix="select" placeholder="Select"/> 
                    </Form.Group>  
                    </div> 
                    </div> 

                    
                    <div className='row optnbx'>
                    <div className='col-1'><h3>6</h3></div>
                    <div className='col-6'><h4>Adult Pre-Exercise Screening - This stage is self-administered and self-evaluated and must be truthful.</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Select Option<span>*</span>: </Form.Label> 
                    <Form.Control type="text" placeholder="Type here..." />
                    </Form.Group>  
                    </div> 
                    </div>  

                    
                    <div className='row optnbx'>
                    <div className='col-1'><h3>7</h3></div>
                    <div className='col-6'><h4>Has your doctor ever told you that you have a heart condition or have you ever suffered a stroke?</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Select Option<span>*</span>: </Form.Label>
                    <Select className="basic-single mb-2" classNamePrefix="select" placeholder="Select"/> 
                    </Form.Group>  
                    </div> 
                    </div> 

                    
                    <div className='row optnbx'>
                    <div className='col-1'><h3>8</h3></div>
                    <div className='col-6'><h4>Do you ever experience unexplained pains in your chest at rest or during physical activity/exercise?</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Select Option<span>*</span>: </Form.Label>
                    <Select className="basic-single mb-2" classNamePrefix="select" placeholder="Select"/> 
                    </Form.Group>  
                    </div> 
                    </div>

                    
                    <div className='row optnbx'>
                    <div className='col-1'><h3>9</h3></div>
                    <div className='col-6'><h4>Do you ever feel faint or have spells of dizziness during physical activity/exercise that causes you to lose balance?</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Select Option<span>*</span>: </Form.Label>
                    <Select className="basic-single mb-2" classNamePrefix="select" placeholder="Select"/> 
                    </Form.Group>  
                    </div> 
                    </div>  

                    
                    <div className='row optnbx'>
                    <div className='col-1'><h3>10</h3></div>
                    <div className='col-6'><h4>Have you had an asthma attack requiring immediate medical attention at any time over the last 12 months?</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Select Option<span>*</span>: </Form.Label>
                    <Select className="basic-single mb-2" classNamePrefix="select" placeholder="Select"/> 
                    </Form.Group>  
                    </div> 
                    </div> 

                    
                    <div className='row optnbx'>
                    <div className='col-1'><h3>11</h3></div>
                    <div className='col-6'><h4>If you have diabetes (type I or type II) have you had trouble controlling your blood glucose in the last 3 months?</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Select Option<span>*</span>: </Form.Label>
                    <Select className="basic-single mb-2" classNamePrefix="select" placeholder="Select"/> 
                    </Form.Group>  
                    </div> 
                    </div>

                    
                    <div className='row optnbx'>
                    <div className='col-1'><h3>12</h3></div>
                    <div className='col-6'><h4>Do you have any diagnosed muscle, bone or joint problems that you have been told could be made worse by participating in physical activity/exercis?</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Select Option<span>*</span>: </Form.Label>
                    <Select className="basic-single mb-2" classNamePrefix="select" placeholder="Select"/> 
                    </Form.Group>  
                    </div> 
                    </div>   


                    
                    <div className='row optnbx'>
                    <div className='col-1'><h3>13</h3></div>
                    <div className='col-6'><h4>Do you have any other medical condition(s) that may make it dangerous for you to participate in physical activity/exercise?</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Select Option<span>*</span>: </Form.Label>
                    <Select className="basic-single mb-2" classNamePrefix="select" placeholder="Select"/>
                    <Form.Label>If any other, Please decribe here. </Form.Label>
                    <Form.Control type="text" placeholder="Type here..." />
                    </Form.Group>  
                    </div> 
                    </div>

                    <div className='row optnbx'>
                    <div className='col-1'><h3>14</h3></div>
                    <div className='col-6'><h4>Are you currently taking any prescribed medications?</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Select Option<span>*</span>: </Form.Label>
                    <Select className="basic-single mb-2" classNamePrefix="select" placeholder="Select"/>
                    <Form.Label>If selected "yes" above please describe below: </Form.Label>
                    <Form.Control type="text" placeholder="Type here..." />
                    </Form.Group>  
                    </div> 
                    </div>     

                    
                    <div className='row optnbx'>
                    <div className='col-1'><h3>15</h3></div>
                    <div className='col-6'><h4>Do you have any family history of heart disease? (e.g. heart attack, stroke)</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Select Option<span>*</span>: </Form.Label>
                    <Select className="basic-single mb-2" classNamePrefix="select" placeholder="Select"/> 
                    </Form.Group>  
                    </div> 
                    </div> 

                     
                    <div className='row optnbx'>
                    <div className='col-1'><h3>16</h3></div>
                    <div className='col-6'><h4>Are you currently pregnant? If so, please indicate your stage of pregnancy.</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Select Option<span>*</span>: </Form.Label>
                    <Select className="basic-single mb-2" classNamePrefix="select" placeholder="Select"/>
                    
                    </Form.Group>  
                    </div> 
                    </div>

                  
                    <div className='row optnbx'>
                    <div className='col-1'><h3>17</h3></div>
                    <div className='col-6'><h4>Bone and Joint Health - Please add any additional conditions not mentioned below?</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Add condition if any.</Form.Label>
                    <Form.Control type="text" placeholder="Type here..." />
                    
                    </Form.Group>  
                    </div> 
                    </div> 

                   
                    <div className='row optnbx'>
                    <div className='col-1'><h3>18</h3></div>
                    <div className='col-6'><h4>Do you currently suffer from pain/discomfort to any of the following areas?</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Select Option<span>*</span>: </Form.Label>
                    <Select isMulti  className="basic-multi-select mb-2"classNamePrefix="select" options={medCondition} /> 
                    <Form.Label>If any other, Please decribe here. </Form.Label>
                    <Form.Control type="text" placeholder="Type here..." />
                    </Form.Group> 
                    </div> 
                    </div>

                   
                    <div className='row optnbx'>
                    <div className='col-1'><h3>19</h3></div>
                    <div className='col-6'><h4>Have you ever suffered any major injury or injuries to the following areas?</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Select Option<span>*</span>: </Form.Label>
                    <Select isMulti  className="basic-multi-select mb-2"classNamePrefix="select" options={medInjury} /> 
                    <Form.Label>If any other, Please decribe here. </Form.Label>
                    <Form.Control type="text" placeholder="Type here..." />
                    </Form.Group> 
                    </div> 
                    </div>

                   
                    <div className='row optnbx'>
                    <div className='col-1'><h3>20</h3></div>
                    <div className='col-6'><h4>Have you had any major surgery in the past <br/>12 months?</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Select Option<span>*</span>: </Form.Label>
                    <Select className="basic-single mb-2" classNamePrefix="select" placeholder="Select"/>
                    <Form.Label>If selected "yes" above please describe  below: </Form.Label>
                    <Form.Control type="text" placeholder="Type here..." />
                    </Form.Group>  
                    </div> 
                    </div> 


                    
                    <div className='row optnbx'>
                    <div className='col-1'><h3>21</h3></div>
                    <div className='col-6'><h4>Vaccination Status - are you fully vaccinated against COVID-19?</h4></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" ">
                    <Form.Label>Select Option<span>*</span>: </Form.Label>
                    <Select className="basic-single mb-2" classNamePrefix="select" placeholder="Select"/>
                   
                    </Form.Group>  
                    </div> 
                    </div> 

                    
                    <div className='row optnbx'>
                    <div className='col-1'><h3>22</h3></div>
                    <div className='col-6 d-block'><h4 className='mt-2'>Any other relevant information?</h4> 
                    <p className=' small  text-danger'>Please feel free to suggest any other informationthat you think may support our coordination of your coaching services. We look forward to seeing you at training!</p></div>
                    <div className='col-5'>
                    <Form.Group className="mb-3" controlId=" "> 
                    <Form.Label>Describe Here </Form.Label>
                    <Form.Control type="text" placeholder="Type here..." />
                    </Form.Group>  
                   
                    </div> 
                    </div>   
                    
                    </div>
                        <div class="row edtprflin mb-4"><div class="col-12 mb-3"><button class="btn btn-primary">Save Health Information</button></div></div>
                    </div>    
                    </Form>
                    </div> 
                </div>
            </div>
        </>

    );

};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        userCoach: state.planner.userCoach,
        groupLevels: state.planner.groupLevels,
    };
};

export default connect(mapStateToProps, { getUserCoach, getGroupLevels })(AthleteProfile);
