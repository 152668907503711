import React, { useState, useRef } from "react";
import AvatarEditor from "react-avatar-editor";
import "./Cropper.scss";

const CropperModal = ({
	src,
	modalOpen,
	setModalOpen,
	setPreview,
	updateLogo,
	uploadType,
	uploadAPI,
}) => {
	const [slideValue, setSlideValue] = useState(10);
	const cropRef = useRef(null);

	const handleSave = async (e) => {
		e.preventDefault();
		if (cropRef) {
			const dataUrl = cropRef.current.getImage().toDataURL();
			const result = await fetch(dataUrl);
			const blob = await result.blob();
			setPreview(URL.createObjectURL(blob));
			setModalOpen(false);
			handleImageUpload(blob);
		}
	};

	const handleImageUpload = async (logo) => {
		let fileTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

		if (logo === undefined) {
			alert("Please select an image file to upload");
			return;
		} else if (fileTypes.indexOf(logo.type) === -1) {
			alert("Please select a file type of JPEG, JPG, PNG, or GIF");
			return;
		}
		const image = new Image();
		image.src = URL.createObjectURL(logo);
		image.onload = async () => {
			const formData = new FormData();
			formData.append(uploadType, logo);
			try {
				const response = await uploadAPI(formData);
				const imagePath = `${response.filename}.${
					response.mimetype.split("/")[1]
				}`;
				updateLogo(response.filename);
			} catch (error) {
				console.error("Error uploading image:", error);
			}
		};
	};
	const handleCancel = (e) => {
		e.preventDefault();
		setModalOpen(false);
	};

	return (
		<div
			className="modal"
			style={{ display: modalOpen ? "block" : "none" }}
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-body">
						<div className="d-flex justify-content-center align-items-center mb-3">
							<AvatarEditor
								ref={cropRef}
								image={src}
								style={{ width: "100%", height: "100%" }}
								border={50}
								borderRadius={150}
								color={[0, 0, 0, 0.72]}
								scale={slideValue / 10}
								rotate={0}
							/>
						</div>
						{/* Bootstrap Slider */}
						<input
							type="range"
							min="10"
							max="50"
							className="form-range mb-3"
							value={slideValue}
							onChange={(e) => setSlideValue(e.target.value)}
						/>
						<div className="d-flex justify-content-around">
							<button
								className="btn btn-outline-light me-2 cancel"
								onClick={handleCancel}
							>
								Cancel
							</button>
							<button
								className="btn btn-primary save "
								onClick={handleSave}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CropperModal;
