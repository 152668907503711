import React, { useState } from "react";
import { socialForgetPassword } from "../utils/api";
import SendEmail from "../components/resetPassword/sendEmail";
import OTPChecker from "../components/resetPassword/otpChecker";

const ForgetPassword = () => {
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [showOTP, setShowOTP] = useState(false);
	const [otp, setOTP] = useState("");
	const [submitting, setSubmitting] = useState(false);

	const generateOPT = () => {
		const otpCode = Math.floor(Math.random() * 9000 + 1000);
		setOTP(otpCode); // Save the generated OTP in the state
		return otpCode;
	};

	const handleSubmit = async (e, email) => {
		e.preventDefault();
		setSubmitting(true); // Set the submitting state to true
		setEmail(email);
		try {
			const otp = generateOPT();
			const response = await socialForgetPassword(email, otp);
			if (response && response.message) {
				setMessage(response.message);
				setShowOTP(true);
			} else {
				setMessage("Unexpected response from the server.");
			}
		} catch (error) {
			console.log("error", error);
			setMessage(
				"Failed to send password reset email. Please try again later."
			);
		} finally {
			setSubmitting(false); // Reset the submitting state to false
		}
	};

	const handleResendOTP = async () => {
		try {
			const newOTP = generateOPT();
			await socialForgetPassword(email, newOTP);
		} catch (error) {
			console.log("error", error);
		}
	};
	return (
		<>
			{!showOTP ? (
				<SendEmail
					handleSubmit={handleSubmit}
					message={message}
					submitting={submitting}
				/>
			) : (
				<OTPChecker
					otp={otp}
					email={email}
					handleResendOTP={handleResendOTP}
				/>
			)}
		</>
	);
};

export default ForgetPassword;
