import React, { useState, useRef, useEffect } from "react";
import autosize from "autosize";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const NewComment = (props) => {
	const [comment, setComment] = useState({
		commentId: "",
		userId: props.userId,
		description: "",
		time: new Date().toISOString(),
		replies: [],
		likes: [],
		dislikes: [],
		loading: false,
	});

	const descriptionInputRef = useRef(null);

	useEffect(() => {
		descriptionInputRef.current.focus();
		autosize(descriptionInputRef.current);
	}, []);

	const createComment = () => {
		if (comment.description === "" || comment.loading) return; // Return if already loading
		setComment({ ...comment, description: "", loading: true }); // Set loading state

		const newComment = {
			...comment,
			commentId: uuidv4(), // Generate a unique comment ID
		};
		const updatedComments = [...props.post.comments, newComment];

		const updatedPost = {
			...props.post,
			comments: updatedComments,
		};
		props
			.createComment(
				updatedPost.postId,
				updatedPost.comments,
				props.user._id,
				props.user.userId
			)
			.then(() => {
				setComment({
					commentId: "",
					userId: props.userId,
					description: "",
					time: new Date().toISOString(),
					replies: [],
					likes: [],
					dislikes: [],
					loading: false,
				});

				props.onUpdateComments(updatedPost);
			});
	};

	const onEnterPress = (e) => {
		if (e.keyCode === 13 && !e.shiftKey) {
			e.preventDefault();
			createComment();
		}
	};

	const handleChange = (e) => {
		e.preventDefault();
		setComment({
			...comment,
			description: e.target.value,
			time: new Date().toISOString(),
		});
	};

	const isMobile = window.matchMedia("(max-width: 500px)").matches;

	return (
		<div
			className={`${
				isMobile
					? "wrtcmnt btmfld"
					: "col-12 nopad new-comment-container"
			}`}
		>
			<input
				placeholder="Write a comment...."
				type="text"
				id="writeCommentField"
				className="form-control"
				onChange={handleChange}
				value={comment.description}
				ref={descriptionInputRef}
				onKeyDown={onEnterPress}
			/>
			<a href="#" onClick={createComment} className="sndbtn"></a>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		userId: state.auth.user.userId,
	};
};

export default connect(mapStateToProps, {})(NewComment);
