import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
	hidePlanerModal,
	loadPlanners,
	selectedPlaner,
	showAddPlanner,
} from "../../../actions";
import * as API from "../../../utils/api.js";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { Button, Modal, Dropdown } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";

const EditPlanner = (props) => {
	const [planners, setPlanners] = useState([]);
	const [grouplevels, setGrouplevels] = useState([]);
	const [selectedValue, setSelectedValue] = useState("");
	const [selectedPlannerId, setselectedPlannerID] = useState("");
	const [selectedGroupLevel, setGroupLevel] = useState("");
	const history = useHistory();
	useEffect(() => {
		API.loadPlanners(props.club._id).then((response) => {
			setPlanners(response.planners);
			setSelectedValue(response.planners[0].slug);
			setselectedPlannerID(response.planners[0]._id);
			setGrouplevels(response.membershipLevels);
		});
	}, []);

	const handleClose = () => {
		props.hidePlanerModal();
	};

	const handleClick = () => {
		if (selectedValue == "" || selectedValue === undefined) {
			alert("Please select planner");
			return;
		}
		props.selectedPlaner(selectedValue);
		let url = `/team/${props.club.slug}/planner/${selectedValue}`;
		history.push(url);
	};
	const handlePlanner = (event) => {
		let selectedPlannerValue = event.target.value;
		const [id, slug] = selectedPlannerValue.split(":");
		setSelectedValue(slug);
		setselectedPlannerID(id);
	};
	const handleGroupLevel = (event) => {
		setGroupLevel(event.target.value);
	};
	const handleEditPlanner = () => {
		console.log(selectedPlannerId);
		props.showAddPlanner(selectedPlannerId);
	};
	return (
		<Modal
			centered
			size="lg"
			show={true}
			onHide={handleClose}
			dialogClassName="planner-dialog"
			className="customize-edit-planner"
		>
			<Form onSubmit={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Load Planner</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Row>
						<div className="styled-select top-one">
							<select
								name="load-planner"
								id="load-planner-sidebar"
								style={{ color: "#333333" }}
								onChange={handlePlanner}
							>
								<option value="">please select</option>
								{planners &&
									planners.length > 0 &&
									planners.map((planner) => (
										<option
											key={
												"load-planner-sidebar" +
												planner._id
											}
											value={`${planner._id}:${planner.slug}`}
										>
											{planner.title}
										</option>
									))}
							</select>
						</div>
					</Form.Row>
					<Form.Row>
						<div className="styled-select top-one">
							<select
								name="load-planner"
								id="load-planner-sidebar"
								style={{ color: "#333333" }}
								onChange={handleGroupLevel}
							>
								{grouplevels &&
									grouplevels.length > 0 &&
									grouplevels.map((grouplevel) => (
										<option
											key={
												"load-planner-sidebar" +
												grouplevel._id
											}
											value={grouplevel._id}
										>
											{grouplevel.levelName}
										</option>
									))}
							</select>
						</div>
					</Form.Row>
				</Modal.Body>
				<Modal.Footer className="buttons-save">
					<Button
						type="button"
						onClick={handleClick}
						variant="coaching-mate save-btn"
					>
						Save
					</Button>
					<Button
						type="button"
						onClick={handleEditPlanner}
						variant="coaching-mate save-btn"
					>
						Edit Planner
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state) => {
	return {
		EditPlanner: state.planner.modals.EditPlanner,
		selectedPlanerId: state.planner.selectedValue,
	};
};
export default connect(mapStateToProps, {
	hidePlanerModal,
	selectedPlaner,
	showAddPlanner,
})(EditPlanner);
