import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
	getSubScribeMemberships,
	getServerUrl,
	updateMembershipStatus,
	userEventAdd,
} from "../utils/api";
import Avatar from "../components/common/avatar";
import Loader from "../components/common/loader";

const ConfirmationDialog = ({ message, onConfirm }) => {
	useEffect(() => {
		alert(message);
		onConfirm();
	}, [message]);

	return null;
};

const UserMembershipItem = ({ pendingUser, onAccept, onReject }) => {
	return (
		<div key={`my-memberships-${pendingUser._id}`} className=" mmbr">
			{/* Render Membership Details */}
			<div className="userthumb">
				<div className="userbx">
					<a>
						<Avatar
							url={"/uploads/user/"}
							state={pendingUser.profilePicture}
							alt={"post img"}
						/>
					</a>
				</div>
				<div>
					<a>{pendingUser.firstName + " " + pendingUser.lastName}</a>
				</div>
				<div className="col">
					<span className="pushright">
						<a
							className="green"
							onClick={() => onAccept(pendingUser._id)}
						>
							Accept
						</a>
						&nbsp; | &nbsp;
						<a onClick={() => onReject(pendingUser._id)}>Reject</a>
					</span>
				</div>
			</div>
		</div>
	);
};

const SubscribedUserItem = ({ user }) => {
	return (
		<div
			key={`my-memberships-${user._id}`}
			className="club-box"
			style={{ marginTop: "0px", marginBottom: "0px" }}
		>
			<Link to={"/profile/" + user.userId}>
				<div className="row">
					<div className="col-2">
						<Avatar
							url={"/uploads/user/"}
							state={user.profilePicture}
							alt={"user profilePicture"}
						/>
					</div>
					<div className="col-7">
						<h5 style={{ display: "block", width: "100%" }}>
							<>{user.firstName + " " + user.lastName}</>
						</h5>
					</div>
				</div>
			</Link>
		</div>
	);
};

const UserSubScribeMemberShips = ({ user, memberShipSlug }) => {
	const [team, setTeam] = useState({ title: "", description: "" });
	const [loading, setLoading] = useState(true);
	const [memberships, setMemberships] = useState([]);
	const [userData, setUserData] = useState([]);
	const [pendingUserData, setPendingUserData] = useState([]);
	const [confirmation, setConfirmation] = useState(null);
	const history = useHistory();
	const { teamSlug } = useParams();

	useEffect(() => {
		getMembershipsData();
	}, [memberShipSlug, user._id]);

	const getMembershipsData = async () => {
		setLoading(true);
		let userId = user._id;
		await getSubScribeMemberships(memberShipSlug, userId).then((resp) => {
			if (resp.success === true) {
				setTeam(resp.team);
				setMemberships(resp.membershipsarr);
				setUserData(resp.userArray);
				setPendingUserData(resp.pendingMembers);
			} else {
				alert("Error fetching data, please try again");
			}
			setLoading(false);
		});
	};

	const handleAcceptMembership = (userId) => {
		setConfirmation({
			message: "Are you sure you want to accept this membership?",
			onConfirm: () => updateUserMembership("accept", userId),
		});
	};

	const handleRejectMembership = (userId) => {
		setConfirmation({
			message: "Are you sure you want to reject this membership?",
			onConfirm: () => updateUserMembership("reject", userId),
		});
	};

	const updateUserMembership = (action, userId) => {
		updateMembershipStatus(memberships, action, userId).then((resp) => {
			if (resp.success === true) {
				alert("Membership approved successfully.");
				getMembershipsData();
			} else {
				alert("Error fetching data, please try again");
			}
		});
		// Close the confirmation dialog after user action
		setConfirmation(null);
	};

	if (loading) {
		return <Loader loading={loading} />;
	}

	return (
		<div className="outbx">
			{confirmation && (
				<ConfirmationDialog
					message={confirmation.message}
					onConfirm={confirmation.onConfirm}
				/>
			)}
			<div className="teams-container">
				<a
					href=""
					className="backbtn"
					onClick={(e) => {
						e.preventDefault();
						history.push("/team/" + teamSlug + "/admin");
					}}
				></a>
				<h6>Memberships</h6>
			</div>
			{pendingUserData.length > 0 && (
				<div className="boxmenu">
					<span>Pending Memberships</span>
					{pendingUserData.map((pendingUser) => (
						<UserMembershipItem
							key={pendingUser._id}
							pendingUser={pendingUser}
							onAccept={() =>
								handleAcceptMembership(pendingUser._id)
							}
							onReject={() =>
								handleRejectMembership(pendingUser._id)
							}
						/>
					))}
				</div>
			)}
			{pendingUserData.length === 0 && (
				<div className="boxmenu mmbr">No Pending Membership Found</div>
			)}
			{userData.length > 0 && (
				<div className="boxmenu">
					<span>Subscribed Users</span>
					{userData.map((user) => (
						<SubscribedUserItem key={user._id} user={user} />
					))}
				</div>
			)}
			{userData.length === 0 && (
				<div className="boxmenu mmbr">No Subscribed Users</div>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	};
};

export default connect(mapStateToProps, {})(UserSubScribeMemberShips);
