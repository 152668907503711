import React from "react";
import Avatar from "../avatar";
import { MdClose, MdAttachFile } from "react-icons/md";

const supportedImageExtensions = ["jpeg", "jpg", "png", "gif"];
const supportedDocumentExtensions = ["pdf", "doc", "docx"];

const getExtension = (filename) => {
	const parts = filename.split(".");
	return parts[parts.length - 1].toLowerCase();
};

const DocumentPreview = ({ file, removeDocument }) => {
	if (!file) {
		return <p>No file provided.</p>;
	}

	const fileExtension = getExtension(file);
	const isImageTypeSupported =
		supportedImageExtensions.includes(fileExtension);
	const isDocumentTypeSupported =
		supportedDocumentExtensions.includes(fileExtension);

	return (
		<>
			{isImageTypeSupported ? (
				<div className="item-preview">
					<span>
						<Avatar
							url={"/uploads/temp/"}
							state={file}
							alt={"Team Cover Photo"}
							style={{ width: "100px", height: "100px" }}
						/>
						<MdClose
							className="svg"
							onClick={() => removeDocument(file)}
						/>
					</span>
				</div>
			) : isDocumentTypeSupported ? (
				<div className="item-preview">
					<span>
						<MdAttachFile
							style={{ width: "100px", height: "100px" }}
						/>
						<MdClose
							className="svg"
							onClick={() => removeDocument(file)}
						/>
					</span>
				</div>
			) : (
				<p>Unsupported file type: {file}</p>
			)}
		</>
	);
};

export default DocumentPreview;
