import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Feed from "../components/Feed";
import LeftSideBar from "../components/LeftSideBar";

// Importing helper functions
import { getGroupBySlug, getPostByGroup, mobileQuery } from "../utils/api";
import Loader from "../components/common/loader";
import Avatar from "../components/common/avatar";

const Group = (props) => {
	const [group, setGroup] = useState({
		title: "",
		slug: "",
		description: "",
		postReqApproval: true,
		postRestriction: "",
	});
	const [posts, setPosts] = useState([]);
	const [isAdmin, setIsAdmin] = useState(false);
	const [isAdminOrJoined, setIsAdminOrJoined] = useState(false);
	const [loading, setLoading] = useState(true);
	const [redirect] = useState(false);
	const [showEllipsisOpt] = useState(false);

	useEffect(() => {
		getGroupData();
	}, [props.groupSlug, props.user._id]);

	const getGroupData = async () => {
		try {
			const resp = await getGroupBySlug(props.groupSlug, props.user._id);

			if (resp.success === true) {
				setGroup(resp.group);
				setIsAdmin(resp.isAdmin);
				setIsAdminOrJoined(resp.isAdminOrJoined);
				setLoading(false);

				const res = await getPostByGroup(resp.group._id);
				setPosts(res.posts);
			} else {
				alert("Error fetching data, please try again");
			}
		} catch (error) {
			alert("Error fetching data, please try again");
		}
	};

	const deletePost = (postId) => {
		props.deletePost(postId);
		getGroupData();
	};

	const changeKudos = async (postId, kudos) => {
		try {
			const post = await props.changeKudos(postId, kudos);
			if (post.postId) {
				setPosts((previousState) => {
					let sortedPosts = previousState.filter(
						(p) => p.postId !== postId
					);
					sortedPosts.push(post);
					sortedPosts.sort(
						(a, b) => Date.parse(b.time) - Date.parse(a.time)
					);
					return sortedPosts;
				});
				return true;
			} else {
				return false;
			}
		} catch (error) {
			return false;
		}
	};

	const createComment = async (postId, postComment) => {
		try {
			const post = await props.createComment(
				postId,
				postComment,
				props.user._id,
				props.user.userId
			);
			if (post.postId) {
				setPosts((previousState) => {
					let sortedPosts = previousState.filter(
						(p) => p.postId !== postId
					);
					sortedPosts.push(post);
					sortedPosts.sort(
						(a, b) => Date.parse(b.time) - Date.parse(a.time)
					);
					return sortedPosts;
				});
				return true;
			} else {
				return false;
			}
		} catch (error) {
			return false;
		}
	};

	const postRestriction = () => {
		return ["allowAll", "allowMembers", "onlyAdmin"].includes(
			group.postRestriction
		);
	};

	const isMobile = window.matchMedia(mobileQuery).matches;
	if (redirect) {
		return <Redirect push to="/home" />;
	}

	if (group.status === "remove") {
		return (
			<div className="outbx">
				<div className="boxmenu">
					Group is removed, <Link to={"/groups/"}> go to groups</Link>
				</div>
			</div>
		);
	}

	if (!props.userSignedIn) {
		return (
			<div className="home-container-no-user">
				<h2>You must login to see your feed.</h2>
				<div>
					<Link to="/signInUser">Log In</Link>
					<Link to="/signupUser">SignUp</Link>
				</div>
			</div>
		);
	}

	if (loading) {
		return <Loader loading={loading} styled={false} />;
	}
	return (
		<div className={isMobile ? "outbx" : "container cntntbx"}>
			<div className={isMobile ? "" : "row"}>
				{isMobile && (
					<div className="teams-container">
						<a
							href="#"
							className="backbtn"
							onClick={(e) => {
								e.preventDefault();
								window.history.back();
							}}
						>
							{" "}
						</a>
						<h6>{group.title}</h6>
					</div>
				)}
				{!isMobile && (
					<div className="col-3">
						<LeftSideBar
							group={group}
							isAdmin={isAdmin}
							isAdminOrJoined={isAdminOrJoined}
						/>
					</div>
				)}
				<div className={isMobile ? "" : "col-9"}>
					<div className="topbanner bxshadow">
						<div className="post-img-parent">
							<Avatar
								url={"/uploads/group/"}
								state={group.coverPhoto}
								alt={"group cover photo"}
								className={"post-img"}
							/>
						</div>
						<div className="row bnrin1">
							<div className="col-md-12 col-lg-5">
								<h3>{group.title}</h3>
							</div>
							<div className="col-md-12 col-lg-7 bnrin2 ">
								<div className="row">
									<div className="col-6 mbrcnt">
										<span>{`${group.membersCount} member${
											group.membersCount > 1 ? "s" : ""
										}`}</span>
										{isAdmin && (
											<span>
												<Link
													to={`/group/${group.slug}/admin`}
													className="grntxt btn"
												>
													Admin
												</Link>
											</span>
										)}

										{showEllipsisOpt && (
											<div className="ovrmnu">
												<ul>
													<li>
														<a href="#">
															Share Group
														</a>
													</li>
													{isAdmin && (
														<li>
															<a
																href="#"
																onClick={() =>
																	props.history.push(
																		`/createGroupEvent/${group.slug}`
																	)
																}
															>
																Create Event
															</a>
														</li>
													)}
												</ul>{" "}
											</div>
										)}
									</div>
									<div className="col-6 text-right">
										<Link
											to={{
												pathname: `/group/${group.slug}/events/`,
												state: {
													groupStatus: group.status,
													isAdminOrJoined:
														isAdminOrJoined,
												},
											}}
											className="btn"
										>
											View Events
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
					{group.status !== "suspend" &&
						props.user._id !== undefined &&
						group.banned !== undefined &&
						!group.banned.includes(props.user._id) &&
						(group.postRestriction === "allowAll" ||
							(group.postRestriction === "allowMembers" &&
								isAdminOrJoined) ||
							(group.postRestriction === "onlyAdmin" &&
								isAdmin)) && (
							<div className="wrtpost">
								<div className="userthumb">
									<a href="#" className="userbx">
										<Avatar
											url={"/uploads/user/"}
											state={props.user.profilePicture}
											alt={"user profile picture"}
										/>
									</a>

									<Link
										to={`/group/${group.slug}/post/create`}
									>
										Post Something...
									</Link>
								</div>
							</div>
						)}

					{posts && (
						<Feed
							posts={posts}
							editPost={props.editPost}
							deletePost={deletePost}
							showPopup={props.showPopup}
							notGeneral={true}
							changeKudos={changeKudos}
							openPictureViewer={props.openPictureViewer}
							createComment={createComment}
							isQuestionPage={props.isQuestionPage}
							hasActionPermission={
								group.banned !== undefined &&
								!group.banned.includes(props.user._id) &&
								postRestriction()
							}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		userSignedIn: state.auth.userSignedIn,
		user: state.auth.user,
	};
};

export default withRouter(connect(mapStateToProps, {})(Group));
