import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import LeftSideBar from "../components/LeftSideBar";
import Post from "../components/Post";

import {
	getInitialSavedPosts,
	deleteTracker,
	getPostListByObjId,
	getServerUrl,
} from "../utils/api";

class SavedContent extends Component {
	state = {
		tabs: {
			users: [],
			teams: [],
			groups: [],
		},
		currTab: "users",
	};

	async componentDidMount() {
		if (this.props.userSignedIn) {
			try {
				const usersData = await getInitialSavedPosts(
					this.props.user.userId,
					"users"
				);
				const teamsData = await getInitialSavedPosts(
					this.props.user.userId,
					"teams"
				);
				const groupsData = await getInitialSavedPosts(
					this.props.user.userId,
					"groups"
				);

				this.setState({
					tabs: {
						users: usersData.savedPosts,
						teams: teamsData.savedPosts,
						groups: groupsData.savedPosts,
					},
				});
			} catch (error) {
				console.error(error);
			}
		}
	}
	handleTabChange = (tab) => {
		this.setState({
			currTab: tab,
		});
	};
	componentWillUnmount() {
		// remove tracker before navigate to other page
		if (this.props.userSignedIn) {
			deleteTracker(this.state.currTrackerId);
		}
	}

	refresh = async () => {
		// delete tracker before refreshing the page
		await deleteTracker(this.state.currTrackerId);

		window.location.reload();
	};

	render() {
		const { currTab, tabs } = this.state;

		return (
			<>
				{!window.matchMedia("(max-width: 500px)").matches ? (
					<div className="container cntntbx">
						<div className="row">
							<div className="col-3">
								<LeftSideBar />
							</div>

							<div className="col-9">
								<InfiniteScroll
									style={{ overflow: "hidden" }}
									dataLength={tabs[currTab].length}
									next={this.getMorePosts}
									hasMore={this.state.hasMore}
									loader={
										<div className="text-center">
											<div
												className="spinner-border"
												role="status"
											>
												<span className="sr-only">
													Loading...
												</span>
											</div>
										</div>
									}
									endMessage={
										<p className="text-center mt-4">
											<b>Yay! You have seen it all</b>
										</p>
									}
								>
									{tabs[currTab].map((post, index) => (
										<Post
											key={`saved-content-${post._id}-${post.postId}`}
											post={post}
											owner={
												this.props.owners !==
													undefined &&
												this.props.owners[index] !==
													undefined &&
												this.props.owners[index]
													.userId === post.userId
													? this.props.owners[index]
													: "Unknown"
											}
											editPost={this.props.editPost}
											deletePost={this.props.deletePost}
											showPopup={this.props.showPopup}
											notGeneral={true}
											createComment={
												this.props.createComment
											}
											changeKudos={this.props.changeKudos}
											openPictureViewer={
												this.props.openPictureViewer
											}
											hasActionPermission={true}
										/>
									))}
								</InfiniteScroll>
							</div>
						</div>
					</div>
				) : (
					<div>
						<div className="subhdr">
							<div className="container nopad">
								<div className="row">
									<div className="col">
										<div className="typsldr">
											<button
												className={
													currTab === "users"
														? "sel"
														: ""
												}
												onClick={() =>
													this.handleTabChange(
														"users"
													)
												}
											>
												Users
											</button>
											<button
												className={
													currTab === "teams"
														? "sel"
														: ""
												}
												onClick={() =>
													this.handleTabChange(
														"teams"
													)
												}
											>
												Teams
											</button>
											<button
												className={
													currTab === "groups"
														? "sel"
														: ""
												}
												onClick={() =>
													this.handleTabChange(
														"groups"
													)
												}
											>
												Groups
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>

						<InfiniteScroll
							style={{ overflow: "hidden" }}
							dataLength={tabs[currTab].length}
							next={this.getMorePosts}
							hasMore={this.state.hasMore}
							loader={
								<div className="text-center">
									<div
										className="spinner-border"
										role="status"
									>
										<span className="sr-only">
											Loading...
										</span>
									</div>
								</div>
							}
							endMessage={
								<p className="text-center mt-4">
									<b>Yay! You have seen it all</b>
								</p>
							}
							refreshFunction={this.refresh}
							pullDownToRefresh
							pullDownToRefreshThreshold={50}
							pullDownToRefreshContent={
								<h3 style={{ textAlign: "center" }}>
									&#8595; Pull down to refresh
								</h3>
							}
							releaseToRefreshContent={
								<h3 style={{ textAlign: "center" }}>
									&#8593; Release to refresh
								</h3>
							}
						>
							{tabs[currTab].map((post, index) => (
								<Post
									key={`saved-content-${post._id}-${post.postId}`}
									post={post}
									owner={
										this.props.owners !== undefined &&
										this.props.owners[index] !==
											undefined &&
										this.props.owners[index].userId ===
											post.userId
											? this.props.owners[index]
											: "Unknown"
									}
									editPost={this.props.editPost}
									deletePost={this.props.deletePost}
									showPopup={this.props.showPopup}
									notGeneral={true}
									createComment={this.props.createComment}
									changeKudos={this.props.changeKudos}
									openPictureViewer={
										this.props.openPictureViewer
									}
									hasActionPermission={true}
								/>
							))}
						</InfiniteScroll>
					</div>
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userSignedIn: state.auth.userSignedIn,
		user: state.auth.user,
	};
};

export default withRouter(connect(mapStateToProps, {})(SavedContent));
