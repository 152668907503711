import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs, Tab, Form } from "react-bootstrap";
import {
	getServerUrl,
	groupUpload,
	groupAdd,
	loadUserGroups,
	getGroups,
	groupAddMember,
	memberRemoveGroup,
	groupRemoveRequest,
} from "../utils/api.js";
import LeftSideBar from "../components/LeftSideBar";
import UploadAvatar from "../components/common/uploadAvatar/index.js";
import Loader from "../components/common/loader/index.js";
import { updateGroups, updateGroupsAdmin, updateUserGroups } from "../actions";
import Avatar from "../components/common/avatar/index.js";
import LoadButton from "../components/common/button/index.js";
import TabToRemove from "../components/common/tabToRemove/index.js";

class Groups extends Component {
	constructor(props) {
		super(props);

		this.state = {
			group: {
				title: "",
				description: "",
				address: "",
				addressCity: "",
				addressCountry: "",
				interest: "Swim",
				logo: "",
				coverPhoto: "",
				teamId: this.props.match.params?.teamSlug || "",
			},
			addedState: false,
			groups: [],
			myGroups: [],
			joinedGroups: [],
			userGroups: this.props.user.groups,
			userGroupRequests: this.props.user.groupRequests,
			team: {},
			loading: true,
			teamSlug: this.props.match.params.teamSlug,
			activeTab: "Create New",
		};

		this.uploadLogoRef = React.createRef();
		this.uploadCoverPhotoRef = React.createRef();

		this.handleTitle = this.handleTitle.bind(this);
		this.handleDescription = this.handleDescription.bind(this);
		this.handleAddress = this.handleAddress.bind(this);
		this.handleAddressCity = this.handleAddressCity.bind(this);
		this.handleAddressCountry = this.handleAddressCountry.bind(this);
		this.handleInterest = this.handleInterest.bind(this);
	}

	componentDidMount() {
		this.setState({
			activeTab:
				this.props.teamSlug === undefined ? "Groups" : "Create New",
		});
		loadUserGroups(
			this.props.user._id,
			this.props.match.params.teamSlug
		).then((resp) => {
			if (resp.success === true) {
				this.props.updateGroupsAdmin(resp.myGroups.length);
				this.props.updateGroups(resp.joinedGroups.length);
				this.setState({
					myGroups: resp.myGroups,
					joinedGroups: resp.joinedGroups,
					team: resp.team,
					loading: false,
				});
			} else this.setState({ loading: false });
		});
	}

	tabChanged = (key) => {
		this.setState({ activeTab: key });

		if (key === "Explore") {
			getGroups(this.props.user._id).then((resp) => {
				if (resp.success === true)
					this.setState({
						groups: resp.groups,
						userGroups: resp.userGroups,
						userGroupRequests: resp.userGroupRequests,
						loading: false,
						addedState: true,
					});
				else this.setState({ loading: false, addedState: true });
			});
			this.setState({ loading: true });
		} else if (key === "Groups") {
			loadUserGroups(this.props.user._id).then((resp) => {
				if (resp.success === true) {
					this.props.updateGroupsAdmin(resp.myGroups.length);
					this.props.updateGroups(resp.joinedGroups.length);
					this.setState({
						myGroups: resp.myGroups,
						joinedGroups: resp.joinedGroups,
						loading: false,
						addedState: true,
					});
				} else this.setState({ loading: false, addedState: true });
			});
		} else if (key === "Create New") {
			this.setState({ addedState: false });
		}
	};

	handleTitle = (e) => {
		this.setState({
			group: { ...this.state.group, title: e.target.value },
		});
	};

	handleDescription = (e) => {
		this.setState({
			group: { ...this.state.group, description: e.target.value },
		});
	};

	handleAddress = (e) => {
		this.setState({
			group: { ...this.state.group, address: e.target.value },
		});
	};

	handleAddressCity = (e) => {
		this.setState({
			group: { ...this.state.group, addressCity: e.target.value },
		});
	};

	handleAddressCountry = (e) => {
		this.setState({
			group: { ...this.state.group, addressCountry: e.target.value },
		});
	};

	handleInterest = (e) => {
		this.setState({
			group: { ...this.state.group, interest: e.target.value },
		});
	};

	openUploadLogo = () => {
		this.uploadLogoRef.current.click();
	};

	openUploadCoverPhoto = () => {
		this.uploadCoverPhotoRef.current.click();
	};

	updateLogo = (ing) => {
		this.setState((prevState) => ({
			group: {
				...prevState.group,
				logo: ing,
			},
		}));
		this.uploadLogoRef.current.value = null;
	};

	uploadCoverPhoto = (e) => {
		let groupCoverPhoto = this.uploadCoverPhotoRef.current.files[0];
		let fileTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

		if (groupCoverPhoto === undefined) {
			alert("Please select image file to upload");
		} else if (fileTypes.indexOf(groupCoverPhoto.type) === -1) {
			alert("Please select file type of JPEG, JPG, PNG or GIF");
		} else {
			const Imagedata = new FormData();
			Imagedata.append("groupUpload", groupCoverPhoto);
			groupUpload(Imagedata).then((imgUpload) => {
				this.setState({
					loading: false,
					group: {
						...this.state.group,
						coverPhoto: imgUpload.filename,
					},
				});
			});
			this.setState({ loading: true });
		}
	};

	addGroup = async (e) => {
		e.preventDefault();
		if (this.state.group.title === "") {
			alert("Enter group title");
			return;
		} else if (this.state.group.description === "") {
			alert("Enter group description");
			return;
		} else if (this.state.group.address === "") {
			alert("Enter group address");
			return;
		} else if (this.state.group.addressCity === "") {
			alert("Enter group city");
			return;
		} else if (this.state.group.addressCountry === "") {
			alert("Select group country");
			return;
		} else if (this.state.group.interest === "") {
			alert("Select group interest");
			return;
		} else if (this.state.group.coverPhoto === "") {
			alert("Upload group cover photo");
			return;
		} else if (this.state.group.logo === "") {
			alert("Upload group logo");
			return;
		} else {
			if (this.state.loading) {
				return;
			}
			this.setState({ loading: true });

			let group = this.state.group;
			let slugify = require("slugify");
			group.slug = slugify(group.title, {
				replacement: "-",
				remove: null,
				lower: true,
			});
			group.creatorId = this.props.user._id;
			await groupAdd(group).then((resp) => {
				if (resp.success === false) {
					alert(resp.msg);
					this.setState({ loading: false });
				} else {
					alert(resp.msg);
					this.setState({
						loading: false,
						myGroups: resp.myGroups,
						group: {
							title: "",
							description: "",
							address: "",
							addressCity: "",
							addressCountry: "",
							interest: "Swim",
							logo: "",
							coverPhoto: "",
							addedState: true,
						},
					});
					this.uploadLogoRef.current.value = null;
					this.uploadCoverPhotoRef.current.value = null;
					this.tabChanged("Groups");
				}
			});
		}
	};

	joinGroup = (e, groupId, groupType) => {
		e.preventDefault();
		groupAddMember(groupId, this.props.user._id, groupType).then((resp) => {
			alert(resp.msg);
			if (resp.success === true) {
				this.setState({
					groups: resp.groups,
					joinedGroups: resp.joinedGroups,
					userGroups: resp.userGroups,
					userGroupRequests: resp.userGroupRequests,
					loading: false,
				});
				this.props.updateUserGroups(
					resp.userGroups,
					resp.userGroupRequests
				);
			} else this.setState({ loading: false });
		});
		this.setState({ loading: true });
	};

	leaveGroup = (e, groupId) => {
		e.preventDefault();
		memberRemoveGroup(groupId, this.props.user._id).then((resp) => {
			alert(resp.msg);
			if (resp.success === true) {
				this.setState({
					groups: resp.groups,
					joinedGroups: resp.joinedGroups,
					userGroups: resp.userGroups,
					userGroupRequests: resp.userGroupRequests,
					loading: false,
				});
				this.props.updateUserGroups(
					resp.userGroups,
					resp.userGroupRequests
				);
			} else this.setState({ loading: false });
		});
		this.setState({ loading: true });
	};

	removeGroupRequest = (e, groupId) => {
		e.preventDefault();
		groupRemoveRequest(groupId, this.props.user._id).then((resp) => {
			alert(resp.msg);
			if (resp.success === true) {
				this.setState({
					groups: resp.groups,
					joinedGroups: resp.joinedGroups,
					userGroups: resp.userGroups,
					userGroupRequests: resp.userGroupRequests,
					loading: false,
				});
				this.props.updateUserGroups(
					resp.userGroups,
					resp.userGroupRequests
				);
			} else this.setState({ loading: false });
		});
		this.setState({ loading: true });
	};

	removePhoto = () => {
		this.setState({
			group: {
				...this.state.group,
				coverPhoto: "",
			},
		});
		this.uploadCoverPhotoRef.current.value = null;
	};

	render() {
		let is_mobile = window.matchMedia("(max-width: 500px)").matches;
		return (
			<div className={is_mobile ? "outbx" : "container cntntbx"}>
				<Loader loading={this.state.loading} />
				<div className={is_mobile ? "" : "row"}>
					{is_mobile ? null : (
						<div className="col-3">
							<LeftSideBar />
						</div>
					)}

					<div className={is_mobile ? "" : "col-9"}>
						<Tabs
							fill
							defaultActiveKey={this.state.activeTab}
							activeKey={this.state.activeTab}
							id="uncontrolled-tab-example"
							onSelect={(k) => this.tabChanged(k)}
						>
							<Tab eventKey="Explore" title="Explore">
								<div
									className={
										is_mobile
											? "main-container"
											: "secteams"
									}
								>
									<h3>Explore Groups</h3>
									<div className={is_mobile ? "" : "row"}>
										{this.state.groups.map((group) => (
											<div
												key={"groups-" + group._id}
												className={
													is_mobile
														? "club-box"
														: "col-lg-6 col-md-12 clubbxsrch"
												}
											>
												<div
													className={
														is_mobile
															? ""
															: "bxshadow teambxim"
													}
												>
													<div className="row">
														<div
															className={
																is_mobile
																	? "col-2 pr-0"
																	: "col-4"
															}
														>
															<Link
																to={
																	"/group/" +
																	group.slug
																}
															>
																<Avatar
																	url={
																		"/uploads/group/"
																	}
																	state={
																		group.logo
																	}
																	alt={
																		"group Logo"
																	}
																/>
															</Link>
														</div>
														{is_mobile ? (
															<div className="col-7">
																<h5
																	style={{
																		display:
																			"block",
																		width: "100%",
																	}}
																>
																	<Link
																		to={
																			"/group/" +
																			group.slug
																		}
																	>
																		{
																			group.title
																		}
																	</Link>
																</h5>
																<small>
																	{
																		group.membersCount
																	}{" "}
																	members
																</small>
															</div>
														) : (
															<div className="col-8 pl-0">
																<Link
																	to={
																		"/group/" +
																		group.slug
																	}
																>
																	{
																		group.title
																	}
																</Link>
																<span className="small pstim">
																	{
																		group.membersCount
																	}{" "}
																	members
																</span>
																<p>
																	<span>
																		{!this.state.userGroups.includes(
																			group._id
																		) &&
																			!this.state.userGroupRequests.includes(
																				group._id
																			) && (
																				<a
																					className="f14 btn"
																					href=""
																					onClick={(
																						e
																					) =>
																						this.joinGroup(
																							e,
																							group._id,
																							group.type
																						)
																					}
																				>
																					Join
																				</a>
																			)}
																		{this.state.userGroups.includes(
																			group._id
																		) && (
																			<a
																				className="f14 btn"
																				href=""
																				onClick={(
																					e
																				) =>
																					this.leaveGroup(
																						e,
																						group._id
																					)
																				}
																			>
																				Leave
																			</a>
																		)}
																		{this.state.userGroupRequests.includes(
																			group._id
																		) && (
																			<a
																				className="f14 btn"
																				href="#"
																				// onClick={(
																				// 	e
																				// ) =>
																				// 	this.removeGroupRequest(
																				// 		e,
																				// 		group._id
																				// 	)
																				// }
																			>
																				Pending
																			</a>
																		)}
																	</span>
																</p>
															</div>
														)}
														{is_mobile ? (
															<div className="col-3 pr-1 pl-0 text-right">
																{!this.state.userGroups.includes(
																	group._id
																) &&
																	!this.state.userGroupRequests.includes(
																		group._id
																	) && (
																		<a
																			className="btn"
																			href=""
																			onClick={(
																				e
																			) =>
																				this.joinGroup(
																					e,
																					group._id,
																					group.type
																				)
																			}
																		>
																			Join
																		</a>
																	)}
																{this.state.userGroups.includes(
																	group._id
																) && (
																	<a
																		className="btn"
																		href=""
																		onClick={(
																			e
																		) =>
																			this.leaveGroup(
																				e,
																				group._id
																			)
																		}
																	>
																		Leave
																	</a>
																)}
																{this.state.userGroupRequests.includes(
																	group._id
																) && (
																	<a
																		className="btn"
																		href="#"
																		// onClick={(
																		// 	e
																		// ) =>
																		// 	this.removeGroupRequest(
																		// 		e,
																		// 		group._id
																		// 	)
																		// }
																	>
																		Pending
																	</a>
																)}
															</div>
														) : null}
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</Tab>
							<Tab eventKey="Groups" title="Groups">
								<div
									className={
										is_mobile
											? "main-container"
											: "secteams"
									}
								>
									<h3>Your Groups</h3>
									<div className={is_mobile ? "" : "row"}>
										{this.state.myGroups.map((group) => (
											<div
												key={"my-groups-" + group._id}
												className={
													is_mobile
														? "club-box"
														: "col-lg-6 col-md-12 clubbxsrch"
												}
											>
												<div
													className={
														is_mobile
															? ""
															: "bxshadow teambxim"
													}
												>
													<div className="row">
														<div
															className={
																is_mobile
																	? "col-2 pr-0"
																	: "col-4"
															}
														>
															<Link
																to={
																	"/group/" +
																	group.slug
																}
															>
																<Avatar
																	url={
																		"/uploads/group/"
																	}
																	state={
																		group.logo
																	}
																	alt={
																		"group Logo"
																	}
																/>
															</Link>
														</div>
														{is_mobile ? (
															<div className="col-7">
																<h5
																	style={{
																		display:
																			"block",
																		width: "100%",
																	}}
																>
																	<Link
																		to={
																			"/group/" +
																			group.slug
																		}
																	>
																		{
																			group.title
																		}
																	</Link>
																</h5>
																<small>
																	{
																		group.membersCount
																	}{" "}
																	members
																</small>
															</div>
														) : (
															<div className="col-8 pl-0">
																<Link
																	to={
																		"/group/" +
																		group.slug
																	}
																>
																	{
																		group.title
																	}
																</Link>
																<span className="small pstim">
																	{
																		group.membersCount
																	}{" "}
																	members
																</span>
																<p>
																	<span>
																		<Link
																			to={
																				"/group/" +
																				group.slug +
																				"/admin"
																			}
																			className="f14 btn"
																		>
																			Admin
																		</Link>
																	</span>
																</p>
															</div>
														)}
														{is_mobile ? (
															<div className="col-3 pl-0 pr-1 text-right">
																<Link
																	to={
																		"/group/" +
																		group.slug +
																		"/admin"
																	}
																	className="btn"
																>
																	Admin
																</Link>
															</div>
														) : null}
													</div>
												</div>
											</div>
										))}
									</div>

									<h3>Groups You Have Joined</h3>
									<div className={is_mobile ? "" : "row"}>
										{this.state.joinedGroups.map(
											(group) => (
												<div
													key={
														"my-groups-" + group._id
													}
													className={
														is_mobile
															? "club-box"
															: "col-lg-6 col-md-12 clubbxsrch"
													}
												>
													<div
														className={
															is_mobile
																? ""
																: "bxshadow teambxim"
														}
													>
														<div className="row">
															<div
																className={
																	is_mobile
																		? "col-2"
																		: "col-4"
																}
															>
																<Link
																	to={
																		"/group/" +
																		group.slug
																	}
																>
																	<Avatar
																		url={
																			"/uploads/group/"
																		}
																		state={
																			group.logo
																		}
																		alt={
																			"group Logo"
																		}
																	/>
																</Link>
															</div>
															{is_mobile ? (
																<div className="col-7 ">
																	<h5
																		style={{
																			display:
																				"block",
																			width: "100%",
																		}}
																	>
																		<Link
																			to={
																				"/group/" +
																				group.slug
																			}
																		>
																			{
																				group.title
																			}
																		</Link>
																	</h5>
																	<small>
																		{
																			group.membersCount
																		}{" "}
																		members
																	</small>
																</div>
															) : (
																<div className="col-8 pl-0">
																	<Link
																		to={
																			"/group/" +
																			group.slug
																		}
																	>
																		{
																			group.title
																		}
																	</Link>
																	<span className="small pstim">
																		{
																			group.membersCount
																		}{" "}
																		members
																	</span>
																	<p>
																		<span>
																			<a
																				className="f14 btn"
																				href=""
																				onClick={(
																					e
																				) =>
																					this.leaveGroup(
																						e,
																						group._id
																					)
																				}
																			>
																				Leave
																			</a>
																		</span>
																	</p>
																</div>
															)}
															{is_mobile && (
																<div className="col-3 pl-0 pr-1 text-right">
																	{group.administrators.includes(
																		this
																			.props
																			.user
																			._id
																	) ? (
																		<Link
																			to={
																				"/group/" +
																				group.slug +
																				"/admin"
																			}
																			className="btn"
																		>
																			Admin
																		</Link>
																	) : group.moderators.includes(
																			this
																				.props
																				.user
																				._id
																	  ) ? (
																		<Link
																			to={
																				"/group/" +
																				group.slug +
																				"/admin"
																			}
																			className="btn"
																		>
																			Moderator
																		</Link>
																	) : (
																		<a
																			className="btn"
																			href=""
																			onClick={(
																				e
																			) =>
																				this.leaveGroup(
																					e,
																					group._id
																				)
																			}
																		>
																			Leave
																		</a>
																	)}
																</div>
															)}
														</div>
													</div>
												</div>
											)
										)}
									</div>
								</div>
							</Tab>
							<Tab eventKey="Create New" title="Create New">
								<div className="main-container createnew upload">
									<h3>Create Group</h3>
									{this.state.teamSlug === undefined && (
										<div className="userthumb">
											<span className="userbx">
												<img
													src={
														this.props.user
															.profilePicture
															? getServerUrl()
																	.apiURL +
															  "/uploads/user/" +
															  this.props.user
																	.profilePicture
															: "/uploads/images/profile_default.png"
													}
													alt=""
												/>
											</span>
											<span>
												{this.props.user.firstName +
													" " +
													this.props.user.lastName}
											</span>
										</div>
									)}
									{this.state.teamSlug !== undefined && (
										<div className="userthumb">
											<span className="userbx">
												<img
													src={
														this.state.team?.logo
															? getServerUrl()
																	.apiURL +
															  "/uploads/team/" +
															  this.state.team
																	?.logo
															: "/uploads/images/profile_default.png"
													}
													alt=""
												/>
											</span>
											<span>
												{this.state.team?.title}
											</span>
										</div>
									)}

									<form>
										<Form.Group controlId="formGroupTitle">
											<Form.Label>Title *</Form.Label>
											<Form.Control
												type="text"
												name="title"
												placeholder="Group title *"
												value={this.state.group.title}
												onChange={this.handleTitle}
											/>
										</Form.Group>

										<Form.Group controlId="formGroupDescription">
											<Form.Label>About *</Form.Label>
											<Form.Control
												as="textarea"
												placeholder="About Group *"
												rows={3}
												value={
													this.state.group.description
												}
												onChange={
													this.handleDescription
												}
											/>
										</Form.Group>

										<Form.Group controlId="formGroupLocation">
											<Form.Label>Location *</Form.Label>
											<Form.Control
												type="text"
												placeholder="Address *"
												onChange={this.handleAddress}
												value={this.state.group.address}
											/>
											<Form.Control
												type="text"
												placeholder="City *"
												onChange={
													this.handleAddressCity
												}
												value={
													this.state.group.addressCity
												}
											/>
											<Form.Control
												as="select"
												onChange={
													this.handleAddressCountry
												}
												value={
													this.state.group
														.addressCountry
												}
											>
												<option value="">
													Country *
												</option>
												<option value="Australia">
													Australia
												</option>
												<option value="Pakistan">
													Pakistan
												</option>
											</Form.Control>
										</Form.Group>

										<Form.Group controlId="formGroupInterest">
											<Form.Label>
												Interested in *
											</Form.Label>
											<Form.Control
												as="select"
												onChange={this.handleInterest}
												value={
													this.state.group.interest
												}
											>
												<option value="Swim">
													Swimming
												</option>
												<option value="Run">
													Running
												</option>
												<option value="Walk">
													Walking
												</option>
												<option value="Bike">
													Cycling
												</option>
												<option value="Strength">
													Weight Lifting
												</option>

												<option value="Flexibility">
													Flexibility
												</option>
												<option value="Recovery">
													Recovery
												</option>
												<option value="Duathlon">
													Duathlon
												</option>
												<option value="Triathlon">
													Triathlon
												</option>
											</Form.Control>
										</Form.Group>
										<TabToRemove
											title={"Cover Photo *"}
											tabTo={"Cover Photo Tab to remove"}
											removePhoto={this.removePhoto}
											logo={this.state.group.coverPhoto}
											upload={"/uploads/temp/"}
										/>
										<Form.Group>
											<a
												className="btn button"
												onClick={() =>
													this.openUploadCoverPhoto()
												}
											>
												Upload Cover Photo
											</a>
											<input
												type="file"
												ref={this.uploadCoverPhotoRef}
												onChange={() =>
													this.uploadCoverPhoto()
												}
												style={{ display: "none" }}
											/>
										</Form.Group>
										<Form.Group>
											<Form.Label>Logo *</Form.Label>
											<UploadAvatar
												uploadLogoRef={
													this.uploadLogoRef
												}
												openUploadLogo={
													this.openUploadLogo
												}
												updateLogo={this.updateLogo}
												uploadType={"groupUpload"}
												uploadAPI={groupUpload}
												a_style={"btn button"}
												btn_text={
													"Upload Logo (Square, min 200 width)"
												}
												displayLogo={
													this.state.group.logo ? (
														<Avatar
															url={
																"/uploads/temp/"
															}
															state={
																this.state.group
																	.logo
															}
															alt={"Group Logo"}
															style={{
																width: "100px",
																height: "100px",
															}}
														/>
													) : null
												}
												addedState={
													this.state.addedState
												}
											/>
										</Form.Group>
										<Form.Group>
											<LoadButton
												submitting={this.state.loading}
												title={"Create Group"}
												btnClass={
													"button subbtn btn-save"
												}
												titleClass={"text-white"}
												onClick={this.addGroup}
											/>
										</Form.Group>
									</form>
								</div>
							</Tab>
						</Tabs>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	};
};

const mapDispatchToProps = {
	updateGroups,
	updateGroupsAdmin,
	updateUserGroups,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Groups));
