// constants for app
// module.exports = {
// 	apiURL: "http://localhost:3001",
// 	mobileQuery: "(max-width: 500px)",
// 	siteURL: "http://localhost:3000",
// };

module.exports = {
	apiURL: "https://api.coachly.net",
	mobileQuery: "(max-width: 500px)",
	siteURL: "https://coachly.net",
};
