/**
 * =====================================
 * REACT SCREEN COMPONENT CLASS
 * =====================================
 * @date created: 18 October 2019
 * @authors: Jay Parikh
 *
 * This file defines the EditEvent screen component. The class EditEvent
 * is where the component is defined. This is a screen component.
 *
 * It contains the form that user can fill to edit an event.
 *
 */

// Importing libraries for setup
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
// Importing helper functions
import * as API from "../utils/api";
import { fileToBase64 } from "../utils/helper";
import GoogleLocationSearch from "../components/GoogleLocationSearch";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import Loader from "../components/common/loader";
import TabToRemove from "../components/common/tabToRemove";

class EditEvent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			updateLoading: false,
			event: {
				title: "",
				description: "",
				start: "",
				end: "",
				startTime: "",
				endTime: "",
				logo: "",
				location: "",
				lat: "",
				lng: "",
				moduleType: "",
				interestValue: "",
				moduleId: "",
			},
			upload: "/uploads/event/",
		};
		this.uploadEventLogoRef = React.createRef();
	}

	componentDidMount() {
		API.getEvent(this.props.eventId).then((eventDetails) => {
			if (eventDetails.eventId) {
				this.setState({
					loading: false,
					event: eventDetails,
					interestValue: eventDetails.interest,
				});
			}
		});
	}

	// Invokes the edit event API call.
	editEvent = async (e) => {
		e.preventDefault();
		// get interest data
		let eventData = this.state.event;

		if (this.state.interestValue) {
			await API.getActivityByTitle(this.state.interestValue).then(
				(activity) => {
					if (activity._id) {
						eventData.interest = {
							id: activity._id,
							name: activity.title,
							icon: activity.activity_icon,
						};
					}
				}
			);
		}
		this.setState({ updateLoading: true }, () => {
			if (this.state.file !== null) {
				const data = new FormData();

				data.append("eventId", this.props.eventId);
				data.append("file", this.state.file);

				axios
					.put(
						API.getServerUrl().apiURL +
							"/events/" +
							this.props.eventId +
							"/coverPicture",
						data
					)
					.then((res) => {
						if (res.status === 200) {
							this.props
								.editEvent(this.props.eventId, this.state.event)
								.then((res) => {
									this.setState({ updateLoading: false });
									this.props.showPopup(
										"Event updated!",
										"Go to Event Page",
										"/events/" + this.props.eventId
									);
								});
						}
					});
			} else {
				this.props
					.editEvent(this.props.eventId, this.state.event)
					.then((res) => {
						this.setState({ updateLoading: false });
						this.props.showPopup(
							"Event updated!",
							"Go to Event Page",
							"/events/" + this.props.eventId
						);
					});
			}
		});
	};

	handleGoogleAddress = (address, lat, lng) => {
		this.setState({
			event: { ...this.state.event, location: address, lat, lng },
		});
	};

	handleEventTitle = (e) => {
		e.preventDefault();
		e.persist();

		this.setState({
			event: { ...this.state.event, title: e.target.value },
		});
	};

	handleEventStartTime = (e) => {
		e.preventDefault();
		e.persist();

		this.setState({
			event: { ...this.state.event, startTime: e.target.value },
		});
	};

	handleEventEndTime = (e) => {
		e.preventDefault();
		e.persist();
		const newEndTime = e.target.value;
		const { startDate, endDate, startTime } = this.state.event;

		// Check if start time is selected
		if (!startTime) {
			alert("Please select the start time first.");
			return;
		}
		const startDateObject = new Date(startDate);
		const selectedEndDate = new Date(endDate);

		// Compare the selected end date and time with the start date and time
		if (selectedEndDate < startDateObject) {
			// If selected end date is earlier than start date, adjust end time to start time
			this.setState({
				event: { ...this.state.event, endTime: startTime },
			});
		} else if (newEndTime <= startTime && startDate === endDate) {
			// If end time is less than or equal to start time, adjust it to be one minute later
			const adjustedEndTime = new Date(startTime);
			adjustedEndTime.setMinutes(adjustedEndTime.getMinutes() + 1);

			const adjustedEndTimeString = `${adjustedEndTime
				.getHours()
				.toString()
				.padStart(2, "0")}:${adjustedEndTime
				.getMinutes()
				.toString()
				.padStart(2, "0")}`;

			this.setState({
				event: { ...this.state.event, endTime: adjustedEndTimeString },
			});
		} else {
			this.setState({
				event: { ...this.state.event, endTime: newEndTime },
			});
		}
	};
	handleEventDescription = (e) => {
		e.preventDefault();
		e.persist();

		this.setState({
			event: { ...this.state.event, description: e.target.value },
		});
	};

	handleEventLocation = (e) => {
		e.preventDefault();
		e.persist();

		this.setState({
			event: { ...this.state.event, location: e.target.value },
		});
	};

	uploadEventLogo = (e) => {
		let eventLogo = this.uploadEventLogoRef.current.files[0];
		let fileTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

		if (eventLogo === undefined) {
			alert("Please select image file to upload");
		} else if (fileTypes.indexOf(eventLogo.type) === -1) {
			alert("Please select file type of JPEG, JPG, PNG or GIF");
		} else {
			const Imagedata = new FormData();
			Imagedata.append("eventUpload", eventLogo);
			API.groupEventUpload(Imagedata).then((imgUpload) => {
				this.setState({
					loading: false,
					event: { ...this.state.event, logo: imgUpload.filename },
				});
			});
			this.setState({ loading: true });
		}
	};

	openUploadEventLogo = () => {
		this.uploadEventLogoRef.current.click();
	};

	removePhoto = () => {
		this.setState({
			event: {
				...this.state.event,
				logo: "",
			},
			upload: "/uploads/temp/",
		});
		this.uploadEventLogoRef.current.value = null;
	};
	handleChange = (field) => (e) => {
		this.setState({ [field]: e.target.value });
	};

	render() {
		const today = new Date();
		const maxDate = new Date(
			today.getFullYear() + 30,
			today.getMonth(),
			today.getDate()
		);

		return (
			<div className="outbx">
				<Loader loading={this.state.loading} />
				<div className="teams-container">
					<Link to="/groups/" className="backbtn">
						{" "}
					</Link>
					<h6>Edit Event</h6>
				</div>
				<div className="boxmenu mmbr crtevnt">
					<form onSubmit={this.editEvent}>
						<div className="form-group">
							<label
								className="form-label"
								htmlFor="formEventName"
							>
								Event Name
							</label>
							<input
								placeholder="Event Name"
								type="text"
								id="formEventName"
								className="form-control"
								value={this.state.event.title}
								onChange={this.handleEventTitle}
							/>
						</div>
						<div className="row">
							<div className="col">
								<div className="form-group">
									<label
										className="form-label"
										htmlFor="formEventStart"
									>
										Start Date
									</label>
									<DateTimePicker
										placeholder="Start Date"
										format="yyyy-MM-dd"
										required
										id="formEventStart"
										className="form-control"
										value={this.state.event.startDate}
										inputProps={{
											readOnly: true, // Disable direct input
										}}
										onChange={(newDate) => {
											const endDate =
												this.state.event.endDate;

											// Compare the selected date with the maximum date
											if (newDate <= maxDate) {
												this.setState({
													event: {
														...this.state.event,
														startDate: newDate,
														endDate:
															endDate < newDate
																? newDate
																: endDate,
														startDateSelected: true, // Flag to indicate start date is selected
													},
												});
											} else {
												// If selected date is beyond the max date, set to today's date
												this.setState({
													event: {
														...this.state.event,
														startDate: new Date(),
														endDate:
															endDate < new Date()
																? new Date()
																: endDate, // Update end date if needed
													},
												});
											}
										}}
										minDate={new Date()} // Set the max value to the current date
										maxDate={maxDate} // Set the maximum value to today + 30 years
									/>
								</div>
							</div>
							<div className="col ">
								<div className="form-group">
									<label
										className="form-label"
										htmlFor="formEventEnd"
									>
										End Date
									</label>

									<DateTimePicker
										placeholder="End Date"
										format="yyyy-MM-dd"
										required
										id="formEventEnd"
										className="form-control"
										value={this.state.event.endDate}
										inputProps={{
											readOnly: true, // Disable direct input
										}}
										onChange={(newDate) => {
											// Check if start date is selected before updating end date
											if (
												this.state.event
													.startDateSelected
											) {
												if (newDate <= maxDate) {
													this.setState({
														event: {
															...this.state.event,
															endDate: newDate,
														},
													});
												} else {
													// If selected date is beyond the max date, set to today's date
													this.setState({
														event: {
															...this.state.event,
															endDate: new Date(),
														},
													});
												}
											} else {
												// Show an alert or message to select start date first
												alert(
													"Please select the start date first."
												);
											}
										}}
										minDate={this.state.event.startDate} // Set the min value to the selected start date
										maxDate={maxDate} // Set the maximum value to today + 30 years
									/>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col">
								<div className="form-group">
									<label
										className="form-label"
										htmlFor="formEventStartTime"
									>
										Start Time
									</label>
									<input
										placeholder="Start Time"
										type="time"
										id="formEventStartTime"
										className="form-control"
										value={this.state.event.startTime}
										onChange={this.handleEventStartTime}
									/>
								</div>
							</div>
							<div className="col pl-0">
								<div className="form-group">
									<label
										className="form-label"
										htmlFor="formEventEndTime"
									>
										End Time
									</label>
									<input
										placeholder="End Time"
										type="time"
										id="formEventEndTime"
										className="form-control"
										value={this.state.event.end}
										onChange={this.handleEventEndTime}
									/>
								</div>
							</div>
						</div>

						<div className="form-group">
							<label
								className="form-label"
								htmlFor="formEventAbout"
							>
								About Event
							</label>
							<textarea
								rows="3"
								id="formEventAbout"
								className="form-control"
								placeholder="About Event"
								onChange={this.handleEventDescription}
								value={this.state.event.description}
							></textarea>
						</div>

						<div className="form-group">
							<label
								className="form-label"
								htmlFor="formTeamAddress"
							>
								Location
							</label>
							<div className="search-location-input">
								<GoogleLocationSearch
									handleGoogleAddress={
										this.handleGoogleAddress
									}
									initialValue={this.state.location}
									placeholder
								/>

								{/*<input
                                        placeholder='Type Address'
                                        type='text'
                                        id='formTeamAddress'
                                        className='form-control pac-target-input'
                                        value={this.state.event.location}
                                        onChange={this.handleEventLocation}
                                        autoComplete='off'
                                        required
                                    />*/}
							</div>
						</div>

						<div className="form-group">
							<label
								className="form-label"
								htmlFor="formTeamActivityType"
							>
								Activity Type
							</label>

							<select
								className="form-control"
								id="formTeamActivityType"
								onChange={this.handleChange("interestValue")}
								value={this.state.eventDetails}
								required
							>
								<option value="" disabled>
									Choose one...
								</option>
								<option value="Swim">Swimming</option>
								<option value="Run">Running</option>
								<option value="Walk">Walking</option>
								<option value="Bike">Cycling</option>
								<option value="Strength">Weight Lifting</option>
								<option value="Flexibility">Flexibility</option>
								<option value="Recovery">Recovery</option>
								<option value="Duathlon">Duathlon</option>
								<option value="Triathlon">Triathlon</option>
							</select>
						</div>
						<TabToRemove
							title={"Event Photo *"}
							tabTo={"Event Photo Tab to remove"}
							removePhoto={this.removePhoto}
							logo={this.state.event.logo}
							upload={this.state.upload}
						/>
						<div className="form-group">
							<a
								className="button"
								onClick={() => this.openUploadEventLogo()}
							>
								Upload Photo
							</a>
							<input
								type="file"
								ref={this.uploadEventLogoRef}
								onChange={() => this.uploadEventLogo()}
								style={{ display: "none" }}
							/>
						</div>

						<div className="form-group">
							<a
								className="button subbtn"
								onClick={(e) => this.editEvent(e)}
							>
								Update Event
							</a>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userSignedIn: state.auth.userSignedIn,
	};
};

export default connect(mapStateToProps, {})(EditEvent);
