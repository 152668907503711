import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import loading from "../assets/loading.svg";
import ReactStars from "react-rating-stars-component";
import {
	getServerUrl,
	getUserEvents,
	editEventStatus,
	getTeamEventsFront,
	getGroupEventsFront,
} from "../utils/api";

import {
	GoogleMap,
	InfoWindow,
	Marker,
	withGoogleMap,
	withScriptjs,
} from "react-google-maps";
import Geocode from "react-geocode";
import { assignTimeAgo, formatDateTime } from "../utils/helper";
import Avatar from "../components/common/avatar";
import { Tab, Tabs } from "react-bootstrap";
import MapWithPins from "../components/common/map/MapWithPins";

const keysConfig = require("../config/keys");

Geocode.setApiKey(keysConfig.google.apiKey);

let dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
let monthNames = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

class Events extends Component {
	constructor(props) {
		super(props);

		this.state = {
			query: "",
			events: [],
			eventsMarkerOpen: "",
			mapCenter: {
				lat: 0,
				lng: 0,
			},
			team: { title: "" },
			zoom: 10,
			loading: true,
			selectedTab: "all",
			teamStatus: "",
			groupStatus: "",
			isAdminOrJoined: "",
		};

		this.mapRef = React.createRef();
	}

	componentDidMount() {
		if (this.props.type === "all") {
			getUserEvents(this.props.user._id, "").then((resp) => {
				if (resp.success === true) {
					const categorizedEvents = {
						team: [],
						group: [],
						user: [],
						all: [],
					};
					if (resp.resultEvents.length > 0) {
						resp.resultEvents.forEach((event) => {
							if (event.moduleType in categorizedEvents) {
								categorizedEvents[event.moduleType].push(event);
							}
							categorizedEvents.all.push(event);
						});

						//this.mapRef.setCenter({lat: resp.resultEvents[0].lat, lng: resp.resultEvents[0].lng});
						this.setState({
							events: categorizedEvents,
							loading: false,
							mapCenter: {
								lat: resp.resultEvents[0].lat,
								lng: resp.resultEvents[0].lng,
							},
						});
					} else
						this.setState({
							events: resp.resultEvents,
							loading: false,
						});
				}
			});
		} else if (this.props.type === "team") {
			this.setState({
				teamStatus: this.props.location.state?.teamStatus || "active",
			});
			getTeamEventsFront(this.props.teamSlug, "").then((resp) => {
				if (resp.success === true) {
					if (resp.resultEvents.length > 0) {
						//this.mapRef.setCenter({lat: resp.resultEvents[0].lat, lng: resp.resultEvents[0].lng});
						this.setState({
							events: resp.resultEvents,
							team: resp.team,
							loading: false,
							mapCenter: {
								lat: resp.resultEvents[0].lat,
								lng: resp.resultEvents[0].lng,
							},
						});
					} else
						this.setState({
							events: resp.resultEvents,
							team: resp.team,
							loading: false,
						});
				}
			});
		} else if (this.props.type === "group") {
			this.setState({
				groupStatus: this.props.location.state?.groupStatus || "active",
				isAdminOrJoined: this.props.location.state?.isAdminOrJoined,
			});
			getGroupEventsFront(this.props.groupSlug, "").then((resp) => {
				if (resp.success === true) {
					if (resp.resultEvents.length > 0) {
						//this.mapRef.setCenter({lat: resp.resultEvents[0].lat, lng: resp.resultEvents[0].lng});
						this.setState({
							events: resp.resultEvents,
							team: resp.team,
							loading: false,
							mapCenter: {
								lat: resp.resultEvents[0].lat,
								lng: resp.resultEvents[0].lng,
							},
						});
					} else
						this.setState({
							events: resp.resultEvents,
							team: resp.team,
							loading: false,
						});
				}
			});
		}
	}

	loadData = (tabKey) => {
		if (tabKey === "all") {
			this.setState({ selectedTab: tabKey });
		} else if (tabKey === "team") {
			this.setState({ selectedTab: tabKey });
		} else if (tabKey === "group") {
			this.setState({ selectedTab: tabKey });
		} else if (tabKey === "user") {
			this.setState({ selectedTab: tabKey });
		}
	};
	ratingChanged = (newRating) => {
		console.log(newRating);
	};

	handleChange = (e) => {
		this.setState({ query: e.target.value });
	};

	handleKeyDown = (e) => {
		if (e.key === "Enter") {
			// only enable search other than empty string
			e.preventDefault();
			if (this.state.query) {
				this.fetchUserEvents(e);
			} else {
				alert("Enter text to search");
			}
		}
	};

	fetchUserEvents = (e) => {
		e.preventDefault();
		if (this.props.type === "all") {
			getUserEvents(this.props.user._id, this.state.query).then(
				(resp) => {
					if (resp.success === true) {
						const categorizedEvents = {
							team: [],
							group: [],
							user: [],
							all: [],
						};
						if (resp.resultEvents.length > 0) {
							resp.resultEvents.forEach((event) => {
								if (event.moduleType in categorizedEvents) {
									categorizedEvents[event.moduleType].push(
										event
									);
								}
								categorizedEvents.all.push(event);
							});
							//this.mapRef.setCenter({lat: resp.resultEvents[0].lat, lng: resp.resultEvents[0].lng});
							this.setState({
								events: categorizedEvents,
								loading: false,
								mapCenter: {
									lat: resp.resultEvents[0].lat,
									lng: resp.resultEvents[0].lng,
								},
							});
						} else
							this.setState({
								events: resp.resultEvents,
								loading: false,
							});
					}
				}
			);
		} else if (this.props.type === "team") {
			getTeamEventsFront(this.props.teamSlug, this.state.query).then(
				(resp) => {
					if (resp.success === true) {
						if (resp.resultEvents.length > 0)
							this.setState({
								events: resp.resultEvents,
								team: resp.team,
								loading: false,
								mapCenter: {
									lat: resp.resultEvents[0].lat,
									lng: resp.resultEvents[0].lng,
								},
							});
						else
							this.setState({
								events: resp.resultEvents,
								team: resp.team,
								loading: false,
							});
					}
				}
			);
		}
		this.setState({ loading: true });
	};

	changeOpenMarker = (eventId) => {
		this.setState({ eventsMarkerOpen: eventId });
	};

	eventUpdate = (ev, action, event) => {
		ev.preventDefault();
		editEventStatus(event._id, this.props.user.userId, action).then(
			(res) => {
				if (res.success) {
					let events = [];
					let oldEvents = this.state.events;
					for (let i = 0; i < oldEvents.length; i++) {
						if (oldEvents[i].eventId === event.eventId) {
							let event = res.event;
							event.moduleTitle = oldEvents[i].moduleTitle;
							event.moduleLogo = oldEvents[i].moduleLogo;
							events.push(event);
						} else events.push(oldEvents[i]);
					}

					this.setState({ events });
				} else {
					alert(res.msg);
				}
			}
		);
	};

	renderMap() {
		const selectedTab = this.state.selectedTab;

		// Filter events based on the selected tab
		let eventsToRender = [];
		if (this.props.type === "all" && selectedTab === "all") {
			eventsToRender = this.state.events.all;
		} else if (this.props.type === "all" && selectedTab === "team") {
			eventsToRender = this.state.events.team;
		} else if (this.props.type === "all" && selectedTab === "group") {
			eventsToRender = this.state.events.group;
		} else if (this.props.type === "all" && selectedTab === "user") {
			eventsToRender = this.state.events.user;
		} else if (this.props.type === "team") {
			eventsToRender = this.state.events;
		} else if (this.props.type === "group") {
			eventsToRender = this.state.events;
		}

		return (
			<MapWithPins
				googleMapURL={keysConfig.google.mapUrl}
				loadingElement={<div style={{ height: `100%` }} />}
				containerElement={<div style={{ height: "30vh" }} />}
				mapElement={<div style={{ height: `100%` }} />}
				locations={eventsToRender} // Pass events to generateMarkers
			/>
		);
	}

	render() {
		return (
			<div className="container-fluid nopad evntsbx follow">
				<div className="teams-container">
					{this.props.type !== "all" && (
						<a
							className="backbtn"
							onClick={(e) => {
								e.preventDefault();
								window.history.back();
							}}
						></a>
					)}
					<h6>
						{this.props.type === "all"
							? "Events "
							: this.state.team.title}
						{this.props.type === "all" && (
							<Link
								to="/events/create"
								className="pushright createbtn f14"
							>
								Create Event
							</Link>
						)}
					</h6>
					<a className="srchevntbtn">
						<img width="20" src="/uploads/images/search.svg" />
					</a>
				</div>

				<div className="srchevnt">
					<form>
						<input
							type="text"
							placeholder=" Search Event "
							required
							value={this.state.query}
							onChange={this.handleChange}
							onKeyDown={this.handleKeyDown}
						/>
						<a href="#" onClick={this.fetchUserEvents}>
							<img width="20" src="/uploads/images/search.png" />
						</a>
					</form>
				</div>
				<div className="tempwide ">
					<div className=" evntbx">
						{this.props.type === "all" && (
							<Tabs fill defaultActiveKey={"all"}>
								<Tab
									eventKey="all"
									title="All"
									style={{
										fontSize: "14px",
										textAlign: "start",
									}}
								>
									{this.state.events.all?.length > 0 && (
										<div
											className="event-map"
											style={{
												width: `100%`,
												height: `auto`,
											}}
										>
											{this.renderMap()}
										</div>
									)}
									{this.state.events.all?.length > 0 ? (
										this.state.events.all.map((event) => {
											let eventStartDisplay =
												formatDateTime(event.start);

											let eventEndDisplay =
												formatDateTime(event.end);

											return (
												<div
													key={
														"team-event-" +
														event._id
													}
													className="srchpstbx mb-1 xxx"
												>
													<div className="usrtop">
														<div className="row">
															<div className="col-2">
																<div className="userthumb">
																	<Link
																		className="userbx roundlogo"
																		to={`/${event.moduleType}/${event.moduleSlug}`}
																	>
																		<Avatar
																			url={`/uploads/${event.moduleType}/`}
																			state={
																				event.moduleLogo
																			}
																			alt={
																				"event picture"
																			}
																		/>
																	</Link>
																</div>
															</div>

															<div className="col-8 ml-2 nopad pt-1">
																<Link
																	to={`/${event.moduleType}/${event.moduleSlug}`}
																>
																	{
																		event.moduleTitle
																	}
																	{event.time && (
																		<span className="small pstim">
																			{assignTimeAgo(
																				event.time
																			)}
																		</span>
																	)}{" "}
																</Link>
															</div>
															<div className="">
																<span className="acttyp">
																	<img
																		src={
																			"/uploads/images/" +
																			event
																				.interest
																				.icon
																		}
																	/>
																</span>
															</div>
														</div>
													</div>

													<div className="mt-1 mb-1">
														<Link
															to={
																"/event/" +
																event.eventId
															}
														>
															{event.title}
														</Link>
													</div>

													<small>
														<span className="f14">
															<img
																width="20"
																src="/uploads/images/calendar.png"
															/>
															{`Start: ${eventStartDisplay}`}
														</span>
													</small>
													<small>
														<span className="f14">
															<img
																width="20"
																src="/uploads/images/calendar.png"
															/>
															{`End: ${eventEndDisplay}`}
														</span>
													</small>

													<br />

													<small>
														<img
															width="20"
															src="/uploads/images/pin.png"
														/>{" "}
														<span className="txtblc">
															{event.location}
														</span>{" "}
													</small>
													<br />
													<small>
														<span className="f14">
															<img
																width="20"
																src="/uploads/images/people.png"
															/>
															{event.attending
																.length > 0
																? `${
																		event
																			.attending
																			.length ===
																		1
																			? "1 Person is Attending"
																			: event
																					.attending
																					.length +
																			  " People are Attending"
																  } `
																: "No one is attending"}
														</span>
													</small>
													<br />
													<small>
														<span className="f14">
															<img
																width="20"
																src="/uploads/images/people.png"
															/>
															{event.interested
																.length > 0
																? `${
																		event
																			.interested
																			.length ===
																		1
																			? "1 Person is Interested"
																			: event
																					.interested
																					.length +
																			  " People are Interested"
																  }`
																: "No one is interested"}
														</span>
													</small>
													<br />
													<div className="">
														<div className="row">
															<div className="col-8 rtngbx">
																<span>
																	Rating:
																</span>
																<ReactStars
																	count={5}
																	size={24}
																	activeColor="#ffd700"
																	value={
																		event.rating !==
																		null
																			? event.rating
																			: 0
																	}
																	edit={false}
																/>
															</div>
															{/* <div className='col-4 text-right'><a className='btn btn-primary' href='#'>Follow</a></div> */}
														</div>
													</div>

													{event.owner ===
													this.props.user
														.userId ? null : (
														<p className="mt-2">
															<a
																href=""
																className={
																	"btn grnbtn mr-2" +
																	(event.attending.includes(
																		this
																			.props
																			.user
																			.userId
																	)
																		? " dislinks blur "
																		: "")
																}
																onClick={(ev) =>
																	this.eventUpdate(
																		ev,
																		"attending",
																		event
																	)
																}
															>
																I am Attending
															</a>
															<a
																href=""
																className={
																	"btn grnbtn" +
																	(event.interested.includes(
																		this
																			.props
																			.user
																			.userId
																	)
																		? " dislinks blur "
																		: "")
																}
																onClick={(ev) =>
																	this.eventUpdate(
																		ev,
																		"interested",
																		event
																	)
																}
															>
																I am Interested
															</a>
														</p>
													)}
												</div>
											);
										})
									) : (
										<p className="feed-container-empty">
											No Event
										</p>
									)}
								</Tab>
								<Tab
									eventKey="group"
									title="Groups"
									style={{
										fontSize: "14px",
										textAlign: "start",
									}}
								>
									{this.state.events.group?.length > 0 && (
										<div
											className="event-map"
											style={{
												width: `100%`,
												height: `auto`,
											}}
										>
											{this.renderMap()}
										</div>
									)}
									{this.state.events.group?.length > 0 ? (
										this.state.events.group.map((event) => {
											let eventStartDisplay =
												formatDateTime(event.start);

											let eventEndDisplay =
												formatDateTime(event.end);

											return (
												<div
													key={
														"team-event-" +
														event._id
													}
													className="srchpstbx mb-1 xxx"
												>
													<div className="usrtop">
														<div className="row">
															<div className="col-2">
																<div className="userthumb">
																	<Link
																		className="userbx roundlogo"
																		to={`/${event.moduleType}/${event.moduleSlug}`}
																	>
																		{" "}
																		<Avatar
																			url={`/uploads/${event.moduleType}/`}
																			state={
																				event.moduleLogo
																			}
																			alt={
																				"event picture"
																			}
																		/>
																	</Link>
																</div>
															</div>

															<div className="col-8 ml-2 nopad pt-1">
																<Link
																	to={`/${event.moduleType}/${event.moduleSlug}`}
																>
																	{
																		event.moduleTitle
																	}
																	{event.time && (
																		<span className="small pstim">
																			{assignTimeAgo(
																				event.time
																			)}
																		</span>
																	)}{" "}
																</Link>
															</div>
															<div className="">
																<span className="acttyp">
																	<img
																		src={
																			"/uploads/images/" +
																			event
																				.interest
																				.icon
																		}
																	/>
																</span>
															</div>
														</div>
													</div>

													<div className="mt-1 mb-1">
														<Link
															to={
																"/event/" +
																event.eventId
															}
														>
															{event.title}
														</Link>
													</div>

													<small>
														<span className="f14">
															<img
																width="20"
																src="/uploads/images/calendar.png"
															/>
															{`Start: ${eventStartDisplay}`}
														</span>
													</small>
													<small>
														<span className="f14">
															<img
																width="20"
																src="/uploads/images/calendar.png"
															/>
															{`End: ${eventEndDisplay}`}
														</span>
													</small>

													<br />

													<small>
														<img
															width="20"
															src="/uploads/images/pin.png"
														/>{" "}
														<span className="txtblc">
															{event.location}
														</span>{" "}
													</small>
													<br />
													<small>
														<span className="f14">
															<img
																width="20"
																src="/uploads/images/people.png"
															/>
															{event.attending
																.length > 0
																? `${
																		event
																			.attending
																			.length ===
																		1
																			? "1 Person is Attending"
																			: event
																					.attending
																					.length +
																			  " People are Attending"
																  } `
																: "No one is attending"}
														</span>
													</small>
													<br />
													<small>
														<span className="f14">
															<img
																width="20"
																src="/uploads/images/people.png"
															/>
															{event.interested
																.length > 0
																? `${
																		event
																			.interested
																			.length ===
																		1
																			? "1 Person is Interested"
																			: event
																					.interested
																					.length +
																			  " People are Interested"
																  }`
																: "No one is interested"}
														</span>
													</small>
													<br />
													<div className="">
														<div className="row">
															<div className="col-8 rtngbx">
																<span>
																	Rating:
																</span>
																<ReactStars
																	count={5}
																	size={24}
																	activeColor="#ffd700"
																	value={
																		event.rating !==
																		null
																			? event.rating
																			: 0
																	}
																	edit={false}
																/>
															</div>
															{/* <div className='col-4 text-right'><a className='btn btn-primary' href='#'>Follow</a></div> */}
														</div>
													</div>

													{event.owner ===
													this.props.user
														.userId ? null : (
														<p className="mt-2">
															<a
																href=""
																className={
																	"btn grnbtn mr-2" +
																	(event.attending.includes(
																		this
																			.props
																			.user
																			.userId
																	)
																		? " dislinks blur "
																		: "")
																}
																onClick={(ev) =>
																	this.eventUpdate(
																		ev,
																		"attending",
																		event
																	)
																}
															>
																I am Attending
															</a>
															<a
																href=""
																className={
																	"btn grnbtn" +
																	(event.interested.includes(
																		this
																			.props
																			.user
																			.userId
																	)
																		? " dislinks blur "
																		: "")
																}
																onClick={(ev) =>
																	this.eventUpdate(
																		ev,
																		"interested",
																		event
																	)
																}
															>
																I am Interested
															</a>
														</p>
													)}
												</div>
											);
										})
									) : (
										<p className="feed-container-empty">
											No Group Event
										</p>
									)}
								</Tab>
								<Tab
									eventKey="team"
									title="Teams"
									style={{
										fontSize: "14px",
										textAlign: "start",
									}}
								>
									{this.state.events.team?.length > 0 && (
										<div
											className="event-map"
											style={{
												width: `100%`,
												height: `auto`,
											}}
										>
											{this.renderMap()}
										</div>
									)}
									{this.state.events.team?.length > 0 ? (
										this.state.events.team.map((event) => {
											let eventStartDisplay =
												formatDateTime(event.start);

											let eventEndDisplay =
												formatDateTime(event.end);

											return (
												<div
													key={
														"team-event-" +
														event._id
													}
													className="srchpstbx mb-1 xxx"
												>
													<div className="usrtop">
														<div className="row">
															<div className="col-2">
																<div className="userthumb">
																	<Link
																		className="userbx roundlogo"
																		to={`/${event.moduleType}/${event.moduleSlug}`}
																	>
																		{" "}
																		<Avatar
																			url={`/uploads/${event.moduleType}/`}
																			state={
																				event.moduleLogo
																			}
																			alt={
																				"event picture"
																			}
																		/>
																	</Link>
																</div>
															</div>

															<div className="col-8 ml-2 nopad pt-1">
																<Link
																	to={`/${event.moduleType}/${event.moduleSlug}`}
																>
																	{
																		event.moduleTitle
																	}
																	{event.time && (
																		<span className="small pstim">
																			{assignTimeAgo(
																				event.time
																			)}
																		</span>
																	)}{" "}
																</Link>
															</div>
															<div className="">
																<span className="acttyp">
																	<img
																		src={
																			"/uploads/images/" +
																			event
																				.interest
																				.icon
																		}
																	/>
																</span>
															</div>
														</div>
													</div>

													<div className="mt-1 mb-1">
														<Link
															to={
																"/event/" +
																event.eventId
															}
														>
															{event.title}
														</Link>
													</div>

													<small>
														<span className="f14">
															<img
																width="20"
																src="/uploads/images/calendar.png"
															/>
															{`Start: ${eventStartDisplay}`}
														</span>
													</small>
													<small>
														<span className="f14">
															<img
																width="20"
																src="/uploads/images/calendar.png"
															/>
															{`End: ${eventEndDisplay}`}
														</span>
													</small>

													<br />

													<small>
														<img
															width="20"
															src="/uploads/images/pin.png"
														/>{" "}
														<span className="txtblc">
															{event.location}
														</span>{" "}
													</small>
													<br />
													<small>
														<span className="f14">
															<img
																width="20"
																src="/uploads/images/people.png"
															/>
															{event.attending
																.length > 0
																? `${
																		event
																			.attending
																			.length ===
																		1
																			? "1 Person is Attending"
																			: event
																					.attending
																					.length +
																			  " People are Attending"
																  } `
																: "No one is attending"}
														</span>
													</small>
													<br />
													<small>
														<span className="f14">
															<img
																width="20"
																src="/uploads/images/people.png"
															/>
															{event.interested
																.length > 0
																? `${
																		event
																			.interested
																			.length ===
																		1
																			? "1 Person is Interested"
																			: event
																					.interested
																					.length +
																			  " People are Interested"
																  }`
																: "No one is interested"}
														</span>
													</small>
													<br />
													<div className="">
														<div className="row">
															<div className="col-8 rtngbx">
																<span>
																	Rating:
																</span>
																<ReactStars
																	count={5}
																	size={24}
																	activeColor="#ffd700"
																	value={
																		event.rating !==
																		null
																			? event.rating
																			: 0
																	}
																	edit={false}
																/>
															</div>
															{/* <div className='col-4 text-right'><a className='btn btn-primary' href='#'>Follow</a></div> */}
														</div>
													</div>

													{event.owner ===
													this.props.user
														.userId ? null : (
														<p className="mt-2">
															<a
																href=""
																className={
																	"btn grnbtn mr-2" +
																	(event.attending.includes(
																		this
																			.props
																			.user
																			.userId
																	)
																		? " dislinks blur "
																		: "")
																}
																onClick={(ev) =>
																	this.eventUpdate(
																		ev,
																		"attending",
																		event
																	)
																}
															>
																I am Attending
															</a>
															<a
																href=""
																className={
																	"btn grnbtn" +
																	(event.interested.includes(
																		this
																			.props
																			.user
																			.userId
																	)
																		? " dislinks blur "
																		: "")
																}
																onClick={(ev) =>
																	this.eventUpdate(
																		ev,
																		"interested",
																		event
																	)
																}
															>
																I am Interested
															</a>
														</p>
													)}
												</div>
											);
										})
									) : (
										<p className="feed-container-empty">
											No Team Event
										</p>
									)}
								</Tab>
								<Tab
									eventKey="user"
									title="Personal"
									style={{
										fontSize: "14px",
										textAlign: "start",
									}}
								>
									{this.state.events.user?.length > 0 && (
										<div
											className="event-map"
											style={{
												width: `100%`,
												height: `auto`,
											}}
										>
											{this.renderMap()}
										</div>
									)}
									{this.state.events.user?.length > 0 ? (
										this.state.events.user.map((event) => {
											let eventStartDisplay =
												formatDateTime(event.start);

											let eventEndDisplay =
												formatDateTime(event.end);

											return (
												<div
													key={
														"team-event-" +
														event._id
													}
													className="srchpstbx mb-1 xxx"
												>
													<div className="usrtop">
														<div className="row">
															<div className="col-2">
																<div className="userthumb">
																	<Link
																		className="userbx roundlogo"
																		to={`/profile/${event.moduleSlug}`}
																	>
																		<Avatar
																			url={`/uploads/${event.moduleType}/`}
																			state={
																				event.moduleLogo
																			}
																			alt={
																				"event picture"
																			}
																		/>
																	</Link>
																</div>
															</div>

															<div className="col-8 ml-2 nopad pt-1">
																<Link
																	to={`/profile/${event.moduleSlug}`}
																>
																	{
																		event.moduleTitle
																	}
																	{event.time && (
																		<span className="small pstim">
																			{assignTimeAgo(
																				event.time
																			)}
																		</span>
																	)}{" "}
																</Link>
															</div>
															<div className="">
																<span className="acttyp">
																	<img
																		src={
																			"/uploads/images/" +
																			event
																				.interest
																				.icon
																		}
																	/>
																</span>
															</div>
														</div>
													</div>

													<div className="mt-1 mb-1">
														<Link
															to={
																"/event/" +
																event.eventId
															}
														>
															{event.title}
														</Link>
													</div>

													<small>
														<span className="f14">
															<img
																width="20"
																src="/uploads/images/calendar.png"
															/>
															{`Start: ${eventStartDisplay}`}
														</span>
													</small>
													<small>
														<span className="f14">
															<img
																width="20"
																src="/uploads/images/calendar.png"
															/>
															{`End: ${eventEndDisplay}`}
														</span>
													</small>

													<br />

													<small>
														<img
															width="20"
															src="/uploads/images/pin.png"
														/>{" "}
														<span className="txtblc">
															{event.location}
														</span>{" "}
													</small>
													<br />
													<small>
														<span className="f14">
															<img
																width="20"
																src="/uploads/images/people.png"
															/>
															{event.attending
																.length > 0
																? `${
																		event
																			.attending
																			.length ===
																		1
																			? "1 Person is Attending"
																			: event
																					.attending
																					.length +
																			  " People are Attending"
																  } `
																: "No one is attending"}
														</span>
													</small>
													<br />
													<small>
														<span className="f14">
															<img
																width="20"
																src="/uploads/images/people.png"
															/>
															{event.interested
																.length > 0
																? `${
																		event
																			.interested
																			.length ===
																		1
																			? "1 Person is Interested"
																			: event
																					.interested
																					.length +
																			  " People are Interested"
																  }`
																: "No one is interested"}
														</span>
													</small>
													<br />
													<div className="">
														<div className="row">
															<div className="col-8 rtngbx">
																<span>
																	Rating:
																</span>
																<ReactStars
																	count={5}
																	size={24}
																	activeColor="#ffd700"
																	value={
																		event.rating !==
																		null
																			? event.rating
																			: 0
																	}
																	edit={false}
																/>
															</div>
															{/* <div className='col-4 text-right'><a className='btn btn-primary' href='#'>Follow</a></div> */}
														</div>
													</div>

													{event.owner !==
														this.props.user
															.userId && (
														<p className="mt-2">
															<a
																href=""
																className={
																	"btn grnbtn mr-2" +
																	(event.attending.includes(
																		this
																			.props
																			.user
																			.userId
																	)
																		? " dislinks blur "
																		: "")
																}
																onClick={(ev) =>
																	this.eventUpdate(
																		ev,
																		"attending",
																		event
																	)
																}
															>
																I am Attending
															</a>
															<a
																href=""
																className={
																	"btn grnbtn" +
																	(event.interested.includes(
																		this
																			.props
																			.user
																			.userId
																	)
																		? " dislinks blur "
																		: "")
																}
																onClick={(ev) =>
																	this.eventUpdate(
																		ev,
																		"interested",
																		event
																	)
																}
															>
																I am Interested
															</a>
														</p>
													)}
												</div>
											);
										})
									) : (
										<p className="feed-container-empty">
											No Personal Event
										</p>
									)}
								</Tab>
							</Tabs>
						)}
						{this.props.type === "team" &&
							(this.state.events.length > 0 ? (
								<div>
									<div
										className="event-map"
										style={{
											width: `100%`,
											height: `auto`,
										}}
									>
										{this.renderMap()}
									</div>
									{this.state.events.map((event) => {
										let eventStartDisplay = formatDateTime(
											event.start
										);

										let eventEndDisplay = formatDateTime(
											event.end
										);

										return (
											<div
												key={"team-event-" + event._id}
												className="srchpstbx mb-1 xxx"
											>
												<div className="usrtop">
													<div className="row">
														<div className="col-2">
															<div className="userthumb">
																<Link
																	className="userbx roundlogo"
																	to={{
																		pathname: `/${event.moduleType}/${event.moduleSlug}`,
																		state: {
																			teamStatus:
																				this
																					.state
																					.teamStatus,
																		},
																	}}
																>
																	<Avatar
																		url={`/uploads/${event.moduleType}/`}
																		state={
																			event.moduleLogo
																		}
																		alt={
																			"event picture"
																		}
																	/>
																</Link>
															</div>
														</div>

														<div className="col-8 ml-2 nopad pt-1">
															<Link
																to={{
																	pathname: `/${event.moduleType}/${event.moduleSlug}`,
																	state: {
																		teamStatus:
																			this
																				.state
																				.teamStatus,
																	},
																}}
															>
																{
																	event.moduleTitle
																}
																{event.time && (
																	<span className="small pstim">
																		{assignTimeAgo(
																			event.time
																		)}
																	</span>
																)}{" "}
															</Link>
														</div>
														<div className="">
															<span className="acttyp">
																<img
																	src={
																		"/uploads/images/" +
																		event
																			.interest
																			.icon
																	}
																/>
															</span>
														</div>
													</div>
												</div>

												<div className="mt-1 mb-1">
													<Link
														to={{
															pathname: `/event/${event.eventId}`,
															state: {
																teamStatus:
																	this.state
																		.teamStatus,
															},
														}}
													>
														{event.title}
													</Link>
												</div>

												<small>
													<span className="f14">
														<img
															width="20"
															src="/uploads/images/calendar.png"
														/>
														{`Start: ${eventStartDisplay}`}
													</span>
												</small>
												<small>
													<span className="f14">
														<img
															width="20"
															src="/uploads/images/calendar.png"
														/>
														{`End: ${eventEndDisplay}`}
													</span>
												</small>

												<br />

												<small>
													<img
														width="20"
														src="/uploads/images/pin.png"
													/>{" "}
													<span className="txtblc">
														{event.location}
													</span>{" "}
												</small>
												<br />
												<small>
													<span className="f14">
														<img
															width="20"
															src="/uploads/images/people.png"
														/>
														{event.attending
															.length > 0
															? `${
																	event
																		.attending
																		.length ===
																	1
																		? "1 Person is Attending"
																		: event
																				.attending
																				.length +
																		  " People are Attending"
															  } `
															: "No one is attending"}
													</span>
												</small>
												<br />
												<small>
													<span className="f14">
														<img
															width="20"
															src="/uploads/images/people.png"
														/>
														{event.interested
															.length > 0
															? `${
																	event
																		.interested
																		.length ===
																	1
																		? "1 Person is Interested"
																		: event
																				.interested
																				.length +
																		  " People are Interested"
															  }`
															: "No one is interested"}
													</span>
												</small>
												<br />
												<div className="">
													<div className="row">
														<div className="col-8 rtngbx">
															<span>Rating:</span>
															<ReactStars
																count={5}
																size={24}
																activeColor="#ffd700"
																value={
																	event.rating !==
																	null
																		? event.rating
																		: 0
																}
																edit={false}
															/>
														</div>
														{/* <div className='col-4 text-right'><a className='btn btn-primary' href='#'>Follow</a></div> */}
													</div>
												</div>

												{event.owner ===
												this.props.user.userId
													? null
													: this.state.teamStatus !==
															"suspend" && (
															<p className="mt-2">
																<a
																	href=""
																	className={
																		"btn grnbtn mr-2" +
																		(event.attending.includes(
																			this
																				.props
																				.user
																				.userId
																		)
																			? " dislinks blur "
																			: "")
																	}
																	onClick={(
																		ev
																	) =>
																		this.eventUpdate(
																			ev,
																			"attending",
																			event
																		)
																	}
																>
																	I am
																	Attending
																</a>
																<a
																	href=""
																	className={
																		"btn grnbtn" +
																		(event.interested.includes(
																			this
																				.props
																				.user
																				.userId
																		)
																			? " dislinks blur "
																			: "")
																	}
																	onClick={(
																		ev
																	) =>
																		this.eventUpdate(
																			ev,
																			"interested",
																			event
																		)
																	}
																>
																	I am
																	Interested
																</a>
															</p>
													  )}
											</div>
										);
									})}
								</div>
							) : (
								<p className="feed-container-empty">No Event</p>
							))}
						{this.props.type === "group" &&
							(this.state.events.length > 0 ? (
								<div>
									<div
										className="event-map"
										style={{
											width: `100%`,
											height: `auto`,
										}}
									>
										{this.renderMap()}
									</div>
									{this.state.events.map((event) => {
										let eventStartDisplay = formatDateTime(
											event.start
										);

										let eventEndDisplay = formatDateTime(
											event.end
										);

										return (
											<div
												key={"team-event-" + event._id}
												className="srchpstbx mb-1 xxx"
											>
												<div className="usrtop">
													<div className="row">
														<div className="col-2">
															<div className="userthumb">
																<Link
																	className="userbx roundlogo"
																	to={{
																		pathname: `/${event.moduleType}/${event.moduleSlug}`,
																		state: {
																			groupStatus:
																				this
																					.state
																					.groupStatus,
																		},
																	}}
																>
																	<Avatar
																		url={`/uploads/${event.moduleType}/`}
																		state={
																			event.moduleLogo
																		}
																		alt={
																			"event picture"
																		}
																	/>
																</Link>
															</div>
														</div>

														<div className="col-8 ml-2 nopad pt-1">
															<Link
																to={{
																	pathname: `/${event.moduleType}/${event.moduleSlug}`,
																	state: {
																		groupStatus:
																			this
																				.state
																				.groupStatus,
																	},
																}}
															>
																{
																	event.moduleTitle
																}
																{event.time && (
																	<span className="small pstim">
																		{assignTimeAgo(
																			event.time
																		)}
																	</span>
																)}{" "}
															</Link>
														</div>
														<div className="">
															<span className="acttyp">
																<img
																	src={
																		"/uploads/images/" +
																		event
																			.interest
																			.icon
																	}
																/>
															</span>
														</div>
													</div>
												</div>

												<div className="mt-1 mb-1">
													<Link
														to={{
															pathname: `/event/${event.eventId}`,
															state: {
																groupStatus:
																	this.state
																		.groupStatus,
																isAdminOrJoined:
																	this.state
																		.isAdminOrJoined,
															},
														}}
													>
														{event.title}
													</Link>
												</div>

												<small>
													<span className="f14">
														<img
															width="20"
															src="/uploads/images/calendar.png"
														/>
														{`Start: ${eventStartDisplay}`}
													</span>
												</small>
												<small>
													<span className="f14">
														<img
															width="20"
															src="/uploads/images/calendar.png"
														/>
														{`End: ${eventEndDisplay}`}
													</span>
												</small>

												<br />

												<small>
													<img
														width="20"
														src="/uploads/images/pin.png"
													/>{" "}
													<span className="txtblc">
														{event.location}
													</span>{" "}
												</small>
												<br />
												<small>
													<span className="f14">
														<img
															width="20"
															src="/uploads/images/people.png"
														/>
														{event.attending
															.length > 0
															? `${
																	event
																		.attending
																		.length ===
																	1
																		? "1 Person is Attending"
																		: event
																				.attending
																				.length +
																		  " People are Attending"
															  } `
															: "No one is attending"}
													</span>
												</small>
												<br />
												<small>
													<span className="f14">
														<img
															width="20"
															src="/uploads/images/people.png"
														/>
														{event.interested
															.length > 0
															? `${
																	event
																		.interested
																		.length ===
																	1
																		? "1 Person is Interested"
																		: event
																				.interested
																				.length +
																		  " People are Interested"
															  }`
															: "No one is interested"}
													</span>
												</small>
												<br />
												<div className="">
													<div className="row">
														<div className="col-8 rtngbx">
															<span>Rating:</span>
															<ReactStars
																count={5}
																size={24}
																activeColor="#ffd700"
																value={
																	event.rating !==
																	null
																		? event.rating
																		: 0
																}
																edit={false}
															/>
														</div>
														{/* <div className='col-4 text-right'><a className='btn btn-primary' href='#'>Follow</a></div> */}
													</div>
												</div>

												{event.owner ===
												this.props.user.userId
													? null
													: this.state.groupStatus !==
															"suspend" &&
													  this.state
															.isAdminOrJoined && (
															<p className="mt-2">
																<a
																	href=""
																	className={
																		"btn grnbtn mr-2" +
																		(event.attending.includes(
																			this
																				.props
																				.user
																				.userId
																		)
																			? " dislinks blur "
																			: "")
																	}
																	onClick={(
																		ev
																	) =>
																		this.eventUpdate(
																			ev,
																			"attending",
																			event
																		)
																	}
																>
																	I am
																	Attending
																</a>
																<a
																	href=""
																	className={
																		"btn grnbtn" +
																		(event.interested.includes(
																			this
																				.props
																				.user
																				.userId
																		)
																			? " dislinks blur "
																			: "")
																	}
																	onClick={(
																		ev
																	) =>
																		this.eventUpdate(
																			ev,
																			"interested",
																			event
																		)
																	}
																>
																	I am
																	Interested
																</a>
															</p>
													  )}
											</div>
										);
									})}
								</div>
							) : (
								<p className="feed-container-empty">No Event</p>
							))}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	};
};

export default withRouter(connect(mapStateToProps, {})(Events));
