import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import {
	getServerUrl,
	membershipUpload,
	teamMembershipAdd,
	loadTeamMemberships,
	getTeamMembership,
	teamMembershipUpdate,
	membershipUpdateUpgradePaths,
	mobileQuery,
	getUser,
} from "../../../utils/api";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import LeftSideBarTeam from "../../LeftSideBarTeam";
import Loader from "../../common/loader";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import Avatar from "../../common/avatar";
import LoadButton from "../../common/button";
import {
	FaDollarSign,
	FaDelicious,
	FaDigitalOcean,
	FaDollyFlatbed,
	FaFirstOrder,
	FaHandHoldingUsd,
	FaSync,
	FaUndo,
	FaMoneyBillAlt,
} from "react-icons/fa";
import { HiUser, HiUsers } from "react-icons/hi";
import { formatDateTime } from "../../../utils/helper";
import TabToRemove from "../../common/tabToRemove";
class TeamMembershipsAdmin extends Component {
	constructor(props) {
		super(props);

		this.state = {
			membership: {
				title: "",
				description: "",
				isPublic: "yes",
				type: "free",
				paymentType: "permanent",
				intervalType: "days",
				interval: 1,
				startDate: "",
				endDate: "",
				totalPayments: 0,
				trial: false,
				trialType: "days",
				trialPeriod: 1,
				price: 0,
				discountPrice: 0,
				logo: "",
				coverPhoto: "",
				subscribeWithoutMembership: true,
				updateDenied: [],
				updateReplace: [],
				coaches: [],
			},
			coachesData: [],
			screenType: "memberships",
			logo: "",
			coverPhoto: "",
			memberships: [],
			coaches: [],
			loading: true,
			isAdmin: false,
			isAdminOrJoined: false,
			upload: "",
		};

		this.uploadMembershipLogoRef = React.createRef();
	}

	componentDidMount() {
		loadTeamMemberships(
			this.props.team._id,
			this.props.team.coaches,
			this.props.user._id
		).then((resp) => {
			if (resp.success === true) {
				this.setState({
					memberships: resp.memberships,
					coaches: resp.coaches,
					loading: false,
					isAdminOrJoined: resp.isAdminOrJoined,
				});
			} else this.setState({ loading: false });
		});
	}

	changeScreen = (e, screenType, id) => {
		e.preventDefault();
		if (screenType === "edit-membership") {
			getTeamMembership(id).then((resp) => {
				if (resp.success === true) {
					let membership = resp.membership;
					membership.price = membership.price.$numberDecimal;
					membership.discountPrice =
						membership.discountPrice.$numberDecimal;

					this.setState({
						screenType,
						membership,
						upload: "/uploads/memberships/",
					});
				} else if (resp.success === false) alert(resp.msg);
			});
		} else if (screenType === "upgrade-paths") {
			this.setState({ screenType });
		} else if (screenType === "create-membership") {
			this.setState({
				screenType,
				loading: false,
				membership: {
					title: "",
					description: "",
					isPublic: "yes",
					type: "free",
					paymentType: "permanent",
					intervalType: "days",
					interval: 1,
					startDate: "",
					endDate: "",
					totalPayments: 0,
					trial: false,
					trialType: "days",
					trialPeriod: 1,
					price: 0,
					discountPrice: 0,
					logo: "",
					coverPhoto: "",
					subscribeWithoutMembership: true,
					updateDenied: [],
					updateReplace: [],
					coaches: [],
				},
				upload: "/uploads/temp/",
			});
		} else {
			this.setState({ screenType, loading: false });
		}
	};

	handleMembershipTitle = (e) => {
		this.setState({
			membership: { ...this.state.membership, title: e.target.value },
		});
	};

	handleMembershipDescription = (e) => {
		this.setState({
			membership: {
				...this.state.membership,
				description: e.target.value,
			},
		});
	};

	handleMembershipPublic = (e) => {
		this.setState({
			membership: { ...this.state.membership, isPublic: e.target.value },
		});
	};

	handleMembershipType = (e) => {
		this.setState({
			membership: {
				...this.state.membership,
				type: e.target.value,
				paymentType: "permanent",
			},
		});
	};

	handleMembershipPaymentType = (e) => {
		this.setState({
			membership: {
				...this.state.membership,
				paymentType: e.target.value,
			},
		});
	};

	handleMembershipInterval = (e) => {
		this.setState({
			membership: { ...this.state.membership, interval: e.target.value },
		});
	};

	handleMembershipIntervalType = (e) => {
		this.setState({
			membership: {
				...this.state.membership,
				intervalType: e.target.value,
			},
		});
	};

	handleMembershipStartDate = (e) => {
		this.setState({
			membership: { ...this.state.membership, startDate: e.target.value },
		});
	};

	handleMembershipEndDate = (e) => {
		this.setState({
			membership: { ...this.state.membership, endDate: e.target.value },
		});
	};

	handleMembershipTrial = (e) => {
		this.setState({
			membership: { ...this.state.membership, trial: e.target.checked },
		});
	};

	handleMembershipTrialType = (e) => {
		this.setState({
			membership: { ...this.state.membership, trialType: e.target.value },
		});
	};

	handleMembershipTrialPeriod = (e) => {
		this.setState({
			membership: {
				...this.state.membership,
				trialPeriod: e.target.value,
			},
		});
	};

	handleMembershipPrice = (e) => {
		this.setState({
			membership: { ...this.state.membership, price: e.target.value },
		});
	};

	handleMembershipDiscountPrice = (e) => {
		this.setState({
			membership: {
				...this.state.membership,
				discountPrice: e.target.value,
			},
		});
	};

	handleMembershipTotalPayments = (e) => {
		this.setState({
			membership: {
				...this.state.membership,
				totalPayments: e.target.value,
			},
		});
	};

	openUploadMembershipLogo = () => {
		this.uploadMembershipLogoRef.current.click();
	};

	uploadMembershipLogo = (e) => {
		let membershipLogo = this.uploadMembershipLogoRef.current.files[0];
		let fileTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

		if (membershipLogo === undefined) {
			alert("Please select image file to upload");
		} else if (fileTypes.indexOf(membershipLogo.type) === -1) {
			alert("Please select file type of JPEG, JPG, PNG or GIF");
		} else {
			const Imagedata = new FormData();
			Imagedata.append("membershipUpload", membershipLogo);
			membershipUpload(Imagedata).then((imgUpload) => {
				this.setState({
					loading: false,
					logo: imgUpload.filename,
					membership: {
						...this.state.membership,
						logo: imgUpload.filename,
					},
				});
			});
			this.setState({ loading: true });
		}
	};

	handleCoachChange = (selectedOption) => {
		let coaches = [];
		if (selectedOption.length > 0) {
			for (let i = 0; i < selectedOption.length; i++) {
				coaches.push(selectedOption[i].value);
			}
		}

		this.setState({ membership: { ...this.state.membership, coaches } });
	};

	addMembership = async (e) => {
		e.preventDefault();

		if (this.state.membership.title === "") {
			alert("Enter membership name");
			return;
		} else if (this.state.membership.description === "") {
			alert("Enter membership description");
			return;
		} else if (
			(this.state.membership.paymentType === "finite" ||
				this.state.membership.paymentType === "recurring") &&
			this.state.membership.interval <= 0
		) {
			alert(
				"Enter positive value to give access for " +
					this.state.membership.intervalType
			);
			return;
		} else if (
			this.state.membership.paymentType === "recurring" &&
			this.state.membership.totalPayments < 0
		) {
			alert("Enter positive value to limit total number of payments");
			return;
		} else if (
			this.state.membership.paymentType === "range" &&
			this.state.membership.startDate === ""
		) {
			alert("Enter membership start date");
			return;
		} else if (
			this.state.membership.paymentType === "range" &&
			this.state.membership.endDate === ""
		) {
			alert("Enter membership end date");
			return;
		} else if (
			this.state.membership.type === "paid" &&
			this.state.membership.price <= 0
		) {
			alert("Enter membership price");
			return;
		} else if (
			this.state.membership.type === "paid" &&
			this.state.membership.discountPrice < 0
		) {
			alert("Enter membership discounted price");
			return;
		} else if (this.state.membership.logo === "") {
			alert("Upload membership logo");
			return;
		} else {
			if (this.state.loading) {
				return;
			}
			this.setState({ loading: true });

			let membership = this.state.membership;
			let slugify = require("slugify");
			membership.slug = slugify(membership.title, {
				replacement: "-",
				remove: null,
				lower: true,
			});
			membership.clubId = this.props.team._id;
			membership.creatorId = this.props.user._id;
			await teamMembershipAdd(membership).then((resp) => {
				if (resp.success == false) {
					alert(resp.msg);
					this.setState({ loading: false });
				} else {
					alert(resp.msg);
					this.setState({
						logo: "",
						memberships: resp.memberships,
						screenType: "memberships",
						membership: {
							title: "",
							description: "",
							isPublic: "yes",
							type: "free",
							paymentType: "permanent",
							intervalType: "days",
							interval: 1,
							startDate: "",
							endDate: "",
							totalPayments: 0,
							trial: false,
							trialType: "days",
							trialPeriod: 1,
							price: 0,
							discountPrice: 0,
							logo: "",
							coverPhoto: "",
							subscribeWithoutMembership: true,
							updateDenied: [],
							updateReplace: [],
							coaches: [],
						},
					});
					this.setState({ loading: false });
				}
			});
		}
	};

	updateMembership = async (e) => {
		e.preventDefault();

		if (this.state.membership.title === "") {
			alert("Enter membership name");
			return;
		} else if (this.state.membership.description === "") {
			alert("Enter membership description");
			return;
		} else if (
			(this.state.membership.paymentType === "finite" ||
				this.state.membership.paymentType === "recurring") &&
			this.state.membership.interval <= 0
		) {
			alert(
				"Enter positive value to give access for " +
					this.state.membership.intervalType
			);
			return;
		} else if (
			this.state.membership.paymentType === "recurring" &&
			this.state.membership.totalPayments < 0
		) {
			alert("Enter positive value to limit total number of payments");
			return;
		} else if (
			this.state.membership.paymentType === "range" &&
			(this.state.membership.startDate === "" ||
				this.state.membership.startDate === null)
		) {
			alert("Enter membership start date");
			return;
		} else if (
			this.state.membership.paymentType === "range" &&
			(this.state.membership.endDate === "" ||
				this.state.membership.endDate === null)
		) {
			alert("Enter membership end date");
			return;
		} else if (
			this.state.membership.type === "paid" &&
			this.state.membership.price <= 0
		) {
			alert("Enter membership price");
			return;
		} else if (
			this.state.membership.type === "paid" &&
			this.state.membership.discountPrice < 0
		) {
			alert("Enter membership discounted price");
			return;
		} else if (this.state.membership.logo === "") {
			alert("Upload membership logo");
			return;
		} else {
			if (this.state.loading) {
				return;
			}
			this.setState({ loading: true });

			let membership = this.state.membership;
			let slugify = require("slugify");
			membership.slug = slugify(membership.title, {
				replacement: "-",
				remove: null,
				lower: true,
			});
			await teamMembershipUpdate(membership, this.state.logo).then(
				(resp) => {
					if (resp.success == false) {
						alert(resp.msg);
						this.setState({ loading: false });
					} else {
						alert(resp.msg);
						this.setState({
							logo: "",
							memberships: resp.memberships,
							screenType: "memberships",
							membership: {
								title: "",
								description: "",
								isPublic: "yes",
								type: "free",
								paymentType: "permanent",
								intervalType: "days",
								interval: 1,
								startDate: "",
								endDate: "",
								totalPayments: 0,
								trial: false,
								trialType: "days",
								trialPeriod: 1,
								price: 0,
								discountPrice: 0,
								logo: "",
								coverPhoto: "",
								subscribeWithoutMembership: true,
								updateDenied: [],
								updateReplace: [],
								coaches: [],
							},
						});
						this.setState({ loading: false });
					}
				}
			);
		}
	};

	handleSubscribeWithoutMembership = (e) => {
		this.setState({
			membership: {
				...this.state.membership,
				subscribeWithoutMembership: e.target.checked,
			},
		});
	};

	handleCanSubscribe = (e, id) => {
		let updateDenied = this.state.membership.updateDenied;
		if (e.target.checked) {
			for (let i = 0; i < updateDenied.length; i++) {
				if (id === updateDenied[i]) {
					updateDenied.splice(i, 1);
				}
			}
		} else {
			updateDenied.push(id);
		}

		this.setState({
			membership: { ...this.state.membership, updateDenied },
		});
	};

	handleCancelMembership = (e, id) => {
		let updateReplace = this.state.membership.updateReplace;
		if (e.target.checked) updateReplace.push(id);
		else {
			for (let i = 0; i < updateReplace.length; i++) {
				if (id === updateReplace[i]) {
					updateReplace.splice(i, 1);
				}
			}
		}

		this.setState({
			membership: { ...this.state.membership, updateReplace },
		});
	};

	saveUpgradePaths = async (e) => {
		e.preventDefault();
		if (this.state.loading) {
			return;
		}
		this.setState({ loading: true });

		let { _id, subscribeWithoutMembership, updateDenied, updateReplace } =
			this.state.membership;
		let membership = {
			_id,
			subscribeWithoutMembership,
			updateDenied,
			updateReplace,
		};

		await membershipUpdateUpgradePaths(membership).then((resp) => {
			if (resp.success === false) {
				alert(resp.msg);
			} else {
				alert(resp.msg);
				this.setState({
					screenType: "edit-membership",
				});
			}
		});
		this.setState({ loading: false });
	};

	removePhoto = () => {
		this.setState({
			membership: {
				...this.state.membership,
				logo: "",
			},
			upload: "/uploads/temp/",
		});
		this.uploadMembershipLogoRef.current.value = null;
	};

	render() {
		const today = new Date();
		const maxDate = new Date(
			today.getFullYear() + 30,
			today.getMonth(),
			today.getDate()
		);
		let isMobile = window.matchMedia(mobileQuery).matches;

		if (this.state.screenType == "memberships") {
			return (
				<div className="outbx">
					<Loader loading={this.state.loading} />

					<div className="teams-container">
						<a
							href=""
							className="backbtn"
							onClick={(e) => this.props.changeScreen(e, "menu")}
						>
							{" "}
						</a>
						<h6>
							Memberships{" "}
							<a
								href=""
								className="pushright createbtn f14"
								onClick={(e) =>
									this.changeScreen(
										e,
										"create-membership",
										""
									)
								}
							>
								Create New
							</a>
						</h6>
					</div>

					{this.state.memberships.length > 0 && (
						<div
							key={`membership-`}
							className="container cntntbx trndgbx"
						>
							<div className="row">
								<div
									className={`${
										!isMobile ? "col-9" : "col-12 nopad"
									}`}
								>
									<div className="lstevnt">
										<div className="row">
											{this.state.memberships.map(
												(membership) => (
													<div
														key={
															"my-memberships-" +
															membership._id
														}
														className="col-lg-6 col-md-12"
													>
														<div className="srchpstbx">
															<div className="pstmd post-img-parent event-img">
																<Avatar
																	url={
																		"/uploads/memberships/"
																	}
																	state={
																		membership.logo
																	}
																	alt={
																		"memberships logo"
																	}
																	className="post-img"
																/>
															</div>
															<div className="mt-1 mb-1">
																<Link
																	to={
																		"/team/" +
																		this
																			.props
																			.team
																			.slug +
																		"/admin/membership/" +
																		membership.slug
																	}
																>
																	{
																		membership.title
																	}
																</Link>
															</div>
															<p>
																<span className=" f14">
																	<FaHandHoldingUsd
																		style={{
																			marginRight:
																				"10px",
																			opacity:
																				".5",
																		}}
																	/>
																	<FaDollarSign
																		style={{
																			opacity:
																				".5",
																		}}
																	/>{" "}
																	{
																		membership
																			.price
																			.$numberDecimal
																	}
																</span>
															</p>
															<p>
																<span className="f14">
																	<FaUndo
																		style={{
																			marginRight:
																				"10px",
																			opacity:
																				".5",
																		}}
																	/>{" "}
																	Interval:{" "}
																	{membership.interval +
																		" " +
																		membership.intervalType}
																</span>
															</p>
															{/* {membership.memberRequestsCount && (
																<p>
																	<span className="f14">
																		<HiUsers
																			style={{
																				marginRight:
																					"10px",
																				opacity:
																					".5",
																			}}
																		/>{" "}
																		Member
																		Request:{" "}
																		{
																			membership.memberRequestsCount
																		}
																	</span>
																</p>
															)} */}
															{membership.order && (
																<p>
																	<span className="f14">
																		<FaFirstOrder
																			style={{
																				marginRight:
																					"10px",
																				opacity:
																					".5",
																			}}
																		/>{" "}
																		Order :{" "}
																		{
																			membership.order
																		}
																	</span>
																</p>
															)}
															{membership.paymentType && (
																<p>
																	<span className="f14">
																		<FaSync
																			style={{
																				marginRight:
																					"10px",
																				opacity:
																					".5",
																			}}
																		/>{" "}
																		Payment
																		Type :{" "}
																		{
																			membership.paymentType
																		}
																	</span>
																</p>
															)}
															{membership.type && (
																<p>
																	<span className="f14">
																		<FaMoneyBillAlt
																			style={{
																				marginRight:
																					"10px",
																				opacity:
																					".5",
																			}}
																		/>{" "}
																		Membership
																		Type :{" "}
																		{
																			membership.type
																		}
																	</span>
																</p>
															)}
															<p>
																<span className="f14">
																	<FaDigitalOcean
																		style={{
																			marginRight:
																				"10px",
																			opacity:
																				".5",
																		}}
																	/>
																	Trial:{" "}
																	{membership.trial
																		? membership.trialPeriod +
																		  " " +
																		  membership.trialType
																		: "No trials"}
																</span>
															</p>
															{membership.totalPayments >
																0 && (
																<p>
																	<span className="f14">
																		<FaDelicious
																			style={{
																				marginRight:
																					"10px",
																				opacity:
																					".5",
																			}}
																		/>{" "}
																		Total
																		Payments
																		:{" "}
																		{
																			membership.totalPayments
																		}
																	</span>
																</p>
															)}
															{membership.coaches
																.length > 0 &&
																membership.coaches.map(
																	(
																		coach,
																		idx
																	) =>
																		coach?.firstName && (
																			<p
																				key={
																					idx
																				}
																			>
																				<span className="f14">
																					<HiUser
																						style={{
																							marginRight:
																								"10px",
																							opacity:
																								".5",
																						}}
																					/>{" "}
																					Coach:{" "}
																					{coach?.firstName +
																						" " +
																						coach?.lastName}
																				</span>
																			</p>
																		)
																)}
															<hr />
															<h5>
																<b>
																	Description
																</b>
															</h5>
															<p>
																<span className="f14">
																	{
																		membership.description
																	}
																</span>
															</p>
															<p
																style={{
																	display:
																		"flex",
																	flexDirection:
																		"row-reverse",
																}}
															>
																{" "}
																<a
																	href=""
																	className={
																		"memberShip-btn"
																	}
																	style={{
																		paddingTop:
																			"5px",
																		paddingBottom:
																			"5px",
																	}}
																	onClick={(
																		e
																	) =>
																		this.changeScreen(
																			e,
																			"edit-membership",
																			membership._id
																		)
																	}
																>
																	Edit
																</a>
															</p>
														</div>
													</div>
												)
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

					{this.state.memberships.length === 0 && (
						<div className="boxmenu mmbr">No memberships</div>
					)}
				</div>
			);
		} else if (
			this.state.screenType === "create-membership" ||
			this.state.screenType == "edit-membership"
		) {
			let paymentTypeDisplay = null,
				paymentTypeOptionsDisplay = null,
				trialPeriodDisplay = null,
				priceDisplay = null,
				totalPaymentsDisplay = null;
			let upgradePathsBtn = null;
			let teamCoaches = [];
			let defaultCoaches = [];

			if (this.state.screenType == "edit-membership")
				upgradePathsBtn = (
					<div className="form-group">
						<a
							className="button"
							onClick={(e) =>
								this.changeScreen(
									e,
									"upgrade-paths",
									this.state.membership._id
								)
							}
						>
							Upgrade Paths *
						</a>
					</div>
				);
			if (this.state.membership.type === "free") {
				paymentTypeDisplay = (
					<div className="form-group">
						<label
							className="form-label"
							htmlFor="formMembershipPaymentType"
						>
							Access Type *
						</label>
						<Form.Control
							as="select"
							id="formMembershipPaymentType"
							onChange={this.handleMembershipPaymentType}
							value={this.state.membership.paymentType}
						>
							<option value="permanent">Permanent access</option>
							<option value="finite">Finite access</option>
							<option value="range">Date range access</option>
						</Form.Control>
					</div>
				);
			} else if (this.state.membership.type === "paid") {
				paymentTypeDisplay = (
					<div className="form-group">
						<label
							className="form-label"
							htmlFor="formMembershipPaymentType"
						>
							Payment Type *
						</label>
						<Form.Control
							id="formMembershipPaymentType"
							as="select"
							onChange={this.handleMembershipPaymentType}
							value={this.state.membership.paymentType}
						>
							<option value="permanent">
								One payment for permanent access
							</option>
							<option value="finite">
								One payment for finite access
							</option>
							<option value="range">
								One payment for date range access
							</option>
							<option value="recurring">
								Recurring payments
							</option>
						</Form.Control>
					</div>
				);
				priceDisplay = (
					<div>
						<div className="form-group">
							<label
								className="form-label"
								htmlFor="formMembershipPrice"
							>
								Price
							</label>
							<input
								placeholder=""
								type="number"
								step="1"
								min="0"
								id="formMembershipPrice"
								className="form-control"
								value={this.state.membership.price}
								onChange={this.handleMembershipPrice}
							/>
						</div>
						<div className="form-group">
							<label
								className="form-label"
								htmlFor="formMembershipDiscountPrice"
							>
								Discounted Price
							</label>
							<input
								placeholder=""
								type="number"
								step="1"
								min="0"
								id="formMembershipDiscountPrice"
								className="form-control"
								value={this.state.membership.discountPrice}
								onChange={this.handleMembershipDiscountPrice}
							/>
						</div>
					</div>
				);
			}

			if (this.state.membership.paymentType === "recurring")
				totalPaymentsDisplay = (
					<div className="form-group">
						<label
							className="form-label"
							htmlFor="formMembershipTotalPayment"
						>
							Total Payments (0 = unlimited)
						</label>
						<input
							placeholder=""
							type="number"
							step="1"
							min="0"
							id="formMembershipTotalPayment"
							className="form-control"
							value={this.state.membership.totalPayments}
							onChange={this.handleMembershipTotalPayments}
						/>
					</div>
				);

			if (
				this.state.membership.paymentType === "finite" ||
				this.state.membership.paymentType === "recurring"
			)
				paymentTypeOptionsDisplay = (
					<div className="form-group">
						<label
							className="form-label"
							htmlFor="formMembershipInterval"
						>
							Grant access for
						</label>
						<div className="row">
							<div className="col">
								<div className="form-group">
									<input
										placeholder=""
										type="number"
										step="1"
										min="1"
										id="formMembershipInterval"
										className="form-control"
										value={this.state.membership.interval}
										onChange={this.handleMembershipInterval}
									/>
								</div>
							</div>
							<div className="col pl-0">
								<div className="form-group">
									<Form.Control
										as="select"
										onChange={
											this.handleMembershipIntervalType
										}
										value={
											this.state.membership.intervalType
										}
									>
										<option value="days">
											{this.state.membership.interval <= 1
												? "day"
												: "days"}
										</option>
										<option value="weeks">
											{this.state.membership.interval <= 1
												? "week"
												: "weeks"}
										</option>
										<option value="months">
											{this.state.membership.interval <= 1
												? "month"
												: "months"}
										</option>
										<option value="years">
											{this.state.membership.interval <= 1
												? "year"
												: "years"}
										</option>
									</Form.Control>
								</div>
							</div>
						</div>
						{totalPaymentsDisplay}
					</div>
				);
			else if (this.state.membership.paymentType === "range")
				paymentTypeOptionsDisplay = (
					<div className="form-group">
						<div className="row">
							<div className="col">
								<div className="form-group">
									<label
										className="form-label"
										htmlFor="formMembershipStart"
									>
										Start Date
									</label>

									<DateTimePicker
										placeholder="Start Date"
										format="yyyy-MM-dd"
										required
										id="formEventStart"
										className="form-control"
										value={this.state.membership.startDate}
										inputProps={{
											readOnly: true, // Disable direct input
										}}
										onChange={(newDate) => {
											const endDate =
												this.state.membership.endDate;

											// Compare the selected date with the maximum date
											if (newDate <= maxDate) {
												this.setState({
													membership: {
														...this.state
															.membership,
														startDate: newDate,
														endDate:
															endDate < newDate
																? newDate
																: endDate,
														startDateSelected: true, // Flag to indicate start date is selected
													},
												});
											} else {
												// If selected date is beyond the max date, set to today's date
												this.setState({
													membership: {
														...this.state
															.membership,
														startDate: new Date(),
														endDate:
															endDate < new Date()
																? new Date()
																: endDate, // Update end date if needed
													},
												});
											}
										}}
										minDate={new Date()} // Set the max value to the current date
										maxDate={maxDate} // Set the maximum value to today + 30 years
									/>
								</div>
							</div>
							<div className="col">
								<div className="form-group">
									<label
										className="form-label"
										htmlFor="formMembershipEnd"
									>
										End Date
									</label>

									<DateTimePicker
										placeholder="End Date"
										format="yyyy-MM-dd"
										required
										id="formEventEnd"
										className="form-control"
										value={this.state.membership.endDate}
										inputProps={{
											readOnly: true, // Disable direct input
										}}
										onChange={(newDate) => {
											// Check if start date is selected before updating end date
											if (
												this.state.membership
													.startDateSelected
											) {
												if (newDate <= maxDate) {
													this.setState({
														membership: {
															...this.state
																.membership,
															endDate: newDate,
														},
													});
												} else {
													// If selected date is beyond the max date, set to today's date
													this.setState({
														membership: {
															...this.state
																.membership,
															endDate: new Date(),
														},
													});
												}
											} else {
												// Show an alert or message to select start date first
												alert(
													"Please select the start date first."
												);
											}
										}}
										minDate={
											this.state.membership.startDate
										} // Set the min value to the selected start date
										maxDate={maxDate} // Set the maximum value to today + 30 years
									/>
								</div>
							</div>
						</div>
					</div>
				);

			if (this.state.membership.trial)
				trialPeriodDisplay = (
					<div className="form-group">
						<label
							className="form-label"
							htmlFor="formMembershipTrialPeriod"
						>
							Free Trial
						</label>
						<div className="row">
							<div className="col">
								<div className="form-group">
									<input
										placeholder=""
										type="number"
										step="1"
										min="1"
										id="formMembershipTrialPeriod"
										className="form-control"
										value={
											this.state.membership.trialPeriod
										}
										onChange={
											this.handleMembershipTrialPeriod
										}
									/>
								</div>
							</div>
							<div className="col pl-0">
								<div className="form-group">
									<Form.Control
										as="select"
										onChange={
											this.handleMembershipTrialType
										}
										value={this.state.membership.trialType}
									>
										<option value="days">
											{this.state.membership
												.trialPeriod <= 1
												? "day"
												: "days"}
										</option>
										<option value="weeks">
											{this.state.membership
												.trialPeriod <= 1
												? "week"
												: "weeks"}
										</option>
										<option value="months">
											{this.state.membership
												.trialPeriod <= 1
												? "month"
												: "months"}
										</option>
										<option value="years">
											{this.state.membership
												.trialPeriod <= 1
												? "year"
												: "years"}
										</option>
									</Form.Control>
								</div>
							</div>
						</div>
					</div>
				);

			if (this.state.coaches.length > 0) {
				let coaches = this.state.coaches;
				for (let i = 0; i < coaches.length; i++) {
					teamCoaches.push({
						value: coaches[i]._id,
						label:
							coaches[i].firstName +
							" " +
							coaches[i].lastName +
							" (" +
							coaches[i].email +
							")",
					});
					if (
						this.state.membership.coaches.includes(coaches[i]._id)
					) {
						defaultCoaches.push(teamCoaches[i]);
					}
				}
			}

			return (
				<div className="outbx">
					<Loader loading={this.state.loading} />

					<div className="teams-container">
						<a
							href=""
							className="backbtn"
							onClick={(e) =>
								this.changeScreen(e, "memberships", "")
							}
						>
							{" "}
						</a>
						{this.state.screenType === "create-membership" && (
							<h6>Create Membership</h6>
						)}
						{this.state.screenType === "edit-membership" && (
							<h6>Edit Membership</h6>
						)}
					</div>

					{!isMobile && (
						<LeftSideBarTeam
							team={this.state.team}
							isAdmin={true}
							isAdminOrJoined={true}
						/>
					)}
					<div className="boxmenu mmbr crtevnt">
						<form>
							<div className="form-group">
								<label
									className="form-label"
									htmlFor="formMembershipName"
								>
									Membership Name *
								</label>
								<input
									placeholder="Membership Name *"
									type="text"
									id="formMembershipName"
									className="form-control"
									value={this.state.membership.title}
									onChange={this.handleMembershipTitle}
								/>
							</div>
							<div className="form-group">
								<label
									className="form-label"
									htmlFor="formEventAbout"
								>
									Description *
								</label>
								<textarea
									rows="3"
									id="formEventAbout"
									placeholder="Description *"
									className="form-control"
									onChange={this.handleMembershipDescription}
									value={this.state.membership.description}
								></textarea>
							</div>
							<div className="form-group">
								<label
									className="form-label"
									htmlFor="formMembershipPublic"
								>
									Is Public *
								</label>
								<Form.Control
									id="formMembershipPublic"
									as="select"
									onChange={this.handleMembershipPublic}
									value={this.state.membership.isPublic}
								>
									<option value="yes">Yes</option>
									<option value="no">No</option>
								</Form.Control>
							</div>
							<div className="form-group">
								<label
									className="form-label"
									htmlFor="formMembershipPaid"
								>
									Is Paid *
								</label>
								<Form.Control
									id="formMembershipPaid"
									as="select"
									onChange={this.handleMembershipType}
									value={this.state.membership.type}
								>
									<option value="free">Free</option>
									<option value="paid">Paid</option>
								</Form.Control>
							</div>
							{paymentTypeDisplay}
							{paymentTypeOptionsDisplay}
							<Form.Group controlId="addMembershipTrial">
								<Form.Check custom type="switch">
									<Form.Check.Input
										checked={this.state.membership.trial}
										onChange={this.handleMembershipTrial}
									/>
									<Form.Check.Label>
										<span className="textadj">
											Allow Free Trial
										</span>
									</Form.Check.Label>
								</Form.Check>
							</Form.Group>
							{trialPeriodDisplay}
							{priceDisplay}

							<div className="form-group">
								<label
									className="form-label"
									htmlFor="formMembershipCoaches"
								>
									Coaches
								</label>
								<Select
									defaultValue={defaultCoaches}
									isMulti
									options={teamCoaches}
									onChange={this.handleCoachChange}
								/>
							</div>

							<TabToRemove
								title={"Cover Photo *"}
								tabTo={"Cover Photo Tab to remove"}
								removePhoto={this.removePhoto}
								logo={this.state.membership.logo}
								upload={this.state.upload}
							/>
							<div className="form-group">
								<a
									className="button"
									onClick={() =>
										this.openUploadMembershipLogo()
									}
								>
									Upload Photo
								</a>
								<input
									type="file"
									ref={this.uploadMembershipLogoRef}
									onChange={() => this.uploadMembershipLogo()}
									style={{ display: "none" }}
								/>
							</div>
							{upgradePathsBtn}

							{this.state.screenType === "create-membership" && (
								<div className="form-group">
									<LoadButton
										submitting={this.state.loading}
										// disabled={this.state.loading}
										title={"Create Membership"}
										btnClass={"button subbtn btn-save"}
										titleClass={"text-white"}
										onClick={this.addMembership}
									/>
								</div>
							)}
							{this.state.screenType === "edit-membership" && (
								<div className="form-group">
									{/* <a
										className="button subbtn"
										onClick={(e) =>
											this.updateMembership(e)
										}
									>
										Update Membership
									</a> */}
									<LoadButton
										submitting={this.state.loading}
										// disabled={this.state.loading}
										title={"Update Membership"}
										btnClass={"button subbtn btn-save"}
										titleClass={"text-white"}
										onClick={this.updateMembership}
									/>
								</div>
							)}
						</form>
					</div>
				</div>
			);
		} else if (this.state.screenType === "upgrade-paths") {
			return (
				<div className="outbx">
					<div className="teams-container">
						<a
							href=""
							className="backbtn"
							onClick={(e) => {
								e.preventDefault();
								this.setState({
									screenType: "edit-membership",
								});
							}}
						>
							{" "}
						</a>
						<h6>Upgrade Paths</h6>
					</div>
					<div className="boxmenu">
						<h6>
							Here you can define which members are allowed to
							subscribe to '{this.state.membership.title}'
						</h6>
						<form>
							<div className="club-box">
								<div className="row">
									<Form.Group controlId="subscribeWithoutMembership">
										<Form.Check custom type="switch">
											<Form.Check.Input
												checked={
													this.state.membership
														.subscribeWithoutMembership
												}
												onChange={
													this
														.handleSubscribeWithoutMembership
												}
											/>
											<Form.Check.Label>
												<span className="textadj">
													Users without membership can
													subscribe
												</span>
											</Form.Check.Label>
										</Form.Check>
									</Form.Group>
								</div>
							</div>
							{this.state.memberships.map((membership) => {
								if (
									this.state.membership._id === membership._id
								) {
									return null;
								} else {
									let updateDenied =
										this.state.membership.updateDenied.includes(
											membership._id
										);
									let updateReplaceDisabled = false;
									if (updateDenied)
										updateReplaceDisabled = true;
									return (
										<div
											key={
												"membership-upgrade-" +
												membership._id
											}
											className=""
										>
											<div className="">
												<Dropdown.Divider
													style={{ width: "100%" }}
												/>
												<Form.Group
													controlId={
														"subscribeWithMembership" +
														membership._id
													}
												>
													<Form.Check
														custom
														type="switch"
													>
														<Form.Check.Input
															checked={
																!updateDenied
															}
															onChange={(e) =>
																this.handleCanSubscribe(
																	e,
																	membership._id
																)
															}
														/>
														<Form.Check.Label>
															<span className="textadj">
																Members of '
																{
																	membership.title
																}
																' can subscribe
															</span>
														</Form.Check.Label>
													</Form.Check>
												</Form.Group>
												<Form.Group
													controlId={
														"cancelMembership" +
														membership._id
													}
												>
													<Form.Check
														custom
														type="switch"
													>
														<Form.Check.Input
															disabled={
																updateReplaceDisabled
															}
															checked={this.state.membership.updateReplace.includes(
																membership._id
															)}
															onChange={(e) =>
																this.handleCancelMembership(
																	e,
																	membership._id
																)
															}
														/>
														<Form.Check.Label>
															<span className="textadj">
																Cancel '
																{
																	membership.title
																}
																' on
																subscription
															</span>
														</Form.Check.Label>
													</Form.Check>
												</Form.Group>
											</div>
										</div>
									);
								}
							})}
						</form>
						<div className="form-group">
							{/* <a
								className="button subbtn"
								onClick={(e) => this.saveUpgradePaths(e)}
								disabled={this.state.loading} // Disable the button when loading is true
							>
								Update Upgrade Paths
							</a> */}
							<LoadButton
								submitting={this.state.loading}
								// disabled={this.state.loading}
								title={"Update Upgrade Paths"}
								btnClass={"button subbtn btn-save"}
								titleClass={"text-white"}
								onClick={this.saveUpgradePaths}
							/>
						</div>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		token: state.auth.token,
	};
};

export default connect(mapStateToProps, {})(TeamMembershipsAdmin);
