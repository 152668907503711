import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";

import {
	getServerUrl,
	getAllFollowersData,
	getAllFollowingUserData,
	updateUnfollowAct,
	updateFollowAct,
} from "../utils/api";

import profileBlank from "../assets/profile_blank.png";
import Loader from "../components/common/loader";
import { updateUserFollow } from "../actions";

const UserFollowers = ({ match, user, updateUserFollow, history }) => {
	const [followers, setFollowers] = useState([]);
	const [following, setFollowing] = useState([]);
	const [loading, setLoading] = useState(true);
	const [updating, setUpdating] = useState(false);

	useEffect(() => {
		loadData(match.params.tabKey);
	}, [match.params.tabKey]);

	const loadData = (tabKey) => {
		setLoading(true);

		if (tabKey === "followers") {
			getAllFollowersData(match.params.userid).then((followers) => {
				setFollowers(followers);
				setLoading(false);
			});
		} else if (tabKey === "following") {
			getAllFollowingUserData(match.params.userid).then((following) => {
				setFollowing(following);
				setLoading(false);
			});
		}
	};

	const followUser = async (followedUserId) => {
		if (updating) return;

		try {
			setUpdating(true);
			const res = await updateFollowAct(user.userId, followedUserId);
			if (res.success) {
				if (match.params.userid === user.userId) {
					setFollowing((prevFollowing) => [
						...prevFollowing,
						res.followedUser,
					]);
				}
				updateUserFollow(res.user.following);
			}
		} catch (error) {
			console.error("Error following user:", error);
		} finally {
			setUpdating(false);
		}
	};

	const unfollowUser = async (unfollowedUserId, index) => {
		if (updating) return;

		try {
			setUpdating(true);
			if (match.params.userid === user.userId) {
				setFollowing((prevFollowing) =>
					prevFollowing.filter(
						(followedUser) =>
							followedUser.userId !== unfollowedUserId
					)
				);
			}

			const res = await updateUnfollowAct(user.userId, unfollowedUserId);
			if (res.success) {
				updateUserFollow(res.user.following);
			}
		} catch (error) {
			console.error("Error unfollowing user:", error);
		} finally {
			setUpdating(false);
		}
	};

	return (
		<div className="outbx follow">
			<div className="teams-container">
				<a
					onClick={() =>
						history.push(`/profile/${match.params.userid}`)
					}
					className="backbtn"
				></a>
				<h6>{match.params.userid}</h6>
			</div>

			<Loader loading={loading} />

			<Tabs
				fill
				defaultActiveKey={match.params.tabKey}
				id="uncontrolled-tab-user-followers"
				onSelect={(k) => loadData(k)}
			>
				<Tab eventKey="followers" title="Followers">
					<div className="main-container">
						{followers.length > 0 ? (
							followers.map((follower, index) => (
								<div
									key={`followers-${follower.userId}-${index}`}
									className="club-box"
								>
									<div className="row">
										<div className="col-2 pr-0">
											<img
												style={{
													borderRadius: "84px",
												}}
												src={
													follower.profilePicture
														? `${
																getServerUrl()
																	.apiURL
														  }/uploads/user/${
																follower.profilePicture
														  }`
														: profileBlank
												}
											/>
										</div>

										<div className="col-7 pl-0">
											<Link
												to={`/profile/${follower.userId}`}
											>
												<h5>{`${follower.firstName} ${follower.lastName}`}</h5>
											</Link>
										</div>
										{user.userId !== follower.userId &&
											!user.following.includes(
												follower.userId
											) && (
												<div className="col-3 text-right pr-2">
													<a
														onClick={() =>
															followUser(
																follower.userId
															)
														}
														className="btn"
													>
														Follow
													</a>
												</div>
											)}
									</div>
								</div>
							))
						) : (
							<p className="feed-container-empty">No Followers</p>
						)}
					</div>
				</Tab>
				<Tab eventKey="following" title="Following">
					<div className="main-container">
						{following.length > 0 ? (
							following.map((followedUser, index) => (
								<div
									key={`following-${followedUser.userId}-${index}`}
									className="club-box"
								>
									<div className="row">
										<div className="col-2">
											<img
												style={{
													borderRadius: "84px",
												}}
												src={
													followedUser.profilePicture
														? `${
																getServerUrl()
																	.apiURL
														  }/uploads/user/${
																followedUser.profilePicture
														  }`
														: profileBlank
												}
											/>
										</div>

										<div className="col-7 pl-0">
											<Link
												to={`/profile/${followedUser.userId}`}
											>
												<h5>{`${followedUser.firstName} ${followedUser.lastName}`}</h5>
											</Link>
										</div>

										{user.following.includes(
											followedUser.userId
										) ? (
											<div
												className="col-3"
												style={{ paddingLeft: "0px" }}
											>
												<a
													onClick={() =>
														unfollowUser(
															followedUser.userId,
															index
														)
													}
													className="btn"
												>
													Unfollow
												</a>
											</div>
										) : (
											<div className="col-3">
												<a
													onClick={() =>
														followUser(
															followedUser.userId
														)
													}
													className="btn"
												>
													Follow
												</a>
											</div>
										)}
									</div>
								</div>
							))
						) : (
							<p className="feed-container-empty">No Following</p>
						)}
					</div>
				</Tab>
			</Tabs>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateUserFollow: (following) => dispatch(updateUserFollow(following)),
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(UserFollowers)
);
