import React, { useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const OTPChecker = ({ otp, email, handleResendOTP }) => {
	const history = useHistory();

	const [timerCount, setTimer] = useState(5);
	const [OTPinput, setOTPinput] = useState(["", "", "", ""]);
	const [disable, setDisable] = useState(true);
	const inputRefs = useRef([]);

	// Function to focus the next input field
	const focusNextInput = (index) => {
		if (inputRefs.current[index + 1]) {
			inputRefs.current[index + 1].focus();
		}
	};

	// Function to focus the previous input field
	const focusPreviousInput = (index) => {
		if (inputRefs.current[index - 1]) {
			inputRefs.current[index - 1].focus();
		}
	};

	const resendOTP = async () => {
		if (disable) return;
		try {
			await handleResendOTP(); // Call the handleResendOTP function passed from the parent component
			setDisable(true);
			setTimer(60);
			alert("A new OTP has successfully been sent to your email.");
		} catch (error) {
			console.log("error", error);
		}
	};
	const verifyOTP = (e) => {
		e.preventDefault();
		if (parseInt(OTPinput.join("")) === otp) {
			history.push("/new-password", { email });
			return;
		}
		alert(
			"The code you have entered is not correct, try again or re-send the link"
		);
		return;
	};
	function handleKeyPress(event, index) {
		if (event.key === "Backspace" && OTPinput[index] === "") {
			// If Backspace is pressed and the input field is empty, focus the previous input field
			focusPreviousInput(index);
		}
	}

	React.useEffect(() => {
		let interval = setInterval(() => {
			setTimer((lastTimerCount) => {
				lastTimerCount <= 1 && clearInterval(interval);
				if (lastTimerCount <= 1) setDisable(false);
				if (lastTimerCount <= 0) return lastTimerCount;
				return lastTimerCount - 1;
			});
		}, 1000);
		return () => clearInterval(interval);
	}, [disable]);
	const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

	return (
		<div className="outbx">
			<div className={`loginbx ${isiOS ? "ios-m" : ""}`}>
				<img src="/uploads/images/grey-logo.png" alt="Logo" />
				<h3>
					<i>Enter OTP</i>
				</h3>
			</div>
			<div className="loginbxin">
				<form onSubmit={verifyOTP}>
					<div className="row justify-content-center mb-4">
						{OTPinput.map((value, index) => (
							<div
								key={index}
								className="col-2"
								style={{ padding: "0px" }}
							>
								<input
									maxLength="1"
									className="form-control text-center"
									style={{
										border: "2px solid #d3d3d3",
										width: "40px",
									}}
									type="text"
									name=""
									id=""
									value={value}
									ref={(el) =>
										(inputRefs.current[index] = el)
									}
									onChange={(e) => {
										const updatedOTP = [...OTPinput];
										updatedOTP[index] = e.target.value;
										setOTPinput(updatedOTP);
										if (
											e.target.value !== "" &&
											index < 3
										) {
											focusNextInput(index);
										}
									}}
									onKeyDown={(e) => handleKeyPress(e, index)}
								/>
							</div>
						))}
					</div>
					<div className="form-group">
						<Button variant="primary" type="submit">
							Verify Account
						</Button>
					</div>
				</form>

				<div className="text-center mt-3">
					<p className="text-muted">
						{disable
							? `Resend OTP in ${timerCount}s`
							: "Didn't receive code?"}
						<a
							className={`ml-1 ${
								disable ? "text-muted" : "text-primary"
							}`}
							style={{
								cursor: disable ? "none" : "pointer",
								textDecorationLine: disable
									? "none"
									: "underline",
							}}
							onClick={() => resendOTP()}
						>
							{disable ? "" : "Resend OTP"}
						</a>
					</p>
				</div>
				<div
					className="frgtbxin"
					style={{ justifyContent: "space-around" }}
				>
					{/* <Link to="/signInUser">Go back</Link> */}
				</div>
			</div>
		</div>
	);
};

export default OTPChecker;
