import React from "react";
import Alert from "react-bootstrap/Alert";

const AlertBox = ({ status, message }) => {
	return (
		<>
			<Alert key={status} variant={status}>
				{message}
			</Alert>
		</>
	);
};

export default AlertBox;
