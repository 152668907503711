/**
 * =====================================
 * REACT COMPONENT CLASS
 * =====================================
 * @date created: 10 October 2019
 * @authors: Fatemeh Fatih, Waqas Rehmani
 *
 * This file defines the Search component. The class Search
 * is where the component is defined.
 *
 * This is a component defined for reusability.
 *
 * This component displays the search functionality.
 *
 */

// Importing libraries for setup
import React, { Component } from "react";
import { connect } from "react-redux";

// Importing helper functions
import { withRouter } from "react-router-dom";
import {
	searchUsers,
	searchPosts,
	searchEvents,
	searchGroups,
	searchTeams,
	getFollowerData,
} from "../utils/api";

// Importing icons and pictures
import loading from "../assets/loading2.svg";

import SearchResult from "./SearchResult";
import ClearSearch from "../components/common/clearSearch";

class Search extends Component {
	// Constructor Search
	constructor(props) {
		super(props);

		this.state = {
			query: "",
			searchTarget: "posts",
			typing: false,
			typingTimeout: 0,
			visible: false,
			dataSource: [],
			searchLoading: false,
			searchVisible: false,
			usersLoading: true,
			postsLoading: true,
			questionsLoading: true,
			eventsLoading: true,
			groupsLoading: true,
			teamsLoading: true,
			postsProfilePicturesLoading: true,
			questionsProfilePicturesLoading: true,
			isSearchEntered: false,
			users: [],
			posts: [],
			generalPostsUPP: [],
			questions: [],
			questionsUPP: [],
			profilePictureKV: {},
			fullNameKV: {},
			events: [],
			groups: [],
			teams: [],
			history: [],
			usersFollowStatus: [],
		};
	}

	componentDidMount() {
		this.recoverHistory();

		//if (!window.matchMedia('(max-width: 500px)').matches) {
		const urlParams = new URLSearchParams(window.location.search);
		let searchTarget = this.props.location.pathname.replace("/search/", "");
		const query = urlParams.get("query");
		if (this.props.location.pathname !== "/search" && searchTarget)
			this.setState({ query, searchTarget, isSearchEntered: true });
		this.handleDesktopSearch(query);
		//}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.activityFilter !== this.props.activityFilter ||
			prevProps.user !== this.props.user ||
			prevProps.locationSearchFilter !== this.props.locationSearchFilter
		) {
			this.handleSearch();
		}
	}

	getUsersFollowStatus = (users) => {
		let usersFollowStatus = [];
		if (users.length > 0) {
			for (const user of users) {
				usersFollowStatus.push(
					user.userId !== this.props.user.userId &&
						user.followers.includes(this.props.user.userId)
				);
			}
		}
		return usersFollowStatus;
	};

	handleChange = (event) => {
		if (event.target.value === "") {
			this.setState({
				query: event.target.value,
				typing: false,
				searchLoading: false,
				searchVisible: false,
				users: [],
				usersFollowStatus: [],
				postsProfilePicturesLoading: false,
				questionsProfilePicturesLoading: false,
				profilePictureKV: {},
				fullNameKV: {},
				posts: [],
				questions: [],
				events: [],
				groups: [],
				teams: [],
				usersLoading: false,
				postsLoading: false,
				questionsLoading: false,
				eventsLoading: false,
				groupsLoading: false,
				teamsLoading: false,
			});
		} else {
			let self = this;
			event.preventDefault();

			this.props.history.push({
				pathname: this.props.pathname,
				search: `?query=${event.target.value}`,
			});

			if (this.state.typingTimeout) {
				clearTimeout(this.state.typingTimeout);
			}

			this.setState({
				query: event.target.value,
				typing: false,
				searchLoading: true,
				searchVisible: true,
				typingTimeout: setTimeout(function () {
					self.handleSearch();
				}, 1800),
			});
		}
	};

	handleSearchPress = (e, query) => {
		e.preventDefault();
		// only enable search other than empty string
		if (query) {
			this.saveToStorage(query);
			this.setState({ query });
			this.props.history.push({
				pathname: this.props.pathname,
				search: `?query=${this.state.query}`,
			});
		}
	};

	// Invokes search on all types of entities involved in this webapp.
	handleSearch = () => {
		this.setState(
			{
				searchVisible: true,
				searchLoading: true,
				usersLoading: true,
				postsLoading: true,
				questionsLoading: true,
				eventsLoading: true,
				groupsLoading: true,
				teamsLoading: true,
			},
			() => {
				if (this.state.query === "") {
					this.setState({
						users: [],
						usersFollowStatus: [],
						postsProfilePicturesLoading: false,
						questionsProfilePicturesLoading: false,
						profilePictureKV: {},
						fullNameKV: {},
						posts: [],
						questions: [],
						events: [],
						groups: [],
						teams: [],
						usersLoading: false,
						postsLoading: false,
						questionsLoading: false,
						eventsLoading: false,
						groupsLoading: false,
						teamsLoading: false,
						searchLoading: false,
					});
				} else {
					searchUsers({
						query: this.state.query,
						activityFilter: this.props.activityFilter,
						location: this.props.locationSearchFilter,
					}).then((res) => {
						let usersFollowStatus = this.getUsersFollowStatus(
							res.users
						);
						this.setState({
							users: res.users,
							usersFollowStatus,
							usersLoading: false,
						});
					});

					searchPosts({
						query: this.state.query,
						activityFilter: this.props.activityFilter,
						location: this.props.locationSearchFilter,
					}).then((posts) => {
						// get all general posts and sort by date
						const generalPosts = posts.filter(
							(postObj) => postObj.post.isQuestion === false
						);

						generalPosts.sort(
							(a, b) => Date.parse(b.time) - Date.parse(a.time)
						);

						// to store profile picture value for posts/questions user
						const profilePictureKV = {};

						// to store full name for posts/questions user
						let fullNameKV = {};

						let generalPostsUPP = [];
						if (generalPosts.length === 0) {
							this.setState({
								postsProfilePicturesLoading: false,
							});
						} else {
							generalPosts.forEach((postObj) => {
								const { user } = postObj;

								// Get profile picture as value of post user (key)
								if (!(user.userId in profilePictureKV)) {
									if (user.profilePicture) {
										profilePictureKV[user.userId] =
											user.profilePicture;
									}
								}
								generalPostsUPP.push(user.profilePicture);

								// Get full name of users
								if (!(user.userId in fullNameKV)) {
									if (user.firstName && user.lastName) {
										const fullName =
											user.firstName +
											" " +
											user.lastName;
										fullNameKV[user.userId] = fullName;
									}
								}

								if (
									generalPosts.length ===
									generalPostsUPP.length
								) {
									this.setState({
										postsProfilePicturesLoading: false,
										profilePictureKV,
										fullNameKV,
									});
								}
							});
						}
						// get all questions and sort by date
						const questions = posts.filter(
							(postObj) => postObj.post.isQuestion === true
						);

						questions.sort(
							(a, b) => Date.parse(b.time) - Date.parse(a.time)
						);

						let questionsUPP = [];

						if (questions.length === 0) {
							this.setState({
								questionsProfilePicturesLoading: false,
							});
						} else {
							questions.forEach((postObj) => {
								const { post, user } = postObj;

								// get profile picture as value of question user (key)
								if (!(user.userId in profilePictureKV)) {
									if (user.profilePicture) {
										profilePictureKV[`${user.userId}`] =
											user.profilePicture;
									}
								}
								questionsUPP.push(user.profilePicture);

								// get full name of users
								if (!(user.userId in fullNameKV)) {
									if (user.firstName && user.lastName) {
										const fullName =
											user.firstName +
											" " +
											user.lastName;

										fullNameKV[user.userId] = fullName;
									}
								}

								if (questions.length === questionsUPP.length) {
									this.setState({
										questionsProfilePicturesLoading: false,
										profilePictureKV,
										fullNameKV,
									});
								}
							});
						}

						this.setState({
							posts: generalPosts,
							questions,
							postsLoading: false,
							questionsLoading: false,
						});
					});

					searchEvents({
						query: this.state.query,
						activityFilter: this.props.activityFilter,
						location: this.props.locationSearchFilter,
					}).then((res) => {
						this.setState({
							events: res,
							eventsLoading: false,
						});
					});

					searchGroups({
						query: this.state.query,
						activityFilter: this.props.activityFilter,
						location: this.props.locationSearchFilter,
					}).then((res) => {
						this.setState({
							groups: res,
							groupsLoading: false,
						});
					});

					searchTeams({
						query: this.state.query,
						activityFilter: this.props.activityFilter,
						location: this.props.locationSearchFilter,
					}).then((res) => {
						this.setState({
							teams: res,
							teamsLoading: false,
						});
					});

					this.setState({
						searchLoading: false,
					});
				}
			}
		);
	};

	handleRecentQuerySearch = (query) => {
		this.saveToStorage(query);

		searchUsers({
			query,
			activityFilter: this.props.activityFilter,
			location: this.props.locationSearchFilter,
		}).then((res) => {
			let usersFollowStatus = this.getUsersFollowStatus(res.users);
			this.setState({
				users: res.users,
				usersFollowStatus,
				usersLoading: false,
			});
		});

		searchPosts({
			query,
			activityFilter: this.props.activityFilter,
			location: this.props.locationSearchFilter,
		}).then((posts) => {
			// get all general posts and sort by date
			const generalPosts = posts.filter(
				(postObj) => postObj.post.isQuestion === false
			);

			generalPosts.sort(
				(a, b) => Date.parse(b.time) - Date.parse(a.time)
			);

			// to store profile picture value for posts/questions user
			const profilePictureKV = {};

			// to store full name for posts/questions user
			let fullNameKV = {};

			let generalPostsUPP = [];
			if (generalPosts.length === 0) {
				this.setState({
					postsProfilePicturesLoading: false,
				});
			} else {
				generalPosts.forEach((postObj) => {
					const { user } = postObj;

					// Get profile picture as value of post user (key)
					if (!(user.userId in profilePictureKV)) {
						if (user.profilePicture) {
							profilePictureKV[user.userId] = user.profilePicture;
						}
					}
					generalPostsUPP.push(user.profilePicture);

					// Get full name of users
					if (!(user.userId in fullNameKV)) {
						if (user.firstName && user.lastName) {
							const fullName =
								user.firstName + " " + user.lastName;
							fullNameKV[user.userId] = fullName;
						}
					}

					if (generalPosts.length === generalPostsUPP.length) {
						this.setState({
							postsProfilePicturesLoading: false,
							profilePictureKV,
							fullNameKV,
						});
					}
				});
			}
			// get all questions and sort by date
			const questions = posts.filter(
				(postObj) => postObj.post.isQuestion === true
			);

			questions.sort((a, b) => Date.parse(b.time) - Date.parse(a.time));

			let questionsUPP = [];

			if (questions.length === 0) {
				this.setState({
					questionsProfilePicturesLoading: false,
				});
			} else {
				questions.forEach((postObj) => {
					const { post, user } = postObj;

					// get profile picture as value of question user (key)
					if (!(user.userId in profilePictureKV)) {
						if (user.profilePicture) {
							profilePictureKV[`${user.userId}`] =
								user.profilePicture;
						}
					}
					questionsUPP.push(user.profilePicture);

					// get full name of users
					if (!(user.userId in fullNameKV)) {
						if (user.firstName && user.lastName) {
							const fullName =
								user.firstName + " " + user.lastName;

							fullNameKV[user.userId] = fullName;
						}
					}

					if (questions.length === questionsUPP.length) {
						this.setState({
							questionsProfilePicturesLoading: false,
							profilePictureKV,
							fullNameKV,
						});
					}
				});
			}

			this.setState({
				posts: generalPosts,
				questions,
				postsLoading: false,
				questionsLoading: false,
			});
		});

		searchEvents({
			query,
			activityFilter: this.props.activityFilter,
			location: this.props.locationSearchFilter,
		}).then((res) => {
			this.setState({
				events: res,
				eventsLoading: false,
			});
		});

		searchGroups({
			query,
			activityFilter: this.props.activityFilter,
			location: this.props.locationSearchFilter,
		}).then((res) => {
			this.setState({
				groups: res,
				groupsLoading: false,
			});
		});

		searchTeams({
			query,
			activityFilter: this.props.activityFilter,
			location: this.props.locationSearchFilter,
		}).then((res) => {
			this.setState({
				teams: res,
				teamsLoading: false,
			});
		});
	};

	handleDesktopSearch = (query) => {
		searchUsers({
			query,
			activityFilter: this.props.activityFilter,
			location: this.props.locationSearchFilter,
		}).then((res) => {
			let usersFollowStatus = this.getUsersFollowStatus(res.users);
			this.setState({
				users: res.users,
				usersFollowStatus,
				usersLoading: false,
			});
		});

		searchPosts({
			query,
			activityFilter: this.props.activityFilter,
			location: this.props.locationSearchFilter,
		}).then((posts) => {
			// get all general posts and sort by date
			const generalPosts = posts.filter(
				(postObj) => postObj.post.isQuestion === false
			);

			generalPosts.sort(
				(a, b) => Date.parse(b.time) - Date.parse(a.time)
			);

			// to store profile picture value for posts/questions user
			const profilePictureKV = {};

			// to store full name for posts/questions user
			let fullNameKV = {};

			let generalPostsUPP = [];
			if (generalPosts.length === 0) {
				this.setState({
					postsProfilePicturesLoading: false,
				});
			} else {
				generalPosts.forEach((postObj) => {
					const { user } = postObj;

					// Get profile picture as value of post user (key)
					if (!(user.userId in profilePictureKV)) {
						if (user.profilePicture) {
							profilePictureKV[user.userId] = user.profilePicture;
						}
					}
					generalPostsUPP.push(user.profilePicture);

					// Get full name of users
					if (!(user.userId in fullNameKV)) {
						if (user.firstName && user.lastName) {
							const fullName =
								user.firstName + " " + user.lastName;
							fullNameKV[user.userId] = fullName;
						}
					}

					if (generalPosts.length === generalPostsUPP.length) {
						this.setState({
							postsProfilePicturesLoading: false,
							profilePictureKV,
							fullNameKV,
						});
					}
				});
			}
			// get all questions and sort by date
			const questions = posts.filter(
				(postObj) => postObj.post.isQuestion === true
			);

			questions.sort((a, b) => Date.parse(b.time) - Date.parse(a.time));

			let questionsUPP = [];

			if (questions.length === 0) {
				this.setState({
					questionsProfilePicturesLoading: false,
				});
			} else {
				questions.forEach((postObj) => {
					const { post, user } = postObj;

					// get profile picture as value of question user (key)
					if (!(user.userId in profilePictureKV)) {
						if (user.profilePicture) {
							profilePictureKV[`${user.userId}`] =
								user.profilePicture;
						}
					}
					questionsUPP.push(user.profilePicture);

					// get full name of users
					if (!(user.userId in fullNameKV)) {
						if (user.firstName && user.lastName) {
							const fullName =
								user.firstName + " " + user.lastName;

							fullNameKV[user.userId] = fullName;
						}
					}

					if (questions.length === questionsUPP.length) {
						this.setState({
							questionsProfilePicturesLoading: false,
							profilePictureKV,
							fullNameKV,
						});
					}
				});
			}

			this.setState({
				posts: generalPosts,
				questions,
				postsLoading: false,
				questionsLoading: false,
			});
		});

		searchEvents({
			query,
			activityFilter: this.props.activityFilter,
			location: this.props.locationSearchFilter,
		}).then((res) => {
			this.setState({
				events: res,
				eventsLoading: false,
			});
		});

		searchGroups({
			query,
			activityFilter: this.props.activityFilter,
			location: this.props.locationSearchFilter,
		}).then((res) => {
			this.setState({
				groups: res,
				groupsLoading: false,
			});
		});

		searchTeams({
			query,
			activityFilter: this.props.activityFilter,
			location: this.props.locationSearchFilter,
		}).then((res) => {
			this.setState({
				teams: res,
				teamsLoading: false,
			});
		});
	};

	onBlur = () => {
		if (this.state.query === "") {
			this.setState({
				visible: false,
			});
		}
	};

	hideSearchResults = () => {
		this.setState({
			visible: false,
			searchVisible: false,
		});
	};

	showSearchResults = () => {
		if (this.state.query === "") {
			this.setState({
				visible: true,
			});
		} else {
			this.setState({
				visible: true,
				searchVisible: true,
			});
		}

		this.refs.inputField.focus();
	};

	goToProfile = (userId) => {
		this.setState({
			searchVisible: false,
		});
		this.props.history.push("/profile/" + userId);
	};

	goToPost = (postId) => {
		this.setState({
			searchVisible: false,
		});
		this.props.history.push("/post/" + postId);
	};

	goToEvent = (eventId) => {
		this.setState({
			searchVisible: false,
		});
		this.props.history.push("/events/" + eventId);
	};

	goToGroup = (groupId) => {
		this.setState({
			searchVisible: false,
		});
		this.props.history.push("/group/" + groupId);
	};

	goToTeam = (slug) => {
		this.setState({
			searchVisible: false,
		});
		this.props.history.push("/team/" + slug);
	};

	handleSearchTarget = (searchTarget) => {
		this.setState({ searchTarget });
	};

	recoverHistory = () => {
		const userId = localStorage.getItem("userData");
		if (!userId) {
			console.error(
				"User ID not found in localStorage. Unable to recover history."
			);
			return;
		}

		let history = JSON.parse(localStorage.getItem(`history_${userId}`));

		this.setState({ history: history ? history : [] });
	};

	saveToStorage = (input) => {
		const userId = localStorage.getItem("userData");
		if (!userId) {
			console.error(
				"User ID not found in localStorage. Unable to save history."
			);
			return;
		}

		// Fetch the existing search history for the user from localStorage
		const existingHistory =
			JSON.parse(localStorage.getItem(`history_${userId}`)) || [];

		// Remove old search input (same as the current input)
		let filteredInputs = [...existingHistory];
		filteredInputs = filteredInputs.filter(
			(prevInput) => prevInput !== input
		);

		const history = [input, ...filteredInputs];

		this.setState({
			history,
			query: "",
		});

		localStorage.setItem(`history_${userId}`, JSON.stringify(history));
	};

	handleSearchChange = (e) => {
		this.setState({ query: e.target.value });
	};

	handleKeyDown = (e) => {
		if (e.key === "Enter") {
			// only enable search other than empty string
			if (e.target.value) {
				this.saveToStorage(e.target.value);
				this.setState({ isSearchEntered: true, query: e.target.value });
				this.props.history.push({
					pathname: "/search/posts",
					search: `?query=${this.state.query}`,
				});
			}
		}
	};

	handleRecentSearchClick = (query) => {
		this.handleRecentQuerySearch(query);
		this.setState({ isSearchEntered: true, query });
		this.props.history.push({
			pathname: "/search/posts",
			search: `?query=${query}`,
		});
	};

	handleDelete = (index) => {
		let history = [...this.state.history];
		history.splice(index, 1);
		this.setState({ history }, () => {
			const userId = localStorage.getItem("userData");
			if (!userId) {
				console.error(
					"User ID not found in localStorage. Unable to update history."
				);
				return;
			}
			localStorage.setItem(`history_${userId}`, JSON.stringify(history));
		});

		return false;
	};

	clearHistory = () => {
		let history = [];

		this.setState({ history }, () => {
			const userId = localStorage.getItem("userData");
			if (!userId) {
				console.error(
					"User ID not found in localStorage. Unable to update history."
				);
				return;
			}
			localStorage.setItem(`history_${userId}`, JSON.stringify(history));
		});

		return false;
	};

	updateEventStatus = (event) => {
		let events = [];
		let oldEvents = this.state.events;
		for (let i = 0; i < oldEvents.length; i++) {
			if (oldEvents[i].eventId === event.eventId) {
				events.push(event);
			} else events.push(oldEvents[i]);
		}

		this.setState({ events });
	};

	// Render method for Search
	render() {
		if (!this.props.isMobile) {
			return (
				<>
					<div className="hdrsec">
						<div className="container">
							<div className="row">
								<div className="col-4 leftprt">
									<div className="row">
										<div className="col-6">
											<div className="logo">
												<img src="/uploads/images/logo.jpg" />
											</div>
										</div>
										<div className="col-6">
											<ul>
												<li>
													<a
														className={
															this.props.location
																.pathname ===
															"/home"
																? "selsec"
																: ""
														}
														onClick={() =>
															this.props.history.push(
																"/home"
															)
														}
													>
														<img src="/uploads/images/home.svg" />
														<span>Feed</span>
													</a>
												</li>
												<li>
													<a
														className={
															this.props.location
																.pathname ===
															"/trending"
																? "selsec"
																: ""
														}
														onClick={() =>
															this.props.history.push(
																"/trending"
															)
														}
													>
														<img src="/uploads/images/trending.svg" />
														<span>Trending</span>
													</a>
												</li>
												<li>
													<a
														className={
															this.props.location
																.pathname ===
															"/questions"
																? "selsec"
																: ""
														}
														onClick={() =>
															this.props.history.push(
																"/questions"
															)
														}
													>
														<img src="/uploads/images/questions.svg" />
														<span>Answers</span>
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>

								<div className="col-4 centerprt">
									<div className="form-group">
										<input
											type="text"
											list="searchHistoryList"
											className="form-control"
											placeholder="Search"
											value={this.state.query}
											onChange={this.handleChange}
											required
										/>
										<datalist id="searchHistoryList">
											{this.state.history.map((query) => (
												<option
													value={query}
													key={query}
												></option>
											))}
										</datalist>
										<button
											type="submit"
											onClick={(e) =>
												this.handleSearchPress(
													e,
													this.state.query
												)
											}
											className={`${
												!this.state.query
													? "dislinks"
													: ""
											}`}
										>
											<img
												src="/uploads/images/search.png"
												className={`${
													!this.state.query
														? "blur"
														: ""
												}`}
											/>
										</button>
									</div>
								</div>

								<div className="col-4 leftprt">
									<ul>
										<li>
											<a href="#">
												<img src="/uploads/images/notification.svg" />
												<span>Alerts</span>
											</a>
										</li>
										<li>
											<a href="#">
												<img src="/uploads/images/messages.svg" />
												<span>Messages</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					{!this.state.searchLoading &&
					!this.state.usersLoading &&
					!this.state.postsLoading &&
					!this.state.postsProfilePicturesLoading &&
					!this.state.questionsLoading &&
					!this.state.questionsProfilePicturesLoading &&
					!this.state.eventsLoading &&
					!this.state.groupsLoading &&
					!this.state.teamsLoading ? (
						<SearchResult
							onHandleSearchTarget={this.handleSearchTarget}
							onQueryChange={this.handleChange}
							onQuerySearch={this.handleSearch}
							onEnterPressed={this.handleKeyDown}
							query={this.state.query}
							searchTarget={this.state.searchTarget}
							filteredUsers={this.state.users}
							filteredPosts={this.state.posts}
							filteredQuestions={this.state.questions}
							filteredEvents={this.state.events}
							filteredGroups={this.state.groups}
							filteredTeams={this.state.teams}
							usersLoading={this.state.usersLoading}
							postsLoading={this.state.postsLoading}
							questionsLoading={this.state.questionsLoading}
							eventsLoading={this.state.eventsLoading}
							groupsLoading={this.state.groupsLoading}
							teamsLoading={this.state.teamsLoading}
							goToProfile={this.goToProfile}
							goToEvent={this.goToEvent}
							goToGroup={this.goToGroup}
							goToTeam={this.goToTeam}
							profilePictureKV={this.state.profilePictureKV}
							fullNameKV={this.state.fullNameKV}
							usersFollowStatus={this.state.usersFollowStatus}
							openPictureViewer={this.props.openPictureViewer}
							updateEventStatus={this.updateEventStatus}
						/>
					) : (
						<div className="loader-content">
							<img width={35} height={35} src={loading} alt="" />
						</div>
					)}
				</>
			);
		} else {
			return (
				<>
					{!this.state.isSearchEntered ? (
						<>
							<div className="wrtpost srch">
								<div className="userthumb">
									<span>
										<img src="/uploads/images/search.png" />
									</span>

									<form>
										<input
											type="text"
											placeholder="Search something here..."
											onChange={this.handleChange}
											onKeyDown={this.handleKeyDown}
											value={this.state.query}
											required
										/>
									</form>
								</div>
							</div>
							{this.state.history.length > 0 && (
								<ClearSearch
									handleRecentSearchClick={
										this.handleRecentSearchClick
									}
									history={this.state.history}
									handleDelete={this.handleDelete}
									clearHistory={this.clearHistory}
								/>
							)}
						</>
					) : (
						<SearchResult
							onHandleSearchTarget={this.handleSearchTarget}
							onQueryChange={this.handleChange}
							onQuerySearch={this.handleSearch}
							onEnterPressed={this.handleKeyDown}
							query={this.state.query}
							searchTarget={this.state.searchTarget}
							filteredUsers={this.state.users}
							filteredPosts={this.state.posts}
							filteredQuestions={this.state.questions}
							filteredEvents={this.state.events}
							filteredGroups={this.state.groups}
							filteredTeams={this.state.teams}
							usersLoading={this.state.usersLoading}
							postsLoading={this.state.postsLoading}
							questionsLoading={this.state.questionsLoading}
							eventsLoading={this.state.eventsLoading}
							groupsLoading={this.state.groupsLoading}
							teamsLoading={this.state.teamsLoading}
							goToProfile={this.goToProfile}
							goToEvent={this.goToEvent}
							goToGroup={this.goToGroup}
							goToTeam={this.goToTeam}
							profilePictureKV={this.state.profilePictureKV}
							fullNameKV={this.state.fullNameKV}
							usersFollowStatus={this.state.usersFollowStatus}
							openPictureViewer={this.props.openPictureViewer}
							updateEventStatus={this.updateEventStatus}
						/>
					)}
				</>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		activityFilter: state.auth.activityFilter,
		locationSearchFilter: state.auth.locationSearchFilter,
	};
};

export default withRouter(connect(mapStateToProps)(Search));
