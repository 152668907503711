import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { getServerUrl, getTeamSocial } from "../utils/api";

class TeamGroups extends Component {
	constructor(props) {
		super(props);

		this.state = {
			team: { title: "", slug: "", description: "" },
			groups: [],
			loading: true,
		};
	}

	componentDidMount() {
		getTeamSocial(this.props.teamSlug, this.props.user._id).then((resp) => {
			if (resp.success == true) {
				this.setState({
					team: resp.team,
					groups: resp.groups,
					loading: false,
				});
			} else {
				alert("Error fetching data, please try again");
			}
		});
	}

	render() {
		return (
			<>
				<div className="teams-container">
					<Link
						to={"/team/" + this.state.team.slug}
						className="backbtn"
					>
						{" "}
					</Link>
					<h6>{this.state.team.title}</h6>
				</div>

				<div className="main-container">
					<h3 className="text-center">
						{this.state.team.groupsCount !== undefined
							? this.state.team.groupsCount === 0
								? "No Sub-Groups"
								: `${this.state.team.groupsCount} Sub-Groups`
							: ""}
					</h3>
					{this.state.groups.length > 0 &&
						this.state.groups.map((group, idx) => (
							<div className="" key={idx}>
								<div className="club-box">
									<div className="">
										<div className="row">
											<div className="col-2 pr-0">
												<Link
													to={"/group/" + group.slug}
												>
													<img
														src={
															group.logo
																? getServerUrl()
																		.apiURL +
																  "/uploads/group/" +
																  group.logo
																: "/uploads/images/group_default.png"
														}
														alt=""
													/>
												</Link>
											</div>
											<div className="col-7">
												<h5>
													<Link
														to={
															"/group/" +
															group.slug
														}
													>
														{group.title}
													</Link>
												</h5>
												<small>
													{group.membersCount} members
												</small>
											</div>
											<div className="col-3 pl-0 pr-1 text-right"></div>
										</div>
									</div>
								</div>
							</div>
						))}
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	};
};

export default withRouter(connect(mapStateToProps, {})(TeamGroups));
