import React, { useEffect, useState } from "react";
import {
	GoogleMap,
	withGoogleMap,
	Marker,
	InfoWindow,
} from "react-google-maps";
import Geocode from "react-geocode";
import keysConfig from "../../../config/keys";
import { getServerUrl } from "../../../utils/api";
import { assignTimeAgo, formatDateTime } from "../../../utils/helper";

Geocode.setApiKey(keysConfig.google.apiKey);
Geocode.enableDebug();

const MapWithPins = withGoogleMap(({ locations, google }) => {
	const [address, setAddress] = useState("");

	useEffect(() => {
		if (locations.length > 0) {
			Geocode.fromLatLng(locations[0].lat, locations[0].lng).then(
				(response) => {
					const address = response.results[0].formatted_address;
					setAddress(address ? address : "");
				},
				(error) => {
					console.error(error);
				}
			);
		}
	}, [locations]);

	return (
		<GoogleMap
			zoom={15}
			center={{ lat: 0, lng: 0 }}
			defaultOptions={{
				fullscreenControl: false,
				zoomControl: false,
				streetViewControl: false,
				scaleControl: false,
				rotateControl: false,
				panControl: false,
				mapTypeControl: false,
			}}
		>
			{console.log("loca", locations)}

			{locations.map((location, index) => (
				<Marker
					key={index}
					name={location.location}
					position={{
						lat: location.lat,
						lng: location.lng,
					}}
				>
					<InfoWindow
						key={index}
						position={{
							lat: location.lat + 0.0018,
							lng: location.lng,
						}}
					>
						<div className="popvrbx">
							<div className="usrtop">
								<div className="row">
									<div className="col-3">
										<div className="userthumb">
											<a className="userbx roundlogo">
												<img
													src={
														getServerUrl().apiURL +
														"/uploads/" +
														location.moduleType +
														"/" +
														location.moduleLogo
													}
												/>
											</a>
										</div>
									</div>
									<div className="col-6 pl-3 nopad pt-1">
										<a>{location.moduleTitle}</a>
										<span
											className="small pstim"
											style={{ marginTop: "5px" }}
										>
											{assignTimeAgo(location.time)}
										</span>{" "}
									</div>
									<div className="col-2">
										<span className="acttyp">
											<img
												src={
													"/uploads/images/" +
													location.interest.icon
												}
											/>
										</span>
									</div>
								</div>
							</div>
							<div className="mt-1 mb-1">
								<p>{location.title}</p>
							</div>
							<small>
								<span className="f12">
									<img
										width="20"
										src="/uploads/images/calendar.png"
									/>
									{`Start: ${formatDateTime(location.start)}`}
								</span>
							</small>
							<small>
								<span className="f12">
									<img
										width="20"
										src="/uploads/images/calendar.png"
									/>
									{`End:  ${formatDateTime(location.end)}`}
								</span>
							</small>

							<small>
								<img width="20" src="/uploads/images/pin.png" />{" "}
								<span className="txtblc f12">
									{location.location}
								</span>{" "}
							</small>
						</div>

						{/* <div>
							<span style={{ padding: "10px", margin: 0 }}>
								{address}
							</span>
						</div> */}
					</InfoWindow>
				</Marker>
			))}
		</GoogleMap>
	);
});

export default MapWithPins;
