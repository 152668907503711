/**
 * =====================================
 * REACT SCREEN COMPONENT CLASS
 * =====================================
 * @date created: 9 September 2019
 * @authors: Jay Parikh, Waqas Rehmani
 *
 * This file defines the Event screen component. The class Event
 * is where the component is defined. This is a screen component.
 *
 * This screen shows the event details.
 *
 */

// Importing libraries for setup
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactStars from "react-rating-stars-component";

// Importing other components
import NewPost from "../screens/NewPost";
import Feed from "../components/Feed";

// Importing libraries for map
import {
	GoogleMap,
	InfoWindow,
	Marker,
	withGoogleMap,
	withScriptjs,
} from "react-google-maps";
import Geocode from "react-geocode";
import { Redirect } from "react-router";

// Importing helper functions
import {
	getServerUrl,
	getEventDetail,
	getAllEventsPosts,
	editEventResponse,
	deleteEvent,
	deletePostsByEvent,
	editEventStatus,
	addEventRating,
} from "../utils/api";

// Importing icons and pictures
import {
	IoIosStar,
	IoIosStarOutline,
	IoMdCheckmarkCircle,
	IoMdCheckmarkCircleOutline,
} from "react-icons/io";
import loading from "../assets/loading.svg";
import Badminton from "../assets/Badminton.svg";
import Cycling from "../assets/Cycling.svg";
import Football from "../assets/Football.svg";
import Gym from "../assets/Gym.svg";
import Running from "../assets/Running.svg";
import Swimming from "../assets/Swimming.svg";
import Tennis from "../assets/Tennis.svg";
import Walking from "../assets/Walking.svg";
import Yoga from "../assets/Yoga.svg";
import { assignTimeAgo, formatDateTime } from "../utils/helper";
import Avatar from "../components/common/avatar";

const keysConfig = require("../config/keys");

Geocode.setApiKey(keysConfig.google.apiKey);
Geocode.enableDebug();

const pictureHelper = {
	Badminton: Badminton,
	Cycling: Cycling,
	Gym: Gym,
	Football: Football,
	Running: Running,
	Swimming: Swimming,
	Tennis: Tennis,
	Walking: Walking,
	Yoga: Yoga,
};

let dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
let monthNames = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

class Event extends Component {
	AsyncMap = withGoogleMap((props) => (
		<GoogleMap
			google={this.props.google}
			zoom={15}
			center={{
				lat: this.state.eventDetails.lat,
				lng: this.state.eventDetails.lng,
			}}
			defaultOptions={{
				fullscreenControl: false,
				zoomControl: false,
				streetViewControl: false,
				scaleControl: false,
				rotateControl: false,
				panControl: false,
				mapTypeControl: false,
			}}
		>
			<Marker
				google={this.props.google}
				name={this.state.eventDetails.title}
				position={{
					lat: this.state.eventDetails.lat,
					lng: this.state.eventDetails.lng,
				}}
			/>

			<InfoWindow
				position={{
					lat: this.state.eventDetails.lat + 0.0018,
					lng: this.state.eventDetails.lng,
				}}
			>
				<div>
					<span style={{ padding: 0, margin: 0 }}>
						{this.state.address}
					</span>
				</div>
			</InfoWindow>
		</GoogleMap>
	));

	constructor(props) {
		super(props);
		this.state = {
			eventDetails: {},
			address: "",
			eventPosts: [],
			loading: true,
			isShowDetails: true,
			redirect: false,
			teamStatus: "",
			groupStatus: "",
			isAdminOrJoined: "",
		};
	}

	componentDidMount() {
		this.setState({
			teamStatus: this.props.location.state?.teamStatus || "active",
			groupStatus: this.props.location.state?.groupStatus || "active",
			isAdminOrJoined: this.props.location.state?.isAdminOrJoined,
		});
		getEventDetail(this.props.eventId, this.props.user.userId).then(
			(eventDetails) => {
				if (eventDetails.eventId) {
					getAllEventsPosts(eventDetails.eventId).then(
						(eventPosts) => {
							this.setState({
								eventDetails,
								eventPosts,
								loading: false,
							});
							Geocode.fromLatLng(
								eventDetails.lat,
								eventDetails.lng
							).then(
								(response) => {
									const address =
										response.results[0].formatted_address;

									this.setState({
										address: address ? address : "",
									});
								},
								(error) => {
									console.error(error);
								}
							);
						}
					);
				}
			}
		);
	}

	// Contains API call to create a post.
	createPost = (newPost) => {
		let promise = new Promise((resolve, reject) => {
			this.props.createPost(newPost).then((post) => {
				if (post.postId) {
					let postToAdd = post;
					this.setState(
						(previousState) => {
							let sortedPosts = previousState.eventPosts;
							sortedPosts.push(postToAdd);
							sortedPosts.sort(
								(a, b) =>
									Date.parse(b.time) - Date.parse(a.time)
							);
							return {
								eventPosts: sortedPosts,
							};
						},
						() => {
							resolve(true);
						}
					);
				} else {
					reject(false);
				}
			});
		});

		return promise;
	};

	// Contains API call to delete a post.
	deletePost = (postId) => {
		let promise = new Promise((resolve, reject) => {
			this.props.deletePost(postId).then((post) => {
				if (post) {
					this.setState(
						(previousState) => ({
							eventPosts: previousState.eventPosts.filter(
								(p) => p.postId !== postId
							),
						}),
						() => {
							resolve(true);
						}
					);
				} else {
					reject(false);
				}
			});
		});
		return promise;
	};

	// Contains API call to change status of kudos.
	changeKudos = (postId, kudos) => {
		let promise = new Promise((resolve, reject) => {
			this.props.changeKudos(postId, kudos).then((post) => {
				if (post.postId) {
					this.setState(
						(previousState) => {
							let sortedPosts = previousState.eventPosts.filter(
								(p) => p.postId !== postId
							);
							sortedPosts.push(post);
							sortedPosts.sort(
								(a, b) =>
									Date.parse(b.time) - Date.parse(a.time)
							);
							return {
								eventPosts: sortedPosts,
							};
						},
						() => {
							resolve(true);
						}
					);
				} else {
					reject(false);
				}
			});
		});
		return promise;
	};

	// Contains API call for creating a comment.
	createComment = (postId, postComment) => {
		let promise = new Promise((resolve, reject) => {
			this.props
				.createComment(
					postId,
					postComment,
					this.props.user._id,
					this.props.user.userId
				)
				.then((post) => {
					if (post.postId) {
						this.setState(
							(previousState) => {
								let sortedPosts =
									previousState.eventPosts.filter(
										(p) => p.postId !== postId
									);
								sortedPosts.push(post);
								sortedPosts.sort(
									(a, b) =>
										Date.parse(b.time) - Date.parse(a.time)
								);
								return {
									eventPosts: sortedPosts,
								};
							},
							() => {
								resolve(true);
							}
						);
					} else {
						reject(false);
					}
				});
		});
		return promise;
	};

	// Updates interested/going accordingly.
	editEventResponse = (responseType) => {
		let editedEvent = this.state.eventDetails;
		if (responseType === "interested") {
			if (editedEvent.interested.includes(this.props.user.userId)) {
				editedEvent = {
					...editedEvent,
					interested: editedEvent.interested.filter(
						(i) => i !== this.props.user.userId
					),
				};
			} else {
				if (editedEvent.attending.includes(this.props.user.userId)) {
					editedEvent = {
						...editedEvent,
						attending: editedEvent.attending.filter(
							(i) => i !== this.props.user.userId
						),
						interested: [
							...editedEvent.interested,
							this.props.user.userId,
						],
					};
				} else {
					editedEvent = {
						...editedEvent,
						interested: [
							...editedEvent.interested,
							this.props.user.userId,
						],
					};
				}
			}
		} else {
			if (editedEvent.attending.includes(this.props.user.userId)) {
				editedEvent = {
					...editedEvent,
					attending: editedEvent.attending.filter(
						(i) => i !== this.props.user.userId
					),
				};
			} else {
				if (editedEvent.interested.includes(this.props.user.userId)) {
					editedEvent = {
						...editedEvent,
						interested: editedEvent.interested.filter(
							(i) => i !== this.props.user.userId
						),
						attending: [
							...editedEvent.attending,
							this.props.user.userId,
						],
					};
				} else {
					editedEvent = {
						...editedEvent,
						attending: [
							...editedEvent.attending,
							this.props.user.userId,
						],
					};
				}
			}
		}
		editEventResponse(this.state.eventDetails.eventId, editedEvent).then(
			(event) => {
				if (event.eventId) {
					this.setState((prevState) => ({
						...prevState,
						eventDetails: event,
					}));
				}
			}
		);
	};

	// Does API call to delete event and all the posts created within the event.
	deleteEvent = () => {
		if (window.confirm("Are you sure you wish to delete this Event?")) {
			deleteEvent(this.props.eventId).then(
				(res) => {
					if (res) {
						deletePostsByEvent(res.eventId).then((res) => {
							if (res) {
								this.props.showPopup(
									"Your Event was deleted.",
									"Close"
								);
								this.setState({ redirect: true });
							}
						});
					}
				},
				(err) => {
					this.props.showPopup(
						"There was error. Please try again",
						"Close"
					);
				}
			);
		}
	};

	eventUpdate = (ev, action, event) => {
		ev.preventDefault();
		editEventStatus(event._id, this.props.user.userId, action).then(
			(res) => {
				if (res.success) {
					let event = res.event;
					event.moduleTitle = this.state.eventDetails.moduleTitle;
					event.moduleLogo = this.state.eventDetails.moduleLogo;
					this.setState({ eventDetails: event });
				} else {
					alert(res.msg);
				}
			}
		);
	};

	ratingChanged = (rating) => {
		addEventRating(this.props.eventId, this.props.user.userId, rating).then(
			(res) => {
				if (res.success) {
					this.setState({
						eventDetails: {
							...this.state.eventDetails,
							rating: res.avgRating,
							userRated: true,
						},
					});
					//alert(res.msg);
				} else {
					alert(res.msg);
				}
			}
		);
	};

	render() {
		if (this.state.redirect) {
			return <Redirect push to="/home" />;
		}
		if (this.props.userSignedIn) {
			if (this.state.loading) {
				return (
					<div className="profile-container-loading">
						<img src={loading} alt="" />
					</div>
				);
			} else {
				let eventStart = new Date(this.state.eventDetails.start);
				let eventEnd = new Date(this.state.eventDetails.end);
				return (
					<div className="container-fluid nopad evntsbx">
						<div className="teams-container">
							<a
								className="backbtn"
								onClick={(e) => {
									e.preventDefault();
									window.history.back();
								}}
							>
								{" "}
							</a>
							<h6>Event Detail</h6>
						</div>

						<div>
							<div className="tempwide">
								<div className=" evntbx">
									<div className="srchpstbx">
										<div className="usrtop">
											<div className="row">
												<div className="col-2">
													<div className="userthumb">
														<Link
															to={`/${this.state.eventDetails.moduleType}/${this.state.eventDetails.moduleSlug}`}
															className="userbx roundlogo"
														>
															<Avatar
																url={`/uploads/${this.state.eventDetails.moduleType}/`}
																state={
																	this.state
																		.eventDetails
																		.moduleLogo
																}
																alt={
																	"event picture"
																}
															/>
														</Link>
													</div>
												</div>
												<div className="col-8 ml-2 nopad pt-1">
													<Link
														to={`/${this.state.eventDetails.moduleType}/${this.state.eventDetails.moduleSlug}`}
													>
														{
															this.state
																.eventDetails
																.moduleTitle
														}
														{this.state.eventDetails
															.time && (
															<span className="small pstim">
																{assignTimeAgo(
																	this.state
																		.eventDetails
																		.time
																)}
															</span>
														)}
													</Link>
												</div>
												<div className="">
													<span className="acttyp">
														<img
															src={
																"/uploads/images/" +
																this.state
																	.eventDetails
																	.interest
																	.icon
															}
														/>
													</span>
												</div>
											</div>
										</div>
										<div className="userbx  mt-3">
											<a href="">
												<Avatar
													url={"/uploads/event/"}
													state={
														this.state.eventDetails
															.logo
													}
													alt={"event picture"}
												/>
											</a>
										</div>
										<div className="mt-3 mb-3">
											<a>
												{this.state.eventDetails.title}
											</a>
										</div>
										<small>
											<span className="f14">
												<img
													width="20"
													src="/uploads/images/calendar.png"
												/>
												{`Start: ${formatDateTime(
													this.state.eventDetails
														.start
												)}`}
											</span>
										</small>
										<small>
											<span className="f14">
												<img
													width="20"
													src="/uploads/images/calendar.png"
												/>
												{`Start: ${formatDateTime(
													this.state.eventDetails.end
												)}`}
											</span>
										</small>

										<br />
										{/*<small><img width="20" src="/uploads/images/eye.svg"/> <span className="txtblc">Public</span></small><br/>*/}
										{/*<small><img width="20" src="/uploads/images/web.svg"/> <span className="txtblc">tri-alliance.com.au</span> </small><br/>*/}
										<small>
											<img
												width="20"
												src="/uploads/images/pin.png"
											/>{" "}
											<span className="txtblc">
												{
													this.state.eventDetails
														.location
												}
											</span>{" "}
										</small>
										<br />
										<small>
											<span className="f14">
												<img
													width="20"
													src="/uploads/images/people.png"
												/>
												{this.state.eventDetails
													.attending.length > 0
													? `${
															this.state
																.eventDetails
																.attending
																.length === 1
																? "1 Person is Attending"
																: this.state
																		.eventDetails
																		.attending
																		.length +
																  " People are Attending"
													  } `
													: "No one is attending"}
											</span>
										</small>
										<br />
										<small>
											<span className="f14">
												<img
													width="20"
													src="/uploads/images/people.png"
												/>
												{this.state.eventDetails
													.interested.length > 0
													? `${
															this.state
																.eventDetails
																.interested
																.length === 1
																? "1 Person is Interested"
																: this.state
																		.eventDetails
																		.interested
																		.length +
																  " People are Interested"
													  }`
													: "No one is interested"}
											</span>
										</small>
										<br />
										<>
											{this.state.teamStatus !==
												"suspend" &&
											this.state.eventDetails
												.userRated === false &&
											this.state.eventDetails.owner !==
												this.props.user.userId ? (
												<div className="row">
													<div className="col-8 rtngbx">
														<span>Rate Event:</span>
														<ReactStars
															count={5}
															size={24}
															activeColor="#ffd700"
															onChange={
																this
																	.ratingChanged
															}
														/>
													</div>
													{/* <div className='col-4 text-right'><a className='btn btn-primary' href='#'>Follow</a></div> */}
												</div>
											) : (
												<div className="">
													<div className="row">
														<div className="col-8 rtngbx">
															<span>Rating:</span>
															<ReactStars
																count={5}
																size={24}
																activeColor="#ffd700"
																value={
																	this.state
																		.eventDetails
																		.rating !==
																	null
																		? this
																				.state
																				.eventDetails
																				.rating
																		: 0
																}
																edit={false}
															/>
														</div>
														{/* <div className='col-4 text-right'><a className='btn btn-primary' href='#'>Follow</a></div> */}
													</div>
												</div>
											)}
										</>
										{this.state.eventDetails.owner ===
										this.props.user.userId
											? null
											: (this.state.teamStatus !==
													"suspend" ||
													this.state.groupStatus !==
														"suspend") &&
											  this.state.isAdminOrJoined && (
													<p className="mt-2">
														<a
															href=""
															className={
																"btn grnbtn mr-2" +
																(this.state.eventDetails.attending.includes(
																	this.props
																		.user
																		.userId
																)
																	? " dislinks blur"
																	: "")
															}
															onClick={(ev) =>
																this.eventUpdate(
																	ev,
																	"attending",
																	this.state
																		.eventDetails
																)
															}
														>
															I am Attending
														</a>
														<a
															href=""
															className={
																"btn grnbtn" +
																(this.state.eventDetails.interested.includes(
																	this.props
																		.user
																		.userId
																)
																	? " dislinks blur"
																	: "")
															}
															onClick={(ev) =>
																this.eventUpdate(
																	ev,
																	"interested",
																	this.state
																		.eventDetails
																)
															}
														>
															I am Interested
														</a>
													</p>
											  )}
										<hr />
										<h5>
											<b>Description</b>
										</h5>
										<p className="">
											{
												this.state.eventDetails
													.description
											}
										</p>
										<h5 className="mt-3 mb-3">
											<b>Location On Google Map</b>
										</h5>
										<hr />
										<div
											className="event-map mb-3"
											style={{
												width: `100%`,
												height: `100%`,
											}}
										>
											<this.AsyncMap
												googleMapURL={
													keysConfig.google.mapUrl
												}
												loadingElement={
													<div
														style={{
															height: `100%`,
														}}
													/>
												}
												containerElement={
													<div
														style={{
															height: "30vh",
														}}
													/>
												}
												mapElement={
													<div
														style={{
															height: `100%`,
														}}
													/>
												}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}
		} else {
			return (
				<div className="home-container-no-user">
					<h2>You must login to see events</h2>
					<div>
						<Link to="/signInUser">Log In</Link>
						<Link to="/signupUser">SignUp</Link>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		userSignedIn: state.auth.userSignedIn,
		user: state.auth.user,
	};
};

export default withRouter(connect(mapStateToProps, {})(Event));
