import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { updatePassword } from "../../../utils/api";
import { useLocation, useHistory } from "react-router-dom";

const NewPAssword = () => {
	const location = useLocation();
	const history = useHistory();
	const email = location.state?.email;
	const [cpassword, setCPassword] = useState("");
	const [password, setPassword] = useState("");
	const handleSubmit = (e) => {
		e.preventDefault();
		if (cpassword === password) {
			updatePassword(email, password);
			history.push("/recovered");
		} else {
			alert("Password and retype password are different");
		}
	};
	const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

	return (
		<div className="outbx">
			<div className={`loginbx ${isiOS ? "ios-m" : ""}`}>
				<img src="/uploads/images/grey-logo.png" />
				<h3>
					<i>New Password</i>
				</h3>
			</div>
			<div className="loginbxin">
				<form onSubmit={handleSubmit}>
					<div className="form-group">
						<input
							type="password"
							id="formGroupPw"
							className="form-control"
							name="password"
							onChange={(e) => setPassword(e.target.value)}
							placeholder="Type your password *"
							required
						/>
					</div>
					<div className="form-group">
						<input
							type="password"
							id="formGroupConfirmPw"
							className="form-control"
							name="cpassword"
							onChange={(e) => setCPassword(e.target.value)}
							placeholder="Retype your password *"
							required
						/>
					</div>
					<div className="form-group">
						<Button
							variant="primary"
							type="submit"
							// disabled={btnDisable}
						>
							Send
						</Button>
					</div>
				</form>
				<div
					className="frgtbxin"
					style={{ justifyContent: "space-around" }}
				>
					<Link to="/signInUser">Go back</Link>
				</div>
			</div>
		</div>
	);
};

export default NewPAssword;
