/**
 * =====================================
 * REACT SCREEN COMPONENT CLASSName
 * =====================================
 * @date created: 12 October 2019
 * @authors: Waqas Rehmani
 *
 * This file defines the Trending screen component. The className Trending
 * is where the component is defined. This is a screen component.
 *
 * This screen shows the Trending Posts in the application.
 *
 */

// Importing libraries for setup
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
	updateUserGroups,
	updateUserTeams,
	updateShowTrendingFilter,
	updateUserFollow,
} from "../actions";
import InfiniteScroll from "react-infinite-scroll-component";
import Feed from "../components/Feed";
import { assignTimeAgo, formatDateTime } from "../utils/helper";
import ReactStars from "react-rating-stars-component";
import ClampLines from "react-clamp-lines";

// Importing helper functions
import {
	getServerUrl,
	groupAddMember,
	memberRemoveGroup,
	teamAddMember,
	memberRemoveTeam,
	getTrendingPosts,
	getTrendingGroups,
	getTrendingTeams,
	getTrendingUsers,
	getTrendingEvents,
	getInitialTrendingQuestions,
	getPostListByObjId,
	updateFollowAct,
	updateUnfollowAct,
	getPostByGroup,
	editEventStatus,
	getAllFollowersData,
	getAllFollowingUserData,
} from "../utils/api";

// Importing other components
//import TrendingPost from '../components/TrendingPost';
import LeftSideBar from "../components/LeftSideBar";

// Importing icons and pictures
import loading from "../assets/loading.svg";
import profileBlank from "../assets/profile_blank.png";
import Loader from "../components/common/loader";
import Avatar from "../components/common/avatar";

let interestIcons = {
	Swim: "swimming.svg",
	Run: "run.svg",
	Strength: "lifting.svg",
	Bike: "cycling.svg",
	Flexibility: "flexibility.svg",
	Walk: "walk.svg",
	Recovery: "recovery.svg",
	Duathlon: "dualthion.svg",
	Triathlon: "trithalion.svg",
};

class Trending extends Component {
	// Constructor for Trending
	constructor(props) {
		super(props);

		this.state = {
			displayPosts: [],
			totalPostsCount: 0,
			currPostTrackerId: "",
			postsOwner: [],
			hasMorePosts: true,
			posts: [],
			owners: [],
			postHashtags: [],
			filterTag: "",
			teams: [],
			groups: [],
			groupsPostsCount: [],
			events: [],
			displayQuestions: [],
			totalQuestionsCount: 0,
			currQuestionTrackerId: "",
			questionsOwner: [],
			hasMoreQuestions: true,
			users: [],
			followers: [],
			following: [],
			currUserFollowingList: [],
			loading: true,
			target: props.tab ? props.tab : "posts",
			updating: false,
		};
	}

	// Get the current address from the default map position and set those values in the state.
	componentDidMount() {
		this.getTrendingContent(this.props.activityFilter);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.filterTag != this.state.filterTag) {
			getTrendingPosts(
				this.state.filterTag,
				this.props.activityFilter
			).then((res) => {
				this.setState({ posts: res.posts, owners: res.owners });
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.activityFilter !== prevProps.activityFilter) {
			this.getTrendingContent(this.props.activityFilter);
		}
	}

	getTrendingContent = (activityFilter) => {
		getInitialTrendingQuestions(
			this.props.user.userId,
			activityFilter
		).then((res) => {
			res.questions &&
				this.setState({
					totalQuestionsCount: res.totalQuestionsLength,
					currQuestionTrackerId: res.currTrackerId,
					displayQuestions: res.questions,
					questionsOwner: res.owners,
					hasMoreQuestions: res.hasMore,
				});
		});

		getTrendingPosts(this.state.filterTag, activityFilter).then((res) => {
			this.setState({
				posts: res.posts,
				owners: res.owners,
				postHashtags: res.postHashtags,
			});
		});

		getTrendingTeams(activityFilter).then((res) => {
			res.teams && this.setState({ teams: res.teams });
		});

		getTrendingGroups(activityFilter).then(async (res) => {
			if (res.group) {
				let groupsPostsCount = [];
				for (const group of res.groups) {
					const resp = await getPostByGroup(group._id);
					groupsPostsCount.push(resp.posts.length);
				}
				this.setState({ groups: res.groups });
				this.setState({ groupsPostsCount });
			}
		});

		getTrendingEvents(activityFilter).then((res) => {
			res.events && this.setState({ events: res.events });
		});

		getTrendingUsers(activityFilter).then(async (res) => {
			if (res.users) {
				this.setState({
					loading: true,
				});
				const followersData = await getAllFollowersData(
					this.props.user.userId
				);
				const followingData = await getAllFollowingUserData(
					this.props.user.userId
				);

				this.setState({
					users: res.users,
					currUserFollowingList: this.props.user.following,
					followers: followersData,
					following: followingData,
					loading: false,
				});
			}
		});
		this.setState({ loading: false });
	};

	getMorePosts = async () => {
		// scroll down => add 5 more posts
		const STEP_LENGTH = 5;

		// get current num of display posts and total posts number
		let currPostsLen = this.state.displayPosts.length;
		let totalPostsLen = this.state.totalPostsCount;

		// make sure have posts to add for display
		if (currPostsLen < totalPostsLen) {
			if (currPostsLen + STEP_LENGTH < totalPostsLen) {
				const res = await getPostListByObjId(
					this.state.currPostTrackerId,
					currPostsLen,
					currPostsLen + STEP_LENGTH
				);

				this.setState({
					displayPosts: [...this.state.displayPosts, ...res.posts],
					postsOwner: [...this.state.postsOwner, ...res.owners],
				});
			} else {
				// display all of the posts as it reached to the end of the num of posts
				const res = await getPostListByObjId(
					this.state.currPostTrackerId,
					currPostsLen,
					totalPostsLen
				);
				this.setState({
					displayPosts: [...this.state.displayPosts, ...res.posts],
					postsOwner: [...this.state.postsOwner, ...res.owners],
					hasMorePosts: false,
				});
			}
		} else {
			this.setState({ hasMorePosts: false });
		}
	};

	getMoreQuestions = async () => {
		// scroll down => add 5 more questions
		const STEP_LENGTH = 5;

		// get current num of display posts and total posts number
		let currQuestionsLen = this.state.displayQuestions.length;
		let totalQuestionsLen = this.state.totalQuestionsCount;

		// make sure have posts to add for display
		if (currQuestionsLen < totalQuestionsLen) {
			if (currQuestionsLen + STEP_LENGTH < totalQuestionsLen) {
				const res = await getPostListByObjId(
					this.state.currQuestionTrackerId,
					currQuestionsLen,
					currQuestionsLen + STEP_LENGTH
				);

				this.setState({
					displayQuestions: [
						...this.state.displayQuestions,
						...res.posts,
					],
					questionsOwner: [
						...this.state.questionsOwner,
						...res.owners,
					],
				});
			} else {
				// display all of the questions as it reached to the end of the num of questions
				const res = await getPostListByObjId(
					this.state.currQuestionTrackerId,
					currQuestionsLen,
					totalQuestionsLen
				);

				this.setState({
					displayQuestions: [
						...this.state.displayQuestions,
						...res.posts,
					],
					questionsOwner: [
						...this.state.questionsOwner,
						...res.owners,
					],
					hasMoreQuestions: false,
				});
			}
		} else {
			this.setState({ hasMoreQuestions: false });
		}
	};

	filterByTag = (ev, tag) => {
		ev.preventDefault();
		this.setState({ filterTag: tag });
	};

	handleTarget = (target) => {
		this.setState({ target });

		this.props.history.push({
			pathname: "/trending/" + target,
		});
	};

	isGroupMember = (groupId) => {
		return this.props.user.groups.includes(groupId);
	};

	joinGroup = (e, groupId, groupType) => {
		e.preventDefault();
		groupAddMember(groupId, this.props.user._id, groupType).then((resp) => {
			alert(resp.msg);
			if (resp.success == true) {
				let groupAccessBtn = document.getElementById(
					`group-btn-${groupId}`
				);
				groupAccessBtn.innerHTML = "Pending";
				groupAccessBtn.className = "smplbtn m-0 dislinks blur";
				this.props.updateUserGroups(
					resp.userGroups,
					resp.userGroupRequests
				);
			}
		});
	};

	leaveGroup = (e, groupId, groupType) => {
		e.preventDefault();
		memberRemoveGroup(groupId, this.props.user._id).then((resp) => {
			alert(resp.msg);
			if (resp.success == true) {
				let groupAccessBtn = document.getElementById(
					`group-btn-${groupId}`
				);
				groupAccessBtn.innerHTML = "Join Group";
				groupAccessBtn.className = "smplbtn m-0";

				groupAccessBtn.onclick = (e) => {
					this.joinGroup(e, groupId, groupType);
				};
				this.props.updateUserGroups(
					resp.userGroups,
					resp.userGroupRequests
				);
			}
		});
	};

	isTeamMember = (teamId) => {
		return this.props.user.teams.includes(teamId);
	};

	joinTeam = (e, teamId, teamType) => {
		e.preventDefault();
		teamAddMember(teamId, this.props.user._id, teamType).then((resp) => {
			alert(resp.msg);
			if (resp.success == true) {
				let teamAccessBtn = document.getElementById(
					`team-btn-${teamId}`
				);
				teamAccessBtn.innerHTML = "Pending";
				teamAccessBtn.className = "smplbtn m-0 dislinks blur";
				this.props.updateUserTeams(
					resp.userTeams,
					resp.userTeamRequests
				);
			}
		});
	};

	leaveTeam = (e, teamId, teamType) => {
		e.preventDefault();
		memberRemoveTeam(teamId, this.props.user._id).then((resp) => {
			alert(resp.msg);
			if (resp.success == true) {
				let teamAccessBtn = document.getElementById(
					`team-btn-${teamId}`
				);
				teamAccessBtn.innerHTML = "Join Team";
				teamAccessBtn.className = "smplbtn m-0";

				teamAccessBtn.onclick = (e) => {
					this.joinTeam(e, teamId, teamType);
				};
				this.props.updateUserTeams(
					resp.userTeams,
					resp.userTeamRequests
				);
			}
		});
	};

	eventUpdate = (ev, action, event) => {
		ev.preventDefault();
		editEventStatus(event._id, this.props.user.userId, action).then(
			(res) => {
				if (res.success) {
					let events = [];
					let oldEvents = this.state.events;
					for (let i = 0; i < oldEvents.length; i++) {
						if (oldEvents[i].eventId === event.eventId) {
							events.push(res.event);
						} else events.push(oldEvents[i]);
					}

					this.setState({ events });
				} else {
					alert(res.msg);
				}
			}
		);
	};

	renderPosts = (posts, owners) => {
		let point = 1;
		let prevPost = null;
		let displayPosts = posts.map((post, index) => {
			if (point === 1) {
				point = 2;
				prevPost = (
					<div className="row snglcoltrn" key={index}>
						<div className="col-12 ">
							{this.displayPost(post, owners, index)}
						</div>
					</div>
				);
				return null;
			} else if (point === 2) {
				point = 3;
				let firstPost = prevPost;
				prevPost = null;
				return (
					<div className="row twocoltrn" key={index}>
						<div className="col-6 pr-0">{firstPost}</div>
						<div className="col-6 pl-0">
							{this.displayPost(post, owners, index)}
						</div>
					</div>
				);
			} else if (point === 3) {
				point = 1;
				return (
					<div className="row snglcoltrn" key={index}>
						<div className="col-12 ">
							{this.displayPost(post, owners, index)}
						</div>
					</div>
				);
			}
		});
		if (prevPost !== null) displayPosts.push(prevPost);
		return displayPosts.length === 0 ? (
			<p className="feed-container-empty">No Post</p>
		) : (
			displayPosts
		);
	};

	displayPost = (post, owners, index) => {
		let video,
			type = "";
		if (post.videoFileName.length > 0) {
			video = post.videoFileName[0];
			if (video.endsWith(".avi")) type = "video/avi";
			else if (video.endsWith(".mp4")) type = "video/mp4";
			else if (video.endsWith(".wmv")) type = "video/x-ms-wmv";
			else if (video.endsWith(".ogg")) type = "video/ogg";
			else if (video.endsWith(".webm")) type = "video/webm";
		}
		return (
			<div className={`item clr-${post.interest.name}`} key={index}>
				<div>
					<div className="trending-post">
						<span className="trntop">
							<Link to={`/profile/${owners[index].userId}`}>
								<Avatar
									url={"/uploads/user/"}
									state={owners[index].profilePicture}
									alt={"Group logo"}
									style={{ width: "100%" }}
								/>
							</Link>
						</span>
						<div className="authname">
							<Link
								to={`/profile/${owners[index].userId}`}
							>{`${owners[index].firstName} ${owners[index].lastName}`}</Link>{" "}
							<span
								className="small pstim "
								style={{ color: "white" }}
							>
								{assignTimeAgo(post.time)}
							</span>
						</div>
						{post.interest.icon && (
							<span className="acttyp pushright">
								<img
									src={`/uploads/images/${post.interest.icon}`}
								/>
							</span>
						)}
					</div>
				</div>
				{post.description && post.imgFileName.length > 0 && (
					<>
						<span className="pstover f14">
							<Link to={"/post/" + post.postId}>
								<ClampLines
									text={post.description}
									id={post._id + "-description"}
									buttons={false}
									innerElement="p"
								/>
							</Link>
						</span>
						<Link to={"/post/" + post.postId}>
							<Avatar
								url={"/uploads/posts/"}
								state={post.imgFileName[0]}
								alt={"post"}
								style={{ maxHeight: "220px" }}
							/>
						</Link>
					</>
				)}
				{post.description &&
					post.imgFileName.length === 0 &&
					post.videoFileName.length === 0 && (
						<Link to={"/post/" + post.postId}>
							<ClampLines
								text={post.description}
								id={post._id + "-description"}
								buttons={false}
								innerElement="p"
								className="pstxt lineclamp f16"
							/>
						</Link>
					)}
				{!post.description && post.imgFileName.length > 0 && (
					<Link to={"/post/" + post.postId}>
						<Avatar
							url={"/uploads/posts/"}
							state={post.imgFileName[0]}
							alt={"post"}
						/>
					</Link>
				)}
				{post.description &&
					post.imgFileName.length === 0 &&
					post.videoFileName.length > 0 && (
						<>
							<span className="pstover f14">
								<Link to={"/post/" + post.postId}>
									<ClampLines
										text={post.description}
										id={post._id + "-description"}
										buttons={false}
										innerElement="p"
									/>
								</Link>
							</span>
							<video width="100%">
								<source
									src={`${
										getServerUrl().apiURL
									}/uploads/posts/${video}`}
									type={type}
								></source>
							</video>
						</>
					)}
				{!post.description &&
					post.imgFileName.length === 0 &&
					post.videoFileName.length > 0 && (
						<video width="100%">
							<source
								src={`${
									getServerUrl().apiURL
								}/uploads/posts/${video}`}
								type={type}
							></source>
						</video>
					)}
			</div>
		);
	};

	renderTeams = (teams) => {
		return teams.length === 0 ? (
			<p className="feed-container-empty">No Team</p>
		) : (
			teams.map((team, idx) => (
				<div className="col-lg-6 col-md-6 clubbxsrch" key={idx}>
					<div className="srchpstbx">
						<div className="usrtop">
							<div className="row">
								<div className="col-2">
									<div className="userthumb">
										<a className="userbx">
											<Avatar
												url={"/uploads/team/"}
												state={team.logo}
												alt={"logo"}
											/>
										</a>
									</div>
								</div>
								<div className="col-8 pl-2 nopad pt-1">
									<Link to={"/team/" + team.slug}>
										{team.title}
									</Link>
									<span className="small pstim">{`${
										team.membersCount
									} Team ${
										team.membersCount < 1
											? "member"
											: "members"
									}`}</span>
									{team.address && (
										<span className="small pstim">
											<img
												src="/uploads/images/pin.png"
												style={{
													width: "9px",
												}}
											/>{" "}
											{team.address}
										</span>
									)}

									{team.description && (
										<p className="f12 mt-1 mb-1 d-flex flex-column">
											<strong className="f12 mt-1 mb-1">
												Description:
											</strong>
											<span>{team.description}</span>
										</p>
									)}
								</div>
								{team.activityType && (
									<div className="col-2">
										<span className="acttyp">
											<img
												src={`/uploads/images/${
													interestIcons[
														team.activityType
													]
												}`}
												style={{
													width: "30px",
												}}
											/>
										</span>
									</div>
								)}
							</div>
						</div>
						{this.props.user._id !== team.creatorId && (
							<p className="mt-1">
								{" "}
								{this.props.user._id !== team.creatorId && (
									<span>
										<a
											id={"team-btn-" + team._id}
											className={`f14 btn ${
												this.props.user.teamRequests.includes(
													team._id
												)
													? "dislinks blur"
													: this.isTeamMember(
															team._id
													  )
													? ""
													: ""
											}`}
											href=""
											onClick={(e) => {
												if (
													this.isTeamMember(team._id)
												) {
													this.leaveTeam(
														e,
														team._id,
														team.type
													);
												} else {
													this.joinTeam(
														e,
														team._id,
														team.type
													);
												}
											}}
										>
											{this.props.user.teamRequests.includes(
												team._id
											)
												? "Pending"
												: !this.isTeamMember(team._id)
												? "Join Team"
												: "Leave Team"}
										</a>
										<a
											className="f14 btn"
											href={
												"/team/" +
												team.slug +
												"/memberships"
											}
										>
											Get Membership
										</a>
									</span>
								)}
							</p>
						)}
					</div>
				</div>
			))
		);
	};

	renderGroups = (groups) => {
		const { groupsPostsCount } = this.state;

		return groups.length === 0 ? (
			<p className="feed-container-empty">No Groups</p>
		) : (
			groups.map((group, index) => (
				<div className="col-lg-6 col-md-6 clubbxsrch" key={index}>
					<div className="srchpstbx">
						<div className="usrtop">
							<div className="row">
								<div className="col-2">
									<div className="userthumb">
										<Link
											to={`/group/${group.slug}`}
											className="userbx"
										>
											<Avatar
												url={"/uploads/group/"}
												state={group.logo}
												alt={"logo"}
											/>
										</Link>
									</div>
								</div>
								<div className="col-8 pl-2 nopad pt-1">
									<Link to={"/group/" + group.slug}>
										{group.title}
									</Link>
									<span className="small pstim">{`${
										group.membersCount
									} Group ${
										group.membersCount < 1
											? "member"
											: "members"
									}`}</span>
									{group.address && (
										<span className="small pstim">
											<img
												src="/uploads/images/pin.png"
												style={{
													width: "9px",
												}}
											/>{" "}
											{group.address}
										</span>
									)}

									{group.description && (
										<p className="f12 mt-1 mb-1 d-flex flex-column">
											<strong className="f12 mt-1 mb-1">
												Description:
											</strong>
											<span>{group.description}</span>
										</p>
									)}
								</div>
								{group.interest && (
									<div className="col-2">
										<span className="acttyp">
											<img
												src={`/uploads/images/${
													interestIcons[
														group.interest
													]
												}`}
												style={{
													width: "30px",
												}}
											/>
										</span>
									</div>
								)}
							</div>
						</div>
						{this.props.user._id !== group.creatorId && (
							<p className="mt-1">
								{" "}
								<a
									id={"group-btn-" + group._id}
									className={`smplbtn m-0 ${
										this.props.user.groupRequests.includes(
											group._id
										)
											? "dislinks blur"
											: this.isGroupMember(group._id)
											? ""
											: ""
									}`}
									href=""
									onClick={(e) => {
										if (this.isGroupMember(group._id)) {
											this.leaveGroup(
												e,
												group._id,
												group.type
											);
										} else {
											this.joinGroup(
												e,
												group._id,
												group.type
											);
										}
									}}
								>
									{this.props.user.groupRequests.includes(
										group._id
									)
										? "Pending"
										: !this.isGroupMember(group._id)
										? "Join Group"
										: "Leave Group"}
								</a>
							</p>
						)}
					</div>
				</div>
			))
		);
	};

	renderUsers = (users) => {
		return users.length === 0 ? (
			<p className="feed-container-empty">No Users</p>
		) : (
			users.map((user, index) => (
				<div className="col-lg-6 col-md-12" key={index}>
					<div className="pplsrch bxshadow">
						<div className="usrtop">
							<div className="row">
								<div className="col-2">
									<div className="userthumb">
										<Link
											to={`/profile/${user.userId}`}
											className="userbx"
										>
											<img
												src={
													user.profilePicture
														? `${
																getServerUrl()
																	.apiURL
														  }/uploads/user/${
																user.profilePicture
														  }`
														: profileBlank
												}
											/>
										</Link>
									</div>
								</div>
								<div className="col-10 nopad">
									<>
										<Link
											to={`/profile/${user.userId}`}
											style={{
												marginRight: "10px",
											}}
										>{`${user.firstName} ${user.lastName}`}</Link>
										{user.role === "Coach" && (
											<img
												src="/uploads/images/cap.svg"
												width="20"
												style={{
													borderRadius: "50px",
													background: "#114e7e",
													padding: "3px",
													marginRight: "10px",
													height: "18px",
												}}
											/>
										)}
										{user.userId !==
											this.props.user.userId &&
											(this.state.currUserFollowingList.includes(
												user.userId
											) ? (
												<a
													className={`smplbtn mt-2`}
													href="#"
													onClick={(e) =>
														this.unfollowUser(
															e,
															user.userId,
															index
														)
													}
												>
													Unfollow
												</a>
											) : (
												<a
													className={`smplbtn m-0 `}
													href="#"
													onClick={(e) =>
														this.followUser(
															e,
															user.userId,
															index
														)
													}
												>
													Follow Me
												</a>
											))}
									</>
									{user.location && (
										<span className="small pstim mt-1">
											{user.location}
										</span>
									)}
									<div className="intrsts mt-2">
										{user.interest.length > 0 &&
											user.interest.map(
												(interest, idx) => (
													<img
														src={`/uploads/images/${interestIcons[interest]}`}
														style={{
															width: "20px",
															marginLeft: "2px",
														}}
														key={idx}
													/>
												)
											)}
									</div>
								</div>
								<div className="col-4 text-right hideit">
									<div
										className="acttyp"
										style={{
											height: "100%",
											width: "100%",
											overflow: "visible",
											marginTop: "10px",
										}}
									>
										{user.interest.length > 0 &&
											user.interest.map(
												(interest, idx) => (
													<img
														src={`/uploads/images/${interestIcons[interest]}`}
														style={{
															width: "20px",
															marginLeft: "2px",
														}}
														key={idx}
													/>
												)
											)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			))
		);
	};

	renderEvents = (events) => {
		let eventStart = new Date(events.start);
		let eventEnd = new Date(events.end);

		return events.length === 0 ? (
			<p className="feed-container-empty">No Events</p>
		) : (
			events.map((event, index) => (
				<div className="col-lg-6 col-md-12" key={index}>
					<div className="srchpstbx">
						<div className="usrtop">
							<div className="row">
								<div className="col-2">
									<div className="userthumb">
										<a className="userbx">
											<Avatar
												url={`/uploads/${event.moduleType}/`}
												state={event.moduleLogo}
												alt={"event picture"}
											/>
										</a>
									</div>
								</div>
								<div className="col-8 pl-2 nopad pt-1">
									<a>{event.moduleTitle}</a>
									{event.time && (
										<span className="small pstim">
											{assignTimeAgo(event.time)}
										</span>
									)}
								</div>
								{event.interest && event.interest.icon && (
									<div className="col-2">
										<span className="acttyp pushright">
											<img
												src={`/uploads/images/${event.interest.icon}`}
											/>
										</span>
									</div>
								)}
							</div>
						</div>
						{event.logo && (
							<div className="pstmd">
								<img
									src={`${
										getServerUrl().apiURL
									}/uploads/event/${event.logo}`}
									style={{
										width: "100%",
									}}
								/>
							</div>
						)}
						<h4 className="mt-3 mb-3">
							<Link to={`/event/${event.eventId}`}>
								{event.title}
							</Link>
						</h4>
						<p>
							<span className="f14">
								<img src="/uploads/images/calendar.png" />
								{`Start: ${formatDateTime(event.start)}`}
							</span>
						</p>
						<p>
							<span className="f14">
								<img src="/uploads/images/calendar.png" />
								{`End: ${formatDateTime(event.end)}`}
							</span>
						</p>
						<p>
							<span className="f14">
								<img src="/uploads/images/pin.png" />
								{event.location}
							</span>
						</p>
						<p className="">
							<span className="f14">
								<img src="/uploads/images/people.png" />
								{event.attending.length > 0
									? `${
											event.attending.length === 1
												? "1 Person is Attending"
												: event.attending.length +
												  " People are Attending"
									  }`
									: "No one is attending"}
							</span>
						</p>
						<p className="">
							<span className="f14">
								<img src="/uploads/images/people.png" />
								{event.interested.length > 0
									? `${
											event.interested.length === 1
												? "1 Person is Interested"
												: event.interested.length +
												  " People are Interested"
									  }`
									: "No one is interested"}{" "}
							</span>
						</p>
						<div className="row">
							<div className="col-8 rtngbx">
								<span>Rating:</span>

								<ReactStars
									count={5}
									size={24}
									activeColor="#ffd700"
									value={
										event.rating !== null ? event.rating : 0
									}
									edit={false}
								/>
							</div>
						</div>
						<a
							href=""
							className={
								"btn grnbtn  mt-2 mr-2 f14" +
								(event.attending.includes(
									this.props.user.userId
								)
									? " dislinks blur"
									: "")
							}
							onClick={(ev) =>
								this.eventUpdate(ev, "attending", event)
							}
						>
							I am Attending
						</a>
						<a
							href=""
							className={
								"btn grnbtn  mt-2 f14" +
								(event.interested.includes(
									this.props.user.userId
								)
									? " dislinks blur"
									: "")
							}
							onClick={(ev) =>
								this.eventUpdate(ev, "interested", event)
							}
						>
							I am Interested
						</a>

						<div className="lkbxbtm f12">
							{/* <div className='row'>
                            <div className='col'>
                                <a href='#'>
                                    <img src='/uploads/images/share.png' />
                                    Share
                                </a>
                            </div>
                        </div> */}
						</div>
					</div>
				</div>
			))
		);
	};

	followUser = async (e, followedUserId, index) => {
		e.preventDefault();
		try {
			const res = await updateFollowAct(
				this.props.user.userId,
				followedUserId
			);
			if (res.success === true) {
				this.setState((prevState) => ({
					following: res.followedUser,
					updating: false,
					currUserFollowingList: prevState.currUserFollowingList
						.filter((u) => u !== res.followedUser.userId)
						.concat(res.followedUser.userId),
				}));

				// Dispatch the action to update the Redux store with the new following list
				this.props.updateUserFollow(res.user.following);
			}
		} catch (error) {
			console.error("Error following user:", error);
		}
		this.setState({ updating: true });
	};

	unfollowUser = async (e, unfollowedUserId, index) => {
		e.preventDefault();
		try {
			const res = await updateUnfollowAct(
				this.props.user.userId,
				unfollowedUserId
			);
			if (res.success === true) {
				this.setState((prevState) => ({
					currUserFollowingList:
						prevState.currUserFollowingList.filter(
							(uId) => uId !== unfollowedUserId
						),
					updating: false,
				}));

				this.props.updateUserFollow(res.user.following);
			}
		} catch (error) {
			console.error("Error unfollowing user:", error);
		}

		this.setState({ updating: true });
	};

	showActivityFilter = () => {
		this.props.updateShowTrendingFilter(true);
	};

	// Render method for Trending Screen
	render() {
		const { target, teams, groups, users, events, posts, owners } =
			this.state;
		const isMobile = window.matchMedia("(max-width: 500px)").matches;
		return (
			<>
				<Loader loading={this.state.loading} />
				<div className="subhdr">
					<div className="container nopad">
						<div className="row">
							<div className="col">
								<div className="typsldr">
									<a
										key={"post"}
										href="#"
										className={`${
											target === "posts" ? "sel" : ""
										}`}
										onClick={() =>
											this.handleTarget("posts")
										}
									>
										Posts
									</a>
									<a
										key={"event"}
										href="#"
										className={`${
											target === "events" ? "sel" : ""
										}`}
										onClick={() =>
											this.handleTarget("events")
										}
									>
										Events
									</a>
									<a
										key={"teams"}
										href="#"
										className={`${
											target === "teams" ? "sel" : ""
										}`}
										onClick={() =>
											this.handleTarget("teams")
										}
									>
										Teams
									</a>
									<a
										href="#"
										className={`${
											target === "groups" ? "sel" : ""
										}`}
										onClick={() =>
											this.handleTarget("groups")
										}
										key={"group"}
									>
										Groups
									</a>
									<a
										href="#"
										className={`${
											target === "users" ? "sel" : ""
										}`}
										onClick={() =>
											this.handleTarget("users")
										}
										key={"people"}
									>
										People
									</a>
									<a
										href="#"
										className={`${
											target === "questions" ? "sel" : ""
										}`}
										onClick={() =>
											this.handleTarget("questions")
										}
										key={"quenstions"}
									>
										Questions
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="fltrot">
					<div
						className="fltbxin"
						onClick={() => this.showActivityFilter()}
					>
						<div>Filters Your Results</div>
						<div className="fltrnd">
							<img src="/uploads/images/filter-icon.svg" />
						</div>
					</div>
				</div>

				{target === "posts" && this.state.postHashtags.length > 0 && (
					<div className="tagline">
						<div className="container nopad">
							<div className="row">
								<div className="col">
									{this.state.postHashtags.map((tag, idx) => {
										return (
											<a
												key={idx}
												className="tag"
												href=""
												onClick={(ev) =>
													this.filterByTag(
														ev,
														tag.hashTags
													)
												}
											>
												#{tag.hashTags}
											</a>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				)}

				<div className="container cntntbx trndgbx">
					<div className="row">
						{!isMobile && (
							<div className="col-3">
								<LeftSideBar />
								{/* <div className=' bxshadow menuinr'>
                                        <div className='userthumb'>
                                            <div className='userbx'>
                                                <img src={user.profilePicture ? `${getServerUrl().apiURL}/uploads/user/${user.profilePicture}` : profileBlank} />
                                            </div>
                                            <div>{user.firstName + ' ' + user.lastName}</div>
                                        </div>
                                    </div>

                                    <div className='mnuen'>
                                        <ul>
                                            <li><a href='#'><span><img src='/uploads/images/recent.svg' /></span>{' '}Most Recent</a></li>
                                            <li><Link to='/groups'><span><img src='/uploads/images/group.svg' /></span>{' '}Groups</Link></li>
                                            <li><Link to='/teams'><span><img src='/uploads/images/team.svg' /></span>{' '}Teams</Link></li>
                                            <li><Link to={'/profile/'+this.props.user.userId+'/follow/followers'}><span><img src='/uploads/images/follow.svg' /></span>{' '}Your Followers{' '}</Link></li>
                                            <li><Link to='/questions/own'><span><img src='/uploads/images/questions.svg' /></span>{' '}Your Questions{' '}</Link></li>
                                            <li><a href='#'><span><img src='/uploads/images/flag.svg' /></span>{' '}Events</a></li>
                                            <li><Link to='/saved'><span><img src='/uploads/images/bookmark.svg' /></span>{' '}Saved</Link></li>
                                            <li><a href='#'><span><img src='/uploads/images/setting.svg' /></span>{' '}Settings</a></li>
                                            <li><a onClick={this.logOutUser} href='#'><span><img src='/uploads/images/logout.svg' /></span>{' '}Logout</a></li>
                                        </ul>
                                        <p className='mt-4 mb-0'><a className='btnspl' href='#'>Join The Group</a></p>
                                    </div> */}
							</div>
						)}
						<div
							className={`${
								!isMobile ? "col-9" : "col-12 nopad"
							}`}
						>
							{target === "posts" && (
								<div className="trending">
									<div className="container">
										<div
											className="row"
											style={{
												marginLeft: "0px",
												marginRight: "0px",
											}}
										>
											<div id="list" className="section">
												{this.renderPosts(
													posts,
													owners
												)}
											</div>
										</div>
									</div>
								</div>
							)}

							{target === "teams" && (
								<div className="secteams">
									<div className="row">
										{this.renderTeams(teams)}
									</div>
								</div>
							)}

							{target === "users" && (
								<div className="row">
									{this.renderUsers(users)}
								</div>
							)}

							{target === "questions" && (
								<InfiniteScroll
									style={{ overflow: "hidden" }}
									dataLength={
										this.state.displayQuestions.length
									}
									next={this.getMoreQuestions}
									hasMore={this.state.hasMoreQuestions}
									loader={
										<div className="text-center">
											<div
												className="spinner-border"
												role="status"
											>
												<span className="sr-only">
													Loading...
												</span>
											</div>
										</div>
									}
									endMessage={
										<p className="text-center mt-4">
											<b>Yay! You have seen it all</b>
										</p>
									}
								>
									<Feed
										key={`own-questions-${this.props.user.userId}-${this.state.totalQuestionsCount}`}
										posts={this.state.displayQuestions}
										owners={this.state.questionsOwner}
										editPost={this.props.editPost}
										deletePost={this.props.deletePost}
										showPopup={this.props.showPopup}
										notGeneral={false}
										changeKudos={this.props.changeKudos}
										openPictureViewer={
											this.props.openPictureViewer
										}
										createComment={this.props.createComment}
										isQuestionPage={true}
									/>
								</InfiniteScroll>
							)}

							{target === "events" && (
								<div className="lstevnt">
									<div className="row">
										{this.renderEvents(events)}
									</div>
								</div>
							)}

							{target === "groups" && (
								<div className="row">
									{this.renderGroups(groups)}
								</div>
							)}
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		activityFilter: state.auth.activityFilter,
	};
};

export default withRouter(
	connect(mapStateToProps, {
		updateUserGroups,
		updateUserTeams,
		updateShowTrendingFilter,
		updateUserFollow,
	})(Trending)
);
