import React, { useState, useRef } from "react";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import { updateUserProfile, profilePhotosUpdate } from "../actions";
import { profileUpdate, getServerUrl } from "../utils/api";
import Avatar from "../components/common/avatar";
import UploadAvatar from "../components/common/uploadAvatar";

const EditPhoto = ({ user, token, redirect, history, userId }) => {
	const [selectedImg, setSelectedImg] = useState("");
	const [redirected, setRedirected] = useState(false);
	const uploadProfilePicRef = useRef(null);
	const dispatch = useDispatch();
	const handleImgSelection = (selected) => {
		if (selected === selectedImg) {
			setSelectedImg("");
		} else {
			const img = new Image();
			img.src = `${getServerUrl().apiURL}/uploads/user/${selected}`;
			img.onload = () => {
				setSelectedImg(selected);
			};
		}
	};

	const openUploadProfilePic = () => {
		uploadProfilePicRef.current.click();
	};

	const updateLogo = (ing) => {
		profilePhotosUpdate(user.userId, ing)(dispatch);
		uploadProfilePicRef.current.value = null;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const edited = { ...user };
		edited.profilePicture = selectedImg;

		updateUserProfile({
			userId: user.userId,
			token: token,
			certificate: "",
			edited,
		})(dispatch);
		setRedirected(true);
	};

	if (user.userId !== userId) return <Redirect to={"/profile/" + userId} />;

	if (redirected && redirect) return <Redirect to={redirect} />;
	return (
		<>
			<div className="teams-container">
				<Link to={"/profile/" + userId} className="backbtn"></Link>
				<h6>
					Profile Photo
					{selectedImg ? (
						<a
							className="pushright createbtn f14"
							onClick={handleSubmit}
							style={{ cursor: "pointer" }}
						>
							Confirm
						</a>
					) : (
						<a
							className="pushright createbtn f14"
							onClick={() =>
								history.push("/profile/" + user.userId)
							}
							style={{ cursor: "pointer" }}
						>
							Cancel
						</a>
					)}
				</h6>
			</div>

			<div className="usrphto">
				<p className="small" style={{ textAlign: "center" }}>
					{!selectedImg
						? "Choose a profile picture that is square"
						: "Click 'Confirm' button on top right to change profile picture"}
				</p>
				<div className="phtoglry">
					<div className="row">
						{user.photos.map((photo, index) => (
							<div
								key={"uploaded-img-" + index}
								className={`col-4 nopad ${
									selectedImg === photo ? "blur" : ""
								}`}
							>
								<a onClick={() => handleImgSelection(photo)}>
									<Avatar
										url={"/uploads/user/"}
										state={photo}
										alt={"Logo"}
										style={{
											width:
												selectedImg === photo
													? "83px"
													: "100px",
											borderRadius: "100%",
											height:
												selectedImg === photo
													? "83px"
													: "100px",
										}}
									/>
									{selectedImg === photo && <>Selected</>}
								</a>
							</div>
						))}
						<div className="col-12 mt-2">
							<UploadAvatar
								uploadLogoRef={uploadProfilePicRef}
								openUploadLogo={openUploadProfilePic}
								updateLogo={updateLogo}
								disable={true}
								a_style={"btnbig"}
								btn_text={"Upload New Photo"}
								uploadType={"profileUpdate"}
								uploadAPI={profileUpdate}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		token: state.auth.token,
		redirect: state.auth.redirect,
	};
};

export default withRouter(
	connect(mapStateToProps, { updateUserProfile, profilePhotosUpdate })(
		EditPhoto
	)
);
