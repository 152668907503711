import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ShowStrengthSession from './modals/view-strength-session';
import ViewSession from './modals/view-sessions';
import ViewExercises from './modals/ViewExercise';
import { connect } from 'react-redux';
import { getServerUrl, updateMemberShipLevel, getGroupsLevels, updateUserPlanner } from '../../utils/api';
import { logOutUser } from "../../actions";
import profileBlank from "../../assets/profile_blank.png";



const LeftSidebar = (props) => {
	const [selectGroupLevels, setselectGroupLevels] = useState(null);
	const [selectPlanner, setselectPlanner] = useState(null);
	const [groupLevels, setgroupLevels] = useState(null);
	const [planners, setPlanners] = useState(null);
	const [userCoach, setuserCoach] = useState(null);
	const [userSelectedPlanner, setuserSelectedPlanner] = useState(null);
	const [athleteLevel, setathleteLevel] = useState(null);
	useEffect(() => {

		setuserSelectedPlanner(props.userplanners?.[0].plannerId);

	}, []);
	const handlePlanner = (event) => {
		let value = event.target.value;
		setselectPlanner({ clubId: props.club._id, plannerId: value, userId: props.user._id })
	}
	const handleGroupLevel = (event) => {
		let value = event.target.value;
		setselectGroupLevels({ clubSlug: props.clubSlug, levelId: value, userId: props.user._id })
	}
	const handleUpdateUserPlanner = () => {
		updateUserPlanner(selectPlanner).then(res => {
			if(res.success==true) {
				alert('planer changed successfully')
			}
		});
	}
	const handleUpdateGroupLevel = () => {
		updateMemberShipLevel(selectGroupLevels).then(res => {
		});
	}
	const logOutAthlete = () => {
		if (window.confirm("Are you sure you wish to log out?")) {
			props.logOutUser();
			window.location.href = '/home';
			// return false;

		}
	};

	const formatDate = (inputDateString) => {
		const monthNames = [
			"Jan", "Feb", "Mar", "Apr", "May", "Jun",
			"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
		];

		// Create a Date object from the input string
		const date = new Date(inputDateString);

		// Extract day, month, and year components from the Date object
		const day = date.getUTCDate();
		const month = monthNames[date.getUTCMonth()];
		const year = date.getUTCFullYear();

		// Construct the desired format: "17 Dec 2019"
		const formattedDate = `${day} ${month} ${year}`;

		return formattedDate;
	}
	let popup;
	if (props.modals.viewSessions)
		popup = <ViewSession club={props.club} />;
	else if (props.modals.showStrengthSession)
		popup = <ShowStrengthSession club={props.club} />;
	else if (props.modals.viewExercise)
		popup = <ViewExercises club={props.club} />;
	return (
		<div id="sidebar-wrapper-left" className="desktop-verstion">
			{popup}
			<div className="wrapin">
				<div className="wrapin-inner">
					<Link to={"/profile/" + props.user.userId + "/edit/"} className="edit-icon-mbt"><i className="fa fa-pencil" aria-hidden="true"></i></Link>
					<div className="profile clearfix">
						<Link to={"/profile/" + props.user.userId} className="avatar-center">
							<div className="profile_pic onine-stats-green-brder">
								<img
									src={
										props.user.profilePicture === ''
											? '/uploads/images/profile_default.png'
											: getServerUrl().apiURL +
											'/uploads/user/' +
											props.user.profilePicture
									}
									className="avatar"
									width="50"
									height="50"
									alt=""
								/>
							</div>
						</Link>
					</div>

					<div className="about-athlete-detail mb-0">
						<div className="athlete-name">
							<span>{props.user.firstName + ' ' + props.user.lastName}</span>
						</div>
					</div>
					<div className="scrollable-container">
						<div className="about-athlete-detail">
							<div className="athlete-level common-listing">
								<span className="gray-text">Athlete Level</span>
								<span className="white-text">{
									(athleteLevel) ? athleteLevel.levelName : null
								}</span>
							</div>
						</div>
						{
							(props.groupLevels) ? (
								<>
									<p className="gray-text text-center mb-2">Select Planner</p>

									<div className='styled-select '>
										{/* <select name='cm2_athlete_group_planner' onChange={handlePlanner} value={props.user.planners[0]?.plannerId}> */}
										<select name='cm2_athlete_group_planner' onChange={handlePlanner} >
											<option>select planner</option>
											{props.groupLevels.planners.map((planner, index) => (
												<option key={index}  value={planner._id}>{planner.title}</option>
											))}
										</select>
									</div>
								</>
							) : ''
						}
						{
							(groupLevels) ? <><div className='styled-select'>
								<select name='cm2_athlete_group_planner' onChange={handleGroupLevel}>
									<option>select group level</option>
									{groupLevels && groupLevels.map((groupLevel, index) => (
										<option key={index} value={groupLevel._id}>{groupLevel.levelName}</option>
									))}
								</select>


							</div>

							</>
								: ''
						}
						<input className='selbtnin' type="button" value="Save" onClick={handleUpdateUserPlanner}></input>
						{/* <input className='selbtnin' type="button" value="Save" onClick={planners ? handleUpdateUserPlanner : handleUpdateGroupLevel}></input> */}


						<div className="joined-date common-listing mb-3">
							<br />
							<span className="gray-text">Joined</span><br />
							<span className="white-text">{(props.user.registered) ? formatDate(props.user.registered) : null}</span>
						</div>

						<div className="group-level common-listing mb-3">
							<span className="gray-text">Group</span><br />
							<span className="white-text">
								Social Swim
								{/* {(props.groups) ? props.groups[0].levelName : null} */}

							</span>
						</div>

						<div className="athlete-navigation">
							<ul>
								<li>
									<Link to={"/team/" + props.clubSlug + "/athlete-dashboard/"}>
										Dashboard
									</Link>
								</li>
								<li>
									<Link to={"/team/" + props.clubSlug + "/athlete-planner/"}>
										Planner
									</Link>
								</li>
								<li>
									<Link to={"/team/" + props.clubSlug + "/athlete-planner/"}>
										Mesages
									</Link>
								</li>
								<li onClick={logOutAthlete}>
									<a href="javascript:void(0);" className="mt-3">Log out</a>
								</li>
							</ul>
						</div>

					</div>


				</div>

			</div>
			{
				(props.userCoach) ?
					<div className="bottom-profile">
						<div className="profile clearfix">
							<Link to={"/profile/" + props.userCoach.userId}>
								<div className="profile_pic">
									<img src={
										props.userCoach.profilePicture
											? getServerUrl().apiURL +
											"/uploads/user/" +
											props.userCoach.profilePicture
											: profileBlank
									} className="avatar user-5-avatar avatar-50 photo" alt="" width="50" height="50" />
								</div>
							</Link>
						</div>
						<div className="coach-name-mbt">
							<span className="white-text">{props.userCoach.firstName + ' ' + props.userCoach.lastName}</span>
							<span className="gray-text">Your Coach</span>
						</div>
						<div className="send-message-btn">
							<a href="#">
								Send Message
							</a>
						</div>
					</div> : null

			}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		modals: state.planner.modals,
		userCoach: state.planner.userCoach,
		groupLevels: state.planner.groupLevels,
	};

};

export default withRouter(connect(mapStateToProps, { logOutUser })(LeftSidebar));
