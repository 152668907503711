import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { hidePlannerGraph} from '../../../actions';
import * as API from '../../../utils/api.js'
import { Modal} from 'react-bootstrap';
import ReactHighcharts from 'react-highcharts';
const PlannerGraph = (props) => {
    const [activeTab, setActiveTab] = useState('time');
    const [hours, sethours] = useState([]);
    const [dates, setDates] = useState([]);
    const [distance, setDistance] = useState([]);
    const [options, setOptions] = useState('');
    const [load, setLoad] = useState('');
    const [programName, setprogramName] = useState('');
    const [programWeek, setprogramWeek] = useState('');
    const [programStartDate, setProgramStartDate] = useState(null);
  const [programEndDate, setProgramEndDate] = useState(null);
    useEffect(() => {
        let dateArray = [];
        let hours_arr = [];
        let distance_arr = [];
        var load_arr = [];
        API.getProgramById(props.programId.program_id).then(response => {
            let programStartDate = new Date(response.startDate);
           let formatStartDate = formatDateMonth(programStartDate);
          let programEndcDate= programStartDate.setDate(programStartDate.getDate() + response.weeks * 7);
            let formatEndDate = formatDateMonth(programEndcDate);
            if (response.sessions && response.sessions.length > 0) {
                response.sessions.forEach((value, index) => {
                    let date = new Date(programStartDate);
                    let days = value.days;
                    let newDate = date.setDate(date.getDate(date) + days);
                    const inputDate = new Date(newDate);
                    const formattedDate = formatDate(inputDate);
                    dateArray.push(formattedDate);

                    // hours 
                    let hours_mins = parseInt(value.hours) + parseFloat((parseInt(value.minutes) / 60).toFixed(2));
                    var hourobj = { y: hours_mins, color: value.color };
                    hours_arr.push(hourobj);
                    //distance
                    let distanceobj = { y: parseInt(value.distance.$numberDecimal), color: value.color };
                    distance_arr.push(distanceobj);
                    // rpe load 

                    var loadobj = { y: parseInt(value.rpeLoad), color: value.color };
                    load_arr.push(loadobj);

                });

            }
            setprogramName(response.title);
            setprogramWeek(response.weeks);
            setDates(dateArray);
            sethours(hours_arr);
            setDistance(distance_arr);
            setLoad(load_arr);
            setProgramStartDate(formatStartDate);
            setProgramEndDate(formatEndDate);
        });

        const params2 = {
            credits: {
                enabled: false
            },
            chart: {
                type: 'column'
            },
            title: {
                text: 'Time Representation'
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: dateArray

            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Hours'
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y:.1f} Hr</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                data: hours_arr,
            }]
        }

        setOptions(params2);
    }, []);
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleClose = () => {
        props.hidePlannerGraph();
    };
    const formatDateMonth = (dateString) => {
        const date = new Date(dateString);
        var options = { day: 'numeric', month: 'long' };
        return date.toLocaleDateString('en-US', options);
        
      };
    const handleTabClick = (index) => {
        setActiveTab(index);
        if (index == 'time') {
            let optionOne = {
                credits: {
                    enabled: false
                },
                chart: {
                    type: 'column'
                },
                title: {
                    text: 'Time Representation'
                },
                subtitle: {
                    text: ''
                },
                xAxis: {
                    categories: dates

                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Hours'
                    }
                },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:.1f} Hr</b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: [{
                    data: hours,
                }]
            }
            setOptions(optionOne);
        }

        else if (index == 'distance') {
            let optiontwo = {
                credits: {
                    enabled: false
                },
                chart: {
                    type: 'column'
                },
                title: {
                    text: 'Distance Representation'
                },
                subtitle: {
                    text: ''
                },
                xAxis: {
                    categories: dates

                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Distance'
                    }
                },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: [{
                    data: distance,
                }]
            }
            setOptions(optiontwo);
        }
        else if (index == 'load') {
            let optionthree = {
                credits: {
                    enabled: false
                },
                chart: {
                    type: 'column'
                },
                title: {
                    text: 'Load Representation'
                },
                subtitle: {
                    text: ''
                },
                xAxis: {
                    categories: dates

                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Load'
                    }
                },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: [{
                    data: load,
                }]
            }
            setOptions(optionthree);
        }
    };
    return (
        <Modal
            centered
            size="xl"
            show={true}
            onHide={handleClose}
            dialogClassName="planner-dialog" className='customize-edit-planner graph-planner-popup'
        >
            <Modal.Header closeButton>
                <Modal.Title>Graphical Representation Of Program: {programName ? programName :''}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row align-items-center planner-bg-modal'>
                <div className="col-sm-6">
                 <h4 class="prog-sess">{programWeek} Week Program from {programStartDate} to {programEndDate}</h4>
            </div>
                <div className='col-sm-6'>
                    <ul className="nav nav-pills">
                        <li className={activeTab == 'time' ? 'active' : ''}><a onClick={() => handleTabClick('time')} href="#menu1">Show Time</a></li>
                        <li className={activeTab == 'distance' ? 'active' : ''}><a onClick={() => handleTabClick('distance')} href="#menu2">Show Distance</a></li>
                        <li className={activeTab == 'load' ? 'active' : ''}><a onClick={() => handleTabClick('load')} href="#menu3">Show Load</a></li>
                    </ul>
                </div>
                </div>
           
                {
                    options?.credits ? (

                        <ReactHighcharts config={options} />

                    ) : (<></>)
                }
            </Modal.Body>

        </Modal>
    );
}

const mapStateToProps = state => {
    return {
        EditPlanner: state.planner.modals.EditPlanner,
        programId: state.planner.programId,
    };
};
export default connect(mapStateToProps, { hidePlannerGraph })(PlannerGraph);
