import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getServerUrl } from "../../utils/api.js";
import profileBlank from "../../assets/profile_blank.png";
import loading from "../../assets/loading.svg";

const PrivateMessagesContactBox = ({
	users,
	onLoadConversation,
	onToggleMessages,
	retrieving,
}) => {
	return (
		<>
			{retrieving ? (
				<img src={loading} alt="" />
			) : (
				<div className="msgitemsbx">
					{users.map((user) => (
						<div className="usrtop" key={`messages-${user._id}`}>
							<div className="row">
								<div className="col-auto f12">
									<div className="userthumb">
										<a className="userbx">
											<img
												src={
													user.profilePicture
														? `${
																getServerUrl()
																	.apiURL
														  }/uploads/user/${
																user.profilePicture
														  }`
														: profileBlank
												}
											/>
										</a>
									</div>
								</div>
								<div className="col-7 pl-0 msgusrnm">
									<a
										href="#"
										onClick={(e) =>
											onLoadConversation(e, user._id, {
												name:
													`${user.firstName} ${user.lastName}` ||
													"",
												profilePicture:
													user.profilePicture || "",
												_id: user._id,
												userId: user.userId || "",
											})
										}
									>
										{`${user.firstName} ${user.lastName}`}
									</a>
									{!this.props.currUser.followers.includes(
										user.userId
									) &&
									!this.props.currUser.following.includes(
										user.userId
									) ? (
										<span className="small lgtgrytxt">
											Not friend
										</span>
									) : user.role === "Coach" ? (
										<span className="small grntxt">
											Coach
										</span>
									) : (
										""
									)}
								</div>
								{user.unseenCount > 0 && (
									<div className="col-1 nopad incmnewmsg">
										<p>{user.unseenCount}</p>
									</div>
								)}
							</div>
						</div>
					))}
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	};
};

export default connect(mapStateToProps, {})(PrivateMessagesContactBox);
