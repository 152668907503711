/**
 * =====================================
 * REACT COMPONENT CLASS
 * =====================================
 * @date created: 24 August 2019
 * @authors: Waqas Rehmani, Hasitha Dias
 *
 * This file defines the Post component. The class Post
 * is where the component is defined.
 *
 * This is a component defined for reusability.
 *
 * This component shows a post that is seen throughout the app.
 *
 */

// Importing libraries for setup
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { DateTime } from "luxon";
import {
	toggleReactionOpt,
	toggleEllipsisOpt,
	updateUserSaved,
	updateShowPostFilter,
	updateShowPostShare,
} from "../actions";
import OutsideClickHandler from "react-outside-click-handler";
import { assignTimeAgo } from "../utils/helper";

// Importing icons and pictures
import profileBlank from "../assets/profile_blank.png";

// Importing helper functions
import {
	getUser,
	getServerUrl,
	getSiteUrl,
	savePostToSavedList,
	unsavePostSavedList,
	sharePost,
} from "../utils/api.js";

// Importing other components
import Kudos from "./Kudos";
import AnswerDetail from "../components/AnswerDetail";
import Share from "./Share";

// Importing icons and pictures
import Badminton from "../assets/Badminton.svg";
import Cycling from "../assets/Cycling.svg";
import Football from "../assets/Football.svg";
import Gym from "../assets/Gym.svg";
import Running from "../assets/Running.svg";
import Swimming from "../assets/Swimming.svg";
import Tennis from "../assets/Tennis.svg";
import Walking from "../assets/Walking.svg";
import Yoga from "../assets/Yoga.svg";
import Avatar from "./common/avatar";

class Post extends Component {
	// Constructor for Post
	constructor(props) {
		super(props);

		this.state = {
			postDetails: {
				id: props.post._id,
				postId: props.post.postId,
				userId: props.post.userId,
				kudos: props.post.kudos,
				type: props.post.type,
				description: props.post.description,
				interest: props.post.interest,
				images: props.post.imgFileName,
				videos: props.post.videoFileName,
				comments: props.post.comments,
			},
			image: "",
			postOwner: {},
			profilePicture: "",
			timeAgo: null,
			editPost: false,
			dropdownVisible: false,
			hasAnswerPermission: true,
			acceptedAnswer: {},
			totalFoundHelpful: 0,
			reaction: "Thumbs Up",
			hasLiked: false,
			hasBumSlapped: false,
			hasBackSlapped: false,
			hasGotThis: false,
			hasBeStrong: false,
			hasAwesome: false,
			hasCongrats: false,
			showReactionOpt: false,
			showEllipsisOpt: false,
			saved: this.props.user.saved,
		};
	}

	// This method is called when the component first mounts after the constructor is called
	componentDidMount() {
		// owner data is not passed or owner is not found
		if (this.props.owner !== undefined && this.props.owner !== "Unknown") {
			this.setState({
				postOwner: this.props.owner,
				profilePicture: this.props.owner.profilePicture,
			});
		} else {
			if (this.props.user.userId !== this.props.post.userId) {
				getUser(this.props.post.userId, this.props.token).then(
					(user) => {
						this.setState({
							postOwner: user,
							profilePicture: user.profilePicture,
						});
					}
				);
			} else {
				this.setState({
					postOwner: this.props.user,
					profilePicture: this.props.user.profilePicture,
				});
			}
		}

		this.assignAnswerPermission(
			this.props.post.comments,
			this.props.post.userId
		);

		this.getAcceptedAnswer(this.props.post.comments);

		this.assignReaction(this.props.post.kudos);

		this.assignTimeAgo(this.props.post.time);
	}

	toggleReactionOpt = () => {
		this.props.toggleReactionOpt(this.props.post._id);
		//this.setState({ showReactionOpt: !this.state.showReactionOpt });
	};

	openToggleReactionOpt = () => {
		if (!this.state.showReactionOpt) {
			this.props.toggleReactionOpt(this.props.post._id);
			this.setState({ showReactionOpt: !this.state.showReactionOpt });
		} else this.setState({ showReactionOpt: !this.state.showReactionOpt });
	};

	toggleEllipsisOpt = () => {
		this.props.toggleEllipsisOpt(this.props.post._id);
		//this.setState({ showEllipsisOpt: !this.state.showEllipsisOpt });
	};

	openToggleEllipsisOpt = () => {
		if (!this.state.showEllipsisOpt) {
			this.props.toggleEllipsisOpt(this.props.post._id);
			this.setState({ showEllipsisOpt: !this.state.showEllipsisOpt });
		} else this.setState({ showEllipsisOpt: !this.state.showEllipsisOpt });
	};

	handleReactionChange = (reaction) => {
		if (reaction === "Thumbs Up") {
			if (
				this.props.post.kudos.likes.find(
					(p) => p === this.props.user.userId
				)
			) {
				this.setState({ hasLiked: true });
			} else {
				this.setState({ hasLiked: false });
			}
		} else if (reaction === "Love It") {
			if (
				this.props.post.kudos.bumSlaps.find(
					(p) => p === this.props.user.userId
				)
			) {
				this.setState({ hasBumSlapped: true });
			} else {
				this.setState({ hasBumSlapped: false });
			}
		} else if (reaction === "Fist Pump") {
			if (
				this.props.post.kudos.backSlaps.find(
					(p) => p === this.props.user.userId
				)
			) {
				this.setState({ hasBackSlapped: true });
			} else {
				this.setState({ hasBackSlapped: false });
			}
		} else if (reaction === "Got This") {
			if (
				this.props.post.kudos.gotThis.find(
					(p) => p === this.props.user.userId
				)
			) {
				this.setState({ hasGotThis: true });
			} else {
				this.setState({ hasGotThis: false });
			}
		} else if (reaction === "Be Strong") {
			if (
				this.props.post.kudos.beStrong.find(
					(p) => p === this.props.user.userId
				)
			) {
				this.setState({ hasBeStrong: true });
			} else {
				this.setState({ hasBeStrong: false });
			}
		} else if (reaction === "Awesome") {
			if (
				this.props.post.kudos.awesome.find(
					(p) => p === this.props.user.userId
				)
			) {
				this.setState({ hasAwesome: true });
			} else {
				this.setState({ hasAwesome: false });
			}
		} else if (reaction === "Congrats") {
			if (
				this.props.post.kudos.congrats.find(
					(p) => p === this.props.user.userId
				)
			) {
				this.setState({ hasCongrats: true });
			} else {
				this.setState({ hasCongrats: false });
			}
		}

		this.setState({ reaction });
	};

	assignAnswerPermission = (answers, postUserId) => {
		answers.forEach((answer) => {
			// user already answered the question
			if (answer.userId === this.props.user.userId) {
				this.setState({ hasAnswerPermission: false });
			}
		});

		if (this.props.user.userId === postUserId) {
			this.setState({ hasAnswerPermission: false });
		}
	};

	getAcceptedAnswer = (answers) => {
		if (this.props.notGeneral) {
			let acceptedAnswer = answers.find((answer) => answer.accepted);
			if (acceptedAnswer !== undefined) {
				this.setState({ acceptedAnswer });
			}
		} else {
			let totalFoundHelpful = 0;
			for (const answer of answers) {
				totalFoundHelpful += answer.likes.length;
			}
			this.setState({ totalFoundHelpful });
		}
	};

	updateAcceptedAnswer = (answer) => {
		this.setState({ acceptedAnswer: answer });
	};

	assignReaction = (kudos) => {
		if (kudos.likes.find((p) => p === this.props.user.userId)) {
			this.setState({ reaction: "Thumbs Up", hasLiked: true });
		} else if (kudos.bumSlaps.find((p) => p === this.props.user.userId)) {
			this.setState({ reaction: "Love It", hasBumSlapped: true });
		} else if (kudos.backSlaps.find((p) => p === this.props.user.userId)) {
			this.setState({ reaction: "Fist Pump", hasBackSlapped: true });
		} else if (kudos.gotThis.find((p) => p === this.props.user.userId)) {
			this.setState({ reaction: "Got This", hasGotThis: true });
		} else if (kudos.beStrong.find((p) => p === this.props.user.userId)) {
			this.setState({ reaction: "Be Strong", hasBeStrong: true });
		} else if (kudos.awesome.find((p) => p === this.props.user.userId)) {
			this.setState({ reaction: "Awesome", hasAwesome: true });
		} else if (kudos.congrats.find((p) => p === this.props.user.userId)) {
			this.setState({ reaction: "Congrats", hasCongrats: true });
		}
	};

	assignTimeAgo = (date) => {
		const units = [
			"year",
			"month",
			"week",
			"day",
			"hour",
			"minute",
			"second",
		];

		// convert date to Duration type
		let dateTime = DateTime.fromISO(date);

		// get time difference compared to the item datetime
		// shift to units for displaying
		const diffTime = dateTime.diffNow().shiftTo(...units);

		// get the first dominant time unit to display
		const unit = units.find((unit) => diffTime.get(unit) !== 0) || "second";

		// create relative time formatter in your locale
		const relativeFormatter = new Intl.RelativeTimeFormat("en", {
			numeric: "auto",
		});

		// math.trunc converts the diff time to whole number
		let timeAgo = relativeFormatter.format(
			Math.trunc(diffTime.as(unit)),
			unit
		);

		this.setState({ timeAgo });
	};

	savePost = (postId) => {
		savePostToSavedList(this.props.user.userId, postId).then((res) => {
			if (res.success === true) {
				this.props.updateUserSaved(res.saved);
				this.setState({ showEllipsisOpt: false, saved: res.saved });
			}
		});
	};

	unsavePost = (postId) => {
		unsavePostSavedList(this.props.user.userId, postId).then((res) => {
			if (res.success === true) {
				this.props.updateUserSaved(res.saved);
				this.setState({ showEllipsisOpt: false, saved: res.saved });
			}
		});
	};

	deletePost = (postId) => {
		if (window.confirm("Are you sure you wish to delete this post?")) {
			this.props.deletePost(postId);
		}
	};

	showActivityFilter = () => {
		if (window.location.pathname === "/home")
			this.props.updateShowPostFilter(true);
	};

	sharePost = (show) => {
		//sharePost();
		this.props.updateShowPostShare(show);
	};

	// Render method for for Post
	render() {
		if (
			this.props.location.pathname === "/saved/" &&
			!this.props.user.saved.includes(this.props.post._id)
		) {
			return null;
		}
		let activityIcon =
			window.location.pathname === "/home" ? { cursor: "pointer" } : {};
		let reaction = "thumbs-up.png";
		if (this.state.reaction === "Love It") reaction = "love-it.png";
		else if (this.state.reaction === "Fist Pump")
			reaction = "first-pump.png";
		else if (this.state.reaction === "Thumbs Up")
			reaction = "thumbs-up.png";
		else if (this.state.reaction === "Got This")
			reaction = "you-got-this.png";
		else if (this.state.reaction === "Be Strong")
			reaction = "be-strong.png";
		else if (this.state.reaction === "Awesome") reaction = "awesome.png";
		else if (this.state.reaction === "Congrats")
			reaction = "congratulations.png";

		return !window.matchMedia("(max-width: 500px)").matches ? (
			<div
				className={`postbx bxshadow ${
					this.props.isQuestionPage ? "psttxt" : ""
				}`}
				style={{ overflow: "hidden" }}
			>
				<div className="usrtop">
					<div className="row">
						<div className="col-6">
							<div className="userthumb">
								<Link
									className="userbx"
									to={`/profile/${this.props.post.userId}`}
								>
									<Avatar
										url={"/uploads/user/"}
										state={this.state.profilePicture}
										alt={"user"}
									/>
								</Link>{" "}
								<div>
									<Link
										to={`/profile/${this.props.post.userId}`}
									>
										{`${this.state.postOwner.firstName} ${this.state.postOwner.lastName}`}
									</Link>
									<span className="small pstim">
										{this.state.timeAgo}
									</span>
								</div>
							</div>
						</div>

						<div className="col-6">
							{this.state.postDetails.interest.icon && (
								<span className="acttyp pushright">
									<img
										src={`/uploads/images/${this.state.postDetails.interest.icon}`}
									/>
								</span>
							)}
						</div>
					</div>
				</div>
				{this.props.isQuestionPage ? (
					<>
						{this.state.postDetails.images.length > 0 &&
							this.state.postDetails.images.map((img) => (
								<div
									className="pstmd post-img-parent"
									key={img}
								>
									<Avatar
										url={"/uploads/posts/"}
										state={img}
										alt={"post"}
										onClick={() =>
											this.props.openPictureViewer(
												`${
													getServerUrl().apiURL
												}/uploads/posts/${img}`
											)
										}
										className={"post-img"}
										style={{
											cursor: "pointer",
										}}
									/>
								</div>
							))}
						{this.state.postDetails.videos.length > 0 &&
							this.state.postDetails.videos.map((video) => {
								let type = "";
								if (video.endsWith(".avi")) type = "video/avi";
								else if (video.endsWith(".mp4"))
									type = "video/mp4";
								else if (video.endsWith(".wmv"))
									type = "video/x-ms-wmv";
								else if (video.endsWith(".ogg"))
									type = "video/ogg";
								else if (video.endsWith(".webm"))
									type = "video/webm";

								return (
									<div className="pstmd" key={video}>
										<video
											width="100%"
											height="240"
											controls
										>
											<source
												src={`${
													getServerUrl().apiURL
												}/uploads/posts/${video}`}
												type={type}
											></source>
										</video>
									</div>
								);
							})}
						<p className="f14">
							{this.state.postDetails.description}
						</p>

						<p className="col-md-12 nopad pt-0">
							<Link
								className="btn btngrn col-md-6"
								to={`/answers/${this.state.postDetails.postId}`}
							>
								{this.state.hasAnswerPermission
									? "Answer This Question"
									: "View All"}
							</Link>
						</p>

						{Object.keys(this.state.acceptedAnswer).length > 0 && (
							<AnswerDetail
								key={this.state.acceptedAnswer.commentId}
								post={this.props.post}
								answers={this.state.postDetails.comments}
								answerOwners={{}}
								answer={this.state.acceptedAnswer}
								acceptedAnswer={this.state.acceptedAnswer}
								createAnswer={this.props.createComment}
								onUpdateAcceptedAnswer={
									this.updateAcceptedAnswer
								}
							/>
						)}
					</>
				) : (
					<>
						{this.props.isFullView && (
							<p className="f14">
								{this.state.postDetails.description}
							</p>
						)}
						{!this.props.isFullView && (
							<Link to={"/post/" + this.state.postDetails.postId}>
								<p className="f14">
									{this.state.postDetails.description}
								</p>
							</Link>
						)}

						{this.state.postDetails.images.map((img) => (
							<div className="pstmd post-img-parent" key={img}>
								<Avatar
									url={"/uploads/posts/"}
									state={img}
									alt={"post"}
									style={{
										cursor: "pointer",
									}}
									className={"post-img"}
									onClick={() =>
										this.props.openPictureViewer(
											`${
												getServerUrl().apiURL
											}/uploads/posts/${img}`
										)
									}
								/>
							</div>
						))}
						{this.state.postDetails.videos.map((video) => {
							let type = "";
							if (video.endsWith(".avi")) type = "video/avi";
							else if (video.endsWith(".mp4")) type = "video/mp4";
							else if (video.endsWith(".wmv"))
								type = "video/x-ms-wmv";
							else if (video.endsWith(".ogg")) type = "video/ogg";
							else if (video.endsWith(".webm"))
								type = "video/webm";

							return (
								<div className="pstmd" key={video}>
									<video width="100%" height="240" controls>
										<source
											src={`${
												getServerUrl().apiURL
											}/uploads/posts/${video}`}
											type={type}
										></source>
									</video>
								</div>
							);
						})}
					</>
				)}

				{!this.props.isQuestionPage && (
					<div className="row">
						<div className="rctbx col-9">
							{this.state.postDetails.kudos.bumSlaps.length >
								0 && (
								<span>
									<img src="/uploads/images/love-it.png" />
									{
										this.state.postDetails.kudos.bumSlaps
											.length
									}
								</span>
							)}
							{this.state.postDetails.kudos.backSlaps.length >
								0 && (
								<span>
									<img src="/uploads/images/first-pump.png" />
									{
										this.state.postDetails.kudos.backSlaps
											.length
									}
								</span>
							)}
							{this.state.postDetails.kudos.likes.length > 0 && (
								<span>
									<img src="/uploads/images/thumbs-up.png" />
									{this.state.postDetails.kudos.likes.length}
								</span>
							)}
							{this.state.postDetails.kudos.gotThis.length >
								0 && (
								<span>
									<img src="/uploads/images/you-got-this.png" />
									{
										this.state.postDetails.kudos.gotThis
											.length
									}
								</span>
							)}
							{this.state.postDetails.kudos.beStrong.length >
								0 && (
								<span>
									<img src="/uploads/images/be-strong.png" />
									{
										this.state.postDetails.kudos.beStrong
											.length
									}
								</span>
							)}
							{this.state.postDetails.kudos.awesome.length >
								0 && (
								<span>
									<img src="/uploads/images/awesome.png" />
									{
										this.state.postDetails.kudos.awesome
											.length
									}
								</span>
							)}
							{this.state.postDetails.kudos.congrats.length >
								0 && (
								<span>
									<img src="/uploads/images/congratulations.png" />
									{
										this.state.postDetails.kudos.congrats
											.length
									}
								</span>
							)}
							{/* <span>
                                <img src='/uploads/images/symbol4.png' />
                                23
                            </span> */}
							<span>
								<img src="/uploads/images/symbol5.png" />
								{this.state.postDetails.comments.length}
							</span>
						</div>
						<div className="optbx col-3 text-right">
							<a
								style={{ cursor: "pointer" }}
								onClick={this.openToggleEllipsisOpt}
								className={
									!this.props.hasActionPermission
										? "dislinks blur"
										: ""
								}
							>
								<img src="/uploads/images/ver-opt.png" />
							</a>

							{this.props.showEllipsisOpt &&
								this.props.showEllipsisOptId ==
									this.props.post._id && (
									<OutsideClickHandler
										onOutsideClick={() => {
											this.toggleEllipsisOpt();
										}}
									>
										<div className="ovrmnu">
											<ul>
												<li>
													{!this.state.saved.includes(
														this.state.postDetails
															.id
													) ? (
														<a
															onClick={() =>
																this.savePost(
																	this.state
																		.postDetails
																		.id
																)
															}
														>
															Save Post
														</a>
													) : (
														<a
															onClick={() =>
																this.unsavePost(
																	this.state
																		.postDetails
																		.id
																)
															}
														>
															Unsave Post
														</a>
													)}
												</li>
												{this.props.post.userId ==
													this.props.user.userId && (
													<li>
														<Link
															to={
																this.props.post
																	.isQuestion
																	? "/question/" +
																	  this.state
																			.postDetails
																			.postId +
																	  "/edit"
																	: "/post/" +
																	  this.state
																			.postDetails
																			.postId +
																	  "/edit"
															}
															onClick={() =>
																this.toggleEllipsisOpt()
															}
														>
															Edit Post
														</Link>
													</li>
												)}
												{this.props.post.userId ==
													this.props.user.userId && (
													<li>
														<a
															onClick={() =>
																this.deletePost(
																	this.state
																		.postDetails
																		.id
																)
															}
														>
															Delete Post
														</a>
													</li>
												)}
											</ul>
										</div>
									</OutsideClickHandler>
								)}
						</div>
					</div>
				)}

				<div className="lkbxbtm f12">
					<div className="row">
						{!this.props.isQuestionPage ? (
							<>
								<div className="col">
									<a
										onClick={this.openToggleReactionOpt}
										className={
											!this.props.hasActionPermission
												? "dislinks blur"
												: ""
										}
										style={{ cursor: "pointer" }}
									>
										<img
											src={`/uploads/images/${
												reaction
												/*this.state.reaction === 'Love It'
                                                    ? 'love-it.png'//this.state.hasBumSlapped
                                                        //? 'bum-c.png'
                                                        //: 'symbol1.png'
                                                    : this.state.reaction ===
                                                      'Fist Pump'
                                                    ? 'first-pump.png'//this.state.hasBackSlapped
                                                        //? 'back-c.png'
                                                        //: 'symbol2.png'
                                                    : this.state.reaction ===
                                                          'Thumbs Up' &&
                                                      'thumbs-up.png'//this.state.hasLiked
                                                    //? 'thumbs-c.png'
                                                    //: 'symbol3.png'*/
											}`}
										/>
										{this.state.reaction}
									</a>
									{this.props.showReactionOpt &&
										this.props.showReactionOptId ==
											this.props.post._id && (
											<OutsideClickHandler
												onOutsideClick={() => {
													this.toggleReactionOpt();
												}}
											>
												<Kudos
													post={this.props.post}
													changeKudos={
														this.props.changeKudos
													}
													onToggleReactionOpt={
														this.toggleReactionOpt
													}
													onHandleReactionChange={
														this
															.handleReactionChange
													}
													isQuestionPage={
														this.props
															.isQuestionPage
													}
												/>
											</OutsideClickHandler>
										)}
								</div>

								<div className="col">
									<Link
										to={`/post/${
											this.state.postDetails.postId
										}${
											!this.props.hasActionPermission
												? "/view"
												: ""
										}`}
									>
										<img src="/uploads/images/comment.png" />
										Comment
									</Link>
								</div>

								<div className="col">
									<a
										className={
											/*!this.props.hasActionPermission || this.props.user.userId === this.props.post.userId ? 'dislinks blur' : */ ""
										}
										style={{ cursor: "pointer" }}
										onClick={() => this.sharePost(true)}
									>
										<img src="/uploads/images/share.png" />
										Share
									</a>
									{this.props.showPostShare && (
										<Share
											title={
												this.state.postDetails
													.description
											}
											url={
												getSiteUrl().siteURL +
												"/post/" +
												this.state.postDetails.postId
											}
										/>
									)}
								</div>
							</>
						) : (
							<>
								<div className="col">
									<a
										className={
											/*!this.props.hasActionPermission || this.props.user.userId === this.props.post.userId ? 'dislinks blur' : */ ""
										}
										style={{ cursor: "pointer" }}
										onClick={() => this.sharePost(true)}
									>
										<img src="/uploads/images/share.png" />
										Share
									</a>
									{this.props.showPostShare && (
										<Share
											title={
												this.state.postDetails
													.description
											}
											url={
												getSiteUrl().siteURL +
												"/answers/" +
												this.state.postDetails.postId
											}
										/>
									)}
								</div>
								<div className="col">
									{!this.state.saved.includes(
										this.state.postDetails.id
									) ? (
										<a
											onClick={() =>
												this.savePost(
													this.state.postDetails.id
												)
											}
											style={{ cursor: "pointer" }}
										>
											<img src="/uploads/images/comment.png" />
											Save
										</a>
									) : (
										<a
											onClick={() =>
												this.unsavePost(
													this.state.postDetails.id
												)
											}
											style={{ cursor: "pointer" }}
										>
											<img src="/uploads/images/comment.png" />
											Unsave
										</a>
									)}
								</div>
								{/* <div className='col'>
                                    <a href='#'>
                                        <img src='/uploads/images/hand.png' />
                                        Report
                                    </a>
                                </div> */}
							</>
						)}
					</div>
				</div>
				{this.props.commentsOwner !== undefined &&
					this.state.postDetails.comments.length > 0 && (
						<div className="comntsec mb-4 mt-0">
							<h6>Comment</h6>
							<div className="comntbx">
								<div className="usrtop">
									<div className="row">
										<div className="col-2">
											<div className="userthumb">
												<Link
													to={
														"/profile/" +
														this.state.postDetails
															?.comments[0]
															?.userId
													}
													className="userbx"
												>
													<Avatar
														url={"/uploads/user/"}
														state={
															this.props
																.commentsOwner[
																this.state
																	.postDetails
																	.comments[0]
																	.userId
															]?.profilePicture
														}
														alt={" profile picture"}
													/>
												</Link>
											</div>
										</div>
										<div className="col-10 nopad pt-1">
											<Link
												to={
													"/profile/" +
													this.state.postDetails
														.comments[0].userId
												}
											>
												{`${
													this.props.commentsOwner[
														this.state.postDetails
															.comments[0].userId
													].firstName
												} 
                                            ${
												this.props.commentsOwner[
													this.state.postDetails
														.comments[0].userId
												].lastName
											}`}
											</Link>
											<span className="small pstim">
												{assignTimeAgo(
													this.state.postDetails
														.comments[0].time
												)}
											</span>
										</div>
									</div>
								</div>
								<div className="cmntxt">
									<p>
										{
											this.state.postDetails.comments[0]
												.description
										}
									</p>
								</div>
							</div>
						</div>
					)}
			</div>
		) : this.props.isQuestionPage ? (
			<>
				<div className="postbx psttxt bxshadow">
					<div className="usrtop">
						<div className="row">
							<div className="col-2">
								<div className="userthumb">
									<Link
										className="userbx"
										to={`/profile/${this.props.post.userId}`}
									>
										<Avatar
											url={"/uploads/user/"}
											state={this.state.profilePicture}
											alt={"user"}
											onClick={() =>
												this.props.history.push(
													"/profile/" +
														this.props.post.userId
												)
											}
										/>
									</Link>
								</div>
							</div>
							<div className="col-8 pl-2 nopad pt-1">
								<Link
									to={"/profile/" + this.props.post.userId}
									className={"post-userId"}
								>
									{this.state.postOwner.firstName}{" "}
									{this.state.postOwner.lastName}
								</Link>

								<span className="small pstim">
									{this.state.timeAgo}
								</span>
							</div>
							{this.state.postDetails.interest.icon && (
								<div
									className="col-2 text-right"
									style={activityIcon}
								>
									<span
										className="acttyp"
										onClick={() =>
											this.showActivityFilter()
										}
									>
										<img
											src={`/uploads/images/${this.state.postDetails.interest.icon}`}
										/>
									</span>
								</div>
							)}
						</div>
					</div>
					{this.state.postDetails.images.length > 0 &&
						this.state.postDetails.images.map((img) => (
							<div className="pstmd post-img-parent" key={img}>
								<Avatar
									url={"/uploads/posts/"}
									state={img}
									alt={"post"}
									className={"post-img"}
									style={{
										cursor: "pointer",
									}}
									onClick={() =>
										this.props.openPictureViewer(
											`${
												getServerUrl().apiURL
											}/uploads/posts/${img}`
										)
									}
								/>
							</div>
						))}
					{this.state.postDetails.videos.length > 0 &&
						this.state.postDetails.videos.map((video) => {
							let type = "";
							if (video.endsWith(".avi")) type = "video/avi";
							else if (video.endsWith(".mp4")) type = "video/mp4";
							else if (video.endsWith(".wmv"))
								type = "video/x-ms-wmv";
							else if (video.endsWith(".ogg")) type = "video/ogg";
							else if (video.endsWith(".webm"))
								type = "video/webm";

							return (
								<div className="pstmd" key={video}>
									<video width="100%" height="240" controls>
										<source
											src={`${
												getServerUrl().apiURL
											}/uploads/posts/${video}`}
											type={type}
										></source>
									</video>
								</div>
							);
						})}
					<div className="qstnbx">
						<p>{this.state.postDetails.description}</p>

						<Link
							className="btn btngrn"
							to={`/answers/${this.state.postDetails.postId}`}
						>
							{this.state.hasAnswerPermission
								? "Answer This Question"
								: "View All"}
						</Link>
					</div>

					{this.props.notGeneral &&
						Object.keys(this.state.acceptedAnswer).length > 0 && (
							<AnswerDetail
								key={this.state.acceptedAnswer.commentId}
								post={this.props.post}
								answers={this.state.postDetails.comments}
								answerOwners={{}}
								answer={this.state.acceptedAnswer}
								acceptedAnswer={this.state.acceptedAnswer}
								createAnswer={this.props.createComment}
								onUpdateAcceptedAnswer={
									this.updateAcceptedAnswer
								}
							/>
						)}
					{!this.props.notGeneral && (
						<div className="row mb-3">
							<div
								className="col-6 pr-4"
								style={{
									paddingLeft: "26px",
								}}
							>
								<p className="grntxt">
									{this.state.totalFoundHelpful} found helpful
								</p>
								{/* <a
                                    className={'btn grnbtn'}
                                    style={{ display: 'block' }}
                                >
                                    {this.state.postDetails.comments.length} answer(s)
                                </a> */}
							</div>

							<div className="col-6 pl-1 text-center">
								<p>
									{this.state.postDetails.comments.length}{" "}
									answer(s)
								</p>
								{/* <a
                                    
                                    className={'btn btngry'}
                                >
                                    found helpful
                                </a> */}
							</div>
						</div>
					)}
					<div className="lkbxbtm f12">
						<div className="row">
							<div className="col">
								<a
									className={
										/*!this.props.hasActionPermission || this.props.user.userId === this.props.post.userId ? 'dislinks blur' : */ ""
									}
									style={{ cursor: "pointer" }}
									onClick={() => this.sharePost(true)}
								>
									<img src="/uploads/images/share.png" />
									{"Share"}
								</a>
								{this.props.showPostShare && (
									<Share
										title={
											this.state.postDetails.description
										}
										url={
											getSiteUrl().siteURL +
											"/answers/" +
											this.state.postDetails.postId
										}
									/>
								)}
							</div>
							<div className="col">
								{!this.state.saved.includes(
									this.state.postDetails.id
								) ? (
									<a
										onClick={() =>
											this.savePost(
												this.state.postDetails.id
											)
										}
										style={{ cursor: "pointer" }}
									>
										<img src="/uploads/images/comment.png" />
										Save
									</a>
								) : (
									<a
										onClick={() =>
											this.unsavePost(
												this.state.postDetails.id
											)
										}
										style={{ cursor: "pointer" }}
									>
										<img src="/uploads/images/comment.png" />
										Unsave
									</a>
								)}
							</div>

							{/* <div className='col'>
                                <a href='#'>
                                    <img src='/uploads/images/hand.png' />
                                    Report
                                </a>
                            </div> */}
						</div>
					</div>
				</div>
			</>
		) : (
			<div className="postbx">
				<div className="usrtop">
					<div className="row">
						<div className="col-2">
							<div className="userthumb">
								<Link
									className="userbx"
									to={`/profile/${this.props.post.userId}`}
								>
									<Avatar
										url={"/uploads/user/"}
										state={this.state.profilePicture}
										alt={"user"}
										onClick={() =>
											this.props.history.push(
												"/profile/" +
													this.props.post.userId
											)
										}
									/>
								</Link>
							</div>
						</div>
						<div className="col-8 pl-2 nopad pt-1">
							<Link
								to={"/profile/" + this.props.post.userId}
								className={"post-userId"}
							>
								{this.state.postOwner.firstName}{" "}
								{this.state.postOwner.lastName}
							</Link>
							<span className="small pstim">
								{this.state.timeAgo}
							</span>
						</div>
						{this.state.postDetails.interest.icon && (
							<div
								className="col-2 text-right"
								style={activityIcon}
							>
								<span
									className="acttyp"
									onClick={() => this.showActivityFilter()}
								>
									<img
										src={`/uploads/images/${this.state.postDetails.interest.icon}`}
									/>
								</span>
							</div>
						)}
					</div>
				</div>
				{this.props.isFullView && (
					<p className="f14">{this.state.postDetails.description}</p>
				)}
				{!this.props.isFullView && (
					<Link to={"/post/" + this.state.postDetails.postId}>
						<p className="f14  lineclamp mb-2">
							{this.state.postDetails.description}
						</p>
					</Link>
				)}
				{this.state.postDetails.images.map((img) => (
					<div className="pstmd post-img-parent" key={img}>
						<Avatar
							url={"/uploads/posts/"}
							state={img}
							className={"post-img"}
							alt={"post"}
							style={{ cursor: "pointer" }}
							onClick={() =>
								this.props.openPictureViewer(
									`${
										getServerUrl().apiURL
									}/uploads/posts/${img}`
								)
							}
						/>
					</div>
				))}
				{this.state.postDetails.videos.map((video) => {
					let type = "";
					if (video.endsWith(".avi")) type = "video/avi";
					else if (video.endsWith(".mp4")) type = "video/mp4";
					else if (video.endsWith(".wmv")) type = "video/x-ms-wmv";
					else if (video.endsWith(".ogg")) type = "video/ogg";
					else if (video.endsWith(".webm")) type = "video/webm";

					return (
						<div className="pstmd" key={video}>
							<video width="100%" height="240" controls>
								<source
									src={`${
										getServerUrl().apiURL
									}/uploads/posts/${video}`}
									type={type}
								></source>
							</video>
						</div>
					);
				})}
				<div className="row">
					<div className="rctbx col-9">
						{this.state.postDetails.kudos.bumSlaps.length > 0 && (
							<span>
								<img src="/uploads/images/love-it.png" />
								{this.state.postDetails.kudos.bumSlaps.length}
							</span>
						)}
						{this.state.postDetails.kudos.backSlaps.length > 0 && (
							<span>
								<img src="/uploads/images/first-pump.png" />
								{this.state.postDetails.kudos.backSlaps.length}
							</span>
						)}
						{this.state.postDetails.kudos.likes.length > 0 && (
							<span>
								<img src="/uploads/images/thumbs-up.png" />
								{this.state.postDetails.kudos.likes.length}
							</span>
						)}
						{this.state.postDetails.kudos.gotThis.length > 0 && (
							<span>
								<img src="/uploads/images/you-got-this.png" />
								{this.state.postDetails.kudos.gotThis.length}
							</span>
						)}
						{this.state.postDetails.kudos.beStrong.length > 0 && (
							<span>
								<img src="/uploads/images/be-strong.png" />
								{this.state.postDetails.kudos.beStrong.length}
							</span>
						)}
						{this.state.postDetails.kudos.awesome.length > 0 && (
							<span>
								<img src="/uploads/images/awesome.png" />
								{this.state.postDetails.kudos.awesome.length}
							</span>
						)}
						{this.state.postDetails.kudos.congrats.length > 0 && (
							<span>
								<img src="/uploads/images/congratulations.png" />
								{this.state.postDetails.kudos.congrats.length}
							</span>
						)}
						{/* <span>
                            <img src='/uploads/images/symbol4.png' />
                            23
                        </span> */}
						<span>
							<img src="/uploads/images/symbol5.png" />
							{this.state.postDetails.comments.length}
						</span>
					</div>
					<div className="optbx col-3 text-right">
						<a
							onClick={this.toggleEllipsisOpt}
							className={
								!this.props.hasActionPermission
									? "dislinks blur"
									: ""
							}
						>
							<img src="/uploads/images/ver-opt.png" />
						</a>
						{this.props.showEllipsisOpt &&
							this.props.showEllipsisOptId ==
								this.props.post._id && (
								<OutsideClickHandler
									onOutsideClick={() => {
										this.toggleEllipsisOpt();
									}}
								>
									<div className="ovrmnu">
										<ul>
											<li>
												{!this.state.saved.includes(
													this.state.postDetails.id
												) ? (
													<a
														onClick={() =>
															this.savePost(
																this.state
																	.postDetails
																	.id
															)
														}
													>
														Save Post
													</a>
												) : (
													<a
														onClick={() =>
															this.unsavePost(
																this.state
																	.postDetails
																	.id
															)
														}
													>
														Unsave Post
													</a>
												)}
											</li>
											{this.props.post.userId ===
												this.props.user.userId && (
												<li>
													<Link
														to={
															this.props.post
																.isQuestion
																? "/question/" +
																  this.state
																		.postDetails
																		.postId +
																  "/edit"
																: "/post/" +
																  this.state
																		.postDetails
																		.postId +
																  "/edit"
														}
														onClick={() =>
															this.toggleEllipsisOpt()
														}
													>
														Edit Post
													</Link>
												</li>
											)}
											{this.props.post.userId ==
												this.props.user.userId && (
												<li>
													<a
														onClick={() =>
															this.deletePost(
																this.state
																	.postDetails
																	.id
															)
														}
													>
														Delete Post
													</a>
												</li>
											)}
											{/* <li>
                                            <a href='#'>Report Post</a>
                                        </li> */}
										</ul>
									</div>
								</OutsideClickHandler>
							)}
					</div>
				</div>
				<div className="lkbxbtm f12">
					<div className="row">
						<div className="col">
							<a
								onClick={this.openToggleReactionOpt}
								className={
									!this.props.hasActionPermission
										? "dislinks blur"
										: ""
								}
							>
								<img
									src={`/uploads/images/${
										reaction
										/*this.state.reaction === 'Love It'
                                            ? 'love-it.png'//this.state.hasBumSlapped
                                                //? 'bum-c.png'
                                                //: 'symbol1.png'
                                            : this.state.reaction ===
                                              'Fist Pump'
                                            ? 'first-pump.png'//this.state.hasBackSlapped
                                                //? 'back-c.png'
                                                //: 'symbol2.png'
                                            : this.state.reaction ===
                                                  'Thumbs Up' &&
                                              'thumbs-up.png'//this.state.hasLiked
                                            //? 'thumbs-c.png'
                                            //: 'symbol3.png'*/
									}`}
								/>
								<span>{this.state.reaction}</span>
							</a>
							{this.props.showReactionOpt &&
								this.props.showReactionOptId ==
									this.props.post._id && (
									<OutsideClickHandler
										onOutsideClick={() => {
											this.toggleReactionOpt();
										}}
									>
										<Kudos
											post={this.props.post}
											changeKudos={this.props.changeKudos}
											onToggleReactionOpt={
												this.toggleReactionOpt
											}
											onHandleReactionChange={
												this.handleReactionChange
											}
											isQuestionPage={
												this.props.isQuestionPage
											}
										/>
									</OutsideClickHandler>
								)}
						</div>
						<div className="col">
							<Link
								to={`/post/${this.state.postDetails.postId}${
									!this.props.hasActionPermission
										? "/view"
										: ""
								}`}
							>
								<img src="/uploads/images/comment.png" />
								<span>Comment</span>
							</Link>
						</div>
						<div className="col">
							<a
								className={
									/*!this.props.hasActionPermission || this.props.user.userId === this.props.post.userId ? 'dislinks blur' : */ ""
								}
								style={{ cursor: "pointer" }}
								onClick={() => this.sharePost(true)}
							>
								<img src="/uploads/images/share.png" />
								Share
							</a>
							{this.props.showPostShare && (
								<Share
									title={this.state.postDetails.description}
									url={
										getSiteUrl().siteURL +
										"/post/" +
										this.state.postDetails.postId
									}
								/>
							)}
						</div>
					</div>
				</div>
				{this.props.commentsOwner !== undefined &&
					this.state.postDetails.comments.length > 0 && (
						<div className="comntsec mb-4 mt-0">
							<h6>Comment</h6>
							<div className="comntbx">
								<div className="usrtop">
									<div className="row">
										<div className="col-2">
											<div className="userthumb">
												<Link
													to={
														"/profile/" +
														this.state.postDetails
															?.comments[0]
															?.userId
													}
													className="userbx"
												>
													<Avatar
														url={"/uploads/user/"}
														state={
															this.props
																.commentsOwner[
																this.state
																	.postDetails
																	.comments[0]
																	.userId
															]?.profilePicture
														}
														alt={" profile picture"}
													/>
												</Link>
											</div>
										</div>
										<div className="col-10 nopad pt-1">
											<Link
												to={
													"/profile/" +
													this.state.postDetails
														?.comments[0]?.userId
												}
											>
												{`${
													this.props.commentsOwner[
														this.state.postDetails
															?.comments[0]
															?.userId
													]?.firstName
												} 
                                            ${
												this.props.commentsOwner[
													this.state.postDetails
														?.comments[0].userId
												]?.lastName
											}`}
											</Link>
											<span className="small pstim">
												{assignTimeAgo(
													this.state.postDetails
														?.comments[0]?.time
												)}
											</span>
										</div>
									</div>
								</div>
								<div className="cmntxt">
									<p>
										{
											this.state.postDetails?.comments[0]
												?.description
										}
									</p>
								</div>
							</div>
						</div>
					)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		token: state.auth.token,
		user: state.auth.user,
		showReactionOpt: state.auth.showReactionOpt,
		showReactionOptId: state.auth.showReactionOptId,
		showEllipsisOpt: state.auth.showEllipsisOpt,
		showEllipsisOptId: state.auth.showEllipsisOptId,
		showPostShare: state.auth.showPostShare,
	};
};

export default withRouter(
	connect(mapStateToProps, {
		toggleReactionOpt,
		toggleEllipsisOpt,
		updateUserSaved,
		updateShowPostFilter,
		updateShowPostShare,
	})(Post)
);
