import React from "react";
const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

const HeaderCoachly = ({ title }) => {
	return (
		<div className={`loginbx ${isiOS ? "ios-m" : ""}`}>
			<img src="/uploads/images/grey-logo.png" />
			<h3>
				<i>{title}</i>
			</h3>
		</div>
	);
};

export default HeaderCoachly;
