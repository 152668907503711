import React from "react";

const ClearSearch = ({
	handleRecentSearchClick,
	history,
	handleDelete,
	clearHistory,
}) => {
	return (
		<div className="rcntsrch">
			<div className="rcntsrch">
				<h6>Recent Searches</h6>
				<ul className="rcntbx">
					{history.map((history, index) => (
						<li key={index}>
							<a
								href="#"
								onClick={() => handleRecentSearchClick(history)}
							>
								{history}
							</a>
							<a
								name={index}
								className="pushright small"
								href="#"
								onClick={() => handleDelete(index)}
							>
								Delete
							</a>
						</li>
					))}
				</ul>
				<p className="text-center">
					<a className="grytxt" href="#" onClick={clearHistory}>
						Clear Search History
					</a>
				</p>
			</div>
		</div>
	);
};

export default ClearSearch;
