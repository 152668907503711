import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import loading from "../assets/loading.svg";
import Select from "react-select";
import {
	getServerUrl,
	getUserTeamMemberships,
	saveUserMemberShipData,
	memberShipsCron,
	cancelUserMemberShipData,
	renewMembership,
	mobileQuery,
} from "../utils/api";
import LeftSideBar from "../components/LeftSideBar";
import Avatar from "../components/common/avatar";
import {
	FaAudible,
	FaDelicious,
	FaDigitalOcean,
	FaDollarSign,
	FaDollyFlatbed,
	FaFirstOrder,
	FaHandHoldingUsd,
	FaMoneyBillAlt,
	FaSync,
	FaUndo,
} from "react-icons/fa";
import { formatDateTime } from "../utils/helper";
import { HiUser, HiUsers } from "react-icons/hi";

class TeamMemberships extends Component {
	constructor(props) {
		super(props);

		this.state = {
			team: { title: "", description: "" },
			screenType: "memberships",
			loading: true,
			memberships: [],
			deniedMemberships: [],
			updateReplace: [],
			usersubmemberships: [],
			usersubmemids: [],
			memSelCoach: [],
			teamCoaches: [],
			isAdmin: false,
			isAdminOrJoined: false,
			teamStatus: "",
			isAdminOrJoined: "",
		};

		this.isMobile = "";
	}

	componentDidMount() {
		this.setState({
			teamStatus: this.props.location.state?.teamStatus || "active",
			isAdminOrJoined: this.props.location.state?.isAdminOrJoined,
		});
		this.isMobile = window.matchMedia(mobileQuery).matches;
		this.getUserTeamMemberships();

		memberShipsCron(this.props.user._id);
	}

	getUserTeamMemberships = () => {
		getUserTeamMemberships(this.props.teamSlug, this.props.user._id).then(
			(resp) => {
				console.log("resp", resp);
				if (resp.success === true) {
					const categorizedMemberships = {
						pending: [],
						active: [],
						cancel: [],
						deactivate: [],
					};
					if (resp.usersubscribedmembership.length > 0) {
						resp.usersubscribedmembership.forEach((membership) => {
							if (membership.status in categorizedMemberships) {
								categorizedMemberships[membership.status].push(
									membership
								);
							}
						});
					}
					const categorizedMembership = {
						pending: [],
						active: [],
						cancel: [],
						deactivate: [],
					};
					if (resp.memberships.length > 0) {
						resp.memberships.forEach((membership) => {
							if (membership.status in categorizedMembership) {
								categorizedMembership[membership.status].push(
									membership
								);
							}
						});
						console.log(
							"categorizedMembership",
							categorizedMembership
						);
					}
					//if(resp.team.creatorId === this.props.user._id)
					this.setState({
						team: resp.team,
						memberships: resp.memberships,
						userMemberships: resp.userMemberships,
						deniedMemberships: resp.deniedMemberships,
						updateReplace: resp.updateReplace,
						usersubmemberships: categorizedMemberships,
						usersubmemids: resp.usersubmemids,
						teamCoaches: resp.coaches,
						isAdmin: resp.isAdmin,
						isAdminOrJoined: resp.isAdminOrJoined,
						loading: false,
					});
				} else alert("Error fetching data, please try again");
			}
		);
	};

	getMemberShips = (membership) => {
		let coachSelect = null;
		let memCoaches = [];
		let teamCoaches = this.state.teamCoaches;
		if (membership.coaches.length > 1) {
			for (let i = 0; i < teamCoaches.length; i++) {
				if (membership.coaches.includes(teamCoaches[i]._id)) {
					memCoaches.push({
						value: teamCoaches[i]._id,
						label:
							teamCoaches[i].firstName +
							" " +
							teamCoaches[i].lastName +
							" (" +
							teamCoaches[i].email +
							")",
					});
				}
			}
			coachSelect = (
				<div style={{ marginTop: "10px", marginBottom: "10px" }}>
					<Select
						defaultValue={[]}
						options={memCoaches}
						onChange={(selectedOption) =>
							this.handleCoachChange(
								selectedOption,
								membership._id
							)
						}
					/>
				</div>
			);
		}

		if (membership.subscribeWithoutMembership == true) {
			let usermem = this.UserSubScribeMemberShips(membership._id);
			if (usermem == false) {
				return (
					<div
						key={"my-memberships-" + membership._id}
						className="col-lg-6 col-md-12"
					>
						<div className="srchpstbx">
							<div className="pstmd post-img-parent event-img">
								<Avatar
									url={"/uploads/memberships/"}
									state={membership.logo}
									alt={"logo"}
									className="post-img"
								/>
							</div>
							{coachSelect}
							<div className="mt-1 mb-1">
								<a>{membership.title}</a>
							</div>

							<p>
								<span className=" f14">
									<FaHandHoldingUsd
										style={{
											marginRight: "10px",
											opacity: ".5",
										}}
									/>
									<FaDollarSign
										style={{
											opacity: ".5",
										}}
									/>{" "}
									{membership.price.$numberDecimal}
								</span>
							</p>
							<p>
								<span className="f14">
									<FaUndo
										style={{
											marginRight: "10px",
											opacity: ".5",
										}}
									/>{" "}
									Interval:{" "}
									{membership.interval +
										" " +
										membership.intervalType}
								</span>
							</p>

							{membership.paymentType && (
								<p>
									<span className="f14">
										<FaSync
											style={{
												marginRight: "10px",
												opacity: ".5",
											}}
										/>{" "}
										Payment Type : {membership.paymentType}
									</span>
								</p>
							)}

							<p>
								<span className="f14">
									<FaDigitalOcean
										style={{
											marginRight: "10px",
											opacity: ".5",
										}}
									/>
									Trial:{" "}
									{membership.trial
										? membership.trialPeriod +
										  " " +
										  membership.trialType
										: "No trials"}
								</span>
							</p>

							{membership.coaches.length > 0 &&
								membership.coaches.map(
									(coach, idx) =>
										coach?.firstName && (
											<p key={idx}>
												<span className="f14">
													<HiUser
														style={{
															marginRight: "10px",
															opacity: ".5",
														}}
													/>{" "}
													Coach:{" "}
													{coach?.firstName +
														" " +
														coach?.lastName}
												</span>
											</p>
										)
								)}

							<hr />
							<h5>
								<b>Description</b>
							</h5>
							<p>
								<span className="f14">
									{membership.description}
								</span>
							</p>
							<p
								style={{
									display: "flex",
									flexDirection: "row-reverse",
								}}
							>
								{this.state.teamStatus !== "suspend" &&
									this.state.isAdminOrJoined && (
										<a
											className="btn grnbtn mr-2"
											onClick={(e) =>
												this.saveUserMembership(
													e,
													membership
												)
											}
										>
											Buy This Package
										</a>
									)}
							</p>
						</div>
					</div>
				);
			}
		} else if (
			membership.subscribeWithoutMembership == false &&
			this.state.userMemberships.length > 0
		) {
			// let res=this.deniedmeM(membership._id);
			// if(res==false){
			// 	return (<div key={"my-memberships-" + membership._id} className='club-box'>
			// 	<div className='row'>
			// 		<div className='col-2'><Link to={"/team/" + this.state.team.slug + "/admin/membership/" + membership.slug}><img src={membership.logo ? getServerUrl().apiURL + '/uploads/memberships/' + membership.logo : '/uploads/images/membership_default.png'} /></Link></div>
			// 		<div className='col-7'><h5 style={{ display: 'block', width: '100%' }}><Link to={"/team/" + this.state.team.slug + "/admin/membership/" + membership.slug}>{membership.title}</Link></h5><small>{membership.membersCount} members</small></div>
			// 		<div className='col-3'><a className='btn' onClick={(e) => this.saveUserMembership(e, membership)}>Subscribe</a></div>
			// 	</div>
			// </div>)
			//  }
			let usermem = this.UserSubScribeMemberShips(membership._id);
			if (usermem == false) {
				return (
					<div
						key={"my-memberships-" + membership._id}
						className="col-lg-6 col-md-12"
					>
						<div className="srchpstbx">
							<div className="pstmd post-img-parent event-img">
								<Avatar
									url={"/uploads/memberships/"}
									state={membership.logo}
									alt={"logo"}
									className="post-img"
								/>
							</div>
							{coachSelect}
							<div className="mt-1 mb-1">
								<a>{membership.title}</a>
							</div>

							<p>
								<span className=" f14">
									<FaHandHoldingUsd
										style={{
											marginRight: "10px",
											opacity: ".5",
										}}
									/>
									<FaDollarSign
										style={{
											opacity: ".5",
										}}
									/>{" "}
									{membership.price.$numberDecimal}
								</span>
							</p>
							<p>
								<span className="f14">
									<FaUndo
										style={{
											marginRight: "10px",
											opacity: ".5",
										}}
									/>{" "}
									Interval:{" "}
									{membership.interval +
										" " +
										membership.intervalType}
								</span>
							</p>

							{membership.paymentType && (
								<p>
									<span className="f14">
										<FaSync
											style={{
												marginRight: "10px",
												opacity: ".5",
											}}
										/>{" "}
										Payment Type : {membership.paymentType}
									</span>
								</p>
							)}

							<p>
								<span className="f14">
									<FaDigitalOcean
										style={{
											marginRight: "10px",
											opacity: ".5",
										}}
									/>
									Trial:{" "}
									{membership.trial
										? membership.trialPeriod +
										  " " +
										  membership.trialType
										: "No trials"}
								</span>
							</p>

							{membership.coaches.length > 0 &&
								membership.coaches.map(
									(coach, idx) =>
										coach?.firstName && (
											<p key={idx}>
												<span className="f14">
													<HiUser
														style={{
															marginRight: "10px",
															opacity: ".5",
														}}
													/>{" "}
													Coach:{" "}
													{coach?.firstName +
														" " +
														coach?.lastName}
												</span>
											</p>
										)
								)}

							<hr />
							<h5>
								<b>Description</b>
							</h5>
							<p>
								<span className="f14">
									{membership.description}
								</span>
							</p>
							<p
								style={{
									display: "flex",
									flexDirection: "row-reverse",
								}}
							>
								{this.state.teamStatus !== "suspend" &&
									this.state.isAdminOrJoined && (
										<a
											className="btn grnbtn mr-2"
											onClick={(e) =>
												this.saveUserMembership(
													e,
													membership
												)
											}
										>
											Buy This Package
										</a>
									)}
							</p>
						</div>
					</div>
				);
			}
		}
		// if(this.state.userMemberships.length>0){

		// }
	};
	deniedmeM = (id) => {
		let deninedMemberShips = this.state.deniedMemberships;
		return deninedMemberShips.indexOf(id) > -1;
	};
	UserSubScribeMemberShips = (id) => {
		let ids = this.state.usersubmemids;
		return ids.indexOf(id) > -1;
	};

	saveUserMembership = (e, membership) => {
		let loggedInUserId = this.props.user._id;
		let memUserId = membership.userId;
		let coach_id = null;
		if (loggedInUserId == memUserId) {
			alert("you cannot purchase your own membership.");
			return false;
		}
		if (membership.coaches.length > 0) {
			if (membership.coaches.length > 1) {
				coach_id = this.state.memSelCoach[membership._id];
				if (coach_id == undefined) {
					alert("Select coach for this membership");
					return false;
				}
			} else coach_id = membership.coaches[0];
		}
		let data = {
			membership: membership,
			user_id: this.props.user._id,
			coach_id,
		};

		saveUserMemberShipData(data).then((membership) => {
			if (membership) {
				alert(membership.msg);
				this.getUserTeamMemberships();

				// this.setState({
				// 	success_m: 'Program added successfully.',
				// 	alert_cls: 'alert-success',
				// 	loading: false
			}
			// });updateReplaceupdateReplace
		});

		return false;
	};
	cancelUserMembership = (e, membership) => {
		let data = {
			membership_id: membership.subId,
			user_id: this.props.user._id,
		};

		cancelUserMemberShipData(data).then((membership) => {
			if (membership) {
				this.getUserTeamMemberships();
				alert("Membership cancel successfully.");
				//	window.location.reload();
				// this.setState({
				// 	success_m: 'Program added successfully.',
				// 	alert_cls: 'alert-success',
				// 	loading: false
			}
			// });updateReplaceupdateReplace
		});

		return false;
	};
	renewUserMembership = (e, membership) => {
		let data = {
			membership_id: membership.subId,
			user_id: this.props.user._id,
		};
		renewMembership(data).then((membership) => {
			if (membership) {
				this.getUserTeamMemberships();
				alert(membership.msg);
			}
		});
	};

	handleCoachChange = (selectedOption, membershipId) => {
		let memSelCoach = this.state.memSelCoach;
		memSelCoach[membershipId] = selectedOption.value;

		this.setState({ memSelCoach });
	};

	render() {
		let loadingHtml = null;
		let isMobile = window.matchMedia(mobileQuery).matches;

		if (this.state.loading && this.state.team.title === "") {
			return (
				<div
					className="profile-container-loading"
					style={{
						position: "absolute",
						height: "100%",
						backgroundColor: "lightgray",
						opacity: "0.8",
					}}
				>
					<img src={loading} alt="" />
				</div>
			);
		}
		if (this.state.memberships.length <= 0) {
			return (
				<div className="outbx">
					{this.isMobile && (
						<div className="teams-container">
							<Link
								to={"/team/" + this.state.team.slug}
								className="backbtn"
							>
								{" "}
							</Link>
							<h6>Memberships</h6>
						</div>
					)}
					<div className="boxmenu">No Memberships</div>
				</div>
			);
		}
		if (this.state.loading) {
			loadingHtml = (
				<div
					className="profile-container-loading"
					style={{
						position: "absolute",
						height: "100%",
						backgroundColor: "lightgray",
						opacity: "0.8",
					}}
				>
					<img src={loading} alt="" />
				</div>
			);
		}

		if (
			this.state.screenType == "memberships" &&
			this.state.memberships.length > 0
		) {
			return (
				<div>
					{loadingHtml}
					{this.isMobile && (
						<div className="teams-container">
							<Link
								to={"/team/" + this.state.team.slug}
								className="backbtn"
							>
								{" "}
							</Link>
							<h6>Memberships</h6>
						</div>
					)}
					<div className="tempwide">
						<div className=" evntbx">
							{!this.isMobile && (
								<div className="col-3">
									<LeftSideBar
										team={this.state.team}
										isAdmin={this.state.isAdmin}
										isAdminOrJoined={
											this.state.isAdminOrJoined
										}
									/>
								</div>
							)}

							{this.state.usersubmemberships.active.length >
								0 && (
								<div>
									<p
										className="feed-container-empty"
										style={{ height: "5vh" }}
									>
										Subscribed Memberships
									</p>
									<div
										key={`membership-`}
										className="container cntntbx trndgbx"
									>
										<div className="row">
											<div
												className={`${
													!isMobile
														? "col-9"
														: "col-12 nopad"
												}`}
											>
												<div className="lstevnt">
													<div className="row">
														{this.state
															.usersubmemberships
															.active === 0 ? (
															<p className="feed-container-empty">
																No Membership
															</p>
														) : (
															this.state.usersubmemberships.active.map(
																(
																	usermemships
																) => (
																	<div
																		key={
																			"my-memberships-" +
																			usermemships.subId
																		}
																		className="col-lg-6 col-md-12"
																	>
																		<div className="srchpstbx">
																			<div className="pstmd post-img-parent event-img">
																				<Avatar
																					url={
																						"/uploads/memberships/"
																					}
																					state={
																						usermemships.logo
																					}
																					alt={
																						"logo"
																					}
																					className="post-img"
																				/>
																			</div>

																			<div className="mt-1 mb-1">
																				<a>
																					{
																						usermemships.title
																					}
																				</a>
																			</div>

																			<p>
																				<span className=" f14">
																					<FaHandHoldingUsd
																						style={{
																							marginRight:
																								"10px",
																							opacity:
																								".5",
																						}}
																					/>
																					<FaDollarSign
																						style={{
																							opacity:
																								".5",
																						}}
																					/>{" "}
																					{
																						usermemships
																							.price
																							.$numberDecimal
																					}
																				</span>
																			</p>
																			<p>
																				<span className="f14">
																					<img src="/uploads/images/calendar.png" />
																					{`Start: ${
																						usermemships.startDate ===
																							null ||
																						""
																							? "not set"
																							: formatDateTime(
																									usermemships.startDate
																							  )
																					}`}
																				</span>
																			</p>
																			<p>
																				<span className="f14">
																					<img src="/uploads/images/calendar.png" />
																					{`End: ${
																						usermemships.expireDate ===
																							null ||
																						""
																							? "not set"
																							: formatDateTime(
																									usermemships.expireDate
																							  )
																					}`}
																				</span>
																			</p>
																			<p>
																				<span className="f14">
																					<FaUndo
																						style={{
																							marginRight:
																								"10px",
																							opacity:
																								".5",
																						}}
																					/>{" "}
																					Interval:{" "}
																					{usermemships.interval +
																						" " +
																						usermemships.intervalType}
																				</span>
																			</p>

																			{usermemships.paymentType && (
																				<p>
																					<span className="f14">
																						<FaSync
																							style={{
																								marginRight:
																									"10px",
																								opacity:
																									".5",
																							}}
																						/>{" "}
																						Payment
																						Type
																						:{" "}
																						{
																							usermemships.paymentType
																						}
																					</span>
																				</p>
																			)}
																			{usermemships.type && (
																				<p>
																					<span className="f14">
																						<FaMoneyBillAlt
																							style={{
																								marginRight:
																									"10px",
																								opacity:
																									".5",
																							}}
																						/>{" "}
																						Membership
																						Type
																						:{" "}
																						{
																							usermemships.type
																						}
																					</span>
																				</p>
																			)}
																			<p>
																				<span className="f14">
																					<FaDigitalOcean
																						style={{
																							marginRight:
																								"10px",
																							opacity:
																								".5",
																						}}
																					/>
																					Trial:{" "}
																					{usermemships.trial
																						? usermemships.trialPeriod +
																						  " " +
																						  usermemships.trialType
																						: "No trials"}
																				</span>
																			</p>
																			{usermemships
																				.coaches
																				.length >
																				0 &&
																				usermemships.coaches.map(
																					(
																						coach,
																						idx
																					) =>
																						coach?.firstName && (
																							<p
																								key={
																									idx
																								}
																							>
																								<span className="f14">
																									<HiUser
																										style={{
																											marginRight:
																												"10px",
																											opacity:
																												".5",
																										}}
																									/>{" "}
																									Coach:{" "}
																									{coach?.firstName +
																										" " +
																										coach?.lastName}
																								</span>
																							</p>
																						)
																				)}

																			{usermemships.status && (
																				<p>
																					<span className="f14">
																						<FaAudible
																							style={{
																								marginRight:
																									"10px",
																								opacity:
																									".5",
																							}}
																						/>
																						Status:{" "}
																						{
																							usermemships.status
																						}
																					</span>
																				</p>
																			)}
																			<hr />
																			<h5>
																				<b>
																					Description
																				</b>
																			</h5>
																			<p>
																				<span className="f14">
																					{
																						usermemships.description
																					}
																				</span>
																			</p>
																			<p
																				style={{
																					display:
																						"flex",
																					flexDirection:
																						"row-reverse",
																				}}
																			>
																				{this
																					.state
																					.teamStatus !==
																					"suspend" &&
																					this
																						.state
																						.isAdminOrJoined &&
																					usermemships.status ===
																						"cancel" && (
																						<a
																							className="btn grnbtn mr-2"
																							onClick={(
																								e
																							) =>
																								this.renewUserMembership(
																									e,
																									usermemships
																								)
																							}
																						>
																							Renew
																							Membership
																						</a>
																					)}

																				{this
																					.state
																					.teamStatus !==
																					"suspend" &&
																					this
																						.state
																						.isAdminOrJoined &&
																					usermemships.status ===
																						"active" && (
																						<a
																							className="btn mob-btn memberShip-btn"
																							style={{
																								paddingTop:
																									"5px",
																								paddingBottom:
																									"5px",
																							}}
																							onClick={(
																								e
																							) =>
																								this.cancelUserMembership(
																									e,
																									usermemships
																								)
																							}
																						>
																							Cancel
																							Membership
																						</a>
																					)}
																			</p>
																		</div>
																	</div>
																)
															)
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
							{this.state.usersubmemberships.cancel.length >
								0 && (
								<div>
									<p
										className="feed-container-empty"
										style={{ height: "5vh" }}
									>
										Renew Memberships
									</p>
									<div
										key={`membership-`}
										className="container cntntbx trndgbx"
									>
										<div className="row">
											<div
												className={`${
													!isMobile
														? "col-9"
														: "col-12 nopad"
												}`}
											>
												<div className="lstevnt">
													<div className="row">
														{this.state
															.usersubmemberships
															.cancel === 0 ? (
															<p className="feed-container-empty">
																No Membership to
																renew
															</p>
														) : (
															this.state.usersubmemberships.cancel.map(
																(
																	usermemships
																) => (
																	<div
																		key={
																			"my-memberships-" +
																			usermemships.subId
																		}
																		className="col-lg-6 col-md-12"
																	>
																		<div className="srchpstbx">
																			<div className="pstmd post-img-parent event-img">
																				<Avatar
																					url={
																						"/uploads/memberships/"
																					}
																					state={
																						usermemships.logo
																					}
																					alt={
																						"logo"
																					}
																					className="post-img"
																				/>
																			</div>

																			<div className="mt-1 mb-1">
																				<a>
																					{
																						usermemships.title
																					}
																				</a>
																			</div>

																			<p>
																				<span className=" f14">
																					<FaHandHoldingUsd
																						style={{
																							marginRight:
																								"10px",
																							opacity:
																								".5",
																						}}
																					/>
																					<FaDollarSign
																						style={{
																							opacity:
																								".5",
																						}}
																					/>{" "}
																					{
																						usermemships
																							.price
																							.$numberDecimal
																					}
																				</span>
																			</p>
																			<p>
																				<span className="f14">
																					<img src="/uploads/images/calendar.png" />
																					{`Start: ${
																						usermemships.startDate ===
																							null ||
																						""
																							? "not set"
																							: formatDateTime(
																									usermemships.startDate
																							  )
																					}`}
																				</span>
																			</p>
																			<p>
																				<span className="f14">
																					<img src="/uploads/images/calendar.png" />
																					{`End: ${
																						usermemships.expireDate ===
																							null ||
																						""
																							? "not set"
																							: formatDateTime(
																									usermemships.expireDate
																							  )
																					}`}
																				</span>
																			</p>
																			<p>
																				<span className="f14">
																					<FaUndo
																						style={{
																							marginRight:
																								"10px",
																							opacity:
																								".5",
																						}}
																					/>{" "}
																					Interval:{" "}
																					{usermemships.interval +
																						" " +
																						usermemships.intervalType}
																				</span>
																			</p>

																			{usermemships.paymentType && (
																				<p>
																					<span className="f14">
																						<FaSync
																							style={{
																								marginRight:
																									"10px",
																								opacity:
																									".5",
																							}}
																						/>{" "}
																						Payment
																						Type
																						:{" "}
																						{
																							usermemships.paymentType
																						}
																					</span>
																				</p>
																			)}
																			{usermemships.type && (
																				<p>
																					<span className="f14">
																						<FaMoneyBillAlt
																							style={{
																								marginRight:
																									"10px",
																								opacity:
																									".5",
																							}}
																						/>{" "}
																						Membership
																						Type
																						:{" "}
																						{
																							usermemships.type
																						}
																					</span>
																				</p>
																			)}
																			<p>
																				<span className="f14">
																					<FaDigitalOcean
																						style={{
																							marginRight:
																								"10px",
																							opacity:
																								".5",
																						}}
																					/>
																					Trial:{" "}
																					{usermemships.trial
																						? usermemships.trialPeriod +
																						  " " +
																						  usermemships.trialType
																						: "No trials"}
																				</span>
																			</p>
																			{usermemships
																				.coaches
																				.length >
																				0 &&
																				usermemships.coaches.map(
																					(
																						coach,
																						idx
																					) =>
																						coach?.firstName && (
																							<p
																								key={
																									idx
																								}
																							>
																								<span className="f14">
																									<HiUser
																										style={{
																											marginRight:
																												"10px",
																											opacity:
																												".5",
																										}}
																									/>{" "}
																									Coach:{" "}
																									{coach?.firstName +
																										" " +
																										coach?.lastName}
																								</span>
																							</p>
																						)
																				)}

																			{usermemships.status && (
																				<p>
																					<span className="f14">
																						<FaAudible
																							style={{
																								marginRight:
																									"10px",
																								opacity:
																									".5",
																							}}
																						/>
																						Status:{" "}
																						{
																							usermemships.status
																						}
																					</span>
																				</p>
																			)}
																			<hr />
																			<h5>
																				<b>
																					Description
																				</b>
																			</h5>
																			<p>
																				<span className="f14">
																					{
																						usermemships.description
																					}
																				</span>
																			</p>
																			<p
																				style={{
																					display:
																						"flex",
																					flexDirection:
																						"row-reverse",
																				}}
																			>
																				{this
																					.state
																					.teamStatus !==
																					"suspend" &&
																					this
																						.state
																						.isAdminOrJoined &&
																					usermemships.status ===
																						"cancel" && (
																						<a
																							className="btn grnbtn mr-2"
																							onClick={(
																								e
																							) =>
																								this.renewUserMembership(
																									e,
																									usermemships
																								)
																							}
																						>
																							Renew
																							Membership
																						</a>
																					)}

																				{this
																					.state
																					.teamStatus !==
																					"suspend" &&
																					this
																						.state
																						.isAdminOrJoined &&
																					usermemships.status ===
																						"active" && (
																						<a
																							className="btn mob-btn memberShip-btn"
																							style={{
																								paddingTop:
																									"5px",
																								paddingBottom:
																									"5px",
																							}}
																							onClick={(
																								e
																							) =>
																								this.cancelUserMembership(
																									e,
																									usermemships
																								)
																							}
																						>
																							Cancel
																							Membership
																						</a>
																					)}
																			</p>
																		</div>
																	</div>
																)
															)
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
							{this.state.usersubmemberships.pending.length >
								0 && (
								<div>
									<p
										className="feed-container-empty"
										style={{ height: "5vh" }}
									>
										Memberships Waiting For Approval
									</p>
									<div
										key={`membership-`}
										className="container cntntbx trndgbx"
									>
										<div className="row">
											<div
												className={`${
													!isMobile
														? "col-9"
														: "col-12 nopad"
												}`}
											>
												<div className="lstevnt">
													<div className="row">
														{this.state
															.usersubmemberships
															.pending === 0 ? (
															<p className="feed-container-empty">
																No Membership
																pending
															</p>
														) : (
															this.state.usersubmemberships.pending.map(
																(
																	usermemships
																) => (
																	<div
																		key={
																			"my-memberships-" +
																			usermemships.subId
																		}
																		className="col-lg-6 col-md-12"
																	>
																		<div className="srchpstbx">
																			<div className="pstmd post-img-parent event-img">
																				<Avatar
																					url={
																						"/uploads/memberships/"
																					}
																					state={
																						usermemships.logo
																					}
																					alt={
																						"logo"
																					}
																					className="post-img"
																				/>
																			</div>

																			<div className="mt-1 mb-1">
																				<a>
																					{
																						usermemships.title
																					}
																				</a>
																			</div>

																			<p>
																				<span className=" f14">
																					<FaHandHoldingUsd
																						style={{
																							marginRight:
																								"10px",
																							opacity:
																								".5",
																						}}
																					/>
																					<FaDollarSign
																						style={{
																							opacity:
																								".5",
																						}}
																					/>{" "}
																					{
																						usermemships
																							.price
																							.$numberDecimal
																					}
																				</span>
																			</p>
																			<p>
																				<span className="f14">
																					<img src="/uploads/images/calendar.png" />
																					{`Start: ${
																						usermemships.startDate ===
																							null ||
																						""
																							? "not set"
																							: formatDateTime(
																									usermemships.startDate
																							  )
																					}`}
																				</span>
																			</p>
																			<p>
																				<span className="f14">
																					<img src="/uploads/images/calendar.png" />
																					{`End: ${
																						usermemships.expireDate ===
																							null ||
																						""
																							? "not set"
																							: formatDateTime(
																									usermemships.expireDate
																							  )
																					}`}
																				</span>
																			</p>
																			<p>
																				<span className="f14">
																					<FaUndo
																						style={{
																							marginRight:
																								"10px",
																							opacity:
																								".5",
																						}}
																					/>{" "}
																					Interval:{" "}
																					{usermemships.interval +
																						" " +
																						usermemships.intervalType}
																				</span>
																			</p>

																			{usermemships.paymentType && (
																				<p>
																					<span className="f14">
																						<FaSync
																							style={{
																								marginRight:
																									"10px",
																								opacity:
																									".5",
																							}}
																						/>{" "}
																						Payment
																						Type
																						:{" "}
																						{
																							usermemships.paymentType
																						}
																					</span>
																				</p>
																			)}
																			{usermemships.type && (
																				<p>
																					<span className="f14">
																						<FaMoneyBillAlt
																							style={{
																								marginRight:
																									"10px",
																								opacity:
																									".5",
																							}}
																						/>{" "}
																						Membership
																						Type
																						:{" "}
																						{
																							usermemships.type
																						}
																					</span>
																				</p>
																			)}
																			<p>
																				<span className="f14">
																					<FaDigitalOcean
																						style={{
																							marginRight:
																								"10px",
																							opacity:
																								".5",
																						}}
																					/>
																					Trial:{" "}
																					{usermemships.trial
																						? usermemships.trialPeriod +
																						  " " +
																						  usermemships.trialType
																						: "No trials"}
																				</span>
																			</p>
																			{usermemships
																				.coaches
																				.length >
																				0 &&
																				usermemships.coaches.map(
																					(
																						coach,
																						idx
																					) =>
																						coach?.firstName && (
																							<p
																								key={
																									idx
																								}
																							>
																								<span className="f14">
																									<HiUser
																										style={{
																											marginRight:
																												"10px",
																											opacity:
																												".5",
																										}}
																									/>{" "}
																									Coach:{" "}
																									{coach?.firstName +
																										" " +
																										coach?.lastName}
																								</span>
																							</p>
																						)
																				)}

																			{usermemships.status && (
																				<p>
																					<span className="f14">
																						<FaAudible
																							style={{
																								marginRight:
																									"10px",
																								opacity:
																									".5",
																							}}
																						/>
																						Status:{" "}
																						{
																							usermemships.status
																						}
																					</span>
																				</p>
																			)}
																			<hr />
																			<h5>
																				<b>
																					Description
																				</b>
																			</h5>
																			<p>
																				<span className="f14">
																					{
																						usermemships.description
																					}
																				</span>
																			</p>
																			<p
																				style={{
																					display:
																						"flex",
																					flexDirection:
																						"row-reverse",
																				}}
																			>
																				{this
																					.state
																					.teamStatus !==
																					"suspend" &&
																					this
																						.state
																						.isAdminOrJoined &&
																					usermemships.status ===
																						"cancel" && (
																						<a
																							className="btn grnbtn mr-2"
																							onClick={(
																								e
																							) =>
																								this.renewUserMembership(
																									e,
																									usermemships
																								)
																							}
																						>
																							Renew
																							Membership
																						</a>
																					)}

																				{this
																					.state
																					.teamStatus !==
																					"suspend" &&
																					this
																						.state
																						.isAdminOrJoined &&
																					usermemships.status ===
																						"active" && (
																						<a
																							className="btn mob-btn memberShip-btn"
																							style={{
																								paddingTop:
																									"5px",
																								paddingBottom:
																									"5px",
																							}}
																							onClick={(
																								e
																							) =>
																								this.cancelUserMembership(
																									e,
																									usermemships
																								)
																							}
																						>
																							Cancel
																							Membership
																						</a>
																					)}
																			</p>
																		</div>
																	</div>
																)
															)
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
							{this.state.usersubmemberships.deactivate.length >
								0 && (
								<div>
									<p
										className="feed-container-empty"
										style={{ height: "5vh" }}
									>
										Deactivate Memberships
									</p>
									<div
										key={`membership-`}
										className="container cntntbx trndgbx"
									>
										<div className="row">
											<div
												className={`${
													!isMobile
														? "col-9"
														: "col-12 nopad"
												}`}
											>
												<div className="lstevnt">
													<div className="row">
														{this.state
															.usersubmemberships
															.deactivate ===
														0 ? (
															<p className="feed-container-empty">
																Membership
																deactivate
															</p>
														) : (
															this.state.usersubmemberships.deactivate.map(
																(
																	usermemships
																) => (
																	<div
																		key={
																			"my-memberships-" +
																			usermemships.subId
																		}
																		className="col-lg-6 col-md-12"
																	>
																		<div className="srchpstbx">
																			<div className="pstmd post-img-parent event-img">
																				<Avatar
																					url={
																						"/uploads/memberships/"
																					}
																					state={
																						usermemships.logo
																					}
																					alt={
																						"logo"
																					}
																					className="post-img"
																				/>
																			</div>

																			<div className="mt-1 mb-1">
																				<a>
																					{
																						usermemships.title
																					}
																				</a>
																			</div>

																			<p>
																				<span className=" f14">
																					<FaHandHoldingUsd
																						style={{
																							marginRight:
																								"10px",
																							opacity:
																								".5",
																						}}
																					/>
																					<FaDollarSign
																						style={{
																							opacity:
																								".5",
																						}}
																					/>{" "}
																					{
																						usermemships
																							.price
																							.$numberDecimal
																					}
																				</span>
																			</p>
																			<p>
																				<span className="f14">
																					<img src="/uploads/images/calendar.png" />
																					{`Start: ${
																						usermemships.startDate ===
																							null ||
																						""
																							? "not set"
																							: formatDateTime(
																									usermemships.startDate
																							  )
																					}`}
																				</span>
																			</p>
																			<p>
																				<span className="f14">
																					<img src="/uploads/images/calendar.png" />
																					{`End: ${
																						usermemships.expireDate ===
																							null ||
																						""
																							? "not set"
																							: formatDateTime(
																									usermemships.expireDate
																							  )
																					}`}
																				</span>
																			</p>
																			<p>
																				<span className="f14">
																					<FaUndo
																						style={{
																							marginRight:
																								"10px",
																							opacity:
																								".5",
																						}}
																					/>{" "}
																					Interval:{" "}
																					{usermemships.interval +
																						" " +
																						usermemships.intervalType}
																				</span>
																			</p>

																			{usermemships.paymentType && (
																				<p>
																					<span className="f14">
																						<FaSync
																							style={{
																								marginRight:
																									"10px",
																								opacity:
																									".5",
																							}}
																						/>{" "}
																						Payment
																						Type
																						:{" "}
																						{
																							usermemships.paymentType
																						}
																					</span>
																				</p>
																			)}
																			{usermemships.type && (
																				<p>
																					<span className="f14">
																						<FaMoneyBillAlt
																							style={{
																								marginRight:
																									"10px",
																								opacity:
																									".5",
																							}}
																						/>{" "}
																						Membership
																						Type
																						:{" "}
																						{
																							usermemships.type
																						}
																					</span>
																				</p>
																			)}
																			<p>
																				<span className="f14">
																					<FaDigitalOcean
																						style={{
																							marginRight:
																								"10px",
																							opacity:
																								".5",
																						}}
																					/>
																					Trial:{" "}
																					{usermemships.trial
																						? usermemships.trialPeriod +
																						  " " +
																						  usermemships.trialType
																						: "No trials"}
																				</span>
																			</p>
																			{usermemships
																				.coaches
																				.length >
																				0 &&
																				usermemships.coaches.map(
																					(
																						coach,
																						idx
																					) =>
																						coach?.firstName && (
																							<p
																								key={
																									idx
																								}
																							>
																								<span className="f14">
																									<HiUser
																										style={{
																											marginRight:
																												"10px",
																											opacity:
																												".5",
																										}}
																									/>{" "}
																									Coach:{" "}
																									{coach?.firstName +
																										" " +
																										coach?.lastName}
																								</span>
																							</p>
																						)
																				)}

																			{usermemships.status && (
																				<p>
																					<span className="f14">
																						<FaAudible
																							style={{
																								marginRight:
																									"10px",
																								opacity:
																									".5",
																							}}
																						/>
																						Status:{" "}
																						{
																							usermemships.status
																						}
																					</span>
																				</p>
																			)}
																			<hr />
																			<h5>
																				<b>
																					Description
																				</b>
																			</h5>
																			<p>
																				<span className="f14">
																					{
																						usermemships.description
																					}
																				</span>
																			</p>
																			<p
																				style={{
																					display:
																						"flex",
																					flexDirection:
																						"row-reverse",
																				}}
																			>
																				{this
																					.state
																					.teamStatus !==
																					"suspend" &&
																					this
																						.state
																						.isAdminOrJoined &&
																					usermemships.status ===
																						"cancel" && (
																						<a
																							className="btn grnbtn mr-2"
																							onClick={(
																								e
																							) =>
																								this.renewUserMembership(
																									e,
																									usermemships
																								)
																							}
																						>
																							Renew
																							Membership
																						</a>
																					)}

																				{this
																					.state
																					.teamStatus !==
																					"suspend" &&
																					this
																						.state
																						.isAdminOrJoined &&
																					usermemships.status ===
																						"active" && (
																						<a
																							className="btn mob-btn memberShip-btn"
																							style={{
																								paddingTop:
																									"5px",
																								paddingBottom:
																									"5px",
																							}}
																							onClick={(
																								e
																							) =>
																								this.cancelUserMembership(
																									e,
																									usermemships
																								)
																							}
																						>
																							Cancel
																							Membership
																						</a>
																					)}
																			</p>
																		</div>
																	</div>
																)
															)
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
							<div>
								{this.state.memberships.length > 0 && (
									<p
										className="feed-container-empty"
										style={{ height: "5vh" }}
									>
										Memberships
									</p>
								)}
								<div
									key={`membership-`}
									className="container cntntbx trndgbx"
								>
									<div className="row">
										<div
											className={`${
												!isMobile
													? "col-9"
													: "col-12 nopad"
											}`}
										>
											<div className="lstevnt">
												<div className="row">
													{this.state.memberships
														.length === 0 ? (
														<p className="feed-container-empty">
															No Membership
														</p>
													) : (
														this.state.memberships.map(
															(membership) =>
																this.getMemberShips(
																	membership
																)
														)
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	};
};

export default withRouter(connect(mapStateToProps, {})(TeamMemberships));
