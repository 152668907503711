import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import LeftSidebar from './LeftSidebar';
import groupLinkIcon from '../../assets/icon1.gif';
import { getAthletePlanner, getUserTeamMemberships, getServerUrl, getAthleteSessionActivities, getSessionsStats, getsessionActivity } from '../../utils/api.js';
import { getUserCoach, getGroupLevels } from "../../actions";
import loading from "../../assets/loading.svg";
import profileBlank from "../../assets/profile_blank.png";
import ReactHighcharts from 'react-highcharts';
import { time } from 'highcharts';
import Select from 'react-select';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const AthleteDashboard = ({ user, clubSlug, getUserCoach, getGroupLevels,userCoach }) => {
	const [club, setClub] = useState('');
	const [loading, setLoading] = useState(true);
	const [planner, setPlanner] = useState(null);
	const [groups, setGroups] = useState(null);
	const [groupLevels, setgroupLevels] = useState(null);
	const [activeTab, setActiveTab] = useState('session');
	const [selectInterval, selectSetInterval] = useState('year');
	const [activities, setActivities] = useState(null);
	const [interval, setInterval] = useState('year');
	const [hours, sethours] = useState([]);
	const [options, setOptions] = useState('');
	const [sessionActivities, setsessionActivities] = useState(null);
	const [selectedActivities, setselectedActivities] = useState([]);
	const [selectedOption, setSelectedOption] = useState(null);
	// const [userCoach, setuserCoach] = useState(null);
	var settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};
	useEffect(() => {
		getAthletePlanner(clubSlug, user._id)
			.then(result => {
				if (result.club === null) {
					setClub(null);
				} else if (result.planner === null) {
					setPlanner(null);
				} else {
					setClub(result.club);
					setPlanner(result.planner);
				}
				setLoading(false);
			});

		getUserTeamMemberships(clubSlug, user._id).then(result => {
			if (result.success == true) {
				setGroups(result.usersubscribedmembership)
			}
		});
		let obj = {
			plannerId: user.planners.length > 0 ? user.planners[0].plannerId : null,
			clubId: user.planners.length > 0 ? user.planners[0].clubId : null,
			userId: user._id
		}
		getAthleteSessionActivities(obj).then(result => {
			if (result.success == true) {
				setActivities(result.programs);
			}
		});
		getsessionActivity().then(result => {

			const optionsselect = result.map((element) => ({
				value: element.value,
				label: element.title
			}));
			setsessionActivities(optionsselect);
		});
		let data = {
			clubSlug: clubSlug,
			userId: user._id,
		}
		getUserCoach(data);
		getGroupLevels(data);
	}, [user]);

	if (club === null) {
		return <Redirect to="/home" />;
	}


	if (loading) {
		return (
			<div className='profile-container-loading'>
				<img src={loading} alt="" />
			</div>
		);
	}

	let programsList = [];
	if (planner && planner.programs.length > 0) {
		planner.programs.forEach((item) => {
			programsList.push(<li key={'program-' + item._id}>{item.title}</li>);
		});
	} else {
		programsList.push(<li key={'no-programs'}>No Programs Found</li>);
	}

	const handleTabClick = (selectedValue) => {
		let load_arr = [];
		let hours_arr = [];
		let dateArray = [];
		let distance_arr = [];
		let obj = {
			plannerId: user.planners.length > 0 ? user.planners[0].plannerId : null,
			clubId: user.planners.length > 0 ? user.planners[0].clubId : null,
			userId: user._id,
			type: selectedValue,
			interval: interval,
			activities: selectedOption
		}
		getSessionsStats(obj).then(response => {
			if (response.success == true) {
				let xAxis = response.xAxis;
				if (response.sessions && response.sessions.length > 0) {
					response.sessions.forEach((resp, index) => {
						let value = resp.session;
						let date = new Date(resp.programDate);
						let days = value.days;
						let newDate = date.setDate(date.getDate(date) + days);
						const inputDate = new Date(newDate);
						const formattedDate = formatDate(inputDate);
						dateArray.push(formattedDate);
						// hours 
						let hours_mins = parseInt(value.hours) + parseFloat((parseInt(value.minutes) / 60).toFixed(2));
						var hourobj = { y: hours_mins, color: '#3c8ab7' };
						hours_arr.push(hourobj);
						//distance
						let distanceobj = { y: parseInt(value.distance.$numberDecimal), color: '#bc984f' };
						distance_arr.push(distanceobj);
						// rpe load 

						var loadobj = { y: parseInt(value.rpeLoad), color: '#b73c59' };
						load_arr.push(loadobj);

					});

					sethours(hours_arr);
				}
				if (selectedValue == 'time') {
					let optionOne = {
						credits: {
							enabled: false
						},
						chart: {
							type: 'column'
						},
						title: {
							text: 'Time Representation'
						},
						subtitle: {
							text: ''
						},
						xAxis: {
							categories: xAxis

						},
						yAxis: {
							min: 0,
							title: {
								text: 'Hours'
							}
						},
						tooltip: {
							headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
							pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
								'<td style="padding:0"><b>{point.y:.1f} Hr</b></td></tr>',
							footerFormat: '</table>',
							shared: true,
							useHTML: true
						},
						plotOptions: {
							column: {
								pointPadding: 0.2,
								borderWidth: 0
							}
						},
						series: [{
							data: hours_arr,
						}]
					}
					setOptions(optionOne);
				} else if (selectedValue == 'distance') {
					let optionOne = {
						credits: {
							enabled: false
						},
						chart: {
							type: 'column'
						},
						title: {
							text: 'Distance Representation'
						},
						subtitle: {
							text: ''
						},
						xAxis: {
							categories: dateArray

						},
						yAxis: {
							min: 0,
							title: {
								text: 'Distance'
							}
						},
						tooltip: {
							headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
							pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
								'<td style="padding:0"><b>{point.y:.1f} Hr</b></td></tr>',
							footerFormat: '</table>',
							shared: true,
							useHTML: true
						},
						plotOptions: {
							column: {
								pointPadding: 0.2,
								borderWidth: 0
							}
						},
						series: [{
							data: distance_arr,
						}]
					}
					setOptions(optionOne);
				} else if (selectedValue == 'load') {
					let optionOne = {
						credits: {
							enabled: false
						},
						chart: {
							type: 'column'
						},
						title: {
							text: 'Load Representation'
						},
						subtitle: {
							text: ''
						},
						xAxis: {
							categories: dateArray

						},
						yAxis: {
							min: 0,
							title: {
								text: 'Load'
							}
						},
						tooltip: {
							headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
							pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
								'<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
							footerFormat: '</table>',
							shared: true,
							useHTML: true
						},
						plotOptions: {
							column: {
								pointPadding: 0.2,
								borderWidth: 0
							}
						},
						series: [{
							data: load_arr,
						}]
					}
					setOptions(optionOne);
				}


			}

		});
		setActiveTab(selectedValue);
	}
	const handleActivitiesChange = (event) => {
		const selectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
		setselectedActivities(selectedValues);
	};
	const formatDate = (date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	};
	const handleIntervalClick = (selectedValue) => {
		let load_arr = [];
		let hours_arr = [];
		let dateArray = [];
		let distance_arr = [];
		let obj = {
			plannerId: user.planners.length > 0 ? user.planners[0].plannerId : null,
			clubId: user.planners.length > 0 ? user.planners[0].clubId : null,
			userId: user._id,
			type: activeTab,
			interval: selectedValue,
			activities: selectedOption

		}
		selectSetInterval(selectedValue);
		getSessionsStats(obj).then(response => {
			if (response.success == true) {
				let xAxis = response.xaxis;
				if (response.sessions && response.sessions.length > 0) {
					response.sessions.forEach((resp, index) => {
						let value = resp.session;
						let date = new Date(resp.programDate);
						let days = value.days;
						let newDate = date.setDate(date.getDate(date) + days);
						const inputDate = new Date(newDate);
						const formattedDate = formatDate(inputDate);
						dateArray.push(formattedDate);
						// hours 
						let hours_mins = parseInt(value.hours) + parseFloat((parseInt(value.minutes) / 60).toFixed(2));
						var hourobj = { y: hours_mins, color: '#3c8ab7' };
						hours_arr.push(hourobj);
						//distance
						let distanceobj = { y: parseInt(value.distance.$numberDecimal), color: '#bc984f' };
						distance_arr.push(distanceobj);
						// rpe load 

						var loadobj = { y: parseInt(value.rpeLoad), color: '#b73c59' };
						load_arr.push(loadobj);

					});

					sethours(hours_arr);
				}
				if (activeTab == 'time') {
					let optionOne = {
						credits: {
							enabled: false
						},
						chart: {
							type: 'column'
						},
						title: {
							text: 'Time Representation'
						},
						subtitle: {
							text: ''
						},
						xAxis: {
							categories: xAxis

						},
						yAxis: {
							min: 0,
							title: {
								text: 'Hours'
							}
						},
						tooltip: {
							headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
							pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
								'<td style="padding:0"><b>{point.y:.1f} Hr</b></td></tr>',
							footerFormat: '</table>',
							shared: true,
							useHTML: true
						},
						plotOptions: {
							column: {
								pointPadding: 0.2,
								borderWidth: 0
							}
						},
						series: [{
							data: hours_arr,
						}]
					}
					setOptions(optionOne);
				} else if (activeTab == 'distance') {
					let optionOne = {
						credits: {
							enabled: false
						},
						chart: {
							type: 'column'
						},
						title: {
							text: 'Distance Representation'
						},
						subtitle: {
							text: ''
						},
						xAxis: {
							categories: dateArray

						},
						yAxis: {
							min: 0,
							title: {
								text: 'Distance'
							}
						},
						tooltip: {
							headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
							pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
								'<td style="padding:0"><b>{point.y:.1f} Hr</b></td></tr>',
							footerFormat: '</table>',
							shared: true,
							useHTML: true
						},
						plotOptions: {
							column: {
								pointPadding: 0.2,
								borderWidth: 0
							}
						},
						series: [{
							data: distance_arr,
						}]
					}
					setOptions(optionOne);
				} else if (activeTab == 'load') {
					let optionOne = {
						credits: {
							enabled: false
						},
						chart: {
							type: 'column'
						},
						title: {
							text: 'Load Representation'
						},
						subtitle: {
							text: ''
						},
						xAxis: {
							categories: dateArray

						},
						yAxis: {
							min: 0,
							title: {
								text: 'Load'
							}
						},
						tooltip: {
							headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
							pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
								'<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
							footerFormat: '</table>',
							shared: true,
							useHTML: true
						},
						plotOptions: {
							column: {
								pointPadding: 0.2,
								borderWidth: 0
							}
						},
						series: [{
							data: load_arr,
						}]
					}
					setOptions(optionOne);
				}


			}

		});

	}
	const formatDateMonth = (date) => {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return [year, month, day].join("-");
	}
	return (
		<div className="container-large clearfix">
			<div id="wrapper" className="athlete-dashboard athlete-profile">
				<LeftSidebar club={club} planner={planner} groupLevels={groupLevels} clubSlug={clubSlug} userCoach={userCoach} groups={groups} />
				<div id="page-content-wrapper">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-12-large w-100">
								<div className="sortable-deactive items-container clearfix">
									<div className="tab-content flwctrl clearfix">
										<div id="gernal-info" className="tab-pane active">
											<div className="col-mainleft equal-height">
												<div className="head-heading">
													<h4>Coach info</h4>
												</div>
												{
													(userCoach) ? <div className="profile-banner white-background clearfix">
														<div className="profile-image">
															<img src={
																userCoach.profilePicture
																	? getServerUrl().apiURL +
																	"/uploads/user/" +
																	userCoach.profilePicture
																	: profileBlank
															} className="avatar user-5-avatar avatar-50 photo" alt="" width="50" height="50" />
														</div>
														<div className="athelete-name text-center">
															<h3>{userCoach.firstName + ' ' + userCoach.lastName}</h3>
														</div>
														<div className="athelete-info">
															<div className="clearfix">
																<span className="f1-in">Planner :</span>
																<span className="f2-in">{(planner) ? planner.title : ''}</span>
															</div>
														</div>
														<div className="btn-grn">
															<a href="">Send Message</a>
														</div>
													</div> : null
												}

												<div className="gropus-sec mt-10">
													<div className="head-heading">
														<h4>Groups</h4>
													</div>
													<div className="grop-content white-background clearfix">
														<div className="grop-content white-background clearfix">
															{groups && groups.map((group, index) =>
																group.slug === "annual-membership" ? null : (
																	<React.Fragment key={index}>
																		<div className='d-flex mb-2'>
																			<div className="group-img">
																				<img
																					loading="lazy"
																					src={
																						group.logo
																							? getServerUrl().apiURL + '/uploads/memberships/' + group.logo
																							: '/uploads/images/membership_default.png'
																					}
																					className="img-responsive group-37-avatar avatar-38 photo"
																					width="38"
																					height="38"
																				/>
																			</div>
																			<div className="group-content">
																				<div className="group-heading-meta">
																					<h4>{group.title}</h4>
																					{/* <p className="members-counter">13 Members</p> */}
																				</div>
																			</div>
																		</div>
																	</React.Fragment>
																)
															)}

															<div className="clearfix"></div>
														</div>

													</div>
												</div>
											</div>
											<div className="col-maincenter equal-height">
												<div className="activity-summary">
													<div className="head-heading">
														<h4>Activity Summary</h4>
													</div>
													<Slider {...settings} className='carousel-inner sliderable-content radio-button-image'>
														{activities && activities.map((activity, index) => (
															(activity.matchedSessions.length > 0) ?
																<div key={index} className="item-slide white-background activity-body">
																	<div style={{ padding: '20px 0px' }}>
																		<h5>{activity.title}</h5>
																	</div>
																	{activity.matchedSessions.map((sessions, index2) => (
																		<React.Fragment key={index2}>
																			<div className="row-item">
																				<div className="icon-placer"><img src={getServerUrl().apiURL + '/uploads/images/' + sessions.icon} alt="" /></div>
																				<div className="rpogram-bdy">

																					<h6>Last Session:</h6>
																					<span>{sessions.title}</span>

																				</div>
																			</div>
																			<div className="row-item">
																				<div className="icon-placer"></div>
																				<div className="rpogram-bdy">

																					<h6>Date:</h6>
																					<span>{formatDateMonth(sessions.startDate)}</span>

																				</div>
																			</div>
																			<div className="row-item">
																				<div className="icon-placer"></div>
																				<div className="rpogram-bdy">

																					<h6>Duration:</h6>
																					<span>{sessions.hours + 'hours'} {sessions.minutes + 'minutes'}</span>

																				</div>
																			</div>
																			<div className="row-item">
																				<div className="icon-placer"></div>
																				<div className="rpogram-bdy">

																					<h6>Distance:</h6>
																					<span>{sessions.distance.numberDecimal} {sessions.unit}</span>

																				</div>
																			</div>

																			{
																				(sessions.sessionType === 'strength') ? (
																					<div className="row-item">
																						<div className="icon-placer"></div>
																						<div className="rpogram-bdy">

																							<h6>Exercises:</h6>
																							<span>{sessions.exercisesTotal}</span>

																						</div>
																					</div>
																				) : null
																			}
																			<div className="row-item">
																				<div className="icon-placer"></div>
																				<div className="rpogram-bdy">

																					<h6>Load:</h6>
																					<span>{sessions.rpeLoad}</span>

																				</div>
																			</div>
																		</React.Fragment>
																	))}
																</div>
																: null
														))}
													</Slider>





													{/* <div className="white-background activity-body">
														<div style={{ padding: '20px 0px' }}>
															<h5>No program to show activity</h5>
														</div>
													</div> */}
												</div>
											</div>
											<div className="col-mainright equal-height">
												<div className="programe-association cover-height">
													<div className="head-heading ">
														<h4>Programs Associated</h4>
													</div>
													<div className="white-background programe-body cover-height">
														<div className="program-session-list">
															<ul>
																{programsList}
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="clearfix"></div>
									<div className="mt-2">
										<div className="gropus-sec">
											<div className="head-heading mngrphd">
												<h4>Session Statistics
													<div className='row' style={{ float: 'right' }}>
														<Select
															defaultValue={selectedOption}
															onChange={setSelectedOption}
															options={sessionActivities}
															isMulti={true}
														/>
													</div>
													{/* <div className="select-fld-abt" style={{ float: 'right', position: 'relative', top: '-7px', right: '10px' }}>
														
														<select id="searchByActivity" className="selectpicker" multiple="">
															{sessionActivities &&
																sessionActivities.map((session, index) => (
																	<option key={index} value={session.value}>
																		{session.title}
																	</option>
																))
															}
														</select>

													</div> */}
												</h4>
											</div>
											<div className="grop-content lictrl white-background clearfix">
												<div className='col-sm-12 topgrphd'>
													<ul className="nav nav-pills">
														<li className={activeTab == 'session' ? 'active' : ''} onClick={() => handleTabClick('session')}>Session</li>
														<li className={activeTab == 'time' ? 'active' : ''} onClick={() => handleTabClick('time')}>Time</li>
														<li className={activeTab == 'distance' ? 'active' : ''} onClick={() => handleTabClick('distance')}>Distance</li>
														<li className={activeTab == 'load' ? 'active' : ''} onClick={() => handleTabClick('load')}>Load</li>
													</ul>
													<ul className="nav nav-pills" style={{ display: 'inline-block', float: 'right' }} >
														<li className={selectInterval == 'year' ? 'active' : ''} onClick={() => handleIntervalClick('year')}>Yearly</li>
														<li className={selectInterval == 'month' ? 'active' : ''} onClick={() => handleIntervalClick('month')}>Monthly</li>
														<li className={selectInterval == 'week' ? 'active' : ''} onClick={() => handleIntervalClick('week')}>Weekly</li>
													</ul>
												</div>

												<div className="clearfix"></div>

												<div>
													{
														(options) ? <ReactHighcharts config={options} /> : ''
													}
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.auth.user,
		userCoach: state.planner.userCoach,
		groupLevels: state.planner.groupLevels,
	};
};

export default connect(mapStateToProps, { getUserCoach, getGroupLevels })(AthleteDashboard);
