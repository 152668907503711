import React, { useEffect, useState, useRef } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import LeftSidebar from './left-sidebar';
import groupLinkIcon from '../../assets/icon1.gif';
import { getTeam } from '../../utils/api.js';
import DatePicker from 'react-datepicker';
import { clearUserNotifications, searchAllProgramSessions, getsessionActivity, getUserByRole, loadPlannerUserGroups, updateAthleteMemberShipPlanner, getUserNotifications, getServerUrl } from '../../utils/api.js';
import ReactToPrint from 'react-to-print';
import { PlannerToPrint } from "./modals/PlannerComponent";
import profileBlank from '../../assets/profile_blank.png';
import { assignTimeAgo } from '../../utils/helper';
const CoachDashboard = ({ user, clubSlug }) => {
	const [club, setClub] = useState('');
	const [loading, setLoading] = useState(true);
	const [leftSidebarDisplay, setLeftSidebarDisplay] = useState(true);
	const [selectedDate, setSelectedDate] = useState(null);
	const [sessionActivities, setSessionActivities] = useState(null);
	const [programSessions, setprogramSessions] = useState(null);
	const [checkprintDuplicates, setCheckPrintDuplicates] = useState(false);
	const [programTittle, setprogramTittle] = useState(false);
	const [plannerTittle, setplannerTittle] = useState(false);
	const [atheletes, setAtheletes] = useState(null);
	const [groups, setGroups] = useState(null);
	const [checkboxes, setCheckboxes] = useState(null);
	const [notifications, setnotifications] = useState(null);
	const [loadResp, setloadResp] = useState(false);
	const [displayPlanner, setdisplayPlanner] = useState(false);
	const [diplayPrograms, setdiplayPrograms] = useState(false);
	const [searchParams, setsearchParams] = useState({
		club_id: null,
		start_date: null,
		check_duplicates: null,
		disply_program: null,
		display_planer: null,
		activities: null,
		coach_id: null
	});
	const componentRef = useRef();
	useEffect(() => {
		getTeam(clubSlug).then((response) => {
			if (response.team === null) {
				setClub(null);
			} else {
				if (response.team.creatorId === user._id) {
					setClub(response.team);
					setLoading(false);
				} else {
					setClub(null);
				}
			}
		});
		getsessionActivity().then((response) => {
			setSessionActivities(response);
		});
		getUserNotifications(user.userId).then((resp) => {
			if (resp.success == true) {
				setnotifications(resp.displayNotif);
			} else {
				alert('Error fetching data, please try again');
			}
		});
	}, [clubSlug, user._id]);
	useEffect(() => {
		if (searchParams.start_date != null) {
			setloadResp(true);
			searchAllProgramSessions(searchParams).then((response) => {
				if (response.success == true) {
					setprogramSessions(response.planner_data);
					setloadResp(false);
				}
			});
		}
	}, [searchParams]);
	useEffect(() => {
		getUserByRole(club._id).then((response) => {
			setAtheletes(response.modifiedMemberships);
		});
		loadPlannerUserGroups(club._id)
			.then(resp => {
				let cbs = {}
				resp.map((item, index) => {
					cbs[index] = item.allowindvisualathleteplanner;

				})
				setCheckboxes(cbs)
				setGroups(resp);
			});
	}, [club._id]);
	const formatDate = (date) => {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return [year, month, day].join("-");
	}
	const handleDateChange = (date) => {
		setSelectedDate(date);
		let modiefiedDate = formatDate(date)
		setsearchParams(prevState => ({
			...prevState,
			club_id: club._id,
			start_date: modiefiedDate,
			coach_id: "5e0dd9fe8c0e1f28ec814871"
		}));


	};
	const leftSidebarChange = () => {
		setLeftSidebarDisplay(!leftSidebarDisplay);
	};
	const handleRemoveDuplicates = (event) => {
		let value = event.target.value;
		setCheckPrintDuplicates(event.target.checked);
		setsearchParams(prevState => ({
			...prevState,
			check_duplicates: value,
		}));
	}
	const handleDisplayPlannerTitle = (event) => {
		let value = event.target.checked;
		setplannerTittle(value);
		setdisplayPlanner(value)
	}
	const handleDisplayProgramTitle = (event) => {
		let value = event.target.checked;
		setdiplayPrograms(value);
	}
	const handleActivites = (event) => {
		let value = event.target.value;
		setsearchParams(prevState => ({
			...prevState,
			activities: value,
		}));
	}
	const handleAllowAthletePlaner = (index, event) => {

		setCheckboxes((prevCheckboxes) => ({
			...prevCheckboxes,
			[index]: !prevCheckboxes[index]
		}));
		let data = {
			club_id: club._id,
			membership_id: event.target.value,
			value: event.target.checked
		}
		updateAthleteMemberShipPlanner(data).then((response) => {
			console.log(response);
		});

	}
	const clearNotifications = () => {
		clearUserNotifications(user.userId).then((resp) => {
			if (resp.success == true) {
				setnotifications(null);
				alert("Notifications cleared");
			} else {
				alert('Error fetching data, please try again');
			}
		});
	}
	const newformatDate = (inputDate) => {
		const date = new Date(inputDate);
		const formattedDate = date.toLocaleDateString("en-US", {
			day: "numeric",
			month: "short",
			year: "numeric"
		});
		return formattedDate;
	}
	if (club === null) {
		return <Redirect to="/home" />;
	}
	if (loading) {
		return (
			<div className="profile-container-loading">
				<img src={loading} alt="" />
			</div>
		);
	}
	const DisplayNotification = ({ notification }) => {
		if (notification.action === 'comment') {
			return (
				<div className="usrtop">
					<div className="row">
						<div className="col-2 f12">
							<div className="userthumb">
								<Link to={'/profile/' + notification.user.userId} className="userbx">
									<img
										src={
											notification.user.logo
												? getServerUrl().apiURL + '/uploads/user/' + notification.user.logo
												: profileBlank
										}
									/>
								</Link>
							</div>
						</div>
						<div className="col-10">
							<Link to={'/profile/' + notification.user.userId}>
								{notification.user.firstName + ' ' + notification.user.lastName}
							</Link>
							<span className="small pstim">{assignTimeAgo(notification.date_notified)}</span>
							<p className="f12">Commented on your <Link to={'/post/' + notification.postId}>post</Link></p>
						</div>
					</div>
				</div>
			);
		} else if (notification.action === 'followed') {
			return (
				<div className="usrtop">
					<div className="row">
						<div className="col-2 f12">
							<div className="userthumb">
								<Link to={'/profile/' + notification.user.userId} className="userbx">
									<img
										src={
											notification.user.logo
												? getServerUrl().apiURL + '/uploads/user/' + notification.user.logo
												: profileBlank
										}
									/>
								</Link>
							</div>
						</div>
						<div className="col-10">
							<Link to={'/profile/' + notification.user.userId}>
								{notification.user.firstName + ' ' + notification.user.lastName}
							</Link>
							<span className="small pstim">{assignTimeAgo(notification.date_notified)}</span>
							<p className="f12">Started Following You</p>
						</div>
					</div>
				</div>
			);
		} else if (notification.action === 'answer') {
			return (
				<div className="usrtop">
					<div className="row">
						<div className="col-2 f12">
							<div className="userthumb">
								<Link to={'/profile/' + notification.user.userId} className="userbx">
									<img
										src={
											notification.user.logo
												? getServerUrl().apiURL + '/uploads/user/' + notification.user.logo
												: profileBlank
										}
									/>
								</Link>
							</div>
						</div>
						<div className="col-10">
							<Link to={'/profile/' + notification.user.userId}>
								{notification.user.firstName + ' ' + notification.user.lastName}
							</Link>
							<span className="small pstim">{assignTimeAgo(notification.date_notified)}</span>
							<p className="f12">Answered on your <Link to={'/answers/' + notification.postId}>question</Link></p>
						</div>
					</div>
				</div>
			);
		} else if (notification.action === 'newMessage') {
			return (
				<div className="usrtop">
					<div className="row">
						<div className="col-2 f12">
							<div className="userthumb">
								<Link to={'/profile/' + notification.user.userId} className="userbx">
									<img
										src={
											notification.user.logo
												? getServerUrl().apiURL + '/uploads/user/' + notification.user.logo
												: profileBlank
										}
									/>
								</Link>
							</div>
						</div>
						<div className="col-10">
							<Link to={'/profile/' + notification.user.userId}>
								{notification.user.firstName + ' ' + notification.user.lastName}
							</Link>
							<span className="small pstim">{assignTimeAgo(notification.date_notified)}</span>
							<p className="f12">Answered on your <Link to={'/answers/' + notification.postId}>question</Link></p>
						</div>
					</div>
				</div>
			);
		}

		return null; // Default case if none of the actions match
	};

	return (
		<div className="container-large clearfix">
			<div id="wrapper" className={`coach-dashboard ${leftSidebarDisplay ? '' : 'toggled-left'}`}>
				<LeftSidebar club={club} sidebarDisplay={leftSidebarDisplay} leftSidebarChange={leftSidebarChange} />
				<div id="page-content-wrapper">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-12">
								<div className="dashboard-heading">
									<h3>
										<a
											href="#menu-toggle-left"
											className="menu-toggle-left"
											id="show-after-left-close"
											style={!leftSidebarDisplay ? { display: 'inline-block' } : {}}
											onClick={(e) => {
												e.preventDefault();
												leftSidebarChange();
											}}
										>
											<span className="icon-bar"></span>
											<span className="icon-bar"></span>
											<span className="icon-bar"></span>
										</a>
										Dashboard
									</h3>
								</div>
								<div className="sortable">
									<div className="col-mainleft">
										<div className="head-heading">
											<h4>Personal Info</h4>
										</div>
										<div className="profile-banner white-background clearfix">
											<div className="profile-image top-sapce">
												<img src={(user.profilePicture) ? getServerUrl().apiURL + '/uploads/user/' + user.profilePicture : profileBlank} className="avatar" alt="" width="150" height="150" />
											</div>
											<div className="athelete-name text-center">
												<h3>{`${user.firstName} ${user.lastName}`}</h3>
											</div>
											<div className="athelete-info">
												{/* <div className="clearfix">
													<span className="f1-in">Level:</span>
													<span className="f2-in">${user.role}</span>
												</div> */}

												<div className="clearfix">
													<span className="f1-in">Role:</span>
													<span className="f2-in">{user.role}</span>
												</div>
												<div className="clearfix">
													<span className="f1-in">Joined:</span>
													<span className="f2-in">{newformatDate(user.registered)}</span>
												</div>
											</div>
										</div>
										<div className="gropus-sec m-p20">
											<div className="head-heading">
												<h4>Groups</h4>
											</div>
											<div className="grop-content white-background clearfix">
												<ul>
													{groups && groups.length > 0 && groups.map((group, index) => (
														<li>
															<Link to={"/team/" + club.slug + '/planner/membership/' + group.memberShipslug}>
																<div className="group-img">
																	<img
																		src={group.logo == '' ? '/uploads/images/dummy.png' : '/uploads/images/' + group.logo}
																		className="img-responsive group-37-avatar avatar-50 photo"
																		alt=""
																		width="50"
																		height="50"
																	/>
																</div>
																<div className="group-content">
																	<div className="group-heading-meta">
																		<h4>{group.memberShiptitle}</h4>
																	</div>
																	<div className="icon-place">
																		<img src={groupLinkIcon} alt="" />
																	</div>
																</div>
															</Link>
															<div>
																<label htmlFor="cm-group-allow-individual-athlete-planner-group1-id">
																	<input
																		type="checkbox"
																		checked={checkboxes[index]}
																		className="cm_group_allow_individual_athlete_planner"
																		value={group.memberShipId} onChange={(event) => handleAllowAthletePlaner(index, event)}
																	/>
																	Allow Individual Athlete Planner
																</label>
															</div>
														</li>
													))}
												</ul>
												<div className="clearfix"></div>
											</div>
										</div>
									</div>
									<div className="col-maincenter">
										<div className="atheletes-panel">
											<div className="head-heading">
												<h4>Athletes</h4>
											</div>
											<div className="white-background container-panel clearfix">
												<div className="head-panel clearfix">
													<ul className="clearfix">
														<li className="ca-ist">Athlete</li>
														<li className="ca-ist2">Income</li>
														<li className="ca-ist3">Last Fortnight</li>
													</ul>
												</div>
												<div className="listing-panel scroll-option">
													<ul className="clearfix">
														{(atheletes) && atheletes.map((athlete, index) =>
															<li key={index}>
																<div className="img-circle-style">
																	<img src={athlete.userId.profilePicture == '' ? '/uploads/images/dummy.png' : '/uploads/images/' + athlete.userId.profilePicture} className="avatar" alt="" width="30" height="30" />
																</div>
																<div className="name-dis">
																	<a href="/athlete-profile/">
																		<h4 title="">{(athlete.userId.username) ? athlete.userId.username : ''}</h4>
																	</a>
																	<span>Athlete level</span>
																</div>
																<div className="income-show">Income total</div>
																<div className="last-mtnh-inco">Last month</div>
															</li>
														)}
													</ul>
												</div>
												<div className="total-section">
													<span className="clr-gry">
														Total: <span className="clr-gren">$Coach income total</span>
													</span>
													<span className="seprator-line"> | </span>
													<span className="clr-gry">
														Last Fortnight: <span className="clr-gren">$Income last month</span>
													</span>
												</div>
												<div className="popup-mnt-coach">
													<a href="" data-toggle="modal" data-target="#coach-earning-modal">
														Manage Extra Hours
													</a>
												</div>
											</div>
										</div>
										<div class="programe-session mt-20">
											<div class="head-heading">
												<h4>Print Coach Planners Sessions</h4>
											</div>
											<div class="white-background inner-body">
												<ul className='label-planner-print'>
													<li>
														<label>Select Date</label>
														<DatePicker
															selected={selectedDate}
															onChange={handleDateChange}
															dateFormat="yyy-MM-dd"
														/>
														<div>
															<label><input id="coach-display-planner-title" type="checkbox" onChange={handleDisplayPlannerTitle} value={plannerTittle ? 'no' : 'yes'} /> Display planner title</label>
														</div>

														<div>

															<label><input id="coach-display-program-title" type="checkbox" onChange={handleDisplayProgramTitle} value={programTittle ? 'no' : 'yes'} /> Display program title</label>
														</div>
													</li>
													<li>
														<label>Session Activity Type</label>
														<select id="print-coach-session-activity-select" class="styled-select" onChange={handleActivites}>
															<option value="">Please Select</option>
															{
																(sessionActivities) && sessionActivities.map((activity, index) => (
																	<option key={index} value={activity.value}>{activity.title}</option>
																))
															}
														</select>
													</li>
													<li>
														<div class="print-planner-sessions-btn">

															{
																(!loadResp && searchParams.start_date != null) ? (<>
																	<ReactToPrint trigger={() => <input type="button" id="print-planner-sessions" name="" value="Print Session(s)" />} content={() => componentRef.current} />
																</>) : (<>
																	<input className='btn-secondary' type="button" disabled id="print-planner-sessions" name="" value="Print Session(s)" />
																</>)
															}
															<div style={{ display: "none" }}>
																{/* programDetails={programName} planner={props.programId.planner} */}
																<PlannerToPrint ref={componentRef} programSessions={programSessions} displayPrograms={diplayPrograms} displayPlanner={displayPlanner} />
															</div>
														</div>
													</li>
												</ul>
											</div>

										</div>
									</div>
								</div>
								<div className="col-mainright">
									<div className="notification">
										<div className="head-heading">
											<h4>Notifications</h4>
											<div class="bodyCon-nt">
												<div class="bodyCon-head">
													{
														(notifications) ? <span class="jbshead">You have {(notifications) ?  notifications.length :0} new messages</span> :''
													}
													
												</div>
												{/* <span class="date-dispay">4 Year Ago</span> */}
											</div>
											{(notifications) && notifications.length > 0 ? (
												<p className="f12">
													<a style={{ cursor: 'pointer' }} onClick={clearNotifications}>
														Clear Notifications
													</a>
												</p>
											) : null}
										</div>
										<div className="white-background coach-dashoboard">
											<div className="notification-listing">
												<div>
													{notifications &&
														notifications.map((notification, index) => (
															<DisplayNotification key={index} notification={notification} />
														))}
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	};
};

export default connect(mapStateToProps, {})(CoachDashboard);
