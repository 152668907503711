import React from "react";
import {
	IoIosAddCircleOutline,
	IoIosCheckmarkCircleOutline,
} from "react-icons/io";
import loading2 from "../../../assets/loading2.svg";

const InputLoader = ({ showLoading, loading, userExists }) => {
	return (
		<>
			{showLoading && (
				<div className="logldr">
					{loading ? (
						<img src={loading2} alt="" />
					) : (
						<div>
							{userExists ? (
								<IoIosCheckmarkCircleOutline
									className="login-loading-true"
									size={22}
								/>
							) : (
								<IoIosAddCircleOutline
									className="login-loading-false"
									size={22}
								/>
							)}
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default InputLoader;
