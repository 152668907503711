import React from "react";
import { Button } from "react-bootstrap";

const LoadButton = ({
	btnDisable,
	submitting,
	title,
	btnClass,
	titleClass,
	onClick,
}) => {
	const handleClick = (e) => {
		if (onClick) {
			onClick(e);
		}
	};
	return (
		<div className="form-group">
			<Button
				variant="primary"
				type="submit"
				disabled={btnDisable || submitting}
				className={btnClass}
				onClick={handleClick}
			>
				{submitting ? (
					<span
						className={`spinner-border spinner-border-sm ${titleClass}`}
						role="status"
						aria-hidden="true"
					></span>
				) : (
					<span className={titleClass}>{title}</span>
				)}
			</Button>
		</div>
	);
};

export default LoadButton;
