import React, { Component } from "react";
import { getServerUrl } from "../utils/api";
import { assignTimeAgo } from "../utils/helper";

import profileBlank from "../assets/profile_blank.png";

class MessageRequest extends Component {
	render() {
		const {
			notFriendUsers,
			notFriendLatestMsg,
			onHandleMessageRequest,
			onLoadConversation,
		} = this.props;
		const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

		return (
			<div className={`cnvrsbx ${isiOS ? "ios" : ""}`}>
				<div className={`msghdr ${isiOS ? "ios" : ""}`}>
					<h5>Message Requests</h5>
					<span
						className="close"
						onClick={this.props.onHandleMessageRequestsBtn}
					>
						×
					</span>
				</div>
				{notFriendUsers.map((user, index) => (
					<div
						key={`not-friend-${user._id}`}
						className="usrmsgbx msgitemsbx"
					>
						<div className="usrtop">
							<div className="row">
								<div className="col-2 f12">
									<div className="userthumb">
										<a className="userbx">
											<img
												src={
													user.profilePicture
														? `${
																getServerUrl()
																	.apiURL
														  }/uploads/user/${
																user.profilePicture
														  }`
														: profileBlank
												}
											/>
										</a>
									</div>
								</div>
								<div className="col-8 nopad mt-2 ml-2">
									<a>{`${user.firstName} ${user.lastName}`}</a>
									{notFriendLatestMsg[index] &&
										notFriendLatestMsg[index].createdAt && (
											<span className="small pstim">
												{assignTimeAgo(
													notFriendLatestMsg[index]
														.createdAt
												)}
											</span>
										)}
								</div>
							</div>
						</div>
						<p className="f14">
							{notFriendLatestMsg[index]?.message}
						</p>

						<div className="row mb-3">
							<div className="col-12 text-center">
								<button
									id="msgreq-viewall"
									className="btn btnblue"
									style={{ width: "100%" }}
									onClick={(e) =>
										onLoadConversation(
											e,
											user._id,
											{
												name:
													`${user.firstName} ${user.lastName}` ||
													"",
												profilePicture:
													user.profilePicture || "",
												_id: user._id,
											},
											true
										)
									}
								>
									View All Messages
								</button>
							</div>
						</div>

						<div className="row mb-3">
							<div className="col-6 pr-1 text-center">
								<button
									id="msgreq-accept"
									className="btn grnbtn"
									style={{ width: "100%" }}
									onClick={(e) =>
										onHandleMessageRequest(
											e,
											user._id,
											index,
											"accept"
										)
									}
								>
									Accept
								</button>
							</div>
							<div className="col-6 pl-1 text-center">
								<button
									id="msgreq-decline"
									className="btn redbtn"
									style={{ width: "100%" }}
									onClick={(e) =>
										onHandleMessageRequest(
											e,
											user._id,
											index,
											"decline"
										)
									}
								>
									Decline
								</button>
							</div>
						</div>
					</div>
				))}
			</div>
		);
	}
}

export default MessageRequest;
