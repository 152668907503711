/**
 * =====================================
 * REACT COMPONENT CLASS
 * =====================================
 * @date created: 17 September 2019
 * @authors: Hasitha Dias, Waqas Rehmani
 *
 * This file defines the Kudos component. The class Kudos
 * is where the component is defined.
 *
 * This is a component defined for reusability.
 *
 * This component shows a the likes, bumslaps and backslaps made on a post.
 *
 */

// Importing libraries for setup
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateKudosScore } from '../utils/api';

class Kudos extends Component {
    // Constructor
    constructor(props) {
        super(props);

        this.state = {
            currentLike: props.post.kudos.likes.find(
                (p) => p === props.user.userId
            ),
            currentBumSlap: props.post.kudos.bumSlaps.find(
                (p) => p === props.user.userId
            ),
            currentBackSlap: props.post.kudos.backSlaps.find(
                (p) => p === props.user.userId
            ),
            currentGotThis: props.post.kudos.gotThis.find(
                (p) => p === props.user.userId
            ),
            currentBeStrong: props.post.kudos.beStrong.find(
                (p) => p === props.user.userId
            ),
            currentAwesome: props.post.kudos.awesome.find(
                (p) => p === props.user.userId
            ),
            currentCongrats: props.post.kudos.congrats.find(
                (p) => p === props.user.userId
            ),
            likes: props.post.kudos.likes.length,
            bumSlaps: props.post.kudos.bumSlaps.length,
            backSlaps: props.post.kudos.backSlaps.length,
            gotThis: props.post.kudos.gotThis.length,
            beStrong: props.post.kudos.beStrong.length,
            awesome: props.post.kudos.awesome.length,
            congrats: props.post.kudos.congrats.length,
        };
    }

    // Adds or removes like from the current user.
    // Note: Adding like will remove backslap and bumslap of the user
    handleLike = async () => {
        if (this.state.currentLike) {
            let kudos = this.props.post.kudos;
            kudos.likes = kudos.likes.filter(
                (p) => p !== this.props.user.userId
            );

            this.props.changeKudos(this.props.post.postId, kudos);

            this.setState(
                (prevState) => {
                    return {
                        currentLike: false,
                        likes: prevState.likes - 1,
                    };
                },
                () => {
                    this.props.onToggleReactionOpt();
                    this.props.onHandleReactionChange('Thumbs Up');
                }
            );
        } else {
            let kudos = this.props.post.kudos;
            kudos.likes.push(this.props.user.userId);

            // filter out post bumslap of the user
            if (this.state.currentBumSlap) {
                kudos.bumSlaps = kudos.bumSlaps.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentBumSlap: false,
                    bumSlaps: !this.state.bumSlaps - 1,
                });
            }
            // filter out post backslap of the user
            else if (this.state.currentBackSlap) {
                kudos.backSlaps = kudos.backSlaps.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentBackSlap: false,
                    backSlaps: !this.state.backSlaps - 1,
                });
            }
            // filter out post gotThis of the user
            else if (this.state.currentGotThis) {
                kudos.gotThis = kudos.gotThis.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentGotThis: false,
                    gotThis: !this.state.gotThis - 1,
                });
            }
            // filter out post beStrong of the user
            else if (this.state.currentBeStrong) {
                kudos.beStrong = kudos.beStrong.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentBeStrong: false,
                    beStrong: !this.state.beStrong - 1,
                });
            }
            // filter out post awesome of the user
            else if (this.state.currentAwesome) {
                kudos.awesome = kudos.awesome.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentAwesome: false,
                    awesome: !this.state.awesome - 1,
                });
            }
            // filter out post congrats of the user
            else if (this.state.currentCongrats) {
                kudos.congrats = kudos.congrats.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentCongrats: false,
                    congrats: !this.state.congrats - 1,
                });
            }
            else {
                // update trending score with +1 score for post/question and user (like)
                if (this.props.post.section.type === 'users') {
                    updateKudosScore(
                        this.props.user._id,
                        this.props.post.section.type,
                        this.props.post._id
                    );
                }

                // update trending score with +1 score for groups / teams
                if (
                    this.props.post.section.type === 'groups' ||
                    this.props.post.section.type === 'teams'
                ) {
                    updateKudosScore(
                        this.props.user._id,
                        this.props.post.section.type,
                        this.props.post.section.id
                    );
                }
            }

            this.setState(
                (prevState) => {
                    return {
                        currentLike: true,
                        likes: prevState.likes + 1,
                    };
                },
                () => {
                    this.props.changeKudos(this.props.post.postId, kudos);
                    this.props.onToggleReactionOpt();
                    this.props.onHandleReactionChange('Thumbs Up');
                }
            );
        }
    };

    // Adds or removes bumslap from the current user.
    // Note: Adding bumslap will remove like and backslap of the user
    handleBumSlap = async () => {
        if (this.state.currentBumSlap) {
            let kudos = this.props.post.kudos;
            kudos.bumSlaps = kudos.bumSlaps.filter(
                (p) => p !== this.props.user.userId
            );

            this.props.changeKudos(this.props.post.postId, kudos);
            this.setState(
                (prevState) => {
                    return {
                        currentBumSlap: false,
                        bumSlaps: prevState.bumSlaps - 1,
                    };
                },
                () => {
                    this.props.onToggleReactionOpt();
                    this.props.onHandleReactionChange('Thumbs Up');
                }
            );
        } else {
            let kudos = this.props.post.kudos;
            kudos.bumSlaps.push(this.props.user.userId);

            // filter out post like of the user
            if (this.state.currentLike) {
                kudos.likes = kudos.likes.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentLike: false,
                    likes: !this.state.likes - 1,
                });
            }
            // filter out post backslap of the user
            else if (this.state.currentBackSlap) {
                kudos.backSlaps = kudos.backSlaps.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentBackSlap: false,
                    backSlaps: !this.state.backSlaps - 1,
                });
            }
            // filter out post gotThis of the user
            else if (this.state.currentGotThis) {
                kudos.gotThis = kudos.gotThis.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentGotThis: false,
                    gotThis: !this.state.gotThis - 1,
                });
            }
            // filter out post beStrong of the user
            else if (this.state.currentBeStrong) {
                kudos.beStrong = kudos.beStrong.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentBeStrong: false,
                    beStrong: !this.state.beStrong - 1,
                });
            }
            // filter out post awesome of the user
            else if (this.state.currentAwesome) {
                kudos.awesome = kudos.awesome.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentAwesome: false,
                    awesome: !this.state.awesome - 1,
                });
            }
            // filter out post congrats of the user
            else if (this.state.currentCongrats) {
                kudos.congrats = kudos.congrats.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentCongrats: false,
                    congrats: !this.state.congrats - 1,
                });
            }
            else {
                // update trending score with +1 score for post/question and user (like)
                if (this.props.post.section.type === 'users') {
                    updateKudosScore(
                        this.props.user._id,
                        this.props.post.section.type,
                        this.props.post._id
                    );
                }

                // update trending score with +1 score for groups / teams
                if (
                    this.props.post.section.type === 'groups' ||
                    this.props.post.section.type === 'teams'
                ) {
                    updateKudosScore(
                        this.props.user._id,
                        this.props.post.section.type,
                        this.props.post.section.id
                    );
                }
            }

            this.setState(
                (prevState) => {
                    return {
                        currentBumSlap: true,
                        bumSlaps: prevState.bumSlaps + 1,
                    };
                },
                () => {
                    this.props.changeKudos(this.props.post.postId, kudos);
                    this.props.onToggleReactionOpt();
                    this.props.onHandleReactionChange('Love It');
                }
            );
        }
    };

    // Adds or removes backslap from the current user.
    // Note: Adding backslap will remove like and bumslap of the user
    handleBackSlap = async () => {
        if (this.state.currentBackSlap) {
            let kudos = this.props.post.kudos;
            kudos.backSlaps = kudos.backSlaps.filter(
                (p) => p !== this.props.user.userId
            );

            this.props.changeKudos(this.props.post.postId, kudos);
            this.setState(
                (prevState) => {
                    return {
                        currentBackSlap: false,
                        backSlaps: prevState.backSlaps - 1,
                    };
                },
                () => {
                    this.props.onToggleReactionOpt();
                    this.props.onHandleReactionChange('Thumbs Up');
                }
            );
        } else {
            let kudos = this.props.post.kudos;
            kudos.backSlaps.push(this.props.user.userId);

            // filter out post like of the user
            if (this.state.likes) {
                kudos.likes = kudos.likes.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentLike: false,
                    likes: !this.state.likes - 1,
                });
            }
            // filter out post bumslap of the user
            else if (this.state.currentBumSlap) {
                kudos.bumSlaps = kudos.bumSlaps.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentBumSlap: false,
                    bumSlaps: !this.state.bumSlaps - 1,
                });
            }
            // filter out post gotThis of the user
            else if (this.state.currentGotThis) {
                kudos.gotThis = kudos.gotThis.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentGotThis: false,
                    gotThis: !this.state.gotThis - 1,
                });
            }
            // filter out post beStrong of the user
            else if (this.state.currentBeStrong) {
                kudos.beStrong = kudos.beStrong.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentBeStrong: false,
                    beStrong: !this.state.beStrong - 1,
                });
            }
            // filter out post awesome of the user
            else if (this.state.currentAwesome) {
                kudos.awesome = kudos.awesome.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentAwesome: false,
                    awesome: !this.state.awesome - 1,
                });
            }
            // filter out post congrats of the user
            else if (this.state.currentCongrats) {
                kudos.congrats = kudos.congrats.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentCongrats: false,
                    congrats: !this.state.congrats - 1,
                });
            }
            else {
                // update trending score with +1 score for post/question and user (like)
                if (this.props.post.section.type === 'users') {
                    updateKudosScore(
                        this.props.user._id,
                        this.props.post.section.type,
                        this.props.post._id
                    );
                }

                // update trending score with +1 score for groups / teams
                if (
                    this.props.post.section.type === 'groups' ||
                    this.props.post.section.type === 'teams'
                ) {
                    updateKudosScore(
                        this.props.user._id,
                        this.props.post.section.type,
                        this.props.post.section.id
                    );
                }
            }

            this.setState(
                (prevState) => {
                    return {
                        currentBackSlap: true,
                        backSlaps: prevState.backSlaps + 1,
                    };
                },
                () => {
                    this.props.changeKudos(this.props.post.postId, kudos);
                    this.props.onToggleReactionOpt();
                    this.props.onHandleReactionChange('Fist Pump');
                }
            );
        }
    };

    handleGotThis = async () => {
        if (this.state.currentGotThis) {
            let kudos = this.props.post.kudos;
            kudos.gotThis = kudos.gotThis.filter(
                (p) => p !== this.props.user.userId
            );

            this.props.changeKudos(this.props.post.postId, kudos);
            this.setState(
                (prevState) => {
                    return {
                        currentGotThis: false,
                        gotThis: prevState.gotThis - 1,
                    };
                },
                () => {
                    this.props.onToggleReactionOpt();
                    this.props.onHandleReactionChange('Thumbs Up');
                }
            );
        } else {
            let kudos = this.props.post.kudos;
            kudos.gotThis.push(this.props.user.userId);

            // filter out post like of the user
            if (this.state.likes) {
                kudos.likes = kudos.likes.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentLike: false,
                    likes: !this.state.likes - 1,
                });
            }
            // filter out post bumslap of the user
            else if (this.state.currentBumSlap) {
                kudos.bumSlaps = kudos.bumSlaps.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentBumSlap: false,
                    bumSlaps: !this.state.bumSlaps - 1,
                });
            }
            // filter out post backslap of the user
            else if (this.state.currentBackSlap) {
                kudos.backSlaps = kudos.backSlaps.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentBackSlap: false,
                    backSlaps: !this.state.backSlaps - 1,
                });
            }
            // filter out post beStrong of the user
            else if (this.state.currentBeStrong) {
                kudos.beStrong = kudos.beStrong.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentBeStrong: false,
                    beStrong: !this.state.beStrong - 1,
                });
            }
            // filter out post awesome of the user
            else if (this.state.currentAwesome) {
                kudos.awesome = kudos.awesome.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentAwesome: false,
                    awesome: !this.state.awesome - 1,
                });
            }
            // filter out post congrats of the user
            else if (this.state.currentCongrats) {
                kudos.congrats = kudos.congrats.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentCongrats: false,
                    congrats: !this.state.congrats - 1,
                });
            }
            else {
                // update trending score with +1 score for post/question and user (like)
                if (this.props.post.section.type === 'users') {
                    updateKudosScore(
                        this.props.user._id,
                        this.props.post.section.type,
                        this.props.post._id
                    );
                }

                // update trending score with +1 score for groups / teams
                if (
                    this.props.post.section.type === 'groups' ||
                    this.props.post.section.type === 'teams'
                ) {
                    updateKudosScore(
                        this.props.user._id,
                        this.props.post.section.type,
                        this.props.post.section.id
                    );
                }
            }

            this.setState(
                (prevState) => {
                    return {
                        currentGotThis: true,
                        gotThis: prevState.gotThis + 1,
                    };
                },
                () => {
                    this.props.changeKudos(this.props.post.postId, kudos);
                    this.props.onToggleReactionOpt();
                    this.props.onHandleReactionChange('Got This');
                }
            );
        }
    };

    handleBeStrong = async () => {
        if (this.state.currentBeStrong) {
            let kudos = this.props.post.kudos;
            kudos.beStrong = kudos.beStrong.filter(
                (p) => p !== this.props.user.userId
            );

            this.props.changeKudos(this.props.post.postId, kudos);
            this.setState(
                (prevState) => {
                    return {
                        currentBeStrong: false,
                        beStrong: prevState.beStrong - 1,
                    };
                },
                () => {
                    this.props.onToggleReactionOpt();
                    this.props.onHandleReactionChange('Thumbs Up');
                }
            );
        } else {
            let kudos = this.props.post.kudos;
            kudos.beStrong.push(this.props.user.userId);

            // filter out post like of the user
            if (this.state.likes) {
                kudos.likes = kudos.likes.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentLike: false,
                    likes: !this.state.likes - 1,
                });
            }
            // filter out post bumslap of the user
            else if (this.state.currentBumSlap) {
                kudos.bumSlaps = kudos.bumSlaps.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentBumSlap: false,
                    bumSlaps: !this.state.bumSlaps - 1,
                });
            }
            // filter out post backslap of the user
            else if (this.state.currentBackSlap) {
                kudos.backSlaps = kudos.backSlaps.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentBackSlap: false,
                    backSlaps: !this.state.backSlaps - 1,
                });
            }
            // filter out post gotThis of the user
            else if (this.state.currentGotThis) {
                kudos.gotThis = kudos.gotThis.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentGotThis: false,
                    gotThis: !this.state.gotThis - 1,
                });
            }
            // filter out post awesome of the user
            else if (this.state.currentAwesome) {
                kudos.awesome = kudos.awesome.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentAwesome: false,
                    awesome: !this.state.awesome - 1,
                });
            }
            // filter out post congrats of the user
            else if (this.state.currentCongrats) {
                kudos.congrats = kudos.congrats.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentCongrats: false,
                    congrats: !this.state.congrats - 1,
                });
            }
            else {
                // update trending score with +1 score for post/question and user (like)
                if (this.props.post.section.type === 'users') {
                    updateKudosScore(
                        this.props.user._id,
                        this.props.post.section.type,
                        this.props.post._id
                    );
                }

                // update trending score with +1 score for groups / teams
                if (
                    this.props.post.section.type === 'groups' ||
                    this.props.post.section.type === 'teams'
                ) {
                    updateKudosScore(
                        this.props.user._id,
                        this.props.post.section.type,
                        this.props.post.section.id
                    );
                }
            }

            this.setState(
                (prevState) => {
                    return {
                        currentBeStrong: true,
                        beStrong: prevState.beStrong + 1,
                    };
                },
                () => {
                    this.props.changeKudos(this.props.post.postId, kudos);
                    this.props.onToggleReactionOpt();
                    this.props.onHandleReactionChange('Be Strong');
                }
            );
        }
    };

    handleAwesome = async () => {
        if (this.state.currentAwesome) {
            let kudos = this.props.post.kudos;
            kudos.awesome = kudos.awesome.filter(
                (p) => p !== this.props.user.userId
            );

            this.props.changeKudos(this.props.post.postId, kudos);
            this.setState(
                (prevState) => {
                    return {
                        currentAwesome: false,
                        awesome: prevState.awesome - 1,
                    };
                },
                () => {
                    this.props.onToggleReactionOpt();
                    this.props.onHandleReactionChange('Thumbs Up');
                }
            );
        } else {
            let kudos = this.props.post.kudos;
            kudos.awesome.push(this.props.user.userId);

            // filter out post like of the user
            if (this.state.likes) {
                kudos.likes = kudos.likes.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentLike: false,
                    likes: !this.state.likes - 1,
                });
            }
            // filter out post bumslap of the user
            else if (this.state.currentBumSlap) {
                kudos.bumSlaps = kudos.bumSlaps.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentBumSlap: false,
                    bumSlaps: !this.state.bumSlaps - 1,
                });
            }
            // filter out post backslap of the user
            else if (this.state.currentBackSlap) {
                kudos.backSlaps = kudos.backSlaps.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentBackSlap: false,
                    backSlaps: !this.state.backSlaps - 1,
                });
            }
            // filter out post gotThis of the user
            else if (this.state.currentGotThis) {
                kudos.gotThis = kudos.gotThis.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentGotThis: false,
                    gotThis: !this.state.gotThis - 1,
                });
            }
            // filter out post beStrong of the user
            else if (this.state.currentBeStrong) {
                kudos.beStrong = kudos.beStrong.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentBeStrong: false,
                    beStrong: !this.state.beStrong - 1,
                });
            }
            // filter out post congrats of the user
            else if (this.state.currentCongrats) {
                kudos.congrats = kudos.congrats.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentCongrats: false,
                    congrats: !this.state.congrats - 1,
                });
            }
            else {
                // update trending score with +1 score for post/question and user (like)
                if (this.props.post.section.type === 'users') {
                    updateKudosScore(
                        this.props.user._id,
                        this.props.post.section.type,
                        this.props.post._id
                    );
                }

                // update trending score with +1 score for groups / teams
                if (
                    this.props.post.section.type === 'groups' ||
                    this.props.post.section.type === 'teams'
                ) {
                    updateKudosScore(
                        this.props.user._id,
                        this.props.post.section.type,
                        this.props.post.section.id
                    );
                }
            }

            this.setState(
                (prevState) => {
                    return {
                        currentAwesome: true,
                        awesome: prevState.awesome + 1,
                    };
                },
                () => {
                    this.props.changeKudos(this.props.post.postId, kudos);
                    this.props.onToggleReactionOpt();
                    this.props.onHandleReactionChange('Awesome');
                }
            );
        }
    };

    handleCongrats = async () => {
        if (this.state.currentCongrats) {
            let kudos = this.props.post.kudos;
            kudos.congrats = kudos.congrats.filter(
                (p) => p !== this.props.user.userId
            );

            this.props.changeKudos(this.props.post.postId, kudos);
            this.setState(
                (prevState) => {
                    return {
                        currentCongrats: false,
                        congrats: prevState.congrats - 1,
                    };
                },
                () => {
                    this.props.onToggleReactionOpt();
                    this.props.onHandleReactionChange('Thumbs Up');
                }
            );
        } else {
            let kudos = this.props.post.kudos;
            kudos.congrats.push(this.props.user.userId);

            // filter out post like of the user
            if (this.state.likes) {
                kudos.likes = kudos.likes.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentLike: false,
                    likes: !this.state.likes - 1,
                });
            }
            // filter out post bumslap of the user
            else if (this.state.currentBumSlap) {
                kudos.bumSlaps = kudos.bumSlaps.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentBumSlap: false,
                    bumSlaps: !this.state.bumSlaps - 1,
                });
            }
            // filter out post backslap of the user
            else if (this.state.currentBackSlap) {
                kudos.backSlaps = kudos.backSlaps.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentBackSlap: false,
                    backSlaps: !this.state.backSlaps - 1,
                });
            }
            // filter out post gotThis of the user
            else if (this.state.currentGotThis) {
                kudos.gotThis = kudos.gotThis.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentGotThis: false,
                    gotThis: !this.state.gotThis - 1,
                });
            }
            // filter out post beStrong of the user
            else if (this.state.currentBeStrong) {
                kudos.beStrong = kudos.beStrong.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentBeStrong: false,
                    beStrong: !this.state.beStrong - 1,
                });
            }
            // filter out post awesome of the user
            else if (this.state.currentAwesome) {
                kudos.awesome = kudos.awesome.filter(
                    (p) => p !== this.props.user.userId
                );

                this.setState({
                    currentAwesome: false,
                    awesome: !this.state.awesome - 1,
                });
            }
            else {
                // update trending score with +1 score for post/question and user (like)
                if (this.props.post.section.type === 'users') {
                    updateKudosScore(
                        this.props.user._id,
                        this.props.post.section.type,
                        this.props.post._id
                    );
                }

                // update trending score with +1 score for groups / teams
                if (
                    this.props.post.section.type === 'groups' ||
                    this.props.post.section.type === 'teams'
                ) {
                    updateKudosScore(
                        this.props.user._id,
                        this.props.post.section.type,
                        this.props.post.section.id
                    );
                }
            }

            this.setState(
                (prevState) => {
                    return {
                        currentCongrats: true,
                        congrats: prevState.congrats + 1,
                    };
                },
                () => {
                    this.props.changeKudos(this.props.post.postId, kudos);
                    this.props.onToggleReactionOpt();
                    this.props.onHandleReactionChange('Congrats');
                }
            );
        }
    };

    // Render Method that actually displays the component
    render() {
        return (
            <div className='ovrmnu lkbx'>
                <ul>
                    <li>
                        <a onClick={this.handleLike}>
                            <img
                                src={/*`/uploads/images/${
                                    this.state.currentLike
                                        ? 'thumbs-c.png'
                                        : 'symbol3.png'
                                }`*/'/uploads/images/thumbs-up.png'}
                            />{' '}
                            <span>Thumbs Up</span>
                        </a>
                    </li>
                    <li>
                        <a onClick={this.handleBumSlap}>
                            <img
                                src={/*`/uploads/images/${
                                    this.state.currentBumSlap
                                        ? 'bum-c.png'
                                        : 'symbol1.png'
                                }`*/'/uploads/images/love-it.png'}
                            />{' '}
                             <span>Love It</span>
                        </a>
                    </li>
                    <li>
                        <a onClick={this.handleBackSlap}>
                            <img
                                src={/*`/uploads/images/${
                                    this.state.currentBackSlap
                                        ? 'back-c.png'
                                        : 'symbol2.png'
                                }`*/'/uploads/images/first-pump.png'}
                            />{' '}
                            <span> Fist Pump</span>
                        </a>
                    </li>
                    <li>
                        <a onClick={this.handleGotThis}>
                            <img
                                src={'/uploads/images/you-got-this.png'}
                            />{' '}
                            <span> Got This</span>
                        </a>
                    </li>
                    <li>
                        <a onClick={this.handleBeStrong}>
                            <img
                                src={'/uploads/images/be-strong.png'}
                            />{' '}
                            <span> Be Strong</span>
                        </a>
                    </li>
                    <li>
                        <a onClick={this.handleAwesome}>
                            <img
                                src={'/uploads/images/awesome.png'}
                            />{' '}
                            <span> Awesome</span>
                        </a>
                    </li>
                    <li>
                        <a onClick={this.handleCongrats}>
                            <img
                                src={'/uploads/images/congratulations.png'}
                            />{' '}
                            <span> Congrats</span>
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    };
};

export default connect(mapStateToProps, {})(Kudos);
