import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as API from '../../../utils/api.js';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { hideViewExercise, ShowEditExercise } from '../../../actions';

const ViewExercise = () => {
    const dispatch = useDispatch();
    const exercise_id = useSelector((state) => state.planner.modalsParams.id);

    const [exerciseTitle, setExerciseTitle] = useState('');
    const [exerciseType, setExerciseType] = useState('');
    const [exerciseDescription, setExerciseDescription] = useState('');
    const [exerciseTips, setExerciseTips] = useState('');
    const [exerciseImgOne, setExerciseImgOne] = useState('');
    const [exerciseImgTwo, setExerciseImgTwo] = useState('');
    const [exerciseImgThree, setExerciseImgThree] = useState('');
    const [videoLink, setVideoLink] = useState('');
    const [exerciseComponents, setExerciseComponents] = useState('');
    const [strength, setStrength] = useState('');

    useEffect(() => {
        API.getExerciseById(exercise_id).then((exercisedata) => {
            setExerciseTitle(exercisedata.title);
            setExerciseType(exercisedata.type);
            setExerciseDescription(exercisedata.description);
            setExerciseTips(exercisedata.tips);
            setStrength(exercisedata.strength);
            setExerciseImgOne(exercisedata.pic1);
            setExerciseImgTwo(exercisedata.pic2);
            setExerciseImgThree(exercisedata.pic3);
            setVideoLink(exercisedata.video);
            setExerciseComponents(exercisedata.components);
        });
    }, [exercise_id]);

    const handleClose = () => {
        dispatch(hideViewExercise());
    };

    const editExercise = (id) => {
        dispatch(ShowEditExercise(id));
        handleClose();
    };

    var settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
    };

    return (
        <Modal
            centered
            size="lg"
            show={true}
            onHide={handleClose}
            dialogClassName="planner-dialog modal-exercise-info"
            className="customize-excersice "
        >
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>{exerciseTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row ">
                        <div className="col-xs-12 col-sm-6 mobile-order-second">
                            <div className="row exImages-section-left ">
                                {exerciseImgOne ? (
                                    <div className="col-sm-6">
                                        <div className="exc-pic-pop br-all-top">
                                            <img className="img-fluid" src={API.getServerUrl().apiURL + '/uploads/exercise/' + exerciseImgOne} />
                                        </div>
                                    </div>
                                ) : null}
                                {exerciseImgTwo ? (
                                    <div className="col-sm-6">
                                        <div className="exc-pic-pop br-all-top">
                                            <img className="img-fluid" src={API.getServerUrl().apiURL + '/uploads/exercise/' + exerciseImgTwo} />
                                        </div>
                                    </div>
                                ) : null}
                                {exerciseImgThree ? (
                                    <div className="col-sm-6">
                                        <div className="exc-pic-pop br-all-top">
                                            <img className="img-fluid" src={API.getServerUrl().apiURL + '/uploads/exercise/' + exerciseImgThree} />
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 mobile-order-first">
                            <div className="video-wrapper">
                                <div className="embed-responsive embed-responsive embed-responsive-16by9">
                                    {videoLink ? (
                                        <iframe
                                            width="560"
                                            className="embed-responsive-item add-ajax-video"
                                            height="315"
                                            src={videoLink}
                                            frameborder="0"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen=""
                                        ></iframe>
                                    ) : null}
                                </div>
                            </div>
                            <div className="ex-descptin-bdy">
                                <h3>Description</h3>
                                <div className="ex-desc-ajax" dangerouslySetInnerHTML={{ __html: exerciseDescription }} />
                                <h3>Tips</h3>
                             
                                <div className="ex-desc-ajax" dangerouslySetInnerHTML={{ __html: exerciseTips }} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Form>
        </Modal>
    );
};

export default connect(null, { ShowEditExercise, hideViewExercise })(ViewExercise);
