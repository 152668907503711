const INITIAL_STATE = {
	email: "",
	password: "",
	user: {},
	error: "",
	loading: true,
	signupLoading: false,
	userSignedIn: false,
	token: "",
	incorrect: false,
	loadedXX: 0,
	redirect: "",
	redirectLogin: "",
	socialDetails: {},
	showReactionOpt: false,
	showReactionOptId: "",
	showEllipsisOpt: false,
	showEllipsisOptId: "",
	activityFilter: [],
	showPostActivityFilter: false,
	showSearchActivityFilter: false,
	locationSearchFilter: "",
	showLocationFilter: true,
	showPostShare: false,
	eventsCount: 0,
	groupsCount: 0,
	myGroupsCount: 0,
	myTeamsCount: 0,
	teamsCount: 0,
	firstTimeLogin: true,
	notificationCount: {
		notification: [],
		groupCount: 0,
		teamsCount: 0,
		personalCount: 0,
	},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "email_changed":
			return { ...state, email: action.payload };
		case "password_changed":
			return { ...state, password: action.payload };
		case "login_user":
			return { ...state, loading: true, error: "" };
		case "login_user_success":
			return {
				...state,
				user: action.payload.user,
				token: action.payload.token,
				// redirect: action.payload.redirect,
				redirectLogin: action.payload.redirect,
				activityFilter: action.payload.user.interest,
				firstTimeLogin: false,
				userSignedIn: true,
				loading: false,
				signupLoading: false,
			};
		case "signup_user_success":
			return {
				...state,
				user: action.payload.user,
				token: action.payload.token,
				redirect: action.payload.redirect,
				firstTimeLogin: action.payload.firstTimeLogin,
				userSignedIn: true,
				loading: false,
				signupLoading: false,
			};
		case "login_user_fail":
			return {
				...state,
				user: {},
				userSignedIn: false,
				incorrect: true,
				loading: false,
				signupLoading: false,
			};
		case "loading_stop":
			return { ...state, loading: false };
		case "logout":
			return { ...state };
		case "signup_loading":
			return { ...state, signupLoading: true };
		case "signup_loading_stop":
			return { ...state, signupLoading: false };
		case "update_user":
			return {
				...state,
				user: action.payload.user,
				firstTimeLogin: false,
				redirect: action.payload.redirect,
				signupLoading: false,
			};
		case "upload_progress":
			return { ...state, loadedXX: action.payload };
		case "sociallogin":
			return {
				...state,
				redirectLogin: action.payload.redirect,
				socialDetails: action.payload.loginDetails,
			};
		case "toggle_reaction_option":
			if (state.showReactionOpt) {
				if (
					action.payload == "" ||
					state.showReactionOptId == action.payload
				) {
					return {
						...state,
						showReactionOpt: false,
						showReactionOptId: "",
					};
				} else return { ...state, showReactionOptId: action.payload };
			} else {
				return {
					...state,
					showReactionOpt: true,
					showReactionOptId: action.payload,
				};
			}
		case "toggle_ellipsis_option":
			if (state.showEllipsisOpt) {
				if (
					action.payload == "" ||
					state.showEllipsisOptId == action.payload
				)
					return {
						...state,
						showEllipsisOpt: false,
						showEllipsisOptId: "",
					};
				else return { ...state, showEllipsisOptId: action.payload };
			} else {
				return {
					...state,
					showEllipsisOpt: true,
					showEllipsisOptId: action.payload,
				};
			}
		case "update_user_saved":
			let updatedUser = { ...state.user, saved: action.payload };
			return {
				...state,
				user: updatedUser,
				showEllipsisOpt: false,
				showEllipsisOptId: "",
			};
		case "update_user_following":
			return {
				...state,
				user: { ...state.user, following: action.payload },
			};
		case "update_post_filter":
			return {
				...state,
				activityFilter: action.payload,
				showPostActivityFilter: false,
			};
		case "update_search_filter":
			return {
				...state,
				activityFilter: action.payload.interest,
				locationSearchFilter: action.payload.location,
				showSearchActivityFilter: false,
			};
		case "update_show_post_filter":
			return { ...state, showPostActivityFilter: action.payload };
		case "update_show_search_filter":
			return {
				...state,
				showSearchActivityFilter: action.payload,
				showLocationFilter: true,
			};
		case "update_show_trending_filter":
			return {
				...state,
				showSearchActivityFilter: action.payload,
				showLocationFilter: false,
			};
		case "update_user_groups":
			let updatedUserGroups = {
				...state.user,
				groups: action.payload.groups,
				groupRequests: action.payload.groupRequests,
			};
			return { ...state, user: updatedUserGroups };
		case "update_user_teams":
			let updatedUserTeams = {
				...state.user,
				teams: action.payload.teams,
				teamRequests: action.payload.teamRequests,
			};
			return { ...state, user: updatedUserTeams };
		case "update_show_post_share":
			return { ...state, showPostShare: action.payload };
		case "update_photos":
			let updatedUserPhotos = {
				...state.user,
				photos: action.payload.photos,
			};
			return { ...state, user: updatedUserPhotos };
		case "update_events_count":
			return {
				...state,
				eventsCount: action.payload,
			};
		case "update_groups_count":
			return {
				...state,
				groupsCount: action.payload,
			};
		case "update_teams_count":
			return {
				...state,
				teamsCount: action.payload,
			};
		case "update_my_groups_count":
			return {
				...state,
				myGroupsCount: action.payload,
			};
		case "update_my_teams_count":
			return {
				...state,
				myTeamsCount: action.payload,
			};
		case "update_notification_count":
			return {
				...state,
				notificationCount: action.payload,
			};
		default:
			return state;
	}
};
