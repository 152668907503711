import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateShowPostShare } from '../actions';
import Modal from 'react-bootstrap/Modal';
import {
    FacebookShareButton,
    FacebookIcon,
    FacebookShareCount,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    WhatsappShareButton,
    WhatsappIcon,
    PinterestShareButton,
    PinterestIcon,
    RedditShareButton,
    RedditIcon,
  } from "react-share";

class PostShare extends Component {
    constructor(props) {
        super(props);
    }

	closeModal = () => {
        this.props.updateShowPostShare(false);
    }

    render(){
        let title = this.props.title;
        let shareUrl = this.props.url;
		return(
			<Modal
                onHide={this.closeModal}
                size="lg"
                show={true}
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Share</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="pstshare">
                        <div className="post-share-facebook">
                            <FacebookShareButton
                                url={shareUrl}
                                quote={title}
                                className="facebook-share-button"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <FacebookShareCount url={shareUrl} className="facebook-share-count">
                                {count => count}
                            </FacebookShareCount>
                        </div>
                        <div className="post-share-twitter">
                            <TwitterShareButton
                                url={shareUrl}
                                title={title}
                                className="twitter-share-button"
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                        </div>
                        <div className="post-share-linkedin">
                            <LinkedinShareButton url={shareUrl} className="linkedin-share-button">
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                        </div>
                        <div className="post-share-whatsapp">
                            <WhatsappShareButton
                                url={shareUrl}
                                title={title}
                                separator=":: "
                                className="whatsapp-share-button"
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                        {/*<div className="post-share-pinterest">
                            <PinterestShareButton
                                url={String(window.location)}
                                media={`${String(window.location)}/${exampleImage}`}
                                className="pinterest-share-button"
                            >
                                <PinterestIcon size={32} round />
                            </PinterestShareButton>
                        </div>*/}
                        <div className="post-share-reddit">
                            <RedditShareButton
                                url={shareUrl}
                                title={title}
                                windowWidth={660}
                                windowHeight={460}
                                className="reddit-share-button"
                            >
                                <RedditIcon size={32} round />
                            </RedditShareButton>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
		);
	}
}

const mapStateToProps = state => {
    return {
        
    };
};

export default connect(mapStateToProps, { updateShowPostShare })(PostShare);