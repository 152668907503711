import React, { useState, useRef } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import GoogleLoginBtn from "react-google-login";
import { GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login";
import { Button } from "react-bootstrap";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import {
	IoIosAddCircleOutline,
	IoIosCheckmarkCircleOutline,
} from "react-icons/io";
import loading2 from "../assets/loading2.svg";
import { userIdExists } from "../utils/api";
import { loginUser, socialLogIn } from "../actions";
import Message from "../components/common/message";
import InputLoader from "../components/common/inputLoader";
import LoadButton from "../components/common/button";

const SignInUser = ({
	redirect,
	incorrect,
	signupLoading,
	loginUser,
	socialLogIn,
}) => {
	const [loginDetails, setLoginDetails] = useState({
		userId: "",
		password: "",
	});
	const [showLoading, setShowLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [userExists, setUserExists] = useState(true);
	const [btnDisable, setBtnDisable] = useState(true);
	const [submitting, setSubmitting] = useState(false);
	const history = useHistory();
	const userIdRef = useRef(null);
	const passwordRef = useRef(null);
	const dispatch = useDispatch();

	const handleChange = () => {
		setLoginDetails({
			userId: userIdRef.current.value,
			password: passwordRef.current.value,
		});
	};

	const handleLoginUser = async (e) => {
		e.preventDefault();
		setSubmitting(true);
		try {
			console.log("incorrect", incorrect);
			setTimeout(() => {});
			await loginUser(loginDetails);
			!signupLoading ? setSubmitting(false) : history.push("/home");
		} catch (error) {
			console.log("error", error);
			setSubmitting(false);
		}
	};

	const checkUserExists = () => {
		if (loginDetails.userId === "") {
			setShowLoading(false);
			setLoading(false);
			setUserExists(true);
			setBtnDisable(true);
		} else {
			setShowLoading(true);
			setLoading(true);
			userIdExists(loginDetails.userId).then((res) => {
				setLoading(false);
				setUserExists(res);
				setBtnDisable(!res);
			});
		}
	};

	const onLoginSuccess = (res) => {
		console.log("GOOGLE", res);
		socialLogIn({
			socialId: res.profileObj.googleId,
			socialType: "google",
			email: res.profileObj.email,
			firstName: res.profileObj.givenName,
			lastName: res.profileObj.familyName,
		})(dispatch);
	};

	const onLoginFailure = (res) => {
		console.log("Log in Failure", res);
	};
	const responseFacebook = (response) => {
		console.log("response", response);
		var data = [];
		data = response.name.split(" ");
		var fname = data[0];
		var latname = data[1];

		socialLogIn({
			socialId: response.id,
			socialType: "facebook",
			email: response.email,
			firstName: fname,
			lastName: latname,
			profilePicture: response.picture.data.url,
		})(dispatch);
	};

	const facebookData = (
		<FacebookLogin
			appId="758640985947939"
			fields="name,email,picture"
			onClick={() => console.log("clicked")}
			callback={responseFacebook}
			disableMobileRedirect={true}
			icon={<FaFacebook size={22} style={{ marginRight: "10px" }} />}
		/>
	);

	if (redirect) {
		return <Redirect to={redirect} />;
	}
	const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

	return (
		<div className={`outbx ${isiOS ? "ios" : ""}`}>
			<div className="loginbx">
				<img src="/uploads/images/grey-logo.png" />
				<h3>
					<i>Welcome, Login Here</i>
				</h3>
			</div>
			<div className={`loginbxin ${isiOS ? "ios-pb" : ""}`}>
				<form onSubmit={handleLoginUser}>
					<Message
						display={!incorrect}
						text={"Incorrect username/email or password"}
					/>
					<div className="form-group">
						<input
							className="form-control"
							placeholder="Username or Email"
							type="text"
							id="formGroupEmail"
							ref={userIdRef}
							onChange={handleChange}
							value={loginDetails.userId}
							required
							onBlur={checkUserExists}
						/>
						<InputLoader
							showLoading={showLoading}
							loading={loading}
							userExists={userExists}
						/>
					</div>
					<div className="form-group">
						<input
							className="form-control"
							style={{ marginBottom: "10px" }}
							id="formGroupPassword"
							placeholder="Password"
							type="password"
							ref={passwordRef}
							value={loginDetails.password}
							onChange={handleChange}
							required
						/>
						<Link
							style={{
								fontSize: "13px",
								padding: "0px 0px 10px 0px",
							}}
							to="/forget-password"
						>
							forget password?
						</Link>
					</div>
					<div className="form-group">
						<LoadButton
							btnDisable={btnDisable}
							submitting={submitting}
							title={"Login"}
						/>
					</div>
				</form>
				<div
					className="frgtbxin"
					style={{ justifyContent: "space-around" }}
				>
					<Link to="/signupUser">Get Registered</Link>
				</div>
				<div className="btnfacbook">{facebookData}</div>
				<div className="btngoogle">
					<GoogleLoginBtn
						clientId="426427320784-snhp8e6pg47en0nmk8egr72lj29rg0na.apps.googleusercontent.com"
						text="Login With google"
						onSuccess={onLoginSuccess}
						onFailure={onLoginFailure}
						cookiePolicy={"single_host_origin"}
						icon={false}
					>
						<FaGoogle size={22} style={{ marginRight: "10px" }} />
						<span>Login With Google</span>
					</GoogleLoginBtn>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		incorrect: state.auth.incorrect,
		userSignedIn: state.auth.userSignedIn,
		redirect: state.auth.redirectLogin,
	};
};

export default connect(mapStateToProps, { loginUser, socialLogIn })(SignInUser);
