import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { signUpUser } from "../actions";
import loading from "../assets/loading.svg";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";

const SignUpUser = (props) => {
	const [userDetails, setUserDetails] = useState({
		userId: "",
		firstName: "",
		lastName: "",
		email: "",
		role: "Athlete",
		password: "",
		dob: "",
		phone: "",
		address: "",
		type: "",
		socialid: "",
	});
	const [cpassword, setCPassword] = useState("");
	const today = new Date().toISOString().split("T")[0];

	const handleChange = (e) => {
		const { name, value } = e.target;

		if (name === "dob") {
			const selectedDate = new Date(value);
			if (selectedDate.toISOString().split("T")[0] > today) {
				setUserDetails({
					...userDetails,
					dob: selectedDate,
				});
			} else {
				setUserDetails({
					...userDetails,
					[name]: value,
				});
			}
		} else if (
			(name === "firstName" || name === "lastName") &&
			!/^[a-zA-Z]*$/.test(value)
		) {
			return;
		} else if (name === "phone" && value.length > 20) {
			const truncatedValue = value.slice(0, 20);
			setUserDetails({
				...userDetails,
				[name]: truncatedValue,
			});
		} else {
			setUserDetails({
				...userDetails,
				[name]: value,
			});
		}
	};

	const changeRoleCoach = () => {
		setUserDetails({
			...userDetails,
			role: "Coach",
		});
	};

	const handleRoleChange = (e) => {
		setUserDetails({
			...userDetails,
			role: e.target.value,
		});
	};

	const signUpUser = (e) => {
		e.preventDefault();

		// assign the user role
		let selectedRole = document.getElementById("roleSelect");

		if (selectedRole.value === "Coach") {
			changeRoleCoach();
		}

		if (cpassword === userDetails.password) {
			props.signUpUser(userDetails);
		} else {
			alert("Password and retype password are different");
		}
	};

	useEffect(() => {
		if (props.sociallogin.email !== undefined) {
			setUserDetails({
				...userDetails,
				email: props.sociallogin.email,
				firstName: props.sociallogin.firstName,
				lastName: props.sociallogin.lastName,
				type: props.sociallogin.socialType,
				socialid: props.sociallogin.socialId,
			});
		}
	}, [props.sociallogin]);

	if (props.user && props.redirect) {
		return <Redirect to={props.redirect} />;
	}
	const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

	return (
		<div className={`lgnrgstr`}>
			<div className={`loginbx ${isiOS ? "ios-m" : ""}`}>
				<img src="/uploads/images/grey-logo.png" />
				<h3>
					<i>Welcome, Signup Here</i>
				</h3>
			</div>

			<form onSubmit={signUpUser}>
				<div className="form-group">
					<select
						className="form-control"
						id="roleSelect"
						onChange={handleRoleChange}
					>
						<option value="Athlete">I am an athlete</option>
						<option value="Coach">I am a coach</option>
					</select>
				</div>

				<div className="form-group">
					<input
						type="text"
						id="formGroupUsername"
						className="form-control"
						name="userId"
						onChange={handleChange}
						value={userDetails.userId}
						placeholder="Type your username *"
						required
					/>
				</div>
				<div className="form-group">
					<input
						type="text"
						id="formGroupFirstName"
						className="form-control"
						name="firstName"
						onChange={handleChange}
						value={userDetails.firstName}
						placeholder="Type your first name *"
						required
					/>
				</div>
				<div className="form-group">
					<input
						type="text"
						id="formGroupLastName"
						className="form-control"
						name="lastName"
						onChange={handleChange}
						value={userDetails.lastName}
						placeholder="Type your last name *"
						required
					/>
				</div>
				<div className="form-group">
					<DateTimePicker
						className="form-control"
						inputProps={{
							readOnly: true,
							disabled: true, // Disable input entirely
						}}
						onChange={(newDate) => {
							if (
								!isNaN(newDate) &&
								newDate <= new Date() &&
								newDate >= new Date("1900-01-01")
							) {
								setUserDetails({
									...userDetails,
									dob: newDate,
								});
							}
						}}
						value={userDetails.dob}
						maxDate={new Date()}
						format="yyyy-MM-dd"
					/>
				</div>
				<div className="form-group">
					<input
						type="number"
						id="formGroupPhone"
						className="form-control"
						name="phone"
						onChange={handleChange}
						value={userDetails.phone}
						placeholder="Type your phone number *"
						required
					/>
				</div>
				<div className="form-group">
					<input
						type="text"
						id="formGroupAddress"
						className="form-control"
						name="address"
						onChange={handleChange}
						value={userDetails.address}
						placeholder="Type your address *"
						required
					/>
				</div>
				<div className="form-group">
					<input
						type="email"
						id="formGroupEmail"
						className="form-control"
						name="email"
						onChange={handleChange}
						value={userDetails.email}
						placeholder="Enter your email *"
						required
						readOnly={userDetails.type ? true : false}
					/>
				</div>
				<div className="form-group">
					<input
						type="password"
						id="formGroupPw"
						className="form-control"
						name="password"
						onChange={handleChange}
						placeholder="Type your password *"
						required
					/>
				</div>
				<div className="form-group">
					<input
						type="password"
						id="formGroupConfirmPw"
						className="form-control"
						name="cpassword"
						onChange={(e) => setCPassword(e.target.value)}
						placeholder="Retype your password *"
						required
					/>
				</div>
				<div className="form-group">
					<button className="button subbtn">Next</button>
				</div>
			</form>

			<p className="text-center">
				Already a user? <Link to="/signInUser">Sign In</Link>
			</p>

			{props.signupLoading ? (
				<div className="signup-user-container-login">
					<img src={loading} alt="" />
				</div>
			) : (
				<br />
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		signupLoading: state.auth.signupLoading,
		user: state.auth.user,
		redirect: state.auth.redirect,
		sociallogin: state.auth.socialDetails,
	};
};

export default connect(mapStateToProps, { signUpUser })(SignUpUser);
