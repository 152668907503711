import React, { Component } from "react";
import { getServerUrl, getUser } from "../utils/api";
import { assignTimeAgo } from "../utils/helper";
import Avatar from "./common/avatar";

class CommentReply extends Component {
	state = {
		editReply: false,
		editedReply: "",
		replyUsers: {},
	};
	componentDidMount() {
		if (this.props.reply.userId === this.props.currentUserId) {
			this.setState({
				replyUsers: this.props.user,
			});
		} else {
			getUser(this.props.reply.userId, this.props.token)
				.then((user) => {
					this.setState({
						replyUsers: user,
					});
				})
				.catch((error) => {
					console.error("Error fetching user data:", error);
				});
		}
	}
	handleEditReply = () => {
		const { reply } = this.props;
		this.setState({
			editReply: true,
			editedReply: reply.description,
		});
	};

	handleEditReplyChange = (e) => {
		this.setState({ editedReply: e.target.value });
	};

	saveEditedReply = () => {
		const { reply, replyId, commentId } = this.props;
		const { editedReply } = this.state;
		const updatedReply = { ...reply, description: editedReply };
		this.props.onUpdateReply(commentId, replyId, updatedReply);
		this.setState({ editReply: false });
	};

	deleteReply = () => {
		const { reply } = this.props;
		this.props.onDeleteReply(reply.replyId);
	};
	render() {
		const { reply, profileBlank } = this.props;
		const { editReply, editedReply, replyUsers } = this.state;

		return (
			<>
				<div className="col-12 replybxin">
					<div className="usrtop">
						<div className="row">
							<div className="col-2">
								<div className="userthumb">
									<a className="userbx">
										<Avatar
											url={"/uploads/user/"}
											state={replyUsers.profilePicture}
											alt={"user"}
										/>
									</a>
								</div>
							</div>
							<div className="col-10 nopad pt-1 pl-3">
								<a>{`${replyUsers.firstName} ${replyUsers.lastName}`}</a>
								<span className="small pstim">
									{assignTimeAgo(reply.time)}
								</span>
							</div>
						</div>
					</div>
					<div className="cmntxt">
						{editReply ? (
							<div className="">
								<span>
									<input
										type="text"
										className="form-control"
										value={editedReply}
										onChange={this.handleEditReplyChange}
									/>
									<a
										style={{ cursor: "pointer" }}
										onClick={this.saveEditedReply}
									>
										Update
									</a>
									<span className="txtgry">
										&nbsp; &nbsp; | &nbsp; &nbsp;
									</span>
									<a
										style={{ cursor: "pointer" }}
										onClick={() =>
											this.setState({ editReply: false })
										}
									>
										Cancel
									</a>
								</span>
							</div>
						) : (
							<p style={{ paddingLeft: "0px" }}>
								{reply.description}
							</p>
						)}
						{this.props.editable && !editReply && (
							<div className="">
								<a
									style={{ cursor: "pointer" }}
									onClick={this.handleEditReply}
								>
									Edit
								</a>
								<span className="txtgry">
									&nbsp; &nbsp; | &nbsp; &nbsp;
								</span>
								<a
									style={{ cursor: "pointer" }}
									onClick={this.deleteReply}
								>
									Delete
								</a>
							</div>
						)}
					</div>
				</div>
			</>
		);
	}
}

export default CommentReply;
