import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { hideSessionDescription } from '../../../actions';
// Importing helper functions
import * as API from '../../../utils/api.js';
import Modal from 'react-bootstrap/Modal';

const SessionDescription = ({ SessionDescriptionDetail, hideSessionDescription }) => {
    const [components, setComponents] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const componentsData = await API.getComponents();
            setComponents(componentsData);
        };
        fetchData();
    }, []);

    const handleClose = () => {
        hideSessionDescription();
    };

    const htmlDecode = (input) => {
        const e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
    };

    return (
        <Modal
            centered
            size="md"
            show={true}
            onHide={handleClose}
            dialogClassName="planner-dialog"
            className="customize-excersice"
        >
            <Modal.Header closeButton>
                <Modal.Title>Session Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {SessionDescriptionDetail.map((set, index) => (
                    <>
                        {(typeof set === 'object') ? (
                            <>


                                <>
                                    <div key={index + set.title} dangerouslySetInnerHTML={{ __html: set.title }} />
                                    {
                                        set.descriptionList?.[0] ?? (<>
                                            <div key={index + set.descriptionList[0]} dangerouslySetInnerHTML={{ __html: set.descriptionList[0] }} />
                                        </>)
                                    }

                                </>

                            </>
                        ) : (
                            <>

                                <div key={index} dangerouslySetInnerHTML={{ __html: set }} />
                            </>)
                        }
                    </>


                ))}
            </Modal.Body>
            <Modal.Footer className="buttons-save"></Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        SessionDescriptionDetail: state.planner.sessionDescription,
    };
};

export default connect(mapStateToProps, { hideSessionDescription })(SessionDescription);




