/**
 * =====================================
 * REACT COMPONENT CLASS
 * =====================================
 * @date created: 28 August 2019
 * @authors: Jay Parikh, Waqas Rehmani
 *
 * This file defines the NewPost component. The class NewPost
 * is where the component is defined.
 *
 * This is a component defined for reusability.
 *
 * This component shows a new post that can made on Home, Event and Group screenss.
 *
 */

// Importing libraries for setup
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

// Importing icons and pictures
import loading from "../assets/loading2.svg";
import profileBlank from "../assets/profile_blank.png";

// Importing helper functions
import {
	getServerUrl,
	mobileQuery,
	eventUpload,
	userEventAdd,
	userEventRemove,
	getActivityByTitle,
} from "../utils/api";

import GoogleLocationSearch from "../components/GoogleLocationSearch";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import LoadButton from "../components/common/button";
import TabToRemove from "../components/common/tabToRemove";
class NewEvent extends Component {
	// Constructor for NewPost
	constructor(props) {
		super(props);

		this.state = {
			event: {
				title: "",
				description: "",
				startDate: "",
				endDate: "",
				startTime: "",
				endTime: "",
				logo: "",
				location: "",
				lat: "",
				lng: "",
			},
			postId: "",
			userId: props.user.userId,
			type: "text",
			interest: [],
			interestValue: "",
			time: new Date(),
			loading: false,
		};

		this.uploadEventLogoRef = React.createRef();
	}

	handleEventTitle = (e) => {
		this.setState({
			event: { ...this.state.event, title: e.target.value },
		});
	};

	handleEventStartDate = (e) => {
		this.setState({
			event: { ...this.state.event, startDate: e.target.value },
		});
	};

	handleEventEndDate = (e) => {
		this.setState({
			event: { ...this.state.event, endDate: e.target.value },
		});
	};

	handleEventStartTime = (e) => {
		this.setState({
			event: { ...this.state.event, startTime: e.target.value },
		});
	};

	handleEventEndTime = (e) => {
		e.preventDefault();
		e.persist();
		const newEndTime = e.target.value;
		const { startDate, endDate, startTime } = this.state.event;

		// Check if start time is selected
		if (!startTime) {
			alert("Please select the start time first.");
			return;
		}
		const startDateObject = new Date(startDate);
		const selectedEndDate = new Date(endDate);

		// Compare the selected end date and time with the start date and time
		if (selectedEndDate < startDateObject) {
			// If selected end date is earlier than start date, adjust end time to start time
			this.setState({
				event: { ...this.state.event, endTime: startTime },
			});
		} else if (newEndTime <= startTime && startDate === endDate) {
			// If end time is less than or equal to start time, adjust it to be one minute later
			const adjustedEndTime = new Date(startTime);
			adjustedEndTime.setMinutes(adjustedEndTime.getMinutes() + 1);

			const adjustedEndTimeString = `${adjustedEndTime
				.getHours()
				.toString()
				.padStart(2, "0")}:${adjustedEndTime
				.getMinutes()
				.toString()
				.padStart(2, "0")}`;

			this.setState({
				event: { ...this.state.event, endTime: adjustedEndTimeString },
			});
		} else {
			this.setState({
				event: { ...this.state.event, endTime: newEndTime },
			});
		}
	};
	handleEventDescription = (e) => {
		this.setState({
			event: { ...this.state.event, description: e.target.value },
		});
	};

	handleEventLocation = (e) => {
		this.setState({
			event: { ...this.state.event, location: e.target.value },
		});
	};

	uploadEventLogo = (e) => {
		let eventLogo = this.uploadEventLogoRef.current.files[0];
		let fileTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

		if (eventLogo === undefined) {
			alert("Please select image file to upload");
		} else if (fileTypes.indexOf(eventLogo.type) === -1) {
			alert("Please select file type of JPEG, JPG, PNG or GIF");
		} else {
			const Imagedata = new FormData();
			Imagedata.append("eventUpload", eventLogo);
			eventUpload(Imagedata).then((imgUpload) => {
				this.setState({
					loading: false,
					event: { ...this.state.event, logo: imgUpload.filename },
				});
			});
			this.setState({ loading: true });
		}
	};

	addEvent = async (e) => {
		e.preventDefault();
		e.persist();

		if (this.state.event.title === "") {
			alert("Enter event name");
			return;
		} else if (
			this.state.event.startDate === "" ||
			this.state.event.startDate === null
		) {
			alert("Enter event start date");
			return;
		} else if (
			this.state.event.endDate === "" ||
			this.state.event.endDate === null
		) {
			alert("Enter event end Date");
			return;
		} else if (this.state.event.startTime === "") {
			alert("Enter event start Time");
			return;
		} else if (this.state.event.endTime === "") {
			alert("Enter event end time");
			return;
		} else if (this.state.event.description === "") {
			alert("Enter event description");
			return;
		} else if (this.state.event.location === "") {
			alert("Event enter location");
			return;
		} else if (this.state.interestValue === "") {
			alert("Event select Activity");
			return;
		} else if (this.state.event.logo === "") {
			alert("Upload event photo");
			return;
		} else {
			if (this.state.loading) {
				return;
			}
			this.setState({ loading: true });

			let teamEvent = this.state.event;
			let slugify = require("slugify");
			teamEvent.slug = slugify(teamEvent.title, {
				replacement: "-",
				remove: null,
				lower: true,
			});
			teamEvent.creatorId = this.props.user._id;
			teamEvent.owner = this.props.user.userId;
			teamEvent.eventId =
				"e" +
				Date.now() +
				Math.floor(Math.random() * 100000).toString();

			// get interest data
			if (this.state.interestValue) {
				await getActivityByTitle(this.state.interestValue).then(
					(activity) => {
						if (activity._id) {
							teamEvent.interest = {
								id: activity._id,
								name: activity.title,
								icon: activity.activity_icon,
							};
						}
					}
				);
			}

			await userEventAdd(teamEvent).then((resp) => {
				if (resp.success === false) {
					alert(resp.msg);
					this.setState({ loading: false });
				} else {
					const confirmation = window.confirm(
						resp.msg + " Do you want to go back?"
					);
					if (confirmation) {
						this.props.history.push("/events/");
					}
					this.setState({
						event: {
							title: "",
							description: "",
							startDate: "",
							endDate: "",
							startTime: "",
							endTime: "",
							logo: "",
							location: "",
							lat: "",
							lng: "",
						},
					});
					this.uploadEventLogoRef.current.value = null;
					this.setState({ loading: false });
				}
			});
		}
	};

	removeEvent = (e, eventId) => {
		e.preventDefault();
		userEventRemove(eventId, this.props.user.userId, "remove").then(
			(resp) => {
				if (resp.success === false) {
					alert(resp.msg);
				} else {
					alert(resp.msg);
					this.setState({ loading: false, events: resp.events });
				}
			}
		);
		this.setState({ loading: true });
	};

	handleGoogleAddress = (address, lat, lng) => {
		this.setState({
			event: { ...this.state.event, location: address, lat, lng },
		});
	};

	handleChange = (field) => (e) => {
		this.setState({ [field]: e.target.value });
	};

	openUploadEventLogo = () => {
		this.uploadEventLogoRef.current.click();
	};
	removePhoto = () => {
		this.setState({
			event: {
				...this.state.event,
				logo: "",
			},
		});
		this.uploadEventLogoRef.current.value = null;
	};

	render() {
		let isMobile = window.matchMedia(mobileQuery).matches;
		const today = new Date();
		const maxDate = new Date(
			today.getFullYear() + 30,
			today.getMonth(),
			today.getDate()
		);
		return (
			<div className="outbx">
				<div className="teams-container">
					<a
						className="backbtn"
						onClick={(e) => {
							e.preventDefault();
							window.history.back();
						}}
					>
						{" "}
					</a>
					<h6>Create Event </h6>
				</div>
				<div className="boxmenu mmbr crtevnt">
					<form>
						<div className="form-group">
							<label
								className="form-label"
								htmlFor="formEventName"
							>
								Event Name*
							</label>
							<input
								placeholder="Event Name*"
								type="text"
								id="formEventName"
								className="form-control"
								value={this.state.event.title}
								onChange={this.handleEventTitle}
							/>
						</div>
						<div className="row">
							<div className="col">
								<div className="form-group">
									<label
										className="form-label"
										htmlFor="formEventStart"
									>
										Start Date*
									</label>
									<DateTimePicker
										placeholder="Start Date"
										format="yyyy-MM-dd"
										required
										id="formEventStart"
										className="form-control"
										value={this.state.event.startDate}
										inputProps={{
											readOnly: true, // Disable direct input
										}}
										onChange={(newDate) => {
											const endDate =
												this.state.event.endDate;

											// Compare the selected date with the maximum date
											if (newDate <= maxDate) {
												this.setState({
													event: {
														...this.state.event,
														startDate: newDate,
														endDate:
															endDate < newDate
																? newDate
																: endDate,
														startDateSelected: true, // Flag to indicate start date is selected
													},
												});
											} else {
												// If selected date is beyond the max date, set to today's date
												this.setState({
													event: {
														...this.state.event,
														startDate: new Date(),
														endDate:
															endDate < new Date()
																? new Date()
																: endDate, // Update end date if needed
													},
												});
											}
										}}
										onClear={() => {
											this.setState({
												event: {
													...this.state.event,
													endDate: "",
												},
											});
										}}
										minDate={new Date()} // Set the max value to the current date
										maxDate={maxDate} // Set the maximum value to today + 30 years
									/>
								</div>
							</div>
							<div className="col">
								<div className="form-group">
									<label
										className="form-label"
										htmlFor="formEventEnd"
									>
										End Date*
									</label>
									<DateTimePicker
										placeholder="End Date"
										format="yyyy-MM-dd"
										required
										id="formEventEnd"
										className="form-control"
										value={this.state.event.endDate}
										inputProps={{
											readOnly: true, // Disable direct input
										}}
										onChange={(newDate) => {
											// Check if start date is selected before updating end date
											if (
												this.state.event
													.startDateSelected
											) {
												if (newDate <= maxDate) {
													this.setState({
														event: {
															...this.state.event,
															endDate: newDate,
														},
													});
												} else {
													// If selected date is beyond the max date, set to today's date
													this.setState({
														event: {
															...this.state.event,
															endDate: new Date(),
														},
													});
												}
											} else {
												// Show an alert or message to select start date first
												alert(
													"Please select the start date first."
												);
											}
										}}
										minDate={this.state.event.startDate} // Set the min value to the selected start date
										maxDate={maxDate} // Set the maximum value to today + 30 years
									/>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col">
								<div className="form-group">
									<label
										className="form-label"
										htmlFor="formEventStartTime"
									>
										Start Time*
									</label>
									<input
										placeholder=""
										type="time"
										id="formEventStartTime"
										className="form-control"
										value={this.state.event.startTime}
										onChange={this.handleEventStartTime}
									/>
								</div>
							</div>
							<div className="col pl-0">
								<div className="form-group">
									<label
										className="form-label"
										htmlFor="formEventEndTime"
									>
										End Time*
									</label>
									<input
										placeholder=""
										type="time"
										id="formEventEndTime"
										className="form-control"
										value={this.state.event.endTime}
										onChange={this.handleEventEndTime}
									/>
								</div>
							</div>
						</div>

						<div className="form-group">
							<label
								className="form-label"
								htmlFor="formEventAbout"
							>
								About Event*
							</label>
							<textarea
								rows="3"
								id="formEventAbout"
								className="form-control"
								onChange={this.handleEventDescription}
								value={this.state.event.description}
							></textarea>
						</div>

						<div className="form-group">
							<label
								className="form-label"
								htmlFor="formTeamAddress"
							>
								Location (Select from dropdown)*
							</label>
							<div className="search-location-input">
								<GoogleLocationSearch
									handleGoogleAddress={
										this.handleGoogleAddress
									}
								/>
								{/*<input
                                    placeholder='Type Address'
                                    type='text'
                                    id='formTeamAddress'
                                    className='form-control pac-target-input'
                                    value={this.state.event.location}
                                    onChange={this.handleEventLocation}
                                    autoComplete='off'
                                    required
                                />*/}
							</div>
						</div>

						<div className="form-group">
							<label
								className="form-label"
								htmlFor="formTeamActivityType"
							>
								Activity Type*
							</label>

							<select
								className="form-control"
								id="formTeamActivityType"
								onChange={this.handleChange("interestValue")}
								value={this.state.interestValue}
								required
							>
								<option value="" disabled>
									Choose one...
								</option>
								<option value="Swim">Swimming</option>
								<option value="Run">Running</option>
								<option value="Walk">Walking</option>
								<option value="Bike">Cycling</option>
								<option value="Strength">Weight Lifting</option>
								<option value="Flexibility">Flexibility</option>
								<option value="Recovery">Recovery</option>
								<option value="Duathlon">Duathlon</option>
								<option value="Triathlon">Triathlon</option>
							</select>
						</div>
						<TabToRemove
							title={"Event Photo *"}
							tabTo={"Event Photo Tab to remove"}
							removePhoto={this.removePhoto}
							logo={this.state.event.logo}
							upload={"/uploads/temp/"}
						/>
						<div className="form-group">
							<a
								className="button"
								onClick={() => this.openUploadEventLogo()}
							>
								Upload Photo
							</a>
							<input
								type="file"
								ref={this.uploadEventLogoRef}
								onChange={() => this.uploadEventLogo()}
								style={{ display: "none" }}
							/>
						</div>

						<div className="form-group">
							<LoadButton
								submitting={this.state.loading}
								// disabled={this.state.loading}
								title={"Create Event"}
								btnClass={"button subbtn btn-save"}
								titleClass={"text-white"}
								onClick={this.addEvent}
							/>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	};
};

export default withRouter(connect(mapStateToProps, {})(NewEvent));
