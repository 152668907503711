import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import { getServerUrl } from "../utils/api";

import loading from "../assets/loading.svg";
import profileBlank from "../assets/profile_blank.png";
import { RiQuestionnaireLine } from "react-icons/ri";
import TabsCount from "./common/tabsCount";
import { FaSearch } from "react-icons/fa";

class Message extends Component {
	componentDidMount() {
		this.props.getNotFriendUsers();
	}

	render() {
		const {
			retrieving,
			users,
			notFriendUsers,
			teams,
			groups,
			tab,
			searchUsersQuery,
			searchTeamsQuery,
			searchGroupsQuery,
			onUpdateBoardsQuery,
			onToggleMessages,
			onHandleMessageRequestsBtn,
			onLoadConversation,
			onUpdateUsersQuery,
			onSearchUsers,
			onSearchBoards,
			onChangeMsgTab,
			handleUserKeyDown,
			handleTeamKeyDown,
		} = this.props;
		const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

		return (
			<div className={`msgsbxs ${isiOS ? "ios" : ""}`}>
				<h5>Messages</h5>
				<a href="#" onClick={onToggleMessages}>
					<span className="close">×</span>
				</a>
				<Tabs
					id="messageTabs"
					className="mb-3"
					activeKey={tab}
					onSelect={(key) => onChangeMsgTab(key)}
					defaultActiveKey="people"
					style={{
						flexWrap: "nowrap",
					}}
				>
					<Tab
						eventKey="people"
						title={
							<TabsCount
								title="People"
								count={
									this.props.notificationCount.personalCount
								}
							/>
						}
					>
						<div className="msgsbxsin">
							<div className="msgflexsb">
								<h5>
									{`${retrieving ? "?" : users.length}`}{" "}
									People In The List{" "}
								</h5>
								<div style={{ position: "relative" }}>
									<RiQuestionnaireLine
										className="msgreqicon"
										onClick={onHandleMessageRequestsBtn}
									/>
									{notFriendUsers.length > 0 && (
										<div className="msgreqcount">
											{notFriendUsers.length}
										</div>
									)}
								</div>
							</div>
							<div className="teamsrchbx mb-3">
								<div className="row">
									<div className="col-12">
										<div className="form-group">
											<input
												placeholder="Search People"
												type="text"
												className="form-control"
												onChange={onUpdateUsersQuery}
												onKeyDown={handleUserKeyDown}
												value={searchUsersQuery}
											/>
											<button
												onClick={() =>
													onSearchUsers(
														searchUsersQuery
													)
												}
											>
												<FaSearch
													style={{
														color: "#6c757d",
														marginBlock: "5px",
													}}
												/>
											</button>
										</div>
									</div>
								</div>
							</div>

							{retrieving ? (
								<img src={loading} alt="" />
							) : (
								<div className="msgitemsbx">
									{users.map((user) => (
										<div
											className="usrtop"
											key={`messages-${user._id}`}
										>
											<div className="row">
												<div className="col-auto f12">
													<div className="userthumb">
														<a className="userbx">
															<img
																src={
																	user.profilePicture
																		? `${
																				getServerUrl()
																					.apiURL
																		  }/uploads/user/${
																				user.profilePicture
																		  }`
																		: profileBlank
																}
															/>
														</a>
													</div>
												</div>
												<div className="col-7 pl-0 msgusrnm">
													<a
														href="#"
														onClick={(e) =>
															onLoadConversation(
																e,
																user._id,
																{
																	name:
																		`${user.firstName} ${user.lastName}` ||
																		"",
																	profilePicture:
																		user.profilePicture ||
																		"",
																	_id: user._id,
																	userId:
																		user.userId ||
																		"",
																}
															)
														}
													>
														{`${user.firstName} ${user.lastName}`}
													</a>
													{!this.props.currUser.followers.includes(
														user.userId
													) &&
													!this.props.currUser.following.includes(
														user.userId
													) ? (
														<span className="small lgtgrytxt">
															Not friend
														</span>
													) : user.role ===
													  "Coach" ? (
														<span className="small grntxt">
															Coach
														</span>
													) : (
														""
													)}
												</div>
												{user.unseenCount > 0 && (
													<div className="col-1 nopad incmnewmsg">
														<p>
															{user.unseenCount}
														</p>
													</div>
												)}
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					</Tab>
					<Tab
						eventKey="teams"
						title={
							<TabsCount
								title="Teams"
								count={this.props.notificationCount.teamsCount}
							/>
						}
					>
						<div className="msgsbxsin">
							<h5>
								{`${retrieving ? "?" : teams.length}`} Teams In
								The List{" "}
							</h5>
							<div className="teamsrchbx mb-3">
								<div className="row">
									<div className="col-12">
										<div className="form-group">
											<input
												placeholder="Search Teams"
												type="text"
												className="form-control"
												onChange={onUpdateBoardsQuery}
												onKeyDown={handleTeamKeyDown}
												value={searchTeamsQuery}
											/>
											<button
												onClick={() =>
													onSearchBoards(
														searchTeamsQuery
													)
												}
											>
												<FaSearch
													style={{
														color: "#6c757d",
														marginBlock: "5px",
													}}
												/>
											</button>
										</div>
									</div>
								</div>
							</div>

							{retrieving ? (
								<img src={loading} alt="" />
							) : (
								<div className="msgitemsbx">
									{teams.map((team) => (
										<div
											className="usrtop"
											key={`teams-${team._id}`}
										>
											<div className="row">
												<div className="col-auto f12">
													<div className="userthumb">
														<a className="userbx">
															<img
																src={
																	team.logo
																		? getServerUrl()
																				.apiURL +
																		  "/uploads/team/" +
																		  team.logo
																		: "/uploads/images/team_default.png"
																}
															/>
															{/*<span className='brdimg1'>
                                                        <img src='/uploads/images/user1.jpg' />
                                                    </span>
                                                    <span className='brdimg2'>
                                                        <img src='/uploads/images/user-thumb-big.jpg' />
                                                    </span>*/}
														</a>
													</div>
												</div>
												<div className="col-7 nopad msgusrnm">
													<a
														href="#"
														onClick={(e) =>
															onLoadConversation(
																e,
																team._id,
																{
																	name:
																		team.title ||
																		"",
																	profilePicture:
																		team.logo ||
																		"",
																	_id: team._id,
																	type: "team",
																}
															)
														}
													>
														{team.title}
													</a>
													<span className="small">{`${
														team.membersCount
													} member${
														team.membersCount > 1
															? "s"
															: ""
													}`}</span>
												</div>
												{team.unseenCount > 0 && (
													<div className="col-1 nopad incmnewmsg">
														<p>
															{team.unseenCount}
														</p>
													</div>
												)}
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					</Tab>
					<Tab
						eventKey="groups"
						title={
							<TabsCount
								title="Groups"
								count={this.props.notificationCount.groupCount}
							/>
						}
					>
						<div className="msgsbxsin">
							<h5>
								{`${retrieving ? "?" : groups.length}`} Groups
								In The List{" "}
							</h5>
							<div className="teamsrchbx mb-3">
								<div className="row">
									<div className="col-12">
										<div className="form-group">
											<input
												placeholder="Search Groups"
												type="text"
												className="form-control"
												onChange={onUpdateBoardsQuery}
												onKeyDown={handleTeamKeyDown}
												value={searchGroupsQuery}
											/>
											<button
												onClick={() =>
													onSearchBoards(
														searchGroupsQuery
													)
												}
											>
												<FaSearch
													style={{
														color: "#6c757d",
														marginBlock: "5px",
													}}
												/>
											</button>
										</div>
									</div>
								</div>
							</div>

							{retrieving ? (
								<img src={loading} alt="" />
							) : (
								<div className="msgitemsbx">
									{groups.map((group) => (
										<div
											className="usrtop"
											key={`groups-${group._id}`}
										>
											<div className="row">
												<div className="col-auto f12">
													<div className="userthumb">
														<a className="userbx">
															<img
																src={
																	group.logo
																		? getServerUrl()
																				.apiURL +
																		  "/uploads/group/" +
																		  group.logo
																		: "/uploads/images/team_default.png"
																}
															/>
															{/*<span className='brdimg1'>
                                                        <img src='/uploads/images/user1.jpg' />
                                                    </span>
                                                    <span className='brdimg2'>
                                                        <img src='/uploads/images/user-thumb-big.jpg' />
                                                    </span>*/}
														</a>
													</div>
												</div>
												<div className="col-7 nopad msgusrnm">
													<a
														href="#"
														onClick={(e) =>
															onLoadConversation(
																e,
																group._id,
																{
																	name:
																		group.title ||
																		"",
																	profilePicture:
																		group.logo ||
																		"",
																	_id: group._id,
																	type: "group",
																}
															)
														}
													>
														{group.title}
													</a>
													<span className="small">{`${
														group.membersCount
													} member${
														group.membersCount > 1
															? "s"
															: ""
													}`}</span>
												</div>
												{group.unseenCount > 0 && (
													<div className="col-1 nopad incmnewmsg">
														<p>
															{group.unseenCount}
														</p>
													</div>
												)}
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					</Tab>
				</Tabs>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		currUser: state.auth.user,
		notificationCount: state.auth.notificationCount,
	};
};

export default connect(mapStateToProps, {})(Message);
