import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
	getServerUrl,
	groupAddMember,
	memberRemoveGroup,
	updateFollowAct,
	updateUnfollowAct,
	getPostByGroup,
	teamAddMember,
	memberRemoveTeam,
	editEventStatus,
	getAllFollowersData,
	getAllFollowingUserData,
	getPostByTeam,
} from "../utils/api";
import {
	assignTimeAgo,
	assignAnswerPermission,
	formatDateTime,
} from "../utils/helper";
import {
	updateUserGroups,
	updateUserTeams,
	updateShowSearchFilter,
	updateUserFollow,
} from "../actions";

import profileBlank from "../assets/profile_blank.png";
import loaderSVG from "../assets/loading2.svg";
import ReactStars from "react-rating-stars-component";
import Avatar from "../components/common/avatar";

let dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
let monthNames = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];
let interestIcons = {
	Swim: "swimming.svg",
	Run: "run.svg",
	Strength: "lifting.svg",
	Bike: "cycling.svg",
	Flexibility: "flexibility.svg",
	Walk: "walk.svg",
	Recovery: "recovery.svg",
	Duathlon: "dualthion.svg",
	Triathlon: "trithalion.svg",
};

class SearchResult extends Component {
	constructor(props) {
		super(props);

		this.state = {
			followers: [],
			following: [],
			currUserFollowingList: [],
			loading: true,
			groupsPostsCount: [],
			updating: false,
			loader: {},
		};
	}

	async componentDidMount() {
		if (
			this.props.searchTarget === "users" &&
			this.props.filteredUsers.length > 0
		) {
			this.loadData();
		}

		if (
			this.props.searchTarget === "groups" &&
			this.props.filteredGroups.length > 0
		) {
			let groupsPostsCount = [];
			for (const group of this.props.filteredGroups) {
				const resp = await getPostByGroup(group._id);
				groupsPostsCount.push(resp.posts.length);
			}
			this.setState({ groupsPostsCount, loading: false });
		}
		if (
			this.props.searchTarget === "teams" &&
			this.props.filteredTeams.length > 0
		) {
			let teamsPostsCount = [];
			for (const team of this.props.filteredTeams) {
				const resp = await getPostByTeam(team._id);
				teamsPostsCount.push(resp.posts.length);
			}
			this.setState({ teamsPostsCount, loading: false });
		}
	}

	loadData = async () => {
		this.setState({
			loading: true,
		});
		try {
			const followersData = await getAllFollowersData(
				this.props.user.userId
			);
			const followingData = await getAllFollowingUserData(
				this.props.user.userId
			);
			this.setState({
				currUserFollowingList: this.props.user.following,
				followers: followersData,
				following: followingData,
				loading: false,
			});
		} catch (error) {
			console.error("Error loading data:", error);
			this.setState({ loading: false });
		}
	};

	async componentDidUpdate(prevProps, prevState) {
		if (
			(prevProps.searchTarget !== "users" &&
				this.props.searchTarget === "users") ||
			(this.props.searchTarget === "users" &&
				prevProps.query !== this.props.query)
		) {
			this.loadData();
		}

		if (
			(prevProps.searchTarget !== "groups" &&
				this.props.searchTarget === "groups") ||
			(this.props.searchTarget === "groups" &&
				prevProps.query !== this.props.query)
		) {
			let groupsPostsCount = [];
			if (this.props.filteredGroups.length > 0) {
				for (const group of this.props.filteredGroups) {
					const resp = await getPostByGroup(group._id);
					groupsPostsCount.push(resp.posts.length);
				}
			}
			this.setState({ groupsPostsCount });
		}
	}

	// check if the current user is part of the group
	isGroupMember = (groupId) => {
		return this.props.user.groups.includes(groupId);
	};

	joinGroup = (e, groupId, groupType) => {
		e.preventDefault();
		if (this.state.loader[groupId]) return;

		this.setState((prevState) => ({
			loader: { ...prevState.loader, [groupId]: true },
		}));
		groupAddMember(groupId, this.props.user._id, groupType)
			.then((resp) => {
				alert(resp.msg);
				if (resp.success === true) {
					let groupAccessBtn = document.getElementById(
						`group-btn-${groupId}`
					);
					groupAccessBtn.innerHTML = "Pending";
					groupAccessBtn.className = "smplbtn m-0 dislinks bblur";
					this.props.updateUserGroups(
						resp.userGroups,
						resp.userGroupRequests
					);
				}
				this.setState((prevState) => ({
					loader: { ...prevState.loader, [groupId]: false },
				}));
			})
			.catch((error) => {
				console.error("Error joining group:", error);
				this.setState((prevState) => ({
					loader: { ...prevState.loader, [groupId]: false },
				}));
			});
	};

	leaveGroup = (e, groupId, groupType) => {
		e.preventDefault();
		memberRemoveGroup(groupId, this.props.user._id).then((resp) => {
			alert(resp.msg);
			if (resp.success == true) {
				let groupAccessBtn = document.getElementById(
					`group-btn-${groupId}`
				);
				groupAccessBtn.innerHTML = "Join Group";
				groupAccessBtn.className = "smplbtn m-0";

				groupAccessBtn.onclick = (e) => {
					this.joinGroup(e, groupId, groupType);
				};
				this.props.updateUserGroups(
					resp.userGroups,
					resp.userGroupRequests
				);
			}
		});
	};

	followUser = async (followedUserId, index) => {
		try {
			const res = await updateFollowAct(
				this.props.user.userId,
				followedUserId
			);
			if (res.success === true) {
				this.setState((prevState) => ({
					following: res.followedUser,
					updating: false,
					currUserFollowingList: prevState.currUserFollowingList
						.filter((u) => u !== res.followedUser.userId)
						.concat(res.followedUser.userId),
				}));

				// Dispatch the action to update the Redux store with the new following list
				this.props.updateUserFollow(res.user.following);
			}
		} catch (error) {
			console.error("Error following user:", error);
		}
	};

	unfollowUser = async (unfollowedUserId, index) => {
		try {
			const res = await updateUnfollowAct(
				this.props.user.userId,
				unfollowedUserId
			);
			if (res.success === true) {
				this.setState((prevState) => ({
					currUserFollowingList:
						prevState.currUserFollowingList.filter(
							(uId) => uId !== unfollowedUserId
						),
					updating: false,
				}));

				this.props.updateUserFollow(res.user.following);
			}
		} catch (error) {
			console.error("Error unfollowing user:", error);
		}

		this.setState({ updating: true });
	};
	isTeamMember = (teamId) => {
		return this.props.user.teams.includes(teamId);
	};

	joinTeam = (e, teamId, teamType) => {
		e.preventDefault();
		if (this.state.loader[teamId]) return; // Prevent multiple requests while loading

		// Set loading state for the specific group to true
		this.setState((prevState) => ({
			loader: { ...prevState.loader, [teamId]: true },
		}));

		teamAddMember(teamId, this.props.user._id, teamType)
			.then((resp) => {
				alert(resp.msg);
				if (resp.success === true) {
					let teamAccessBtn = document.getElementById(
						`team-btn-${teamId}`
					);
					teamAccessBtn.innerHTML = "Pending";
					teamAccessBtn.className = "smplbtn m-0 dislinks bblur";
					this.props.updateUserTeams(
						resp.userTeams,
						resp.userTeamRequests
					);
					this.setState((prevState) => ({
						loader: { ...prevState.loader, [teamId]: false },
					}));
				}
			})
			.catch((error) => {
				console.error("Error joining team:", error);
				this.setState((prevState) => ({
					loader: { ...prevState.loader, [teamId]: false },
				}));
			});
	};

	leaveTeam = (e, teamId, teamType) => {
		e.preventDefault();
		memberRemoveTeam(teamId, this.props.user._id).then((resp) => {
			alert(resp.msg);
			if (resp.success == true) {
				let teamAccessBtn = document.getElementById(
					`team-btn-${teamId}`
				);
				teamAccessBtn.innerHTML = "Join Team";
				teamAccessBtn.className = "smplbtn m-0";

				teamAccessBtn.onclick = (e) => {
					this.joinTeam(e, teamId, teamType);
				};
				this.props.updateUserTeams(
					resp.userTeams,
					resp.userTeamRequests
				);
			}
		});
	};

	eventUpdate = (ev, action, event) => {
		ev.preventDefault();
		editEventStatus(event._id, this.props.user.userId, action).then(
			(res) => {
				if (res.success) {
					this.props.updateEventStatus(res.event);
				} else {
					alert(res.msg);
				}
			}
		);
	};

	displayEvent = (event, index) => {
		return (
			<>
				<div className="usrtop">
					<div className="row">
						<div className="col-2">
							<div className="userthumb">
								<a className="userbx">
									<Avatar
										url={`/uploads/${event.moduleType}/`}
										state={event.moduleLogo}
										alt={"event logo"}
										// style={{ width: "100%" }}
									/>
								</a>
							</div>
						</div>
						<div className="col-8 pl-2 nopad pt-1">
							<a>
								{event.moduleType === "user" &&
								event.owner === this.props.user.userId
									? this.props.user.firstName +
									  " " +
									  this.props.user.lastName
									: event.moduleTitle}
							</a>
							<span className="small pstim">
								{assignTimeAgo(event.time)}
							</span>
						</div>
						<div className="col-2">
							<span className="acttyp">
								<img
									src={
										"/uploads/images/" + event.interest.icon
									}
								/>
							</span>
						</div>
					</div>
				</div>
				<h4 className="mt-3 mb-3">
					<Link to={"/event/" + event.eventId}>{event.title}</Link>
				</h4>
				<p>
					<span className="f12">
						<img
							src="/uploads/images/calendar.png"
							style={{
								marginRight: "10px",
								opacity: ".5",
							}}
						/>
						{`Start: ${formatDateTime(event.start)}`}
					</span>
				</p>
				<p>
					<span className="f12">
						<img
							src="/uploads/images/calendar.png"
							style={{
								marginRight: "10px",
								opacity: ".5",
							}}
						/>
						{`End: ${formatDateTime(event.end)}`}
					</span>
				</p>
				<p>
					<span className="f12">
						<img
							src="/uploads/images/pin.png"
							style={{
								marginRight: "10px",
								opacity: ".5",
							}}
						/>
						{event.location}
					</span>
				</p>
				<p className="">
					<span className="f12">
						<img
							src="/uploads/images/people.png"
							style={{
								marginRight: "10px",
								opacity: ".5",
							}}
						/>
						{event.attending.length > 0
							? `${
									event.attending.length === 1
										? "1 Person is Attending"
										: event.attending.length +
										  " People are Attending"
							  }`
							: "No one is attending"}
					</span>
				</p>
				<p className="">
					<span className="f12">
						<img
							src="/uploads/images/people.png"
							style={{
								marginRight: "10px",
								opacity: ".5",
							}}
						/>
						{event.interested.length > 0
							? `${
									event.interested.length === 1
										? "1 Person is Interested"
										: event.interested.length +
										  " People are Interested"
							  }`
							: "No one is interested"}{" "}
					</span>
				</p>

				<div className="row">
					<div className="col-8 rtngbx">
						<span>Rating:</span>

						<ReactStars
							count={5}
							size={24}
							activeColor="#ffd700"
							value={event.rating !== null ? event.rating : 0}
							edit={false}
						/>
					</div>
				</div>
				{event.owner === this.props.user.userId
					? null
					: event.moduleType !== "user" && (
							<p className="mt-2">
								<a
									href=""
									className={
										"btn grnbtn  mt-2 mr-2 f12" +
										(event.attending.includes(
											this.props.user.userId
										)
											? " dislinks blur"
											: "")
									}
									onClick={(ev) =>
										this.eventUpdate(ev, "attending", event)
									}
								>
									I am Attending
								</a>
								<a
									href=""
									className={
										"btn grnbtn  mt-2 f12" +
										(event.interested.includes(
											this.props.user.userId
										)
											? " dislinks blur"
											: "")
									}
									onClick={(ev) =>
										this.eventUpdate(
											ev,
											"interested",
											event
										)
									}
								>
									I am Interested
								</a>
							</p>
					  )}

				{/*<a href='' className='btn grnbtn' onClick={() => }>
                    I am Interested
                </a>*/}
			</>
		);
	};

	showActivityFilter = () => {
		this.props.updateShowSearchFilter(true);
	};

	render() {
		const isMobile = window.matchMedia("(max-width: 500px)").matches;

		return (
			<div className="srchbxout">
				{isMobile && (
					<div className="wrtpost srch">
						<div className="userthumb">
							<span>
								<img src="/uploads/images/search.png" />
							</span>

							<input
								type="text"
								placeholder="Search something here..."
								onChange={(e) => this.props.onQueryChange(e)}
								onKeyDown={(e) => {
									this.props.onEnterPressed(e);
								}}
								value={this.props.query}
							/>
						</div>
					</div>
				)}

				<div className="typsldr">
					<a
						className={
							this.props.searchTarget === "posts" ? "sel" : ""
						}
						href="#"
						onClick={() => {
							this.props.onHandleSearchTarget("posts");
							this.props.history.push({
								pathname: "/search/posts",
								search: `${this.props.location.search}`,
							});
						}}
					>
						Posts
					</a>
					<a
						className={
							this.props.searchTarget === "events" ? "sel" : ""
						}
						href="#"
						onClick={() => {
							this.props.onHandleSearchTarget("events");
							this.props.history.push({
								pathname: "/search/events",
								search: `${this.props.location.search}`,
							});
						}}
					>
						Events
					</a>
					<a
						className={
							this.props.searchTarget === "teams" ? "sel" : ""
						}
						href="#"
						onClick={() => {
							this.props.onHandleSearchTarget("teams");
							this.props.history.push({
								pathname: "/search/teams",
								search: `${this.props.location.search}`,
							});
						}}
					>
						Teams
					</a>
					<a
						className={
							this.props.searchTarget === "groups" ? "sel" : ""
						}
						href="#"
						onClick={() => {
							this.props.onHandleSearchTarget("groups");
							this.props.history.push({
								pathname: "/search/groups",
								search: `${this.props.location.search}`,
							});
						}}
					>
						Groups
					</a>
					<a
						className={
							this.props.searchTarget === "users" ? "sel" : ""
						}
						href="#"
						onClick={() => {
							this.props.onHandleSearchTarget("users");
							this.props.history.push({
								pathname: "/search/users",
								search: `${this.props.location.search}`,
							});
						}}
					>
						People
					</a>
					<a
						className={
							this.props.searchTarget === "questions" ? "sel" : ""
						}
						href="#"
						onClick={() => {
							this.props.onHandleSearchTarget("questions");
							this.props.history.push({
								pathname: "/search/questions",
								search: `${this.props.location.search}`,
							});
						}}
					>
						Questions
					</a>
				</div>
				{this.props.query && (
					<div className="fltrot">
						<div
							className="fltbxin"
							onClick={() => this.showActivityFilter()}
						>
							<div>Filters Your Results</div>
							<div className="fltrnd">
								<img src="/uploads/images/filter-icon.svg" />
							</div>
						</div>
					</div>
				)}
				<div className="srcposts">
					{this.props.searchTarget === "posts" ? (
						this.props.filteredPosts.length > 0 ||
						this.props.postsLoading ? (
							this.props.postsLoading ? (
								<div className="loader-content">
									<img
										width={35}
										height={35}
										src={loaderSVG}
										alt=""
									/>
								</div>
							) : (
								this.props.filteredPosts.map(
									(postObj, index) => (
										<div
											className="srchpstbx"
											key={"search-post-" + index}
										>
											<div className="usrtop">
												<div className="row">
													<div className="col-2">
														<div className="userthumb">
															<a className="userbx">
																<Avatar
																	url={
																		"/uploads/user/"
																	}
																	state={
																		postObj
																			.user
																			.profilePicture
																	}
																	alt={"logo"}
																/>
															</a>
														</div>
													</div>
													<div className="col-8 pl-2 nopad pt-1">
														<a
															onClick={() =>
																this.props.goToProfile(
																	postObj.user
																		.userId
																)
															}
														>
															{postObj.user.userId
																? postObj.user
																		.firstName +
																  " " +
																  postObj.user
																		.lastName
																: ""}
														</a>
														<span className="small pstim">
															{assignTimeAgo(
																postObj.post
																	.time
															)}
														</span>
													</div>
													{postObj.post.interest
														.icon && (
														<div className="col-2">
															<span className="acttyp">
																<img
																	src={`/uploads/images/${postObj.post.interest.icon}`}
																/>
															</span>
														</div>
													)}
												</div>
											</div>
											<p>
												<Link
													to={
														"/post/" +
														postObj.post.postId
													}
												>
													<>
														{
															postObj.post
																.description
														}
													</>
												</Link>
												{postObj.post.imgFileName &&
													postObj.post.imgFileName
														.length > 0 && (
														<Avatar
															url={
																"/uploads/posts/"
															}
															state={
																postObj.post
																	.imgFileName[0]
															}
															alt={"post"}
															style={{
																width: "75px",
															}}
														/>
													)}
											</p>

											{/*post.imgFileName &&
                                        post.imgFileName.map((img) => (
                                            <div className='pstmd' key={img}>
                                                <img
                                                    src={`${getServerUrl().apiURL}/uploads/posts/${img}`}
                                                    onClick={() => this.props.openPictureViewer(`${getServerUrl().apiURL}/uploads/posts/${img}`)}
                                                    style={{cursor: 'pointer'}}
                                                    alt=''
                                                />
                                            </div>
                                        ))*/}
											{/*post.videoFileName &&
                                        post.videoFileName.map((video) => (
                                            <div className='pstmd' key={video}>
                                                <video width='100%' height='240' controls>
                                                    <source
                                                        src={`${getServerUrl().apiURL}/uploads/posts/${video}`}
                                                        type='video/mp4'
                                                    ></source>
                                                </video>
                                            </div>
                                        ))*/}

											<div className="rctbx">
												{postObj.post.kudos.bumSlaps
													.length > 0 && (
													<span>
														<img src="/uploads/images/love-it.png" />
														{
															postObj.post.kudos
																.bumSlaps.length
														}
													</span>
												)}
												{postObj.post.kudos.backSlaps
													.length > 0 && (
													<span>
														<img src="/uploads/images/first-pump.png" />
														{
															postObj.post.kudos
																.backSlaps
																.length
														}
													</span>
												)}
												{postObj.post.kudos.likes
													.length > 0 && (
													<span>
														<img src="/uploads/images/thumbs-up.png" />
														{
															postObj.post.kudos
																.likes.length
														}
													</span>
												)}
												{postObj.post.kudos.gotThis
													.length > 0 && (
													<span>
														<img src="/uploads/images/you-got-this.png" />
														{
															postObj.post.kudos
																.gotThis.length
														}
													</span>
												)}
												{postObj.post.kudos.beStrong
													.length > 0 && (
													<span>
														<img src="/uploads/images/be-strong.png" />
														{
															postObj.post.kudos
																.beStrong.length
														}
													</span>
												)}
												{postObj.post.kudos.awesome
													.length > 0 && (
													<span>
														<img src="/uploads/images/awesome.png" />
														{
															postObj.post.kudos
																.awesome.length
														}
													</span>
												)}
												{postObj.post.kudos.congrats
													.length > 0 && (
													<span>
														<img src="/uploads/images/congratulations.png" />
														{
															postObj.post.kudos
																.congrats.length
														}
													</span>
												)}
												{/* <span>
                                            <img src='/uploads/images/symbol4.png' />
                                            23
                                        </span> */}
												<span>
													<img src="/uploads/images/symbol5.png" />
													{
														postObj.post.comments
															.length
													}
												</span>
											</div>

											<div className="lkbxbtm f12">
												{/*<div className='row'>
                                            <div className='col'>
                                                <a
                                                    onClick={() => {
                                                        this.props.history.push(`/post/${post.postId}${post.section.type !== 'users' ? '/view' : ''}`);
                                                    }}
                                                >
                                                    <img src='/uploads/images/comment.png' />
                                                    {post.section.type !== 'users' ? 'View Comment' : 'Comment'}
                                                </a>
                                            </div>
                                        </div>*/}
											</div>
										</div>
									)
								)
							)
						) : (
							<p className="feed-container-empty">
								No Post Found
							</p>
						)
					) : (
						""
					)}
				</div>
				<div className="srcteams ">
					{this.props.searchTarget === "teams" ? (
						this.props.filteredTeams.length > 0 ||
						this.props.teamsLoading ? (
							this.props.teamsLoading ? (
								<div className="loader-content">
									<img
										width={35}
										height={35}
										src={loaderSVG}
										alt=""
									/>
								</div>
							) : (
								this.props.filteredTeams.map((team, index) => (
									<div
										className="srchpstbx"
										key={"team-search-" + index}
									>
										<div className="usrtop">
											<div className="row">
												<div className="col-2">
													<div className="userthumb">
														<a className="userbx">
															<Avatar
																url={
																	"/uploads/team/"
																}
																state={
																	team.logo
																}
																alt={"logo"}
															/>
														</a>
													</div>
												</div>
												<div className="col-8 pl-2 nopad pt-1">
													<Link
														to={
															"/team/" + team.slug
														}
													>
														{team.title}
													</Link>
													<span className="small pstim">{`${
														team.membersCount
													} Team ${
														team.membersCount < 1
															? "member"
															: "members"
													}`}</span>
													{team.address && (
														<span className="small pstim">
															<img
																src="/uploads/images/pin.png"
																style={{
																	width: "9px",
																}}
															/>{" "}
															{team.address}
														</span>
													)}

													{team.description && (
														<p className="f12 mt-1 mb-1 d-flex flex-column">
															<strong className="f12 mt-1 mb-1">
																Description:
															</strong>
															<span>
																{
																	team.description
																}
															</span>
														</p>
													)}
												</div>
												{team.activityType && (
													<div className="col-2">
														<span className="acttyp">
															<img
																src={`/uploads/images/${
																	interestIcons[
																		team
																			.activityType
																	]
																}`}
																style={{
																	width: "30px",
																}}
															/>
														</span>
													</div>
												)}
											</div>
										</div>
										{this.props.user._id !==
											team.creatorId && (
											<p className="mt-2 mb-0">
												<span>
													<a
														id={
															"team-btn-" +
															team._id
														}
														className={`smplbtn m-0 mr-2 ${
															this.props.user.teamRequests.includes(
																team._id
															)
																? "dislinks bblur"
																: this.isTeamMember(
																		team._id
																  )
																? ""
																: ""
														}`}
														href=""
														onClick={(e) => {
															if (
																this.isTeamMember(
																	team._id
																)
															) {
																this.leaveTeam(
																	e,
																	team._id,
																	team.type
																);
															} else {
																this.joinTeam(
																	e,
																	team._id,
																	team.type
																);
															}
														}}
													>
														{this.state.loader[
															team._id
														]
															? "sending"
															: this.props.user.teamRequests.includes(
																	team._id
															  )
															? "Pending"
															: !this.isTeamMember(
																	team._id
															  )
															? "Join Team"
															: "Leave Team"}
													</a>
													<a
														className="smplbtn m-0"
														href={
															"/team/" +
															team.slug +
															"/memberships"
														}
													>
														Get Membership
													</a>
												</span>
											</p>
										)}
									</div>
								))
							)
						) : (
							<p className="feed-container-empty">
								No Team Found
							</p>
						)
					) : (
						""
					)}
				</div>
				<div className="srchpeople">
					{this.props.searchTarget === "users" &&
						(this.props.filteredUsers.length > 0 ||
						this.props.usersLoading ? (
							this.props.usersLoading ? (
								<div className="loader-content">
									<img
										width={35}
										height={35}
										src={loaderSVG}
										alt=""
									/>
								</div>
							) : (
								this.props.filteredUsers.map((user, index) => (
									<div
										className="pplsrch"
										key={"search-user-" + index}
									>
										<div className="usrtop">
											<div className="row">
												<div className="col-2">
													<div className="userthumb">
														<Link
															to={
																"/profile/" +
																user.userId
															}
															className="userbx"
														>
															<Avatar
																url={
																	"/uploads/user/"
																}
																state={
																	user.profilePicture
																}
																alt={"logo"}
															/>
														</Link>
													</div>
												</div>
												<div className="col-6">
													<Link
														to={
															"/profile/" +
															user.userId
														}
													>
														{`${user.firstName} ${user.lastName}`}
													</Link>{" "}
													{user.role === "Coach" && (
														<img
															src="/uploads/images/cap.svg"
															width="20"
															style={{
																borderRadius:
																	"50px",
																background:
																	"#114e7e",
																padding: "3px",
																marginRight:
																	"10px",
																marginLeft:
																	"5px",
																height: "18px",
															}}
														/>
													)}
													{user.location && (
														<span className="small pstim">
															{user.location}
														</span>
													)}
													{user.userId !==
														this.props.user
															.userId &&
														(this.state.currUserFollowingList.includes(
															user.userId
														) ? (
															<div>
																<a
																	className={`smplbtn m-0 `}
																	href="#"
																	onClick={() =>
																		this.unfollowUser(
																			user.userId,
																			index
																		)
																	}
																>
																	Unfollow
																</a>
															</div>
														) : (
															<div>
																<a
																	className={`smplbtn m-0 `}
																	href="#"
																	onClick={() =>
																		this.followUser(
																			user.userId,
																			index
																		)
																	}
																>
																	Follow Me
																</a>
															</div>
														))}
												</div>
												<div className="col-4 text-right">
													<div
														className="acttyp"
														style={{
															height: "100%",
															width: "100%",
															overflow: "visible",
															marginTop: "10px",
														}}
													>
														{user.interest.length >
															0 &&
															user.interest.map(
																(
																	interest,
																	index
																) => (
																	<img
																		src={`/uploads/images/${interestIcons[interest]}`}
																		style={{
																			width: "20px",
																			marginLeft:
																				"2px",
																		}}
																		key={
																			index
																		}
																	/>
																)
															)}
													</div>
												</div>
											</div>
										</div>
									</div>
								))
							)
						) : (
							<p className="feed-container-empty">
								No User Found
							</p>
						))}
				</div>

				<div className="srchquestions">
					{this.props.searchTarget === "questions" ? (
						this.props.filteredQuestions.length > 0 ||
						this.props.questionsLoading ? (
							this.props.questionsLoading ? (
								<div className="loader-content">
									<img
										width={35}
										height={35}
										src={loaderSVG}
										alt=""
									/>
								</div>
							) : (
								this.props.filteredQuestions.map(
									(questionObj, index) => (
										<div
											className="srchpstbx"
											key={"questions-search-" + index}
										>
											<div className="usrtop">
												<div className="row">
													<div className="col-2">
														<div className="userthumb">
															<a className="userbx">
																<Avatar
																	url={
																		"/uploads/user/"
																	}
																	state={
																		questionObj
																			.user
																			.profilePicture
																	}
																	alt={
																		"profile "
																	}
																/>
															</a>
														</div>
													</div>
													<div className="col-8 pl-2 nopad pt-1">
														<a
															onClick={() =>
																this.props.goToProfile(
																	questionObj
																		.user
																		.userId
																)
															}
														>
															{questionObj.user
																.userId
																? questionObj
																		.user
																		.firstName +
																  " " +
																  questionObj
																		.user
																		.lastName
																: ""}
														</a>
														<span className="small pstim">
															{assignTimeAgo(
																questionObj.post
																	.time
															)}
														</span>
													</div>
													{questionObj.post.interest
														.icon && (
														<div className="col-2">
															<span className="acttyp">
																<img
																	src={`/uploads/images/${questionObj.post.interest.icon}`}
																/>
															</span>
														</div>
													)}
												</div>
											</div>
											<p>
												{questionObj.post.description}
											</p>
											<a
												onClick={() =>
													this.props.history.push(
														`/answers/${questionObj.post.postId}`
													)
												}
												className="btn grnbtn"
											>
												{assignAnswerPermission(
													questionObj.post.comments,
													this.props.user.userId,
													questionObj.post.userId
												)
													? "Answer This Question"
													: "View All"}
											</a>
										</div>
									)
								)
							)
						) : (
							<p className="feed-container-empty">
								No Question Found
							</p>
						)
					) : (
						""
					)}
				</div>
				<div className="srchevents">
					{this.props.searchTarget === "events" ? (
						this.props.filteredEvents.length > 0 ||
						this.props.eventsLoading ? (
							this.props.eventsLoading ? (
								<div className="loader-content">
									<img
										width={35}
										height={35}
										src={loaderSVG}
										alt=""
									/>
								</div>
							) : (
								this.props.filteredEvents.map(
									(event, index) => (
										<div
											className="srchpstbx"
											key={"event-search-" + index}
										>
											{this.displayEvent(event, index)}
										</div>
									)
								)
							)
						) : (
							<p className="feed-container-empty">
								No Event Found
							</p>
						)
					) : (
						""
					)}
				</div>
				<div className="srchgroups">
					{this.props.searchTarget === "groups" ? (
						this.props.filteredGroups.length > 0 ||
						this.props.groupsLoading ? (
							this.props.groupsLoading ? (
								<div className="loader-content">
									<img
										width={35}
										height={35}
										src={loaderSVG}
										alt=""
									/>
								</div>
							) : (
								this.props.filteredGroups.map(
									(group, index) => (
										<div
											className="srchpstbx"
											key={"group-search-" + index}
										>
											<div className="usrtop">
												<div className="row">
													<div className="col-2">
														<div className="userthumb">
															<a className="userbx">
																<Avatar
																	url={
																		"/uploads/group/"
																	}
																	state={
																		group.logo
																	}
																	alt={"logo"}
																/>
															</a>
														</div>
													</div>
													<div className="col-8 pl-2 nopad pt-1">
														<Link
															to={
																"/group/" +
																group.slug
															}
														>
															{group.title}
														</Link>
														<span className="small pstim">
															{` ${
																group.membersCount
															} Group ${
																group.membersCount ===
																1
																	? "member"
																	: "members"
															}`}
														</span>
														{group.address && (
															<span className="small pstim">
																<img
																	src="/uploads/images/pin.png"
																	style={{
																		width: "9px",
																	}}
																/>
																{group.address}
															</span>
														)}

														{group.description && (
															<p className="f12 mt-1 mb-1 d-flex flex-column">
																<strong className="f12 mt-1 mb-1">
																	Description:
																</strong>
																<span>
																	{
																		group.description
																	}
																</span>
															</p>
														)}
													</div>
													{group.interest && (
														<div className="col-2">
															<span className="acttyp">
																<img
																	src={`/uploads/images/${
																		interestIcons[
																			group
																				.interest
																		]
																	}`}
																	style={{
																		width: "30px",
																	}}
																/>
															</span>
														</div>
													)}
												</div>
											</div>
											{this.props.user._id !==
												group.creatorId && (
												<p className="mt-2 mb-0">
													{" "}
													<a
														id={
															"group-btn-" +
															group._id
														}
														className={`smplbtn m-0 ${
															this.props.user.groupRequests.includes(
																group._id
															)
																? "dislinks bblur"
																: this.isGroupMember(
																		group._id
																  )
																? ""
																: ""
														}`}
														href=""
														onClick={(e) => {
															if (
																this.isGroupMember(
																	group._id
																)
															) {
																this.leaveGroup(
																	e,
																	group._id,
																	group.type
																);
															} else {
																this.joinGroup(
																	e,
																	group._id,
																	group.type
																);
															}
														}}
													>
														{this.state.loader[
															group._id
														]
															? "sending"
															: this.props.user.groupRequests.includes(
																	group._id
															  )
															? "Pending"
															: !this.isGroupMember(
																	group._id
															  )
															? "Join Group"
															: "Leave Group"}
													</a>
												</p>
											)}
										</div>
									)
								)
							)
						) : (
							<p className="feed-container-empty">
								No Group Found
							</p>
						)
					) : (
						""
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	};
};

const mapDispatchToProps = {
	updateUserGroups,
	updateUserTeams,
	updateShowSearchFilter,
	updateUserFollow,
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SearchResult)
);
