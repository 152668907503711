import React, { Component } from "react";
import { connect } from "react-redux";
import { updateShowSearchFilter, applySearchFilter } from "../actions";
import Modal from "react-bootstrap/Modal";
import profileBlank from "../assets/profile_blank.png";
import { getServerUrl } from "../utils/api";
import Avatar from "./common/avatar";

class SearchActivityFilter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			interest: props.interest,
			location: props.locationSearchFilter,
		};
	}

	handleChangeInterests = () => {
		let interest = [];

		if (this.cbSwimming.checked) interest = [...interest, "Swim"];
		if (this.cbRunning.checked) interest = [...interest, "Run"];
		if (this.cbWalking.checked) interest = [...interest, "Walk"];
		if (this.cbCycling.checked) interest = [...interest, "Bike"];
		if (this.cbLifting.checked) interest = [...interest, "Strength"];
		if (this.cbFlexibility.checked) interest = [...interest, "Flexibility"];
		if (this.cbRecovery.checked) interest = [...interest, "Recovery"];
		if (this.cbDuathlon.checked) interest = [...interest, "Duathlon"];
		if (this.cbTriathlon.checked) interest = [...interest, "Triathlon"];

		this.setState({ interest });
	};

	closeModal = () => {
		this.props.updateShowSearchFilter(false);
	};

	applyFilter = () => {
		if (this.state.interest.length > 0)
			this.props.applySearchFilter(
				this.state.interest,
				this.state.location
			);
		else alert("Please select at least one interest");
	};

	handleLocationChange = (e) => {
		this.setState({ location: e.target.value });
	};

	revertDefault = (ev) => {
		ev.preventDefault();
		this.setState({ interest: this.props.user.interest });
	};

	render() {
		let interest = this.state.interest;
		return (
			<Modal
				onHide={this.closeModal}
				size="lg"
				show={true}
				centered
				aria-labelledby="contained-modal-title-vcenter"
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="selactvty">
						<div className="lgnrgstr wizard-step-container">
							<h6 className="usrfltr text-center">
								<Avatar
									url={"/uploads/user/"}
									state={this.props.user.profilePicture}
									alt={"profile pic"}
								/>
								Your Preferred Interests{" "}
								<p className="text-center">
									<a
										href=""
										onClick={(ev) => this.revertDefault(ev)}
									>
										Filter results to default
									</a>
								</p>
							</h6>
							<div className="row">
								<div className="col-12">
									<label className="form-check">
										<img src="/uploads/images/swimming.svg" />{" "}
										Swimming
										<input
											type="checkbox"
											aria-label="swimming"
											className="form-check-input"
											value="Swim"
											onChange={
												this.handleChangeInterests
											}
											ref={(val) =>
												(this.cbSwimming = val)
											}
											checked={
												interest.includes("Swim")
													? true
													: false
											}
										/>
									</label>
								</div>
								<div className="col-12">
									<label className="form-check">
										<img src="/uploads/images/run.svg" />{" "}
										Running
										<input
											type="checkbox"
											aria-label="running"
											className="form-check-input"
											value="Run"
											onChange={
												this.handleChangeInterests
											}
											ref={(val) =>
												(this.cbRunning = val)
											}
											checked={
												interest.includes("Run")
													? true
													: false
											}
										/>
									</label>
								</div>
							</div>

							<div className="row">
								<div className="col-12">
									<label className="form-check">
										<img src="/uploads/images/walk.svg" />{" "}
										Walking
										<input
											type="checkbox"
											aria-label="walking"
											className="form-check-input"
											value="Walk"
											onChange={
												this.handleChangeInterests
											}
											ref={(val) =>
												(this.cbWalking = val)
											}
											checked={
												interest.includes("Walk")
													? true
													: false
											}
										/>
									</label>
								</div>
								<div className="col-12">
									<label className="form-check">
										<img src="/uploads/images/cycling.svg" />{" "}
										Cycling
										<input
											type="checkbox"
											aria-label="cycling"
											className="form-check-input"
											value="Bike"
											onChange={
												this.handleChangeInterests
											}
											ref={(val) =>
												(this.cbCycling = val)
											}
											checked={
												interest.includes("Bike")
													? true
													: false
											}
										/>
									</label>
								</div>
							</div>

							<div className="row">
								<div className="col-12">
									<label className="form-check">
										<img src="/uploads/images/lifting.svg" />{" "}
										Weight Lifting
										<input
											type="checkbox"
											aria-label="lifting"
											className="form-check-input"
											value="Strength"
											onChange={
												this.handleChangeInterests
											}
											ref={(val) =>
												(this.cbLifting = val)
											}
											checked={
												interest.includes("Strength")
													? true
													: false
											}
										/>
									</label>
								</div>
								<div className="col-12">
									<label className="form-check">
										<img src="/uploads/images/flexibility.svg" />{" "}
										Flexibility
										<input
											type="checkbox"
											aria-label="flexibility"
											className="form-check-input"
											value="Flexibility"
											onChange={
												this.handleChangeInterests
											}
											ref={(val) =>
												(this.cbFlexibility = val)
											}
											checked={
												interest.includes("Flexibility")
													? true
													: false
											}
										/>
									</label>
								</div>
							</div>

							<div className="row">
								<div className="col-12">
									<label className="form-check">
										<img src="/uploads/images/recovery.svg" />{" "}
										Recovery
										<input
											type="checkbox"
											aria-label="recovery"
											className="form-check-input"
											value="Recovery"
											onChange={
												this.handleChangeInterests
											}
											ref={(val) =>
												(this.cbRecovery = val)
											}
											checked={
												interest.includes("Recovery")
													? true
													: false
											}
										/>
									</label>
								</div>
								<div className="col-12">
									<label className="form-check">
										<img src="/uploads/images/dualthion.svg" />{" "}
										Duathlon
										<input
											type="checkbox"
											aria-label="duathlon"
											className="form-check-input"
											value="Duathlon"
											onChange={
												this.handleChangeInterests
											}
											ref={(val) =>
												(this.cbDuathlon = val)
											}
											checked={
												interest.includes("Duathlon")
													? true
													: false
											}
										/>
									</label>
								</div>
							</div>

							<div className="row">
								<div className="col-12">
									<label className="form-check">
										<img src="/uploads/images/trithalion.svg" />{" "}
										Triathlon
										<input
											type="checkbox"
											aria-label="triathlon"
											className="form-check-input"
											value="Triathlon"
											onChange={
												this.handleChangeInterests
											}
											ref={(val) =>
												(this.cbTriathlon = val)
											}
											checked={
												interest.includes("Triathlon")
													? true
													: false
											}
										/>
									</label>
								</div>
							</div>

							<div className="row">
								<div className="col-12">
									<hr />
								</div>
							</div>

							{/* <div className="row slcbx">
                                <div className="col-12">
                                    <label className="form-check">
                                        Select All Activities
                                        <input type="checkbox" aria-label="triathlon" className="form-check-input" value="Triathlon" onChange={this.handleChangeInterests} ref={(val) => (this.cbTriathlon = val)} checked={interest.includes("Triathlon") ? true : false} /> 
                                         
                                    </label>
                                </div> 
                            </div> */}
							{this.props.showLocationFilter && (
								<div className="row mt-2">
									<div className="col-12">
										<div className="form-group ">
											<label
												className="form-label"
												htmlFor="formTeamAddress"
											>
												Location
											</label>
											<div className="search-location-input">
												<div className="search-location-input">
													<input
														placeholder="Enter address"
														type="text"
														className="form-control pac-target-input"
														value={
															this.state.location
														}
														autoComplete="off"
														onChange={(e) =>
															this.handleLocationChange(
																e
															)
														}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>

						<div className="row ">
							<div className="col-12 text-center">
								<button onClick={this.applyFilter}>
									Apply Filter
								</button>
							</div>
						</div>

						{/* <p className="text-center"><a href="#">Filter results to default</a></p> */}
					</div>
				</Modal.Body>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		interest: state.auth.activityFilter,
		locationSearchFilter: state.auth.locationSearchFilter,
		showLocationFilter: state.auth.showLocationFilter,
	};
};

export default connect(mapStateToProps, {
	updateShowSearchFilter,
	applySearchFilter,
})(SearchActivityFilter);
