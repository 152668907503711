import React from "react";

const Message = ({ display, text }) => {
	return (
		<p
			style={{
				color: "red",
				fontSize: "13px",
				display: display ? "none" : "block",
			}}
		>
			{text}
		</p>
	);
};

export default Message;
