import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Importing icons and pictures
import loading from "../assets/loading2.svg";
import profileBlank from "../assets/profile_blank.png";

import LeftSideBar from "../components/LeftSideBar";

// Importing helper functions
import { fileToBase64 } from "../utils/helper";
import {
	getServerUrl,
	uploadPostMediaTemp,
	uploadPostMediaMain,
	getActivityByTitle,
	getPost,
	editPost,
	mobileQuery,
} from "../utils/api";
import TabToRemove from "../components/common/tabToRemove";

class EditQuestion extends Component {
	constructor(props) {
		super(props);

		let interestChecked = {
			Badminton: false,
			Cycling: false,
			Football: false,
			Gym: false,
			Running: false,
			Swimming: false,
			Tennis: false,
			Walking: false,
			Yoga: false,
		};

		this.state = {
			post: {},
			type: "text",
			description: "",
			interest: [],
			interestValue: "",
			file: null,
			video: null,
			section: {},
			loading: true,
			interestChecked: interestChecked,
			uploadedImage: "",
			uploadedVideo: "",
			imgs: [],
			imgsNew: [],
			imgsDateNow: [],
			imgsFile: [],
			videos: [],
			videosNew: [],
			videosDateNow: [],
			videosFile: [],
			isQuestion: "",
			imgsDeleted: [],
			videosDeleted: [],
		};

		this.uploadPostPictureRef = React.createRef();
		this.uploadPostVideoRef = React.createRef();
	}

	componentDidMount() {
		getPost(this.props.match.params.questionId).then((res) => {
			let post = res.post;
			if (post.postId) {
				this.setState({
					post,
					loading: false,
					type: post.type,
					description: post.description,
					interestValue: post.interest.name,
					section: post.section,
					isQuestion: post.isQuestion,
					imgs: post.imgFileName,
					videos: post.videoFileName,
				});
			}
		});
	}

	// Resets all interests back to unselected.
	resetInterest = () => {
		this.setState({
			interestChecked: {
				Badminton: false,
				Cycling: false,
				Football: false,
				Gym: false,
				Running: false,
				Swimming: false,
				Tennis: false,
				Walking: false,
				Yoga: false,
			},
		});
	};

	// Generates an array with all of the selected interests.
	compileInterests = () => {
		let interest = [];

		if (this.state.interestChecked.Badminton) interest.push("Badminton");

		if (this.state.interestChecked.Cycling) interest.push("Cycling");

		if (this.state.interestChecked.Football) interest.push("Football");

		if (this.state.interestChecked.Gym) interest.push("Gym");

		if (this.state.interestChecked.Running) interest.push("Running");

		if (this.state.interestChecked.Swimming) interest.push("Swimming");

		if (this.state.interestChecked.Tennis) interest.push("Tennis");

		if (this.state.interestChecked.Walking) interest.push("Walking");

		if (this.state.interestChecked.Yoga) interest.push("Yoga");

		return interest;
	};

	// Handles API call for creating a post.
	editPost = async (e) => {
		e.preventDefault();
		let editedPost = {
			type: this.state.type,
			description: this.state.description,
			editedTime: new Date(),
			interest: {},
			imgFileName: this.state.imgs.concat(this.state.imgsNew),
			videoFileName: this.state.videos.concat(this.state.videosNew),
			imgsDeleted: this.state.imgsDeleted,
			videosDeleted: this.state.videosDeleted,
			section: this.state.section,
		};

		// get interest data
		if (this.state.interestValue) {
			await getActivityByTitle(this.state.interestValue).then(
				(activity) => {
					if (activity._id) {
						editedPost.interest = {
							id: activity._id,
							name: activity.title,
							icon: activity.activity_icon,
						};
					}
				}
			);
		}

		this.setState(
			{
				loading: true,
			},
			() => {
				editPost(this.state.post.postId, editedPost).then(
					async (res) => {
						if (res) {
							this.state.imgsFile.forEach((file, index) => {
								this.uploadPostPictureToPosts(
									file,
									this.state.imgsDateNow[index]
								);
							});

							this.state.videosFile.forEach((file, index) => {
								this.uploadPostVideoToPosts(
									file,
									this.state.videosDateNow[index]
								);
							});

							this.setState({
								description: "",
								interests: [],
								loading: false,
								type: "text",
								uploadedImage: "",
								uploadedVideo: "",
								imgs: [],
								imgsNew: [],
								imgsDateNow: [],
								imgsFile: [],
								videos: [],
								videosNew: [],
								videosDateNow: [],
								videosFile: [],
							});
							this.resetInterest();

							/*{
                            !window.matchMedia('(max-width: 500px)').matches
                                ? window.location.reload()
                                : window.history.back();
                        }*/
							window.history.back();
						}
					}
				);
			}
		);
	};

	handleChange = () => {
		this.setState({ description: this.descriptionInput.value });
	};

	// Removing the photo from post.
	removePhoto = (filename) => {
		const newImgList = this.state.imgs.filter((img) => img !== filename);

		this.setState({
			type:
				newImgList.length > 0 ||
				this.state.imgsNew.length > 0 ||
				this.state.videos.length > 0 ||
				this.state.videosNew.length > 0
					? "image"
					: "text",
			imgs: newImgList,
			imgsDeleted: [...this.state.imgsDeleted, filename],
		});
	};

	removePhotoNew = (filename) => {
		let photoIndex = this.state.imgsNew.indexOf(filename);
		const newImgList = this.state.imgsNew.filter((img) => img !== filename);
		const newImgDateNowList = [...this.state.imgsDateNow];
		newImgDateNowList.splice(photoIndex, 1);

		const newImgFileList = [...this.state.imgsFile];
		newImgFileList.splice(photoIndex, 1);

		this.setState({
			uploadedImage: "",
			type:
				(newImgList.length > 0 ||
					this.state.imgs.length > 0 ||
					this.state.videos.length > 0 ||
					this.state.videosNew.length > 0) > 0
					? "image"
					: "text",
			imgsNew: newImgList,
			imgsDateNow: newImgDateNowList,
			imgsFile: newImgFileList,
		});
	};

	openUploadPostPicture = () => {
		this.uploadPostPictureRef.current.click();
	};

	uploadPostPictureToTemp = () => {
		let postPicture = this.uploadPostPictureRef.current.files[0];
		let fileTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

		if (postPicture === undefined) {
			alert("Please select image file to upload");
		} else if (fileTypes.indexOf(postPicture.type) === -1) {
			alert("Please select file type of JPEG, JPG, PNG or GIF");
		} else {
			const dateNow = Date.now();
			const imageData = new FormData();
			imageData.append("mediaDateNow", dateNow);
			imageData.append("mediaToTempUpload", postPicture);

			uploadPostMediaTemp(imageData).then((imgUpload) => {
				this.setState({
					uploadedImage: imgUpload.filename,
					type: "image",
					imgsFile: [...this.state.imgsFile, postPicture],
					imgsNew: [...this.state.imgsNew, imgUpload.filename],
					imgsDateNow: [...this.state.imgsDateNow, dateNow],
				});
			});
		}
	};

	uploadPostPictureToPosts = (postPicture, storedDateNow) => {
		const imageData = new FormData();
		imageData.append("mediaDateNow", storedDateNow);
		imageData.append("mediaToPostsUpload", postPicture);

		uploadPostMediaMain(imageData);
	};

	// Removing the video from post.
	removeVideo = (filename) => {
		const newVideoList = this.state.videos.filter(
			(video) => video !== filename
		);

		this.setState({
			type:
				newVideoList.length > 0 ||
				this.state.videosNew.length > 0 ||
				this.state.imgs.length > 0 ||
				this.state.imgsNew.length > 0
					? "image"
					: "text",
			videos: newVideoList,
			videosDeleted: [...this.state.videosDeleted, filename],
		});
	};

	removeVideoNew = (filename) => {
		let videoIndex = this.state.videosNew.indexOf(filename);

		const newVideoList = this.state.videosNew.filter(
			(video) => video !== filename
		);

		const newVideoDateNowList = [...this.state.videosDateNow];
		newVideoDateNowList.splice(videoIndex, 1);

		const newVideoFileList = [...this.state.videosFile];
		newVideoFileList.splice(videoIndex, 1);

		this.setState({
			uploadedVideo: "",
			type:
				newVideoList.length > 0 ||
				this.state.videos.length > 0 ||
				this.state.imgs.length > 0 ||
				this.state.imgsNew.length > 0
					? "image"
					: "text",
			videosNew: newVideoList,
			videosDateNow: newVideoDateNowList,
			videosFile: newVideoFileList,
		});
	};

	openUploadPostVideo = () => {
		this.uploadPostVideoRef.current.click();
	};

	uploadPostVideoToTemp = () => {
		let postVideo = this.uploadPostVideoRef.current.files[0];
		let fileTypes = [
			"video/mp4",
			"video/x-ms-wmv",
			"video/avi",
			"video/ogg",
			"video/webm",
		];

		if (postVideo === undefined) {
			alert("Please select image file to upload");
		} else if (fileTypes.indexOf(postVideo.type) === -1) {
			alert("Please select file type of MP4");
		} else {
			const dateNow = Date.now();
			const videoData = new FormData();
			videoData.append("mediaDateNow", dateNow);
			videoData.append("mediaToTempUpload", postVideo);

			uploadPostMediaTemp(videoData).then((videoUpload) => {
				this.setState({
					uploadedVideo: videoUpload.filename,
					type: "image",
					videosFile: [...this.state.videosFile, postVideo],
					videosNew: [...this.state.videosNew, videoUpload.filename],
					videosDateNow: [...this.state.videosDateNow, dateNow],
				});
			});
		}
	};

	uploadPostVideoToPosts = (postVideo, storedDateNow) => {
		const videoData = new FormData();
		videoData.append("mediaDateNow", storedDateNow);
		videoData.append("mediaToPostsUpload", postVideo);

		uploadPostMediaMain(videoData);
	};

	handleSelectionChange = (e) => {
		this.setState({ interestValue: e.target.value });
	};

	render() {
		const isMobile = window.matchMedia(mobileQuery).matches;

		return this.state.isQuestion ? (
			<>
				{isMobile && (
					<div className="teams-container">
						<a
							className="backbtn"
							onClick={(e) => {
								e.preventDefault();
								window.history.back();
							}}
						>
							{" "}
						</a>
						<h6>Edit Question</h6>
					</div>
				)}
				<div
					className={
						isMobile
							? "main-container createnew"
							: "container cntntbx"
					}
				>
					<div className={isMobile ? "" : "row"}>
						{isMobile ? null : (
							<div className="col-3">
								<LeftSideBar />
							</div>
						)}

						<div className={isMobile ? "" : "col-9 createnew"}>
							{isMobile && (
								<div className="userthumb mt-2">
									<span className="userbx">
										<img
											src={
												this.props.user.profilePicture
													? `${
															getServerUrl()
																.apiURL
													  }/uploads/user/${
															this.props.user
																.profilePicture
													  }`
													: profileBlank
											}
										/>
									</span>
									<span
										className={`${
											!isMobile ? "text-white" : ""
										}`}
									>{`${this.props.user.firstName} ${this.props.user.lastName}`}</span>
								</div>
							)}

							<form onSubmit={this.editPost}>
								<div className="form-group">
									<label
										className="form-label"
										htmlFor="postDescription"
									>
										Write Something
									</label>
									<textarea
										rows="3"
										id="postDescription"
										className="form-control"
										placeholder={"What's on your mind?"}
										ref={(val) =>
											(this.descriptionInput = val)
										}
										onChange={this.handleChange}
										value={this.state.description}
										required={
											this.state.imgs.length === 0 &&
											this.state.imgsNew.length === 0 &&
											this.state.videos.length == 0 &&
											this.state.videosNew.length === 0
												? true
												: false
										}
									></textarea>
								</div>

								<div className="form-group">
									<label
										className="form-label"
										htmlFor="interestSelection"
									>
										Interest
									</label>
									<select
										className="custom-select"
										id="interestSelection"
										onChange={this.handleSelectionChange}
										value={this.state.interestValue}
										required
									>
										<option value="" disabled>
											Choose one...
										</option>
										<option value="Swim">Swimming</option>
										<option value="Run">Running</option>
										<option value="Walk">Walking</option>
										<option value="Bike">Cycling</option>
										<option value="Strength">
											Weight Lifting
										</option>

										<option value="Flexibility">
											Flexibility
										</option>
										<option value="Recovery">
											Recovery
										</option>
										<option value="Duathlon">
											Duathlon
										</option>
										<option value="Triathlon">
											Triathlon
										</option>
									</select>
								</div>
								<div className="sclopts d-flex">
									<div className="text-center">
										<a
											onClick={() =>
												this.openUploadPostPicture()
											}
										>
											<input
												type="file"
												ref={this.uploadPostPictureRef}
												onChange={() =>
													this.uploadPostPictureToTemp()
												}
												onClick={(e) =>
													(e.target.value = null)
												}
												required={
													!this.state.description
														? true
														: false
												}
												style={{ display: "none" }}
											/>{" "}
											<img src="/uploads/images/pst-photo.svg" />
											<span>Photo</span>
										</a>
									</div>
									<div>|</div>
									<div className="text-center">
										<a
											onClick={() =>
												this.openUploadPostVideo()
											}
										>
											<input
												type="file"
												ref={this.uploadPostVideoRef}
												onChange={() =>
													this.uploadPostVideoToTemp()
												}
												onClick={(e) =>
													(e.target.value = null)
												}
												style={{ display: "none" }}
											/>{" "}
											<img src="/uploads/images/pst-video.svg" />
											<span>Video</span>
										</a>
									</div>
								</div>

								{this.state.imgs.map((img) => (
									<TabToRemove
										tabTo={"Click to remove"}
										removePhoto={this.removePhoto}
										logo={img}
										upload={"/uploads/posts/"}
									/>
								))}
								{this.state.imgsNew.map((img) => (
									<TabToRemove
										tabTo={"Click to remove"}
										removePhoto={this.removePhotoNew}
										logo={img}
										upload={"/uploads/temp/"}
									/>
								))}

								{this.state.videos.map((video) => {
									let type = "";
									if (video.endsWith(".avi"))
										type = "video/avi";
									else if (video.endsWith(".mp4"))
										type = "video/mp4";
									else if (video.endsWith(".wmv"))
										type = "video/x-ms-wmv";
									else if (video.endsWith(".ogg"))
										type = "video/ogg";
									else if (video.endsWith(".webm"))
										type = "video/webm";

									return (
										<div
											key={video}
											className="new-post-uploaded-image mt-1"
										>
											<div
												onClick={() =>
													this.removeVideo(video)
												}
											>
												Click to remove
											</div>

											<video
												width="100%"
												height="240"
												controls
											>
												<source
													src={`${
														getServerUrl().apiURL
													}/uploads/posts/${video}`}
													type={type}
												></source>
											</video>
										</div>
									);
								})}

								{this.state.videosNew.map((video) => {
									let type = "";
									if (video.endsWith(".avi"))
										type = "video/avi";
									else if (video.endsWith(".mp4"))
										type = "video/mp4";
									else if (video.endsWith(".wmv"))
										type = "video/x-ms-wmv";
									else if (video.endsWith(".ogg"))
										type = "video/ogg";
									else if (video.endsWith(".webm"))
										type = "video/webm";

									return (
										<div
											key={video}
											className="new-post-uploaded-image mt-1"
										>
											<div
												onClick={() =>
													this.removeVideoNew(video)
												}
											>
												Click to remove
											</div>

											<video
												width="100%"
												height="240"
												controls
											>
												<source
													src={`${
														getServerUrl().apiURL
													}/uploads/temp/${video}`}
													type={type}
												></source>
											</video>
										</div>
									);
								})}

								<button className="button subbtn">
									Update Your Question
								</button>
							</form>
						</div>
					</div>
				</div>
			</>
		) : (
			<>
				{isMobile && (
					<div className="teams-container">
						<a
							className="backbtn"
							onClick={(e) => {
								e.preventDefault();
								window.history.back();
							}}
						>
							{" "}
						</a>
						<h6>Edit Question</h6>
					</div>
				)}
				<div className="main-container createnew">
					<div className="userthumb mt-2">
						<span className="userbx">
							<img
								src={
									this.props.user.profilePicture
										? `${
												getServerUrl().apiURL
										  }/uploads/user/${
												this.props.user.profilePicture
										  }`
										: profileBlank
								}
							/>
						</span>
						<span
							className={`${!isMobile ? "text-white" : ""}`}
						>{`${this.props.user.firstName} ${this.props.user.lastName}`}</span>
					</div>
					<h3>Write a question</h3>
					<form onSubmit={this.createPost}>
						<div className="form-group">
							<textarea
								rows="3"
								id="postDescription"
								className="form-control"
								ref={(val) => (this.descriptionInput = val)}
								onChange={this.handleChange}
								value={this.state.description}
								required
							></textarea>
						</div>

						<div className="form-group">
							<label
								className="form-label"
								htmlFor="interestSelection"
							>
								Interest
							</label>
							<select
								className="custom-select"
								id="interestSelection"
								onChange={this.handleSelectionChange}
								value={this.state.interestValue}
								required
							>
								<option value="" disabled>
									Choose one...
								</option>
								<option value="Swim">Swimming</option>
								<option value="Run">Running</option>
								<option value="Walk">Walking</option>
								<option value="Bike">Cycling</option>
								<option value="Strength">Weight Lifting</option>
								<option value="Flexibility">Flexibility</option>
								<option value="Recovery">Recovery</option>
								<option value="Duathlon">Duathlon</option>
								<option value="Triathlon">Triathlon</option>
							</select>
						</div>

						<button className="button subbtn">
							Update Your Question
						</button>
					</form>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	};
};

export default withRouter(connect(mapStateToProps, {})(EditQuestion));
