import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Post from "./Post";

const getOwner = (owners, userId) => {
	return owners !== undefined && owners.length > 0
		? owners.find((owner) => owner.userId === userId) || "Unknown"
		: "Unknown";
};

const Feed = ({
	posts,
	owners,
	commentsOwner,
	editPost,
	deletePost,
	showPopup,
	notGeneral,
	createComment,
	changeKudos,
	openPictureViewer,
	isQuestionPage,
	hasActionPermission,
	loading,
}) => {
	const [sortedPosts, setSortedPosts] = useState([]);

	useEffect(() => {
		// Sort the posts by date when the component receives new posts
		setSortedPosts(
			posts?.sort((a, b) => Date.parse(b.time) - Date.parse(a.time))
		);
	}, [posts]);
	return (
		<div className="feed-container">
			{sortedPosts?.length > 0 ? (
				sortedPosts.map((post) =>
					!post.hasOwnProperty("status") ||
					post.status === "active" ? (
						<Post
							key={`${post._id}-${post.postId}`}
							post={post}
							owner={getOwner(owners, post.userId)}
							commentsOwner={
								commentsOwner !== undefined
									? commentsOwner
									: undefined
							}
							editPost={editPost}
							deletePost={deletePost}
							showPopup={showPopup}
							notGeneral={notGeneral}
							createComment={createComment}
							changeKudos={changeKudos}
							openPictureViewer={openPictureViewer}
							isQuestionPage={isQuestionPage}
							hasActionPermission={
								hasActionPermission !== undefined
									? hasActionPermission
									: true
							}
						/>
					) : null
				)
			) : loading && loading === true ? (
				<div className="feed-container-empty"></div>
			) : (
				<div className="feed-container-empty">
					There are no posts right now.
				</div>
			)}
		</div>
	);
};

export default connect()(Feed);
